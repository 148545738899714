import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    width: "533px",
    color: "#313131",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "15px",
    boxShadow: theme.shadows[5],

    "&:focus": {
      outline: "none",
    },
  },
  paper: {
    padding: theme.spacing(0, 4, 0),
  },
  archiveLabel: {
    fontFamily: "'Avenir Book', sans-serif !important",
    lineHeight: "22px",
    textTransform: "capitalize",
    textDecoration: "underline",
    color: "#abadad",
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0px -32px",
    padding: "0 20px",
    height: 68,
    background: "#ABADAD",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  body: {
    overflowX: "hidden",
    overflowY: "scroll",
  },
  formControl: {
    width: "100%",
    height: "400px",
    "& > :last-child": {
      paddingBottom: "20px",
    },
  },
  print: {
    fontSize: "30px",
    lineHeight: "41px",
    color: "#000000",
    fontFamily: "'Avenir Black', sans-serif",
    fontWeight: 900,
  },
  period: {
    fontFamily: "AvenirNext",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#000000",

    "& > :first-child": {
      marginLeft: "15px",
    },
  },
  printmode: {
    display: "flex",
    alignItems: "center",

    "& > :first-child": {
      marginRight: "50px",
    },
  },
  icon: {
    color: "#000",
    cursor: "pointer",
    fontSize: "30px",
    fontWeight: "bold",
    transition: "all .3s ease",
  },
  description: {
    fontFamily: "'Avenir Black', sans-serif",
    fontWeight: 900,
    fontSize: "15px",
    lineHeight: "20px",
    color: "#313131",
    marginTop: 38,
  },
  stats: {
    width: "96%",
    marginTop: "34px",

    "& > :first-child": {
      fontFamily: "'Avenir Black', sans-serif",
      fontWeight: 900,
      fontSize: "15px",
      lineHeight: "20px",
      marginBottom: "22px",
    },
  },
  statsBreakdown: {
    display: "flex",
    justifyContent: "space-between",
  },
  statsContent: {
    fontSize: "14px",
    lineHeight: "17px",
    textTransform: "capitalize",

    "& > *": {
      display: "flex",
      alignItems: "center",
    },

    "& > *:not(:last-child)": {
      marginBottom: "24px",
    },
  },

  checkBox: {
    padding: 0,
    marginRight: "16px",

    "&.MuiCheckbox-root": {
      fontSize: "2rem",
    },

    "&.MuiCheckbox-colorPrimary.Mui-checked": {
      color: "#000",
    },
    "&:hover": {
      background: "rgba(0,0,0,0.3) !important",
    },
  },
  line: {
    opacity: " 0.3",
    height: "1px",
    background: "#979797",
  },

  bottom: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "74px",
    "& > div > :last-child": {
      marginRight: "32px",
      marginLeft: "16px",
    },
  },
  info: {
    display: "flex",
    justifyContent: "space-around",
    fontFamily: "Avenir Book, sans-serif",
    fontSize: 12,
    backgroundColor: "#ABADAD",
    minWidth: 80,
  },
  arrow: {
    color: "#ABADAD",
  },
}));
