/**
 * @prettier
 */
import { DB } from "firebase-tools/db";
import { IEvent } from "interfaces/IEvent";

export class EventService extends DB {
  private _collection: string = "events";
  private _doc: string = "";
  constructor(doc?: string) {
    super();
    if (doc) {
      this.doc = doc;
    }
  }
  set doc(value: string) {
    this._doc = value;
  }
  set collection(value: string) {
    this._collection = value;
  }

  get doc() {
    return this._doc;
  }
  get collection() {
    return this._collection;
  }

  async addEvent(event: IEvent): Promise<boolean> {
    return this.set(this.collection, this.doc, event);
  }

  async updateEvent(event: any): Promise<boolean> {
    return this.update(this.collection, this.doc, event);
  }

  async deleteEvent(): Promise<boolean> {
    return this.delete(this.collection, this.doc);
  }
}
