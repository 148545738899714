import styled from "styled-components";

import { ReactComponent as CarrouselIcon } from "static/media/carrousel.svg";
import { ReactComponent as VideoCamIcon } from "static/media/videoCam.svg";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 28px;
  margin-top: 50px;
  @media screen and (max-width: 1200px) {
    grid-gap: 20px;
  }
  @media screen and (max-width: 768px) {
    grid-gap: 10px;
  }
`;
export const Overlay = styled.div`
  opacity: 0;
  cursor: pointer;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.15);
`;
export const MediaContainer = styled.div`
  background: #fff;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 360px;
  display: flex;
  cursor: pointer;
  &:hover #overlay {
    opacity: 1;
  }
  ::before {
    content: "";
    padding-bottom: 100%;
    display: inline-block;
    vertical-align: top;
  }

  @media screen and (max-width: 1060px) {
    max-height: 293px;
  }
  @media screen and (max-width: 900px) {
    max-height: 250px;
  }
  @media screen and (max-width: 700px) {
    max-height: 190px;
  }
`;
export const CarouselIcon = styled(CarrouselIcon)`
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px 5px 0px;
  fill: #fff;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
`;
export const VideoIcon = styled(VideoCamIcon)`
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px 5px 0px;
  fill: #fff;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
`;
