//this file will need refactoring <Tooltip></Tooltip> has been repeated
import React, { FC, useEffect } from "react";
import {
  Box,
  Checkbox,
  Grid,
  Input,
  MenuItem,
  Select,
  useTheme,
} from "@material-ui/core";

import { ControlLabel } from "components/styled/Common";

import { useStylesSelect } from "./stylesSelect";

interface IChannels {
  defaultValue?: string;
  value: any;
  options: Array<string | number>;
  label?: string;
  multiple: boolean;
  setItems: (e: any) => void;
  setError: (e: any) => void;
  setCategoryOption: (data: any) => void;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function getStyles(name, channels, theme) {
  return {
    fontWeight:
      channels.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ChannelsV2: FC<IChannels> = ({
  value,
  label,
  options,
  setItems,
  setError,
  defaultValue = "Select...",
  multiple = true,
  setCategoryOption,
}) => {
  const theme = useTheme();
  const classes = useStylesSelect();

  useEffect(() => {
    const mapCategories = () => {
      const selectedChannelNames = value.map((channel) =>
        channel.toLowerCase()
      );
      const selectedCategories = [
        ...(selectedChannelNames.includes("facebook")
          ? [
              "Facebook Feed",
              "Facebook Story",
              "Facebook Reel",
              "Facebook Live",
              "Facebook Group Post",
            ]
          : []),
        ...(selectedChannelNames.includes("instagram")
          ? [
              "Instagram Feed",
              "Instagram Story",
              "Instagram Reels",
              "Instagram Live",
              "Instagram Broadcast Channel",
            ]
          : []),
        ...(selectedChannelNames.includes("twitter")
          ? ["Twitter Feed", "Twitter Thread", "Twitter Poll"]
          : []),
        ...(selectedChannelNames.includes("tiktok")
          ? ["TikTok Feed", "TikTok Live"]
          : []),
        ...(selectedChannelNames.includes("youtube")
          ? [
              "Youtube Feed",
              "YouTube Community Tab",
              "YouTube Live",
              "YouTube Short",
            ]
          : []),
        ...(selectedChannelNames.includes("snapchat")
          ? ["Snapchat Spotlight", "Snapchat Story"]
          : []),
        ...(selectedChannelNames.includes("threads") ? ["Threads Feed"] : []),
        ...(selectedChannelNames.includes("discord")
          ? ["Discord Channel Message"]
          : []),
        ...(selectedChannelNames.includes("all")
          ? [
              "Facebook Feed",
              "Facebook Story",
              "Facebook Reel",
              "Facebook Live",
              "Facebook Group Post",
              "Instagram Feed",
              "Instagram Story",
              "Instagram Reels",
              "Instagram Live",
              "Instagram Broadcast Channel",
              "Twitter Feed",
              "Twitter Thread",
              "Twitter Poll",
              "TikTok Feed",
              "TikTok Live",
              "Threads Feed",
              "Snapchat Spotlight",
              "Snapchat Story",
              "Discord Channel Message",
              "Youtube Feed",
              "YouTube Community Tab",
              "YouTube Live",
              "YouTube Short",
            ]
          : []),
      ];
      setCategoryOption(selectedCategories);
    };

    mapCategories();
  }, [value, setCategoryOption]);

  const handleChange = (event) => {
    setError({
      textError: "",
      linkErr: "",
      platformError: "",
      instagramError: "",
      titleError: "",
      channelsError: "",
    });
    let channels = event.target.value;

    if (channels.includes("all")) {
      channels = [
        "all",
        "instagram",
        "facebook",
        "twitter",
        "tiktok",
        "youtube",
        "discord",
        "snapchat",
        "threads",
      ];
    }
    setItems(channels);
    // setSelectedChannels(channels);
  };

  return (
    <Grid
      id="client-list"
      style={{
        // display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <ControlLabel component="span">{label}</ControlLabel>
      <Box>
        <Select
          disableUnderline
          autoWidth={true}
          id="multi-select"
          multiple={multiple}
          value={value}
          displayEmpty={true}
          renderValue={() =>
            !multiple
              ? value
                ? value
                : defaultValue
              : !!value.length
              ? value.join(", ")
              : "Select Channel"
          }
          onChange={handleChange}
          input={<Input />}
          className={classes.select}
          inputProps={{
            className: classes.selectInput,
            classes: {
              icon: classes.selectIcon,
              iconOpen: classes.selectIconOpen,
              selectMenu: classes.selectOverrideChannels,
            },
          }}
          MenuProps={{
            classes: { paper: classes.selectOverrideChannels },
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 5.2 + ITEM_PADDING_TOP,
                width: 200,
                marginTop: "8px",
                borderRadius: 15,
              },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            getContentAnchorEl: null,
          }}
        >
          {options.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, value, theme)}
            >
              <Checkbox
                checked={value?.map((val) => val?.toLowerCase()).includes(name)}
                style={{
                  color: "rgba(0, 0, 0, 0.54)",
                }}
              />
              {name}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Grid>
  );
};

export default ChannelsV2;
