import React, { FC } from "react";
import {
  Tooltip,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  Paper,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import { useStylesV2 } from "../stylesV2";
import { numberWithCommas } from "utils/numberFormat";

const YouTubeTop10: FC<{ posts: Array<any> }> = ({ posts }) => {
  const classes = useStylesV2({});

  return (
    <div id="YouTube-Top 10 Most Viewed Videos">
      <div
        className={classes.statsName}
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "20px",
          padding: "30px 0px 25px 0px",
          fontFamily: "Avenir Book, sans-serif",
          fontWeight: 900,
        }}
      >
        <span>Top 10 Most Viewed Videos</span>
        <Tooltip
          title={"Top 10 videos for the selected period."}
          arrow
          interactive
          classes={{ tooltip: classes.info }}
        >
          <InfoIcon />
        </Tooltip>
      </div>
      <TableContainer
        component={Paper}
        style={{
          boxShadow: "0px 5px 45px rgba(0, 0, 0, 0.07)",
          borderRadius: "15px",
        }}
      >
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow
              className={classes.tableHeader}
              style={{
                padding: 200,
              }}
            >
              <TableCell className={classes.tableCell} align="left">
                Video Title
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                Views
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                Likes
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                Dislikes
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                Comments
              </TableCell>
              {/* <TableCell
                className={classes.tableCell}
                align="center"
              ></TableCell> */}
            </TableRow>
          </TableHead>
          {/* Table Body */}
          <TableBody>
            {posts.map((post) => (
              <TableRow key={post.videoLink} className={classes.row}>
                <TableCell align="left" className={classes.tableCell2}>
                  <div className={`${classes.tableRow}`}>
                    <span className={classes.tableRowDetails}>
                      <a
                        href={post.videoLink}
                        className={classes.postDetails}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {post.videoTitle} [{post.duration}]
                      </a>
                    </span>
                  </div>
                </TableCell>
                <TableCell align="center" className={classes.tableCell2}>
                  <div className={`${classes.tableRow}`}>
                    <span className={classes.tableRowDetails}>
                      {numberWithCommas(parseInt(post.viewCount))}
                    </span>
                  </div>
                </TableCell>
                <TableCell align="center" className={classes.tableCell2}>
                  <div className={`${classes.tableRow}`}>
                    <span className={classes.tableRowDetails}>
                      {numberWithCommas(parseInt(post.likeCount))}
                    </span>
                  </div>
                </TableCell>
                <TableCell align="center" className={classes.tableCell2}>
                  <div className={`${classes.tableRow}`}>
                    <span className={classes.tableRowDetails}>
                      {numberWithCommas(parseInt(post.dislikeCount))}
                    </span>
                  </div>
                </TableCell>
                <TableCell align="center" className={classes.tableCell2}>
                  <div className={`${classes.tableRow}`}>
                    <span className={classes.tableRowDetails}>
                      {numberWithCommas(parseInt(post.commentCount))}
                    </span>
                  </div>
                </TableCell>

                {/* <TableCell align="center">
                  <div className={`${classes.post} ${classes.tableCell}`}>
                    <a
                      href={post.videoLink}
                      className={classes.postDetails}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        disableRipple
                        type="button"
                        classes={{
                          label: classes.archiveLabel,
                        }}
                        style={{
                          marginTop: "-6px",
                        }}
                      >
                        URL
                      </Button>
                    </a>
                  </div>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default YouTubeTop10;
