import React from "react";

export const EditIcon = (props) => {
  const { width = "14", height = "13", color = "#000" } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.410156 10.2186V12.8748H3.06641L10.9006 5.04065L8.24432 2.3844L0.410156 10.2186ZM12.9547 2.98648C13.231 2.71023 13.231 2.26398 12.9547 1.98773L11.2972 0.330234C11.021 0.0539844 10.5747 0.0539844 10.2985 0.330234L9.00224 1.62648L11.6585 4.28273L12.9547 2.98648Z"
        fill={color}
      />
    </svg>
  );
};
