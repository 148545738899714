import React, { FC, useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Button } from "@material-ui/core";
import Cancel from "@material-ui/icons/Cancel";

import CarouselStyles from "components/styled/Carousel";
import { useStyles } from "./styles";
import Video from "../Video";
import {
  handleDeleteVideo,
  handleDeleteImage,
} from "../../BePlanned/helpers/posts";
import Image from "../Image/Image";

export interface CarouselProps {
  images: Array<any>;
  iconClass?: string;
  setImages?: (images) => void;
  post?: any;
  platform?: any;
  edit?: any;
  video: string;
  setLink?: (link) => void;
  setVideo?: (video) => void;
  setLinkInput?: (input) => void;
}

export const formatDropboxLink = (link: string) => {
  if (link.includes("?dl=0") || link.includes("?dl=1")) {
    let dropboxLink: any = link.split("?");
    dropboxLink = dropboxLink[0] + "?raw=1";
    return dropboxLink;
  }
};

export const CarouselItem: FC<CarouselProps> = ({
  images,
  iconClass,
  setImages,
  post,
  platform,
  edit,
  video,
  setVideo,
  setLink,
  setLinkInput,
}) => {
  const classes = useStyles({ edit });

  const vimeoRegex = /^(http:\/\/|https:\/\/)(vimeo\.com)\/([\w]+)?$/;
  const youtubeRegex = /(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/;
  const getImages = () => {
    let data = images.map((image, i) =>
      image !== "" ? (
        <div key={i}>
          {iconClass && (
            <Button
              disableRipple
              className={classes.close}
              onClick={() =>
                handleDeleteImage(post, image, images, setImages, edit)
              }
            >
              <Cancel />
            </Button>
          )}

          <Image src={image} alt={`post pic ${i + 1}`} image />
        </div>
      ) : null
    );
    data = data.filter((item) => item !== null);
    return data;
  };

  const [data, setData]: any = useState(getImages());

  useEffect(() => {
    const data = getImages();
    if (
      (video &&
        video?.includes("http") &&
        (vimeoRegex.test(video) || youtubeRegex.test(video))) ||
      (video && video?.includes("http") && video.includes("firebasestorage")) ||
      (video && video.includes("dropbox"))
    ) {
      data.unshift(
        <Video
          video={video.includes("dropbox") ? formatDropboxLink(video) : video}
          deleteVideo={handleDeleteVideo}
          isEdit={edit}
          platform={platform}
          post={post}
          setVideo={setVideo}
          setLink={setLink}
          setLinkInput={setLinkInput}
          deleteIcon={iconClass}
          key={`${video}`}
        />
      );
    }
    setData(data);
    //eslint-disable-next-line
  }, [images, video]);
  return (
    <CarouselStyles isEdit={edit} isCarousel={data.length > 1}>
      {data.length === 1 ? (
        <div style={{ width: "100%" }}>
          {video === "" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
              }}
            >
              {getImages()}
            </div>
          ) : (
            <>
              <Video
                video={
                  video.includes("dropbox") ? formatDropboxLink(video) : video
                }
                deleteVideo={handleDeleteVideo}
                isEdit={edit}
                platform={platform}
                post={post}
                setVideo={setVideo}
                setLink={setLink}
                setLinkInput={setLinkInput}
                deleteIcon={iconClass}
                isCarousel={data.length > 1}
              />
            </>
          )}
        </div>
      ) : (
        <CarouselStyles isEdit={edit}>
          <Carousel
            dynamicHeight={false}
            showThumbs={false}
            autoPlay={false}
            showArrows={data.length > 1}
            showIndicators={data.length > 1}
            showStatus={false}
            className={classes.container}
            infiniteLoop={true}
          >
            {data}
          </Carousel>
        </CarouselStyles>
      )}
    </CarouselStyles>
  );
};
