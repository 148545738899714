import { firestore } from "firebase-tools/firebase";
import { navigate } from "@reach/router";
import { InstagramSearchAgentArgs } from "./launchAutoFollowAgent";

export const editInstagramSearchAgent = async (
  id: string,
  data: Partial<InstagramSearchAgentArgs>
) => {
  try {
    await firestore
      .collection("instagram-autofollow-campaigns")
      .doc(id)
      .update({ ...data, status: "new" });
    navigate(`/campaigns/organic/instagram/${id}`);
  } catch (error) {
    console.log(error, "RESPONSE ERROR");
  }
};
