/**
 * @prettier
 */
import moment from "moment";
import { firestore, storage } from "./firebase";

export class DB {
  private db: firebase.firestore.Firestore;
  constructor() {
    this.db = firestore;
  }

  public get(
    collectionName: string,
    orderField: string = "createdAt",
    orderDirection: firebase.firestore.OrderByDirection = "desc"
  ): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      this.db
        .collection(collectionName)
        .orderBy(orderField, orderDirection)
        .get()
        .then((query: any) => {
          return resolve(
            query.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }))
          );
        }, reject);
    });
  }

  public getbyArray(
    collectionName: string,
    fieldName,
    arrayValues,
    operation: firebase.firestore.WhereFilterOp = "in"
  ): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      this.db
        .collection(collectionName)
        .where(fieldName, operation, arrayValues)
        .get()
        .then((query: any) => {
          return resolve(
            query.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }))
          );
        }, reject);
    });
  }

  public getByField(
    collectionName: string,
    fieldName: string,
    value: any,
    fieldName2?: string,
    value2?: any,
    operation: firebase.firestore.WhereFilterOp = "==",
    orderField: string = "createdAt",
    orderDirection: firebase.firestore.OrderByDirection = "desc"
  ): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      fieldName2
        ? this.db
            .collection(collectionName)
            .where(fieldName, operation, value)
            .where(fieldName2, operation, value2)
            .orderBy(orderField, orderDirection)
            .get()
            .then((query: any) => {
              return resolve(
                query.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }))
              );
            }, reject)
        : this.db
            .collection(collectionName)
            .where(fieldName, operation, value)
            .orderBy(orderField, orderDirection)
            .get()
            .then((query: any) => {
              return resolve(
                query.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }))
              );
            }, reject);
    });
  }

  protected getByActiveUser(
    collectionName: string,
    fieldName: string,
    value: any,
    operation: firebase.firestore.WhereFilterOp = "==",
    orderField2?: any,
    orderField: string = "createdAt",
    orderDirection: firebase.firestore.OrderByDirection = "desc"
  ): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      this.db
        .collection(collectionName)
        .where(fieldName, operation, value)
        .orderBy(orderField2, orderDirection)
        .orderBy(orderField, orderDirection)
        .get()
        .then((query: any) => {
          return resolve(
            query.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }))
          );
        }, reject);
    });
  }

  public metricsByDate = async (
    collectionName: string,
    fieldValue: string,
    from: any,
    to: any,
    fieldName2?: string,
    fieldValue2?: string,
    fieldName: string = "username",
    dateField: string = "created_at",
    sortType: firebase.firestore.OrderByDirection = "asc",
    operation: firebase.firestore.WhereFilterOp = "=="
  ): Promise<Array<any>> => {
    return new Promise((resolve, reject) => {
      fieldName2
        ? this.db
            .collection(collectionName)
            .where(fieldName, operation, fieldValue)
            .where(fieldName2, operation, fieldValue2)
            .where(dateField, ">=", adjustForTimezone(from))
            .where(dateField, "<=", adjustForTimezone(to))
            .orderBy(dateField, sortType)
            .get()
            .then((query: any) => {
              return resolve(
                query.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }))
              );
            }, reject)
        : this.db
            .collection(collectionName)
            .where(fieldName, operation, fieldValue)
            .where(dateField, ">=", adjustForTimezone(from))
            .where(dateField, "<=", adjustForTimezone(to))
            .orderBy(dateField, sortType)
            .get()
            .then((query: any) => {
              return resolve(
                query.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }))
              );
            }, reject);
    });
  };

  public getLastUpdated = async (collectionName: string) => {
    return new Promise<Date | undefined>((resolve, reject) => {
      this.db
        .collection(collectionName)
        .orderBy("created_at", "desc")
        .limit(1)
        .get()
        .then((query: any) => {
          const lastUpdated = new Date(query.docs[0].data().created_at);
          return resolve(
            isNaN(lastUpdated.getTime()) ? undefined : lastUpdated
          );
        }, reject);
    });
  };

  protected getDoc(collectionName: string, doc: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.db
        .collection(collectionName)
        .doc(doc)
        .get()
        .then((doc: any) => {
          return resolve({ ...doc.data(), id: doc.id });
        }, reject);
    });
  }

  protected set(
    collectionName: string,
    doc: string,
    data: any
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.db
        .collection(collectionName)
        .doc(doc)
        .set(data)
        .then(
          () => resolve(true),
          () => reject(false)
        );
    });
  }

  protected async set2(
    collectionName: string,
    doc: string,
    data: any
  ): Promise<string | null> {
    try {
      await this.db.collection(collectionName).doc(doc).set(data);
      return doc;
    } catch (error) {
      console.error("Error occurred while adding post:", error);
      return null;
    }
  }
  protected update(
    collectionName: string,
    documentId: string,
    data: object
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.db
        .collection(collectionName)
        .doc(documentId)
        .update(data)
        .then(
          () => resolve(true),
          () => reject(false)
        );
    });
  }

  protected delete(collectionName: string, doc: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.db
        .collection(collectionName)
        .doc(doc)
        .delete()
        .then(
          () => resolve(true),
          () => reject(false)
        );
    });
  }

  protected static getStorage(storageName: string) {
    return storage.ref(storageName);
  }
}

const adjustForTimezone = (date: Date) => {
  return new Date(moment(date).format("YYYY-MM-DD"));
  // const dt = new Date(date);
  // return new Date(dt.valueOf() - dt.getTimezoneOffset() * 60 * 1000);
};
