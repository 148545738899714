/**
 * @prettier
 */
import { DB } from "firebase-tools/db";
import { IAgent } from "interfaces/IAgent";

export class AgentService extends DB {
  private collectionName: string = "client-agents";
  private _doc: string = "";
  constructor(doc?: string) {
    super();
    if (doc) {
      this.doc = doc;
    }
  }
  set doc(value: string) {
    this._doc = value;
  }

  get doc() {
    return this._doc;
  }
  async getAgentByDoc(): Promise<IAgent> {
    return this.getDoc(this.collectionName, this.doc);
  }

  async getAgentByField(
    fieldName: string,
    value: any,
    fieldName2?: string,
    value2?: any,
    operation: firebase.firestore.WhereFilterOp = "==",
    orderField: string = "created_at"
  ): Promise<Array<IAgent>> {
    return this.getByField(
      this.collectionName,
      fieldName,
      value,
      fieldName2,
      value2,
      operation,
      orderField
    );
  }

  async addAgent(agent: IAgent): Promise<boolean> {
    return this.set(this.collectionName, this.doc, agent);
  }

  async updateAgent(agent: any): Promise<boolean> {
    return this.update(this.collectionName, this.doc, agent);
  }

  async deleteAgent(): Promise<boolean> {
    return this.delete(this.collectionName, this.doc);
  }
}
