export const deleteEmail = async (
  userService: any,
  user: any,
  email: string
) => {
  const secEmails = user.secondaryEmails.filter(
    (item: any) => item.email !== email
  );
  user.secondaryEmails = secEmails;
  await userService.updateUser(user);
};
