import React, { FC } from "react";
import countryName from "country-name";

import AudienceProgress from "./AudienceProgress";

interface Props {
  metrics: {
    areas: Array<any>;
    max: number;
  };
  type: string;
}
const locationColors = [
  "linear-gradient(270deg, #F093FB -32.58%, #F5576C 100%)",
  "linear-gradient(190.11deg, #83D600 6.85%, #18AD49 125.86%)",
  "linear-gradient(123.25deg, #F76B1C -12.38%, #FAD961 166.03%)",
  "linear-gradient(163.38deg, #8B9DDD -9.75%, #6E86DA 84.22%)",
  "linear-gradient(161.31deg, #FF4F4F -9.75%, #F76060 84.22%)",
  "linear-gradient(180deg, #FFF6BA 0%, #F8E46E 100%)",
];
export const DemoAreas: FC<Props> = ({ metrics, type }) => {
  return (
    <>
      {metrics.areas?.map((area: [string, number, number], index: number) => {
        const data = {
          name:
            type === "country"
              ? countryName(area.length ? area[0] : "", "en") || "Others"
              : area[0],
          pastValue: area[1],
          value: area[2],
        };
        return (
          <AudienceProgress
            color={locationColors[index]}
            data={data}
            compareValue={metrics.max}
            key={index}
          />
        );
      })}
    </>
  );
};
