import React, { ChangeEvent, FC } from "react";
import { Grid, Box, Input, Select, MenuItem } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import { ControlLabel } from "components/styled/Common";
import { useStyles } from "./styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function getStyles(name, channels, theme) {
  return {
    fontWeight:
      channels.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
interface SearchProps {
  value: any;
  setItems: (e: any) => void;
  options: Array<string | number>;
  label?: string;
  multiple: boolean;
  defaultValue?: string;
  width?: string;
  labelStyle?: any;
  onChangeHandler?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const MultiSelect: FC<SearchProps> = ({
  value,
  setItems,
  options,
  label,
  width,
  defaultValue = "Select...",
  multiple = true,
  labelStyle = null,
  onChangeHandler,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  let style = width ? { width } : {};

  const handleChange = (event) => {
    setItems(event.target.value);
    if (onChangeHandler && typeof onChangeHandler === "function") {
      onChangeHandler(event);
    }
  };
  return (
    <Grid
      id="client-list"
      style={{
        // display: "flex",
        ...(width ? {} : { display: "flex" }),
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <ControlLabel component="span" style={labelStyle ? labelStyle : {}}>
        {label}
      </ControlLabel>
      <Box>
        <Select
          disableUnderline
          autoWidth={true}
          id="multi-select"
          multiple={multiple}
          value={value}
          displayEmpty={true}
          renderValue={() =>
            !multiple
              ? value
                ? value
                : defaultValue
              : !!value.length
              ? value.join(", ")
              : "Default (all)"
          }
          onChange={handleChange}
          input={<Input />}
          className={classes.select}
          inputProps={{
            className: classes.selectInput,
            classes: {
              icon: classes.selectIcon,
              iconOpen: classes.selectIconOpen,
              selectMenu: classes.selectOverrideChannels,
            },
          }}
          style={style}
          MenuProps={{
            classes: { paper: classes.selectOverrideChannels },
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 5.2 + ITEM_PADDING_TOP,
                width: width ? 200 : 170,
                marginTop: "8px",
                borderRadius: 15,
              },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            getContentAnchorEl: null,
          }}
        >
          {options.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, value, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Grid>
  );
};

export default MultiSelect;
