/**
 * @prettier
 */

import React, { FunctionComponent, useEffect } from "react";
import { Navbar } from "./Navbar";
import { PageType } from "types/PageType";
import { RouteComponentProps } from "@reach/router";
import { IUser } from "interfaces/IUser";
import { useUser } from "UserContext";
import { useStyles } from "./styles";
import { updateUserPageVisit } from "../../utils/userActivity";

export const DashboardPage: FunctionComponent<
  PageType | RouteComponentProps
> = ({ children }) => {
  const classes = useStyles({});
  const [user] = useUser() as Array<IUser>;

  useEffect(() => {
    if (user?.id) {
      updateUserPageVisit(user);
    }
  }, [user]);
  return (
    <>
      {user && (
        <div className={classes.dashboardRoot}>
          <div id="header">
            <Navbar />
          </div>

          <div className={classes.container} id="container">
            <div id="to-print" className={classes.toPrint}>
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
