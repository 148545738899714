import React, { FC } from "react";
import { Checkbox } from "@material-ui/core";

import { useStyles } from "./styles";

interface IStats {
  id: string;
  value: string;
  checked: boolean;
  media?: string[];
}

interface IMediaPrintMode {
  selectedStats: any;
  mediaName: string;
  mediaType: any;
  handleChange: any;
}

const MediaPrintMode: FC<IMediaPrintMode> = ({
  selectedStats,
  mediaName,
  mediaType,
  handleChange,
}) => {
  const classes = useStyles({});

  return (
    <div className={classes.stats}>
      <div>{mediaName}</div>
      <div className={classes.statsBreakdown}>
        <div className={classes.statsContent}>
          {selectedStats[mediaType].statsCount.map((val: IStats, index) => {
            return (
              <div key={index}>
                <Checkbox
                  className={classes.checkBox}
                  color="primary"
                  checked={val.checked}
                  onChange={(e) =>
                    handleChange(e, index, val.value, mediaType, "statsCount")
                  }
                />
                {val.value}
              </div>
            );
          })}
        </div>
        {selectedStats[mediaType].audience && (
          <div className={classes.statsContent}>
            {selectedStats[mediaType].audience.map((val: IStats, index) => {
              return (
                <div key={index}>
                  <Checkbox
                    className={classes.checkBox}
                    color="primary"
                    checked={val.checked}
                    onChange={(e) =>
                      handleChange(e, index, val.value, mediaType, "audience")
                    }
                  />
                  {val.value}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default MediaPrintMode;
