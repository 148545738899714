import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((props) => ({
  container: {
    // display: "flex",
    // justifyContent: "center",
    height: (props: any) => props.edit && "336px",
  },
  close: {
    color: "#FF5269",
    minWidth: "20px",
    marginLeft: "12px",
    position: "absolute",
    zIndex: 100,
    right: 0,
    top: 0,
  },
}));
