//this file will need refactoring <Tooltip></Tooltip> has been repeated
import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Grid,
  Input,
  MenuItem,
  Select,
  useTheme,
} from "@material-ui/core";

import { ControlLabel } from "components/styled/Common";
import { useStylesSelect } from "./stylesSelect";
import {
  extractCategoriesValues,
  filterCategoriesByChannels,
  handleCategoriesChange,
  handleChannelsChange,
} from "./helpers/socialPost";

type CategoryError = {
  facebook: string;
  instagram: string;
  twitter: string;
  tiktok: string;
  youtube: string;
  snapchat: string;
  threads: string;
  discord: string;
  custom: string;
};
interface IChannels {
  selectedCategories: Array<string>;
  setSelectedCategories: (value) => void;
  defaultValue?: string;
  label?: string;
  multiple: boolean;
  setItems: (e: any) => void;
  setError: (e: any) => void;
  selectedChannels: string[];
  category: any;
  setCategory: (event: any) => void;
  setCategoryError: (categoryError: CategoryError) => void;
  categoryError: CategoryError;
  categoryOption: string[];
  setCategoryOption: (data: any) => void;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function getStyles(channelName, selectedChannels, theme) {
  return {
    fontWeight: selectedChannels.includes(channelName)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const PostCategoriesV2: FC<IChannels> = ({
  selectedCategories,
  setSelectedCategories,
  label,
  setItems,
  setError,
  defaultValue = "Select...",
  multiple = true,
  category,
  selectedChannels,
  setCategory,
  setCategoryError,
  categoryError,
  categoryOption,
  setCategoryOption,
}) => {
  const theme = useTheme();
  const classes = useStylesSelect();

  const errors = Object.values(categoryError).filter((err) => err !== "");

  const manipulateArr = (arr, val) => {
    const i = arr.indexOf(val);
    if (i > -1) {
      arr.splice(i, 1);
    } else {
      arr.push(val);
    }
  };
  const handleChange = (event) => {
    setError({
      textError: "",
      linkErr: "",
      platformError: "",
      instagramError: "",
      titleError: "",
      channelsError: "",
    });
    const categories = event.target.value;
    setItems(event.target.value);

    setSelectedCategories(categories);

    let catdata = handleCategoriesChange(categories);

    setCategory(catdata);
  };

  useEffect(() => {
    let catdata = handleChannelsChange(selectedChannels, category);
    let filteredCat = filterCategoriesByChannels(
      selectedChannels,
      selectedCategories
    );

    setItems(filteredCat);
    setCategory(catdata);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChannels]);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const mapdata = (value: string, platform: string) => {
    if (platform?.toLowerCase().includes("facebook")) {
      const FB: string[] = [...category.facebook];

      manipulateArr(FB, value);

      setCategory({
        ...category,
        facebook: FB,
      });
    }
    if (platform?.toLowerCase().includes("instagram")) {
      const IG: string[] = [...category.instagram];
      manipulateArr(IG, value);

      setCategory({
        ...category,
        instagram: IG,
      });
    }
    if (platform?.toLowerCase().includes("twitter")) {
      const TW: string[] = [...category.twitter];
      manipulateArr(TW, value);

      setCategory({
        ...category,
        twitter: TW,
      });
    }
    if (platform?.toLowerCase().includes("tiktok")) {
      const tiktok: string[] = [...category.tiktok];
      manipulateArr(tiktok, value);

      setCategory({
        ...category,
        tiktok,
      });
    }
    if (platform?.toLowerCase().includes("youtube")) {
      const youtube: string[] = [...category.youtube];
      manipulateArr(youtube, value);

      setCategory({
        ...category,
        youtube,
      });
    }
    if (platform?.toLowerCase().includes("discord")) {
      const discord: string[] = [...category.discord];
      manipulateArr(discord, value);

      setCategory({
        ...category,
        discord,
      });
    }
    if (platform?.toLowerCase().includes("threads")) {
      const threads: string[] = [...category.threads];
      manipulateArr(threads, value);

      setCategory({
        ...category,
        threads,
      });
    }
    if (platform?.toLowerCase().includes("snapchat")) {
      const snapchat: string[] = [...category.snapchat];
      manipulateArr(snapchat, value);

      setCategory({
        ...category,
        snapchat,
      });
    }
    if (platform?.toLowerCase().includes("custom")) {
      const custom: string[] = [...category.custom];
      manipulateArr(custom, value);

      setCategory({
        ...category,
        custom,
      });
    }
  };

  const checkedStatus = (selectedCategories, name) => {
    return selectedCategories.includes(name);
  };

  return (
    <Grid
      id="client-list"
      style={{
        // display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <ControlLabel component="span" key={0}>
        {label}
      </ControlLabel>
      <Box>
        <Select
          disableUnderline
          autoWidth={true}
          id="multi-select"
          multiple={multiple}
          value={selectedCategories}
          disabled={selectedChannels.length > 0 ? false : true}
          displayEmpty={true}
          renderValue={() =>
            selectedCategories && selectedCategories.length
              ? selectedCategories.join(", ")
              : "Select Category"
          }
          onChange={handleChange}
          input={<Input />}
          className={classes.select}
          inputProps={{
            className: classes.selectInput,
            classes: {
              icon: classes.selectIcon,
              iconOpen: classes.selectIconOpen,
              selectMenu: classes.selectOverrideChannels,
            },
          }}
          MenuProps={{
            classes: { paper: classes.selectOverrideChannels },
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 5.2 + ITEM_PADDING_TOP,
                width: 200,
                marginTop: "8px",
                borderRadius: 15,
              },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            getContentAnchorEl: null,
          }}
        >
          {categoryOption.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, selectedCategories, theme)}
            >
              <Checkbox
                checked={checkedStatus(selectedCategories, name)}
                style={{
                  color: "rgba(0, 0, 0, 0.54)",
                }}
              />
              {name}
            </MenuItem>
          ))}
        </Select>

        {errors.map((err) => (
          <div style={{ color: "#f44336", padding: "0 0 5px 0", fontSize: 15 }}>
            {err || ""}
          </div>
        ))}
      </Box>
    </Grid>
  );
};

export default PostCategoriesV2;
