import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { FieldTitle } from "components/styled/CreateEditPost";
import { DateInput } from "components/styled/Common";
import { DateTime } from "luxon";
import { SchedulePostService } from "services/SchedulePostService";
import { v4 as uuidv4 } from "uuid";
import { navigate } from "@reach/router";
import { urlDates } from "utils/dateFormat";
import moment from "moment";
import ActionPrompt from "./ActionPrompt";
import styled from "styled-components";
interface IProp {
  client: any;
  post: any;
}

const channelOptions = [
  "Facebook",
  "Instagram",
  // "Twitter",
  // "TikTok",
  // "YouTube",
  // "Youtube Short",
  // "Others",
  // "All",
];
const categoryOptions = [
  "Facebook Feed",
  "Facebook Story",
  "Facebook Reel",
  "Facebook Live",
  "Facebook Group Post",
  "Instagram Feed",
  "Instagram Story",
  "Instagram Reels",
  "Instagram Live",
  "Instagram Broadcast Channel",
  "Twitter Feed",
  "Twitter Thread",
  "Twitter Poll",
  "TikTok Feed",
  "TikTok Live",
  "TikTok Short",
  "Threads Feed",
  "Snapchat Spotlight",
  "Snapchat Story",
  "Discord Channel Message",
  "Youtube Feed",
  "YouTube Community Tab",
  "YouTube Live",
  "YouTube Short",
];
const useStyles = makeStyles((theme) => ({
  // Add any additional styles you may need
  formContainer: {
    backgroundColor: "white",
    padding: theme.spacing(2),
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
    outline: "none",
  },
  // Add any additional styles you may need for the form
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "auto",
  },
  form: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    width: "100%",
    height: "100%",
    minHeight: "109px", // Assuming "hug" means auto
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    outline: "none",
    overflowY: "auto",
  },
  closeIcon: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  chip: {
    margin: "4px 4px 0px 0px",
    maxWidth: "100%",
    fontFamily: "'Avenir Book', sans-serif",
  },
  choose: {
    display: "flex",
    alignItems: "center",
    border: "2px solid #ABADAD",
    // border: (props: any) =>
    //   props.dropdown ? "2px solid #000" : "2px solid #ABADAD",
    width: "100%",
    borderRadius: "10px",
    marginTop: "5px",
    "&:hover": {
      border: "2px solid #000",
    },
  },

  radioLabel: {
    color: "black" /* Set the color of the checked radio button */,
  },
  input: {
    width: "94%",
    padding: "12.5px",
    border: "2px solid #ABADAD",
    boxSizing: "border-box",
    borderRadius: "10px",
    cursor: "pointer",
    fontFamily: "'Avenir Book', sans-serif",
    lineHeight: "20px",
    fontSize: "15px",
    "&:focus": {
      border: "2px solid #000",
      outline: "none",
    },
  },
  field: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    // marginTop: "26px",
    transition: "all 0.3s ease",
  },
  uploadIcons: {
    display: "flex",
    // paddingTop: 5,
    alignItems: "end",
    // justifyContent: "flex-end",
    width: "94%",
    "& > *": {
      cursor: "pointer",
    },
  },
  error: {
    color: "#f44336",
    fontSize: "15px",
    textAlign: "center",
    width: "75%",
  },
  highlight: {
    border: "none",
    background: "none",
    padding: "0px",
    "&:active, &:focus": {
      border: "none",
      outline: "none",
      padding: "0px",
    },
  },

  uploadImgContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    // marginTop: 12,
    width: "94%",
  },

  icon: {
    color: "#000",
    cursor: "pointer",
    fontSize: "30px",
    fontWeight: "bold",
    transition: "all .3s ease",
  },
  loadingIconContainer: {
    display: "inline-block",
    animation: "$pulse 1.5s infinite alternate", // Reference the pulse animation
  },

  loadingIcon: {
    width: "30px", // Adjust the initial size of the icon
    height: "30px", // Adjust the initial size of the icon
    transition: "all 0.3s ease-in-out", // Smooth transition for size change
  },

  loadingText: {
    marginLeft: "8px", // Add some spacing between the icon and text
  },

  "@keyframes pulse": {
    to: {
      transform: "scale(1.2)", // Adjust the scale factor for the pulse effect
    },
  },
  savebtn: {
    marginTop: "10px",
    width: "150px",
    height: "45px",
    padding: "11.5px 8px 11.5px 8px",
    borderRadius: "15px",
    border: "1px solid",
    backgroundColor: "#000",
    cursor: "pointer",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    color: "white",
    fontSize: "15px",
    fontWeight: 900,
    fontFamily: "Avenir",
    alignItems: "center",
    "&:disabled": {
      backgroundColor: "#888",
      cursor: "not-allowed",
      color: "#666",
    },
  },
  chatgpticonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%", // Optional: Set height for full-height centering
  },
  "@media (max-width: 600px)": {
    chatgpticonContainer: {
      height: "50vh", // Adjust the height for smaller screens
    },
  },
  label: {
    marginTop: "5px",
  },
  btnstyle: {
    // width: "200px",
    height: "45px",
    padding: "11.5px 50px 11.5px 50px",
    borderRadius: "15px",
    backgroundColor: "#000000",
    color: "white",
    fontFamily: "Avenir",
    fontSize: "13px",
    fontWeight: 900,
    lineHeight: "22px",
    letterSpacing: "0.429px",
    textAlign: "center",
  },
  btnDisableStyle: {
    opacity: 0.5,
    cursor: "not-allowed",
    // Add any other styles you want for the disabled state
  },
}));

const StyledRadio = styled(Radio)`
  &.Mui-checked {
    color: black; /* Set the color of the checked radio button */
  }
`;

const SchedulePost = ({ client, post }: IProp) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [channel, setChannel] = useState("Facebook");
  const [category, setCategory] = useState(
    channel === "Instagram" ? "Instagram Feed" : "Facebook Feed"
  );
  const [selectedDate, setSelectedDate]: any = useState(
    DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd'T'HH:mm")
  );
  const [categories, setCategories] = useState([] as string[]);

  const [formData, setFormData] = useState({
    // Add your form fields here
    title: "",
    content: "",
  });

  const handleClose = () => {
    setOpen(false);
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };
  const getFormattedDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = `${(now.getMonth() + 1).toString().padStart(2, "0")}`;
    const day = `${now.getDate().toString().padStart(2, "0")}`;
    const hours = `${now.getHours().toString().padStart(2, "0")}`;
    const minutes = `${now.getMinutes().toString().padStart(2, "0")}`;

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const addPostSchedule = async () => {
    const id = uuidv4();
    let SchedulePost = new SchedulePostService("scheduled-posts", id);

    try {
      setLoading(true);
      let clientName = client?.firstName || "";
      if (client?.lastName) {
        clientName = clientName + " " + client?.lastName;
      }
      const resp = await SchedulePost.addPost({
        ...post,
        status: "Pending",
        platform: channel,
        scheduledCategory: category,
        clientName,
        date: moment(selectedDate).toDate(),
        created_at: new Date(),
      });
      setLoading(false);
      handleClose();
      navigate(`/post-schedule`);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const filterCategories = () => {
      let data = categoryOptions.filter((val) => {
        return val.toLowerCase().includes(channel.toLowerCase());
      });
      if (channel === "Instagram") {
        setCategory("Instagram Feed");
      } else if (channel === "Facebook") {
        setCategory("Facebook Feed");
      }
      setCategories(data);
    };
    filterCategories();
  }, [category, channel]);

  return (
    <>
      <Tooltip
        title={
          post.internal_status_weekly !== "Client Approved"
            ? "The Post must be Client Approved before it can be scheduled"
            : ""
        }
        arrow
        placement="top"
        style={{ fontSize: "14px" }}
      >
        <span>
          <Button
            style={{
              width: "100%",
            }}
            disableRipple
            variant="outlined"
            type="button"
            id="action-btn"
            className={classes.btnstyle}
            onClick={() => {
              setOpen(true);
            }}
          >
            Schedule
          </Button>
        </span>
      </Tooltip>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        aria-labelledby="chatgpt-post-generator-title"
        aria-describedby="chatgpt-post-generator-description"
      >
        <div className={classes.formContainer}>
          <form
            className={classes.form}
            // onSubmit={(e: any) => generateChatGptPost(e)}
          >
            <IconButton className={classes.closeIcon} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                fontFamily: "Avenir",
                fontSize: "30px",
                fontWeight: 900,
                lineHeight: "41px",
                letterSpacing: "0em",
                textAlign: "left",
                padding: "10px",
              }}
            >
              Post Auto Scheduler
            </Typography>
            <br />
            <Grid container>
              <Grid
                xs={12}
                md={12}
                style={{
                  padding: "10px",
                }}
              >
                <div>
                  <FieldTitle>Platform</FieldTitle>
                  <Select
                    id="postTypeSelect"
                    variant="outlined"
                    value={channel}
                    // className={classes.label}
                    onChange={(event) => setChannel(event.target.value as any)}
                    style={{
                      width: "100%",
                      border: "1px solid #E8E8E8",
                      borderRadius: "15px",
                      height: "300px !important", // Adjust the height value as needed
                    }}
                  >
                    {channelOptions.map((channel) => (
                      <MenuItem key={channel} value={channel}>
                        {capitalizeFirstLetter(channel)}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <br />

                {channel && (
                  <div>
                    <FieldTitle>Post Category</FieldTitle>
                    <br />
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="postCategory"
                        name="postCategory"
                        value={category}
                        onChange={(event) => setCategory(event.target.value)}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        {categories.map((val) => (
                          <FormControlLabel
                            key={val}
                            value={val}
                            control={<StyledRadio />} // Use the StyledRadio component here
                            label={capitalizeFirstLetter(val)}
                            disabled={
                              val !== "Instagram Feed" &&
                              val !== "Facebook Feed"
                            }
                            style={{ marginRight: "20px" }}
                            classes={{ root: "radio-label" }} // Add a class for custom styling
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </div>
                )}
                <div style={{ marginTop: "10px" }}>
                  <FieldTitle>Publish Date</FieldTitle>

                  <DateInput
                    style={{
                      border: "1px solid #E8E8E8",
                      borderRadius: "15px",
                      marginTop: "8px",
                      width: "100%",
                    }}
                  >
                    <TextField
                      id="datetime-local"
                      type="datetime-local"
                      value={selectedDate}
                      InputLabelProps={{
                        shrink: false,
                      }}
                      onChange={(e) => handleDateChange(e.target.value)}
                      inputProps={{
                        min: getFormattedDate(),
                      }}
                    />
                  </DateInput>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      fontFamily: "Avenir",
                    }}
                  >
                    {` You can view and manage all your scheduled posts on the
                    'Post Auto Schedule' page.`}
                  </p>
                </div>

                <Grid container>
                  <Grid xs={12} md={6}></Grid>
                  <Grid
                    xs={12}
                    md={3}
                    style={{
                      paddingRight: "5px",
                    }}
                  >
                    <button
                      type="submit"
                      style={{
                        width: "100%",
                        height: "45px",
                        padding: "11.5px 8px 11.5px 8px",
                        borderRadius: "15px",
                        border: "1px solid",
                        backgroundColor: "white",
                        cursor: "pointer",
                        position: "relative", // Add position relative for proper positioning of loader
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      disabled={loading}
                      onClick={handleClose}
                    >
                      Reset
                    </button>
                  </Grid>
                  <Grid
                    xs={12}
                    md={3}
                    // style={{
                    //   paddingLeft: "5px",
                    // }}
                  >
                    <ActionPrompt
                      handleChange={addPostSchedule}
                      submitting={loading}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default SchedulePost;
