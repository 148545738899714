import React, { FC, useState } from "react";
import styled from "styled-components";
import Collapse from "@material-ui/core/Collapse";

import { useStyles } from "../styles";
import { useStyles as useNoteStyles } from "./styles";
import NoteField from "./NoteField";
import Note, { NoteType } from "./Note";
import { IUser } from "interfaces/IUser";
import { FieldTitle } from "components/styled/CreateEditPost";
import {
  addCommentNotification,
  addPostNotification,
} from "helpers/notification/addNotification";
import { NotificationActions } from "interfaces/INotification";
// import { fontFamily } from "html2canvas/dist/types/css/property-descriptors/font-family";

interface NotesProps {
  user: IUser;
  note: string;
  setNote: (note: string) => void;
  setNoteType: (type: string) => void;
  noteType: string;
  internalNotes: Array<any>;
  externalNotes: Array<any>;
  post?: any;
  tempNotes?: Array<any>;
  setTempNotes?: (notes: Array<any>) => void;
  client: IUser;
}

export const Title = styled.div`
  font-family: "Avenir Black", sans-serif;
  font-size: 15px;
  font-weight: 900;
  color: #000;
`;
export const ExpandButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 10px;
  line-height: 25px;
  color: #abadad;
  margin-left: 14px;
  background: none;
  font-family: "Avenir Book", sans-serif;
`;

const Notes: FC<NotesProps> = ({
  user,
  note,
  setNote,
  setNoteType,
  noteType,
  internalNotes,
  externalNotes,
  post,
  tempNotes,
  setTempNotes,
  client,
}) => {
  const classes = useStyles({});
  const notesClass = useNoteStyles({});
  const [internalOpen, setInternalOpen] = useState(true);
  const [externalOpen, setExternalOpen] = useState(true);
  const adminNotes = tempNotes?.filter((note) => note.type !== "external");
  const clientNotes = tempNotes?.filter((note) => note.type !== "internal");
  const iNotes = post ? internalNotes : adminNotes;
  const exNotes = post ? externalNotes : clientNotes;

  return (
    <div className={classes.field} style={{ width: "94%", marginTop: 30 }}>
      <FieldTitle style={{ fontSize: "15px" }}>Write a Comment</FieldTitle>

      <NoteField
        user={user}
        note={note}
        setNote={setNote}
        setNoteType={setNoteType}
        noteType={noteType}
        post={post}
        tempNotes={tempNotes}
        setTempNotes={setTempNotes}
        collection={"multichannel-posts"}
        client={client}
      />
      <div className={notesClass.noteTitle}>
        <Title style={{}}>Internal Comments {"(" + iNotes?.length + ")"}</Title>
        <ExpandButton
          onClick={() => setInternalOpen(!internalOpen)}
          style={{
            color: "#000000",
            fontFamily: "Avenir",
            fontSize: "14px",
            fontWeight: 350,
          }}
        >
          {" "}
          {internalOpen ? "Show Less" : "Show More"}
        </ExpandButton>
      </div>
      <Collapse in={internalOpen} timeout="auto" unmountOnExit>
        {iNotes?.map((noteObject: NoteType) => (
          <Note
            note={noteObject}
            noteType="internal"
            post={post}
            key={noteObject.id}
            authUser={user}
            collection={"multichannel-posts"}
            tempNotes={tempNotes}
            setTempNotes={setTempNotes}
          />
        ))}
      </Collapse>
      <div className={notesClass.noteTitle}>
        <Title>External Comments {"(" + exNotes?.length + ")"}</Title>
        <ExpandButton
          onClick={() => setExternalOpen(!externalOpen)}
          style={{
            color: "#000000",
            fontFamily: "Avenir",
            fontSize: "14px",
            fontWeight: 350,
          }}
        >
          {" "}
          {externalOpen ? "Show Less" : "Show More"}
        </ExpandButton>
      </div>
      <Collapse in={externalOpen} timeout="auto" unmountOnExit>
        {exNotes?.map((noteObject: NoteType) => (
          <Note
            note={noteObject}
            noteType="external"
            post={post}
            key={noteObject.id}
            authUser={user}
            collection={"multichannel-posts"}
            tempNotes={tempNotes}
            setTempNotes={setTempNotes}
          />
        ))}
      </Collapse>
    </div>
  );
};

export default Notes;
