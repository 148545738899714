import { FC, useEffect } from "react";

export const ScrollToTop: FC<{ children: any }> = ({ children }) => {
  useEffect(() => {
    try {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  }, []);

  return children;
};
