import React, { FC, useState } from "react";
import {
  Modal,
  Button,
  Backdrop,
  Fade,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import { Close, CheckCircle } from "@material-ui/icons";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { Formik } from "formik";
import * as Yup from "yup";

import { UserService } from "services/UserService";
import firebase from "firebase/app";

import { useStyles } from "./styles";
import Name from "../Common/Form/Name";
import Email from "../Common/Form/Email";
import { IUser } from "interfaces/IUser";
import { sendLoginInvite } from "../ClientInfo/requests/sendSignInEmail";
import { success } from "../../utils/notification";
import { SendButton, CancelButton } from "components/styled/Common";

interface IPopup {
  edit?: string;
  adminAdd?: string;
  user?: any;
}

const Popup: FC<IPopup> = ({ edit, adminAdd, user }) => {
  const classes = useStyles({});

  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const handleIsOpen = (): void => {
    return setIsOpen(!isOpen);
  };

  const updateAdmin = async (values) => {
    const firstName = values.name.split(" ").slice(0, 1).join(" ");
    const lastName = values.name.split(" ").slice(1).join(" ");

    setIsSaving(true);
    const User = new UserService(user.id);
    await User.updateUser({
      role: values.role,
      firstName,
      lastName,
    })
      .then(() => {
        setIsOpen(false);
      })
      .catch((error) => {
        setError(error);
      });
    setIsSaving(false);
  };

  const createUser = async (values) => {
    setIsSaving(true);
    const password = Math.random().toString(36).slice(-6);
    const User = new UserService();
    User.getUserByField("email", values.email)
      .then(async (users: Array<IUser>) => {
        if (users && users.length) {
          setError("You already sent an invite to this email!");
          setIsSaving(false);
        } else {
          const User = new UserService();
          const app = firebase.app();
          const functions = app.functions("europe-west2");
          const createUser = functions.httpsCallable(
            "authentication-createUser"
          );
          const authAccount: any = await createUser({
            email: values.email,
            password,
          });
          if (authAccount.data.uid) {
            User.doc = authAccount.data.uid;
            await User.addUser({
              email: values.email,
              firstName: values.name.split(" ").slice(0, 1).join(" "),
              lastName: values.name.split(" ").slice(1).join(" "),
              avatarUrl: "",
              role: values.role,
              isActivated: true,
              isInvited: true,
              isSignedUp: true,
              createdAt: new Date(),
              facebook: {
                url: "",
                username: "",
              },
              instagram: {
                url: "",
                username: "",
              },
              twitter: {
                url: "",
                username: "",
              },
              secondaryEmails: [],
            } as IUser).catch(setError);
            await sendLoginInvite(
              values.email,
              values.name,
              "admin",
              password,
              values.role === "viewer"
                ? "Viewer"
                : values.role === "superAdmin"
                ? "Super Admin"
                : "Admin"
            );
            setIsSaving(false);
            setIsOpen(false);
            success("Invite sent successfully!");
          } else {
            setError(authAccount.data.errorInfo.message);
            setIsSaving(false);
          }
        }
      })
      .catch((e) => {
        setIsSaving(false);
        setError(e.message);
      });
  };

  const handleClose = (e) => {
    if (e.key === "Escape") {
      setIsOpen(false);
    }
  };

  return (
    <div>
      {edit && <div onClick={handleIsOpen}>Edit Admin</div>}
      {adminAdd && (
        <Button
          disableRipple
          type="button"
          className={classes.btn}
          onClick={handleIsOpen}
        >
          Add New Admin
        </Button>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        onClose={handleClose}
        className={classes.modal}
        open={isOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <Paper style={{ outline: "none", borderRadius: "15px" }}>
            <Formik
              enableReinitialize={true}
              initialValues={{
                name: user ? `${user.firstName} ${user.lastName}` : "",
                email: user ? user.email : "",
                role: user ? user.role : "",
              }}
              onSubmit={(values) => {
                if (edit) {
                  updateAdmin(values);
                } else {
                  createUser(values);
                }
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().required("Required!"),
                email: Yup.string()
                  .email("Invalid email!")
                  .required("Required!"),
                role: Yup.string().required("Required!"),
              })}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                } = props;
                return (
                  <form noValidate onSubmit={handleSubmit} className={""}>
                    <div className={classes.container} id="popup">
                      <div className={classes.paper}>
                        <div className={classes.top}>
                          <div className={classes.print}>
                            {edit && "Edit Admin"}
                            {adminAdd && "Add New Admin"}
                          </div>
                          <Close
                            className={classes.icon2}
                            onClick={handleIsOpen}
                          />
                        </div>

                        <div className={classes.content}>
                          <div style={{ fontSize: 15, marginBottom: 20 }}>
                            {adminAdd &&
                              "Please enter the information of the new admin user."}
                            {edit &&
                              "Please change the information of the admin user."}
                          </div>
                          <Grid
                            style={{
                              marginTop: "unset",
                              maxWidth: "320px",
                            }}
                          >
                            <p
                              className={classes.inputLabel}
                              style={{
                                marginBottom: "-4px",
                              }}
                            >
                              Email
                            </p>
                            <Email
                              color="#000000"
                              marginLeft="unset"
                              value={values.email}
                              label=""
                              width="100%"
                              variant="outlined"
                              error={errors.email && touched.email}
                              placeholder="admin@email.com"
                              onChangeHandler={handleChange}
                              handleBlur={handleBlur}
                              borderColor={
                                errors.email && touched.email
                                  ? "#f44336"
                                  : "#000"
                              }
                              helperText={
                                errors.email && touched.email && errors.email
                              }
                              disabled={edit ? true : false}
                              backgroundColor={!edit ? "#fff" : "#f2f2f2"}
                            />
                          </Grid>
                          <Grid
                            style={{
                              maxWidth: "320px",
                              marginTop: "20px",
                            }}
                          >
                            <p
                              className={classes.inputLabel}
                              style={{
                                marginBottom: "-4px",
                              }}
                            >
                              Name
                            </p>
                            <Name
                              name="name"
                              color="#000000"
                              label=""
                              variant="outlined"
                              width="100%"
                              value={values.name}
                              placeholder="David Bowie"
                              error={
                                errors.name && touched.name && !values.name
                              }
                              onChangeHandler={handleChange}
                              handleBlur={handleBlur}
                              borderColor={
                                errors.name && touched.name ? "#f44336" : "#000"
                              }
                              helperText={
                                errors.name && touched.name && errors.name
                              }
                            />
                          </Grid>
                          <Grid
                            style={{
                              marginTop: "20px",
                            }}
                          >
                            <p className={classes.inputLabel}>
                              Choose admin permissions:
                            </p>
                            <RadioGroup
                              name="role"
                              value={values.role}
                              onChange={handleChange}
                              className={classes.formGroup}
                              onBlur={handleBlur}
                            >
                              <div style={{ display: "flex" }}>
                                <FormControlLabel
                                  value="superAdmin"
                                  control={
                                    <Radio
                                      icon={
                                        <CircleUnchecked
                                          className={`${classes.radioIcon} ${classes.radioUnchecked}`}
                                        />
                                      }
                                      checkedIcon={
                                        <CheckCircle
                                          className={`${classes.radioIcon} ${classes.radioUnchecked}`}
                                        />
                                      }
                                      color="primary"
                                      className={`${classes.radioIcon} ${classes.radioUnchecked}`}
                                    />
                                  }
                                  label=""
                                />
                                <div className={classes.labelGroup}>
                                  <div className={classes.radioLabel}>
                                    Super Admin
                                  </div>
                                  <div>
                                    (can change permissions, and edit/view)
                                  </div>
                                </div>
                              </div>
                              <div style={{ display: "flex" }}>
                                <FormControlLabel
                                  value="admin"
                                  control={
                                    <Radio
                                      icon={
                                        <CircleUnchecked
                                          className={`${classes.radioIcon} ${classes.radioUnchecked}`}
                                        />
                                      }
                                      checkedIcon={
                                        <CheckCircle
                                          className={`${classes.radioIcon} ${classes.radioUnchecked}`}
                                        />
                                      }
                                      color="primary"
                                      className={`${classes.radioIcon} ${classes.radioUnchecked}`}
                                    />
                                  }
                                  label=""
                                />
                                <div className={classes.labelGroup}>
                                  <div className={classes.radioLabel}>
                                    Admin
                                  </div>
                                  <div>(can edit/view)</div>
                                </div>
                              </div>
                              <div style={{ display: "flex" }}>
                                <FormControlLabel
                                  value="viewerAdmin"
                                  control={
                                    <Radio
                                      icon={
                                        <CircleUnchecked
                                          className={`${classes.radioIcon} ${classes.radioUnchecked}`}
                                        />
                                      }
                                      checkedIcon={
                                        <CheckCircle
                                          className={`${classes.radioIcon} ${classes.radioUnchecked}`}
                                        />
                                      }
                                      color="primary"
                                      className={`${classes.radioIcon} ${classes.radioUnchecked}`}
                                    />
                                  }
                                  label=""
                                />
                                <div className={classes.labelGroup}>
                                  <div className={classes.radioLabel}>
                                    Viewer
                                  </div>
                                  <div>(can only view)</div>
                                </div>
                              </div>
                            </RadioGroup>
                            <div style={{ color: "#f44336" }}>
                              {touched.role && errors.role} {error}
                            </div>
                          </Grid>
                        </div>
                      </div>
                      <div className={classes.line} />
                      <div className={classes.bottom}>
                        <div>
                          <CancelButton disableRipple onClick={handleIsOpen}>
                            cancel
                          </CancelButton>
                          <SendButton
                            className={classes.printMode}
                            type="submit"
                            disableRipple
                          >
                            {isSaving ? (
                              <CircularProgress size={30} color="inherit" />
                            ) : (
                              (edit && "Save Changes") ||
                              (adminAdd && " Create Admin")
                            )}
                          </SendButton>
                        </div>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
};

export default Popup;
