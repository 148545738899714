import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  avatar: {
    display: "inline-block",
    height: "60px",
    width: "60px",
  },
  icon: {
    background: "#000",
    height: "60px",
    width: "60px",
    margin: "0 auto",
  },
  svg: {
    width: "40%",
    height: "40%",
  },
  img: {
    width: "40px",
    left: "35.67%",
    right: "60.36%",
    top: "15.64%",
    bottom: "18.79%",
    marginRight: "15px",
  },
  row: {
    height: "120.25px",
  },
  clientName: {
    height: "22px",
    left: "0%",
    right: "0%",
    top: "calc(50% - 22px/2 - 14px)",
    fontFamily: "Avenir Black, sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#000000",
  },
  client: {
    display: "flex",
    flexDirection: "column",

    "& p": {
      minWidth: 140,
    },
  },
  toggle: {
    height: "30px",
    fontSize: "12px",
    textTransform: "capitalize",
  },
  tableCell: {
    alignItems: "start",
    "&.MuiTableCell-root": {
      "&:first-of-type": {
        padding: "16px 16px 16px 3rem",
      },
    },
  },
  tableData: {
    fontFamily: "Avenir Black, sans-serif",
  },
  chipLabel: {
    fontFamily: "Avenir Black, sans-serif",
    fontSize: "10px",
    lineHeight: "11px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#FFFFFF",
  },
  chipRoot: {
    background: "#FF5269",
    borderRadius: "2px",
    height: "22px",
  },

  root: {
    display: "flex",
    zIndex: 5,
  },
  label: {
    textTransform: "capitalize",
    height: "22px",
    left: "17.16%",
    right: "46.27%",
    top: "calc(50% - 22px/2 - 81px)",
    fontFamily: "Avenir Black, sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#000000",
  },
  line: {
    left: "0.75%",
    right: "0.75%",
    top: "61.35%",
    bottom: "37.68%",
    border: "1px solid #E8E8E8",
  },
  button: {
    zIndex: 1,
    left: "0.37%",
    width: "134px",
    right: "0.37%",
    top: "0%",
    height: "45px",
    bottom: "78.26%",
    border: "1px solid #E8E8E8",
    borderRadius: 10,
  },
  popper: {
    background: "#FFFFFF",
    border: "1px solid #E8E8E8",
    boxSizing: "border-box",
    zIndex: 10,
    width: "134px",
    height: "82px",
    left: "8.96%",
    right: "33.58%",
    top: "calc(50% - 160px/2 + 23px)",
  },
  li: {
    fontFamily: "Avenir Black, sans-serif",
    fontSize: "11.5px",
    lineHeight: "26.5px",
    color: "#000000",

    "&:last-child": {
      color: "#FF5269",
    },
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    width: "533px",
    color: "#000000",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "15px",
    boxShadow: theme.shadows[5],
    fontSize: 14,
    fontFamily: "Avenir Black, sans-serif",

    "&:focus": {
      outline: "none",
    },
  },
  paper: {
    padding: theme.spacing(0, 4, 0),
  },
  chooseAcc: {
    fontFamily: "Avenir Black, sans-serif",
    fontSize: "11.5px",
    lineHeight: "40px",
    color: "#000000",
    textTransform: "capitalize",
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0px -32px",
    padding: "0 20px",
    height: 68,
    background: "#ABADAD",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    marginBottom: 30,
  },

  content: {
    maxHeight: 380,
    overflowY: "scroll",
    color: "#000000",

    "&::-webkit-scrollbar-track": {
      marginTop: "-30px",
    },
  },
  print: {
    fontSize: "30px",
    lineHeight: "26px",
    textTransform: "capitalize",
    fontFamily: "Avenir Black, sans-serif",
    fontWeight: 900,
  },
  printmode: {
    display: "flex",
    alignItems: "center",

    "& > :first-child": {
      marginRight: "50px",
    },
  },
  icon2: {
    color: "#fff",
    cursor: "pointer",
    fontSize: "30px",
    fontWeight: "bold",
    transition: "all .3s ease",
  },
  checkBox: {
    padding: 0,
    marginRight: "16px",
    fontSize: "30px",

    "&.MuiCheckbox-colorPrimary.Mui-checked": {
      color: "#1D4E89",
    },
  },
  line2: {
    opacity: " 0.3",
    height: "1px",
    background: "#979797",
    marginTop: "20px",
  },

  bottom: {
    display: "flex",
    justifyContent: "flex-end !important",
    alignItems: "center",
    height: "74px",

    "& > div": {
      display: "flex",
      justifyContent: "space-between",
      width: "95%",
    },
    "& > div > :last-child": {
      marginRight: "32px",
      marginLeft: "16px",
    },
  },

  printMode: {
    color: "#1D4E89 !important",
  },

  btn: {
    width: "159px",
    height: "45px",
    background: "#000000",
    borderRadius: "15px",
    textTransform: "capitalize",
    fontFamily: "Avenir Black, sans-serif",
    fontSize: "15px",
    fontWeight: 900,
    fontStyle: "normal",
    lineHeight: "22px",
    color: "#FFFFFF",
    marginBottom: "5px",
    justifyContent: "center",
    float: "right",
    alignItems: "center",

    "&:hover": {
      backgroundColor: "#000000",
      opacity: 0.85,
    },
  },
  inputLabel: {
    height: "17px",
    fontFamily: "Avenir Black, sans-serif",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#000000",
    margin: "unset",
  },
  radioLabel: {
    fontWeight: 600,
  },
  formGroup: {
    "&>*": {
      marginTop: "10px",
    },
  },
  labelGroup: {
    lineHeight: "21px",
  },
  radioIcon: {
    fontSize: "30px",
    "&.MuiRadio-colorPrimary.Mui-checked": {
      color: "#000",
    },
    "&:hover": {
      background: "rgba(0,0,0,0.3) !important",
    },
  },
  radioUnchecked: {
    "&.MuiRadio-root": {
      color: "#ABADAD",
    },
  },

  delete: {
    background: "linear-gradient(161.21deg, #484848 -9.75%, #353535 84.22%)",
    width: "150px !important",
  },
  createBtn: {
    fontFamily: "Avenir Black, sans-serif",
  },
  submit: {
    color: "#1D4E89 !important",
  },
  error: {
    color: "#f44336",
    fontSize: "15px",
    textAlign: "center",
    width: "75%",
  },
}));
