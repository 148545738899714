/**
 * @prettier
 */

import React, { FunctionComponent, useState, Fragment } from "react";
import { CircularProgress, Button } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { RouteComponentProps } from "@reach/router";
import { Formik } from "formik";
import * as Yup from "yup";

import { auth } from "firebase-tools/firebase";
import Email from "components/Common/Form/Email";
import { useStyles } from "./styles";

export const ForgotPass: FunctionComponent<RouteComponentProps> = () => {
  const [errorText, setErrorText] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles({});

  const resetPassword = async (email: string) => {
    setIsLoading(true);
    await auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setIsEmailSent(true);
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          setErrorText("User with the given email doesn't exist.");
        } else {
          setErrorText("An error occurred, please retry.");
        }
      });
    setIsLoading(false);
  };

  return (
    <Formik
      initialValues={{ email: "" }}
      onSubmit={(values) => {
        resetPassword(values.email);
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email("Invalid email!").required("Required!"),
      })}
    >
      {(props) => {
        const { values, touched, errors, handleChange, handleSubmit } = props;
        return (
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            {isEmailSent === false ? (
              <Fragment>
                <Email
                  autoFocus
                  onChangeHandler={(e) => {
                    handleChange(e);
                    setErrorText("");
                  }}
                  value={values.email}
                  helperText={errors.email && touched.email && errors.email}
                  error={errors.email && touched.email}
                  color={
                    errors.email ? "#f44336 !important" : "#fff !important"
                  }
                  marginTop="24px"
                />
                <span className={classes.error}>{errorText}</span>
                <Button
                  disableRipple
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  classes={{ disabled: classes.disabledButton }}
                  disabled={isLoading}
                >
                  {!isLoading && "Reset Password"}
                  {isLoading && <CircularProgress size={30} color="inherit" />}
                </Button>
              </Fragment>
            ) : (
              <Alert severity="success">
                <AlertTitle>Email Sent</AlertTitle>
                Go to your email to reset password
              </Alert>
            )}
          </form>
        );
      }}
    </Formik>
  );
};
