/**
 * @prettier
 */
import React, { FC, Fragment } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

import { PageType } from "types/PageType";
import { useAuthHook, AuthHookType } from "hooks/AuthHook";

const App: FC<PageType> = ({ location, children }: PageType) => {
  const isAllowRender = useAuthHook({ location } as AuthHookType);

  return <Fragment>{isAllowRender ? children : <LinearProgress />}</Fragment>;
};

export default App;
