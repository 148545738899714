import React, { FC, useState, useEffect } from "react";
import moment from "moment";
import history from "history/browser";

import { PageType } from "../../types/PageType";
import { ClientList } from "components/BeMeasured/ClientList";
import { PageTitle, Root, Container } from "components/styled/Common";
import { updateLastSelection } from "utils/lastSelection";
import { useUser } from "UserContext";
import { IUser } from "interfaces/IUser";
import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import useCollectionSubscriber from "../../hooks/useCollectionSubscriber";
import { firestore } from "../../firebase-tools/firebase";
import { DateRange } from "materialui-daterange-picker";
import DateRangePicker from "components/Common/DateRange";
import BePlannedGrid from "components/BePlannedGrid";
import { IPost } from "interfaces/IPost";
import CustomLoader from "components/CustomLoader";
import { EmptyData } from "components/styled/Common";
import { urlDates } from "utils/dateFormat";

export const BePlannedGridPage: FC<PageType> = ({ location }) => {
  const [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: moment().startOf("day").toDate(),
    endDate: moment().add(6, "days").startOf("day").toDate(),
  });
  const [authUser] = useUser() as Array<IUser>;

  const [, setClient]: any = useState("");
  const [loading, setLoading] = useState(false);
  const [paramsChange, setParamsChange]: any = useState("");

  const from = new Date(dateRange.startDate as Date);
  let to = new Date(dateRange.endDate as Date);
  to = moment(to).add(1, "days").toDate();
  let userRef = firestore.collection("users").doc(authUser.id);
  let client =
    useDocumentSubscriber({ ref: userRef }).document?.lastClientSelection || "";

  const setLastSelection = (selectedUser) => {
    setClient(selectedUser);
    updateLastSelection(selectedUser, authUser.id);
  };

  let instagramQueries: any = [];

  if (client !== "") {
    instagramQueries = [
      firestore
        .collection("multichannel-posts")
        .where("user_email", "==", client.email)
        .where("date", ">=", from)
        .where("date", "<=", to)
        .orderBy("date", "desc"),
    ];
  }
  let instagram;

  instagram = useCollectionSubscriber<IPost>({
    queries: instagramQueries,
    deps: [dateRange, client],
  });

  let igPosts = instagram.documents.filter((ch) =>
    ch.channels.includes("instagram")
  );

  igPosts = igPosts.filter(
    (post) =>
      (!!post.image_url?.length || post.video_url?.length) &&
      post.category?.instagram?.some((item) =>
        [
          "Instagram Feed",
          "Instagram Carousel",
          "Instagram IGTV",
          "Instagram Reels",
        ].some((neededItem) => item === neededItem)
      )
  );
  useEffect(() => {
    if (!!authUser?.lastClientSelection) {
      setClient(authUser?.lastClientSelection);
    } else {
      setClient("");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [instagram.documents]);

  useEffect(() => {
    urlDates(dateRange, history, paramsChange, setDateRange, location);

    if (client !== "") {
      setLoading(true);
    }
  }, [dateRange, client, paramsChange, location]);
  return (
    <Root>
      <Container
        style={{
          overFlow: "scroll",
        }}
      >
        <PageTitle id="page-title">
          <span style={{ marginTop: 32 }}>Be-Planned Grid Layout</span>
          <div id="selector">
            <ClientList
              client={client}
              setClient={setLastSelection}
              setLoading={setLoading}
            />
            <DateRangePicker
              dateRange={dateRange}
              setDateRange={setDateRange}
              setParamsChange={setParamsChange}
              container="grid"
            />
          </div>
        </PageTitle>
        {!loading ? (
          client !== "" ? (
            <BePlannedGrid posts={igPosts} user={client} />
          ) : (
            <EmptyData id="empty-analytics">
              To view the Be-Planned Grid Layout of a client, please choose a
              client above.
            </EmptyData>
          )
        ) : (
          <CustomLoader />
        )}
      </Container>
    </Root>
  );
};
