import React, { FunctionComponent, useState } from "react";
import linkifyHtml from "linkifyjs/html";

import { useStyles } from "./styles";

interface ReadMoreProps {
  readMoreCharacterLimit: number;
  showLessButton: boolean;
  text: string;
  post: any;
  role: string;
}

export const ReadMore: FunctionComponent<ReadMoreProps> = ({
  readMoreCharacterLimit,
  showLessButton,
  text,
  post,
  role,
}) => {
  const classes = useStyles({});
  const ELLIPSES = "...";
  const SHOW_LESS_TEXT = " show less";
  const SHOW_MORE_TEXT = "show more";
  const [showingAll, setShowingAll] = useState(false);
  let noAction;

  if (!text) {
    return <div></div>;
  }
  if (role === "") {
    noAction =
      (!post.image_url || post.image_url === "") &&
      text.length < readMoreCharacterLimit;
  } else {
    noAction =
      role === "twitter"
        ? (!post.imageUrl || post.imageUrl === "") && text.length < 500
        : role === "facebook"
        ? (!post.metadata.full_picture || post.metadata.full_picture === "") &&
          text.length < 500
        : (!post.metadata.media_url || post.metadata.media_url === "") &&
          text.length < 500;
  }

  const toggleReadMore = () => {
    setShowingAll(!showingAll);
  };
  const getReadMoreParts = (text: string, readMoreCharacterLimit: number) => {
    let teaserText;
    let remainingText;
    let remainingWordsArray: Array<any> = [];

    if (text) {
      const teaserWordsArray = text.split(" ");

      while (teaserWordsArray.join(" ").length > readMoreCharacterLimit) {
        remainingWordsArray.unshift(teaserWordsArray.pop());
      }

      teaserText = teaserWordsArray.join(" ");

      if (remainingWordsArray.length > 0) {
        remainingText = remainingWordsArray.join(" ");
      }
    }

    return {
      teaserText,
      remainingText,
    };
  };

  const getText = (
    showingAll: boolean,
    text: string,
    readMoreCharacterLimit: number
  ) => {
    let { teaserText, remainingText }: any = getReadMoreParts(
      text,
      readMoreCharacterLimit
    );

    if (!showingAll && text?.length > readMoreCharacterLimit) {
      const linkyfiedText: any = teaserText.replace(/\s*$/, "");
      return (
        <span>
          <span
            style={{ wordBreak: "break-word" }}
            dangerouslySetInnerHTML={{
              __html: linkifyHtml(linkyfiedText, {
                target: {
                  url: "_blank",
                },
              }),
            }}
          />
          {ELLIPSES}
        </span>
      );
    }
    const startText = teaserText ? teaserText + " " : "";
    const endText = remainingText ? remainingText + " " : "";
    const fullText = startText + endText;

    const linkyfiedText = fullText.replace(/\s*$/, "");

    return (
      <span>
        <span
          style={{ wordBreak: "break-word" }}
          dangerouslySetInnerHTML={{
            __html: linkifyHtml(linkyfiedText, {
              target: {
                url: "_blank",
              },
            }),
          }}
        />
        {ELLIPSES}
      </span>
    );
  };

  const getActionButton = (showingAll: boolean, showLessButton: any) => {
    if (showingAll && !showLessButton) {
      return;
    }

    let buttonText = showingAll ? SHOW_LESS_TEXT : SHOW_MORE_TEXT;

    return (
      <button onClick={toggleReadMore} className={classes.readmoreButton}>
        {buttonText}
      </button>
    );
  };

  let textToDisplay = getText(showingAll, text, readMoreCharacterLimit);
  let actionButton = getActionButton(showingAll, showLessButton);
  return (
    <div>
      {textToDisplay}{" "}
      {text.length > readMoreCharacterLimit && !noAction && actionButton}
    </div>
  );
};
