import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  row: {
    borderBottom: "1px solid #C4C4C4",
  },
  tableCell: {
    alignItems: "center",
    height: 110,
    color: "#000",
    borderBottom: "none",
    "@media screen and (max-width: 1150px)": {
      height: "auto",
    },
  },
  icon: {
    width: "27px",
    height: "31px",
  },
  cellHeader: {
    fontWeight: 900,
    fontSize: "20px",
    lineHeight: "27px",
    fontFamily: "'Avenir Black', sans-serif",
    "@media screen and (max-width: 1500px)": {
      height: "auto",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "22px",
    },
  },
  cellData: {
    fontWeight: 500,
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: "18px",
    lineHeight: "25px",
    marginTop: 12,
    "@media screen and (max-width: 1500px)": {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  type: {
    fontFamily: "'Avenir Black', sans-serif",
    display: "flex",
    alignItems: "center",
  },
  alignCenter: {
    display: "flex",
    alignItems: "center",
  },
  viewButton: {
    fontSize: "18px !important",
    lineHeight: "25px !important",
    height: "45px !important",
    display: "flex",
    width: "auto !important",
    padding: "0px 30px !important",
    "& span > svg": {
      marginRight: 11,
    },
    "@media screen and (max-width: 1500px)": {
      fontSize: "14px !important",
      lineHeight: "22px !important",
      display: "flex",
    },
  },
  moreOptions: {
    paddingTop: 0,
    "& > div": {
      top: "-33px",
    },
  },
});
