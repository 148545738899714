import React, { FC } from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { navigate } from "@reach/router";

import InstagramIcon from "static/media/instagram-vector.png";
import FacebookIcon from "static/media/fb-vector.png";
import TwitterIcon from "static/media/twitter-vector.png";
import { ReactComponent as PlayIcon } from "static/media/playIconGreen.svg";
import { ReactComponent as PauseIcon } from "static/media/pauseIconRed.svg";
import { ReactComponent as ViewIcon } from "static/media/viewIcon.svg";
import { useStyles } from "./styles";
import { SendButton } from "components/styled/Common";
import MoreOptions from "./Components/MoreOptions";
import { SocialMediaPlatform } from "containers/BeAmplified/Campaigns/Organic/SelectCampaign";

interface IAmplified {
  campaigns: {
    facebook: any[];
    instagram: any[];
    twitter: any[];
  };
}

const BeAmplified: FC<IAmplified> = ({ campaigns }) => {
  const classes = useStyles({});

  const icons = {
    facebook: FacebookIcon,
    instagram: InstagramIcon,
    twitter: TwitterIcon,
  };

  let allCampaigns = [
    ...campaigns.facebook,
    ...campaigns.twitter,
    ...campaigns.instagram,
  ];

  const sortedCampaigns = allCampaigns
    .filter((camp) => !!camp.createdAt)
    .sort(
      (a, b) =>
        new Date(b.createdAt?.seconds * 1000).getTime() -
        new Date(a.createdAt?.seconds * 1000).getTime()
    );

  return (
    <TableBody>
      {sortedCampaigns.map((campaign, i) => {
        const platform = campaign.platform;
        let headers = ["# of Tweets", "# of follows"];

        if (platform === "twitter") {
          if (campaign.campaignType === "autoPoster") {
            headers = ["# of Tweets", "# of Tweets sent"];
          }
        }

        if (platform === "instagram") {
          headers = ["# of posts/likes", "# of Users"];
        }

        if (platform === "facebook") {
          if (campaign.campaignType === "auto-dm") {
            headers = ["# of Users", "# of messages sent"];
          } else if (campaign.campaignType === "groupScrapper") {
            headers = ["# of Users", "# of posts/likes"];
          } else {
            headers = ["# of posts/likes", "# of Users"];
          }
        }
        return (
          <TableRow key={i + campaign.id} className={classes.row}>
            <TableCell
              align="left"
              className={`${classes.tableCell} ${classes.type}`}
            >
              {" "}
              <img
                src={icons[platform]}
                className={classes.icon}
                style={{ marginRight: 17 }}
                alt="platform"
              />{" "}
              <div>
                <div className={classes.cellHeader}>
                  {campaign.campaignName}
                </div>
                <div className={classes.cellData}>{campaign.campaignType}</div>
              </div>
            </TableCell>
            <TableCell align="left" className={classes.tableCell}>
              <div>
                <div className={classes.cellHeader}>Created</div>
                <div className={classes.cellData}>
                  {campaign.createdAt
                    ? new Date(campaign.createdAt?.seconds * 1000)
                        .toISOString()
                        ?.split("T")[0]
                    : "-:-"}
                </div>
              </div>
            </TableCell>
            <TableCell align="left" className={classes.tableCell}>
              <div>
                <div className={classes.cellHeader}>{headers[0]}</div>
                <div className={classes.cellData}>{campaign.posts}</div>
              </div>
            </TableCell>
            <TableCell align="left" className={classes.tableCell}>
              <div>
                <div className={classes.cellHeader}>{headers[1]}</div>
                <div className={classes.cellData}>
                  {campaign?.followedProfilesCount ||
                    campaign?.followsCount ||
                    campaign?.postCount ||
                    0}
                </div>
              </div>
            </TableCell>
            <TableCell align="left" className={classes.tableCell}>
              <div>
                <div className={classes.cellHeader}>Status</div>
                <div className={`${classes.cellData} ${classes.alignCenter}`}>
                  {campaign.status === "running" && <PlayIcon />}{" "}
                  {campaign.status === "paused" && <PauseIcon />}
                  <span style={{ marginLeft: 11 }}>{campaign.status}</span>
                </div>
              </div>
            </TableCell>
            <TableCell align="left" className={classes.tableCell}>
              <SendButton
                onClick={() => {
                  if (campaign.campaignType === "autoPoster") {
                    navigate(`/campaigns/organic/autoposter/${campaign.id}`);
                    return;
                  } else if (campaign.campaignType === "auto-dm") {
                    navigate(
                      `/campaigns/organic/${platform}/auto-dm/${campaign.id}`
                    );
                    return;
                  } else if (
                    campaign.campaignType === "autofollow" ||
                    campaign.campaignType === "search-scrapper"
                  ) {
                    navigate(`/campaigns/organic/${campaign.id}`);
                    return;
                  } else if (campaign.campaignType === "igAutoLiker") {
                    navigate(
                      `/campaigns/organic/auto-liker/${SocialMediaPlatform.Instagram.toLowerCase()}/${
                        campaign.id
                      }`
                    );
                    return;
                  } else if (campaign.campaignType === "igAutoCommenter") {
                    navigate(
                      `/campaigns/organic/auto-commenter/${SocialMediaPlatform.Instagram.toLowerCase()}/${
                        campaign.id
                      }`
                    );
                    return;
                  } else if (campaign.campaignType === "autoLiker") {
                    navigate(
                      `/campaigns/organic/auto-liker/${SocialMediaPlatform.Twitter.toLowerCase()}/${
                        campaign.id
                      }`
                    );
                    return;
                  } else if (campaign.campaignType === "fbAutoLiker") {
                    navigate(
                      `/campaigns/organic/auto-liker/${SocialMediaPlatform.Facebook.toLowerCase()}/${
                        campaign.id
                      }`
                    );
                    return;
                  } else if (
                    campaign.campaignType === "groupScrapper" ||
                    campaign.campaignType === "groupScrapper-scrapper"
                  ) {
                    navigate(
                      `/campaigns/organic/extractor/${SocialMediaPlatform.Facebook.toLowerCase()}/${
                        campaign.id
                      }`
                    );
                    return;
                  } else if (
                    campaign.campaignType.toLowerCase() === "autofollow" ||
                    campaign.campaignType === "hashtag-scrapper"
                  ) {
                    navigate(
                      `/campaigns/organic/${SocialMediaPlatform.Instagram}/${campaign.id}`
                    );
                    return;
                  } else if (
                    campaign.campaignType === "commentsExtractor-scrapper"
                  ) {
                    navigate(
                      `/campaigns/organic/extractor/${SocialMediaPlatform.Instagram.toLowerCase()}/${
                        campaign.id
                      }`
                    );
                    return;
                  }
                }}
                disableRipple
                className={classes.viewButton}
              >
                <ViewIcon />
                View
              </SendButton>
            </TableCell>
            <TableCell
              align="left"
              className={`${classes.tableCell} ${classes.moreOptions}`}
            >
              <MoreOptions campaign={campaign} platform={platform as any} />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default BeAmplified;
