import React, { useState } from "react";
import { Modal, Backdrop, Fade, Grid } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { useStyles } from "./styles";

import { SendButton, CancelButton } from "components/styled/Common";

interface IProp {
  handleChange: (e?: any) => void;
  setIsOpen: (e) => void;
  isOpen: boolean;
}

const UnsavedChangesPrompt = ({ handleChange, setIsOpen, isOpen }: IProp) => {
  const classes = useStyles({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState("");

  const handleClose = (e) => {
    if (e.key === "Escape") {
      setIsOpen(false);
    }
  };

  return (
    <Grid style={{ width: "80%" }}>
      <Modal
        open={isOpen}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.container}>
            <div className={classes.paper}>
              <div className={classes.top}>
                <div className={classes.print}>Attention: Unsaved Changes</div>
                <Close
                  className={classes.icon}
                  onClick={() => setIsOpen(!isOpen)}
                />
              </div>
              <div style={{ marginTop: "30px", marginBottom: "30px" }}>
                You have unsaved changes. Are you sure you want to continue
                without saving?
              </div>
            </div>
            <span className={classes.error}>{error}</span>

            <div className={classes.line} />
            <div
              className={classes.bottom}
              style={{ justifyContent: "flex-between" }}
            >
              <CancelButton disableRipple onClick={() => setIsOpen(!isOpen)}>
                Cancel
              </CancelButton>
              <SendButton
                onClick={(e) => {
                  e.preventDefault();
                  handleChange(true);
                }}
                disableRipple
              >
                <span>Confirm</span>
              </SendButton>
            </div>
          </div>
        </Fade>
      </Modal>
    </Grid>
  );
};

export default UnsavedChangesPrompt;
