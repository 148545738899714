import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    left: "0.09%",
    right: "0.28%",
    top: "0%",
    bottom: "0%",
    alignItems: "center",
    zIndex: 1,
  },
  modal: {
    display: "flex",
    alignItems: "baseline",
    width: "494px",
    height: "436px",
    margin: "auto",
    justifyContent: "flex-start",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 5px 45px rgba(0, 0, 0, 0.07)",
    borderRadius: "15px",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: "37px 37px 0px 37px",
    outline: "none",
    flexDirection: "column",
  },
  inviteButton: {
    width: "192px !important",
    height: "46px !important",
    "&:hover": {
      opacity: 0.8,
    },
    borderRadius: "10px",
  },
  icon: {
    color: "rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
  },
  top: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    height: "30px",
    fontFamily: "AvenirNext-DemiBold",
    fontSize: "22px",
    lineHeight: "26px",
    color: "#313131",
    margin: "unset",
  },
  form: {
    width: "86.3%",
  },
  buttonContainer: {
    display: "flex",
    position: "absolute",
    left: "51.03%",
    right: "5.63%",
    top: "85.18%",
    bottom: "6.55%",
    justifyContent: "flex-end",
  },
  input: {
    width: "100%",
  },
  cancel: {
    marginRight: "25px",
  },
  line: {
    position: "absolute",
    left: "0%",
    right: "0%",
    top: "80.66%",
    mixBlendMode: "normal",
    opacity: 0.3,
    border: "1px solid #979797",
  },
  subTitle: {
    height: "40px",
    fontFamily: "AvenirNext",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#313131",
    margin: "20px 0 10px 0",
  },
  label: {
    margin: "20px 0 -10px 0",
    height: "19px",
    fontFamily: "AvenirNext-DemiBold",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "bold",
    color: "#313131",
  },
  cancelLabel: {
    fontFamily: "AvenirNext-DemiBold",
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#313131",
    mixBlendMode: "normal",
    opacity: 0.5,
  },
  inviteLabel: {
    fontFamily: "AvenirNext-DemiBold",
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#1D4E89",
  },
  error: {
    color: "#f44336",
    fontSize: "13px",
    textAlign: "center",
    width: "75%",
    zIndex: 5,
  },
});
