import styled from "styled-components";
import Vimeo from "@u-wave/react-vimeo";
import { Button, Box, TableCell, TableRow } from "@material-ui/core";

//unless something is specific to a given component, let's add it to this file
//or another modularized file/dir. most of these wrappers are the same
export const PageTitle = styled.div`
  min-height: 85.5px;
  font-style: normal;
  font-size: 30px;
  line-height: 25px;
  color: #101010;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px auto;
  font-family: "Avenir Black", sans-serif;
  font-weight: 900;
  #selector {
    display: flex;
  }
`;

export const Root = styled.div`
  overflow: hidden;
  padding-bottom: 20px;
  @media screen and (max-width: 768px) {
    padding: 0 10px;
  }
`;

export const Container = styled.div`
  /* max-width: 935px; */
`;
export const Image = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;
export const Video = styled.video`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;
export const VimeoPlayer = styled(Vimeo)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    position: unset !important;
  }
`;
export const YouTube = styled.iframe`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    position: unset !important;
  }
`;
export const PostContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 70px;
`;
export const EmptyData = styled.div`
  font-size: 18px;
  line-height: 21px;
  color: #313131;
  font-family: "Avenir Book", sans-serif;
  padding-top: 73px;
`;
export const Cell = styled(TableCell)`
  align-items: start;

  &.MuiTableCell-root {
    &:first-of-type {
      padding: 16px 16px 16px 6rem;
    }
  }

  &.avatar {
    display: "inline-block";
    height: "60px";
    width: "60px";
  }
`;
export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  &.tableCell {
    align-items: start;
    &.MuiTableCell-root {
      &:first-of-type {
        padding: 16px 16px 16px 6rem;
      }
    }
  }
  &.tableData {
    font-family: "Avenir Book", sans-serif;
  }
`;
export const TableWrapper = styled.div`
  box-shadow: 0px 5px 45px rgba(0, 0, 0, 0.07);
  border-radius: 15px;
  @media screen and (max-width: 420px) {
    margin-top: 125px !important;
  }
  @media screen and (max-width: 600px) {
    margin-top: 100px;
  }
  &.table {
    min-width: 650px;
    width: 99%;
  }
`;
export const HeaderRow = styled(TableRow)`
  background-color: #ffffff;
  height: 76px;
`;
export const HeaderCell = styled(TableCell)`
  height: 22px;
  left: 0%;
  right: 94.24%;
  top: calc(50% - 22px / 2 - 0.04px);
  font-family: "Avenir Black", sans-serif;
  font-weight: 900;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
  width: 25%;
  &.MuiTableCell-root {
    &:first-of-type {
      padding: 16px 16px 16px 6rem;
    }
  }
`;
export const SendButton = styled(Button)`
  width: 137px;
  height: 30px;
  font-family: "Avenir Black", sans-serif;
  font-weight: 900;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff !important;
  text-transform: capitalize;
  mix-blend-mode: normal;
  background: ${(props) =>
    props.disabled ? "#c4c4c4 !important" : "#000000 !important"};
  border-radius: 10px;
  &:hover {
    opacity: 0.8;
    background: ${(props) =>
      props.disabled ? "#c4c4c4 !important" : "#000000 !important"};
  }
  @media screen and (max-width: 400px) {
    min-width: ${(props: any) =>
      props.fullWidth ? "100%" : "112px !important"};
  }
`;

export const SendButton2 = styled(Button)`
  width: 137px;
  height: 30px;
  font-family: "Avenir Black", sans-serif;
  font-weight: 900;
  font-size: 15px;
  line-height: 22px;
  color: "#000000",
  text-transform: capitalize;
  mix-blend-mode: normal;
  background: ${(props) =>
    props.disabled ? "#c4c4c4 !important" : "#ffffff !important"};
  border-radius: 10px;
  border: 1px solid #000;
  &:hover {
    opacity: 0.8;
    background: ${(props) =>
      props.disabled ? "#c4c4c4 !important" : "#000000 !important"};
  }
  @media screen and (max-width: 400px) {
    min-width: ${(props: any) =>
      props.fullWidth ? "100%" : "112px !important"};
  }
`;
export const CancelButton = styled(SendButton)`
  background: #abadad;
  &:hover {
    background: #abadad;
  }
`;

export const CancelButton2 = styled(SendButton2)`
  background: #ffffff;
  width: "100%",
  height: "45px",
  padding: "11.5px 8px 11.5px 8px",
  borderRadius: "15px",
  border: 1px solid #000;
  &:hover {
    background: #ffffff;
  }
`;
export const ControlLabel = styled(Box)`
  font-size: 15px;
  line-height: 22px;
  font-family: "Avenir Black", sans-serif;
  font-weight: 900;
  color: #000000;
  margin-right: 15px;
`;

export const PostPaper = styled.div`
  padding: 0px 32px 0px;
  @media screen and (max-width: 430px) {
    padding: 0px 10px 0px;
    & > div:first-of-type {
      margin: 0px -10px;
      padding: 0 5px;
    }
  }
`;

export const ArchivePaper = styled.div`
  padding: 0px 32px 0px;
`;
export const ArchiveContainer = styled.div`
  display: flex;
  align-items: baseline;
  max-width: 494px;
  width: 76%;
  height: 484px;
  margin: auto;
  justify-content: flex-start;
  background-color: #ffffff;
  box-shadow: 0px 5px 45px rgba(0, 0, 0, 0.07);
  border-radius: 15px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  outline: none;
  flex-direction: column;

  // width: "533px",
  color: #000;

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 430px) {
    height: 508px;
    width: 90%;

    h2 {
      font-size: 25px;
    }
  }
`;

export const PostCategoryStyle = styled.div`
  border: 2px solid #abadad;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  min-height: 20px;
  margin-bottom: 5px;
  border-top: none;
  label {
    display: flex;
    align-items: center;
    padding: 13px;
    font-size: 15px;
    line-height: 20px;
    color: #000;
    margin: 0;
    border-bottom: 2px solid #abadad;
    :hover {
      background: rgba(0, 0, 0, 0.08);
    }
    span:first-child {
      padding: 0 6px 0 0;

      span {
        margin: 0;
      }
    }
  }
`;

export const EmojiPicker = styled.div`
  z-index: 5222248154684;

  @media screen and (max-width: 430px) {
    & > section {
      width: 330px !important;
      right: -90px !important;
    }
  }
`;

export const Toast = styled.div`
  .toast-progressbar {
    background-color: #fff !important;
  }
`;

export const DateInput = styled.div`
  font-size: 15px;
  font-family: "Avenir Book", sans-serif;
  line-height: 20px;
  border: 2px solid #abadad;
  border-radius: 10px;
  padding: 12.5px;
  margin-top: 5px;
  width: 94%;
  max-height: 45px;
  background: #fff;

  &:hover {
    border: 2px solid #000;
  }

  &:active {
    border: 2px solid #000;
  }

  & > div {
    width: 100%;
  }

  & > div > div {
    input {
      padding: 0;
    }

    &::before,
    &::after {
      border-bottom: none;
    }

    &:hover:not(.Mui-disabled):before {
      border-bottom: none;
    }
  }
`;

export const TableHeaderWrapper = styled.div`
  width: 83%;
  overflow-x: auto;
  position: fixed;
  top: 84px;
  animation: slideDown 200ms ease-in;

  &::-webkit-scrollbar {
    height: 2px !important;
  }

  @media screen and (max-width: 1830px) {
    width: 82.8%;
  }

  @media screen and (max-width: 1680px) {
    width: 82.6%;
  }

  @media screen and (max-width: 1500px) {
    width: 82.4%;
  }

  @media screen and (max-width: 1400px) {
    width: 82.2%;
  }

  @media screen and (max-width: 1300px) {
    width: 82%;
  }

  @media screen and (max-width: 1200px) {
    width: 81.8%;
  }

  @media screen and (max-width: 1130px) {
    width: 81.4%;
  }

  @media screen and (max-width: 1000px) {
    top: 80px;
    width: 81.2%;
  }

  @media screen and (max-width: 940px) {
    width: 81%;
  }

  @media screen and (max-width: 880px) {
    width: 80.6%;
  }

  @media screen and (max-width: 810px) {
    width: 80.2%;
  }

  @media screen and (max-width: 740px) {
    width: 79.8%;
  }

  @media screen and (max-width: 630px) {
    width: 79%;
  }

  @media screen and (max-width: 600px) {
    width: 88.4%;
  }

  @media screen and (max-width: 540px) {
    width: 88%;
  }

  @media screen and (max-width: 490px) {
    width: 86.8%;
  }

  @media screen and (max-width: 400px) {
    width: 85.8%;
  }

  @media screen and (max-width: 360px) {
    width: 83.8%;
  }

  @keyframes slideDown {
    0% {
      transform: translateY(-50%);
    }
    100% {
      transform: translateY(0%);
    }
  }
`;
