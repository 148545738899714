import React, { FC, useState } from "react";
import { Grid, Box, CircularProgress, Button } from "@material-ui/core";

import Name from "components/Common/Form/Name";
import { useStyles } from "./styles";
import { capitalize } from "utils/stringFormat";
import Tooltip from "components/Common/Tooltip";
import { tooltips } from "./helpers/constants";
import { checkPermissions } from "./helpers/checkChange";
import Image from "components/Common/Image/Image";
import TitTokOAuth from "./SocialAuth/TitTokOAuth";
import FacebookOAuth from "./SocialAuth/FacebookOAuth";
import FacebookUserPages from "./SocialAuth/FacebookUserPages";
import InstagramOAuth from "./SocialAuth/InstagramOAuth";
import InstagramUserPages from "./SocialAuth/InstagramUserPages";
import FacebookOAuthPersonal from "./SocialAuth/FacebookOAuthPersonal";
import TwitterOAuth from "./SocialAuth/TwitterOAuth";
import YoutubeOAuth from "./SocialAuth/YoutubeOAuth";
// import GreenCheck from "assets/greencheck.svg";

type ISocialMediaField = {
  value: any;
  touched: any;
  errors: any;
  mediaName: string;
  link: string;
  name: string;
  placeholder: string;
  userInfo: any;
  dataError?: any;
  MediaIcon: any;
  checkChange: any;
  handleChange: any;
  setToSave: any;
  setRetry: any;
  mobileMenu: any;
};

const SocialMediaField: FC<ISocialMediaField> = ({
  value,
  touched,
  errors,
  userInfo,
  mediaName,
  link,
  name,
  placeholder,
  dataError,
  MediaIcon,
  checkChange,
  handleChange,
  setRetry,
  setToSave,
  mobileMenu,
}) => {
  const classes = useStyles({});
  const [permissionErrors, setPermErrors]: any = useState({
    instagram: "",
    facebook: "",
    twitter: "",
    tikTok: "",
    youtube: "",
    spotify: "",
    appleMusic: "",
  });
  const [permissionLoading, setPermissionLoading] = useState(false);
  const [permissionMessages, setPermissionMessages] = useState({
    instagram: "",
    facebook: "",
    twitter: "",
    tikTok: "",
    youtube: "",
    spotify: "",
    appleMusic: "",
  });

  const commonErr = "Getting historical data failed.";
  const shortCommonErr = "Historical data failed.";

  const permissions = async (username: string) => {
    setPermissionLoading(true);
    await checkPermissions(mediaName, username, {
      errors: { permissionErrors, setPermErrors },
      messages: { permissionMessages, setPermissionMessages },
    });
    setPermissionLoading(false);
    updateUI();
  };

  //forceUpdate hook
  const useForceUpdate = () => {
    const [, setValue] = useState(0);
    return () => setValue((value) => value + 1);
  };
  const updateUI = useForceUpdate();

  let username = value;
  if (mediaName === "instagram") {
    username = value.instagram ?? value.facebook;
  }

  const checkFB = () => {
    if (
      (mediaName === "facebook" && userInfo?.facebook?.accessToken) ||
      (userInfo?.facebook?.username && !userInfo?.facebook?.permission)
    ) {
      return true;
    }
    return false;
  };

  const checkIG = () => {
    if (
      (mediaName === "instagram" && userInfo?.instagram?.accessToken) ||
      (userInfo?.instagram?.username && !userInfo?.instagram?.permission)
    ) {
      return true;
    }
    return false;
  };

  return (
    <Grid item container xs={12} style={{ marginTop: "unset" }}>
      <Box component="p" width={1} className={classes.socialIcon}>
        <Grid className={classes.socialTitle}>
          <span className={classes.socialLabels}>
            <Image
              className={classes.img}
              src={MediaIcon}
              alt={`${mediaName}-icon`}
            />
            {capitalize(mediaName)}
          </span>
          <span className={classes.flex}>
            <span style={{ marginRight: "5px", textAlign: "right" }}>
              {permissionErrors[mediaName] !== "" && (
                <span className={classes.error}>
                  {permissionErrors[mediaName]}
                </span>
              )}
              {permissionMessages[mediaName] !== "" && (
                <span className={classes.error} style={{ color: "#000" }}>
                  {permissionMessages[mediaName]}
                </span>
              )}
              {value !== "" &&
                ![
                  "tiktok",
                  "facebook",
                  "instagram",
                  "twitter",
                  "youtube",
                ].includes(mediaName) && (
                  <span
                    className={classes.retry}
                    onClick={async () => {
                      await permissions(username);
                    }}
                  >
                    {permissionLoading ? (
                      <CircularProgress size={30} color="inherit" />
                    ) : (
                      "Test permissions"
                    )}
                  </span>
                )}
              {mediaName === "tiktok" && (
                <>
                  {userInfo?.tiktok?.accessToken && (
                    <span
                      style={{
                        color: "green",
                      }}
                    >
                      Connected{" "}
                    </span>
                  )}
                </>
              )}

              {mediaName === "twitter" && (
                <>
                  {userInfo?.twitter?.accessToken && (
                    <span
                      style={{
                        color: "green",
                      }}
                    >
                      Connected{" "}
                    </span>
                  )}
                </>
              )}
              {mediaName === "youtube" && (
                <>
                  {userInfo?.youtube?.accessToken && (
                    <span
                      style={{
                        color: "green",
                      }}
                    >
                      Connected{" "}
                    </span>
                  )}
                </>
              )}
              {mediaName === "facebook" && (
                <>
                  {checkFB() && (
                    <span
                      style={{
                        color: "green",
                      }}
                    >
                      Connected{" "}
                    </span>
                  )}
                </>
              )}

              {mediaName === "instagram" && (
                <>
                  {checkIG() && (
                    <span
                      style={{
                        color: "green",
                      }}
                    >
                      Connected{" "}
                    </span>
                  )}
                </>
              )}

              {dataError !== "" &&
                ["instagram", "twitter"].includes(mediaName) && (
                  <div className={classes.flex}>
                    <span
                      className={classes.error}
                      style={{ textAlign: "right" }}
                    >
                      {dataError === commonErr && mobileMenu
                        ? shortCommonErr
                        : dataError}
                    </span>
                    {/* <button
                      type="submit"
                      className={classes.retry}
                      onClick={() => {
                        setRetry(true);
                      }}
                      style={{ paddingRight: 0, marginLeft: 0 }}
                    >
                      Retry
                    </button> */}
                  </div>
                )}
            </span>
            <Tooltip title={tooltips[mediaName]} />
          </span>
        </Grid>
      </Box>

      {mediaName === "tiktok" && userInfo && (
        <TitTokOAuth userInfo={userInfo} />
      )}
      {mediaName === "twitter" && userInfo && (
        <TwitterOAuth userInfo={userInfo} />
      )}
      {mediaName === "youtube" && userInfo && (
        <YoutubeOAuth userInfo={userInfo} />
      )}

      {mediaName === "facebook" && userInfo && (
        <>
          <FacebookOAuth userInfo={userInfo} />
          <br />
          <FacebookOAuthPersonal userInfo={userInfo} />
        </>
      )}
      {mediaName === "instagram" && userInfo && (
        <InstagramOAuth userInfo={userInfo} />
      )}
      {mediaName === "facebook" &&
        userInfo &&
        userInfo?.facebook?.pages &&
        !window.location.pathname?.includes("create") && (
          <FacebookUserPages userInfo={userInfo} mediaIcon={MediaIcon} />
        )}

      {mediaName === "instagram" &&
        userInfo &&
        userInfo?.instagram?.pages &&
        !window.location.pathname?.includes("create") && (
          <InstagramUserPages userInfo={userInfo} mediaIcon={MediaIcon} />
        )}

      {!["tiktok", "facebook", "instagram", "twitter", "youtube"].includes(
        mediaName
      ) && (
        <Name
          name={name}
          label=""
          variant="outlined"
          marginTop="unset"
          width="100%"
          icon={false}
          inputSpan={link}
          spanMargin="0"
          placeholder={placeholder}
          value={mediaName === "instagram" ? value.instagram : value}
          error={errors[name] && touched[name]}
          onChangeHandler={(e) => {
            checkChange(e, mediaName, userInfo, setToSave);
            handleChange(e);
          }}
          borderColor={errors[name] && touched[name] ? "#f44336" : "#000"}
          helperText={errors[name] && touched[name] && errors[name]}
          readonly={false}
        />
      )}
      {/* {["youtube"].includes(mediaName) && (
        <Name
          name={name}
          label=""
          variant="outlined"
          marginTop="unset"
          width="100%"
          icon={false}
          inputSpan={link}
          spanMargin="0"
          placeholder={placeholder}
          value={mediaName === "instagram" ? value.instagram : value}
          error={errors[name] && touched[name]}
          onChangeHandler={(e) => {
            checkChange(e, mediaName, userInfo, setToSave);
            handleChange(e);
          }}
          borderColor={errors[name] && touched[name] ? "#f44336" : "#000"}
          helperText={errors[name] && touched[name] && errors[name]}
          readonly={true}
        />
      )} */}
    </Grid>
  );
};

export default SocialMediaField;
