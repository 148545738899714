/**
 * @prettierdesc
 */

import React, { FC, useState } from "react";
import { Grid } from "@material-ui/core";
import { navigate } from "@reach/router";

import { firestore } from "firebase-tools/firebase";
import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import { IUser } from "interfaces/IUser";
import { useUser } from "UserContext";

import SelectCampaignStyles from "components/styled/SelectCampaign";
import dropdownArrow from "static/media/dropdown-arrow.png";
import SelectCampaignBox from "components/BeAmplified/Campaigns/Organic/Components/SelectCampaignBox";
import { ToastContainer } from "react-toastify";
import showToast from "utils/toast";

type SelectBoxProps = {
  action: Function;
  title: string;
  actionText: string;
  description: string;
};

export enum SocialMediaPlatform {
  Twitter = "twitter",
  Instagram = "instagram",
  Facebook = "facebook",
}

export const SelectScrapper: FC<{ toggleCreate: Function }> = ({
  toggleCreate,
}) => {
  const [sectionCollapse, setSectionCollapse] = useState<SocialMediaPlatform[]>(
    (Object.values(SocialMediaPlatform) as unknown) as SocialMediaPlatform[]
  );
  const [authUser] = useUser() as Array<IUser>;

  let userRef = firestore.collection("users").doc(authUser.id);
  let client =
    useDocumentSubscriber({ ref: userRef }).document?.lastClientSelection || "";

  const isOpen = (section: SocialMediaPlatform) =>
    sectionCollapse.includes(section);

  const toggleSection = (section: SocialMediaPlatform) => {
    const isSectionOpen = isOpen(section);
    if (isSectionOpen) {
      setSectionCollapse((sections) =>
        sections.filter((item) => item !== section)
      );
      return;
    }

    setSectionCollapse((sections) => [...sections, section]);
  };

  const validateBeforeAction = (
    action: Function,
    platform: SocialMediaPlatform
  ) => () => {
    switch (platform) {
      case SocialMediaPlatform.Facebook:
        if (!client?.facebook?.username?.length) {
          showToast(
            `Seems like ${client?.firstName} ${client?.lastName} doesn't have ${platform} account added to the platform. Please go to Admin Panel, then find ${client?.firstName} ${client?.lastName}'s profile and Edit the account. Make sure to add the client's ${platform} account and save the changes.`
          );

          return;
        }
        break;
      case SocialMediaPlatform.Instagram:
        if (!client?.instagram?.username?.length) {
          showToast(
            `Seems like ${client?.firstName} ${client?.lastName} doesn't have ${platform} account added to the platform. Please go to Admin Panel, then find ${client?.firstName} ${client?.lastName}'s profile and Edit the account. Make sure to add the client's ${platform} account and save the changes.`
          );
          return;
        }
        break;
      case SocialMediaPlatform.Twitter:
        if (!client?.twitter?.username?.length) {
          showToast(
            `Seems like ${client?.firstName} ${client?.lastName} doesn't have ${platform} account added to the platform. Please go to Admin Panel, then find ${client?.firstName} ${client?.lastName}'s profile and Edit the account. Make sure to add the client's ${platform} account and save the changes.`
          );
          return;
        }
        break;
    }

    action(platform);
  };

  const twitterSelectBoxOptions = [
    {
      action: toggleCreate,
      title: "Twitter Hashtag",
      actionText: "Search",
      description:
        "Extracts Twitter users who post particular hashtags and keywords.",
    },
  ];
  const instagramSelectBoxOptions = [
    {
      action: toggleCreate,
      title: "Instagram Hashtag",
      actionText: "Search",
      description:
        "Extracts the most popular Instagram posts linked to a hashtag or location.",
    },
    {
      action: () => navigate("/campaigns/organic/create/comments-extractor"),
      title: "Instagram Posts",
      actionText: "Extractor",
      description: "Extracts comments from posts",
    },
  ];
  const facebookSelectBoxOptions = [
    {
      action: () =>
        navigate("/campaigns/organic/create/extractor?flow=scrappers"),
      title: "Facebook",
      actionText: "Group Scrapper",
      description: "Extracts the members of a Facebook group",
    },
  ];
  return (
    <SelectCampaignStyles>
      <ToastContainer />
      <div>
        <span className="title__main">Scrape Data</span>

        <p className="page-description">
          Select a Social Media channel from which you would like to extract
          data.
        </p>
      </div>

      {/* Organic social campaigns */}
      {/* Facebook Organic campaigns */}
      <div className="elements-container">
        <p
          className="title__organic"
          onClick={() => toggleSection(SocialMediaPlatform.Facebook)}
        >
          <span>Facebook Scrappers</span>
          <img
            src={dropdownArrow}
            alt="dropdownArrow"
            className={`dropdownArrow ${
              isOpen(SocialMediaPlatform.Facebook) ? "collapse" : ""
            }`}
          />
        </p>

        {isOpen(SocialMediaPlatform.Facebook) && (
          <Grid
            container
            style={{
              columnGap: "30px",
            }}
          >
            {facebookSelectBoxOptions.map((item: SelectBoxProps) => (
              <Grid
                lg={3}
                md={3}
                sm={12}
                xl={3}
                xs={12}
                item
                key={item.description}
              >
                <SelectCampaignBox
                  title={item.title}
                  actionText={item.actionText}
                  action={validateBeforeAction(
                    item.action,
                    SocialMediaPlatform.Facebook
                  )}
                  description={item.description}
                  platform={SocialMediaPlatform.Facebook}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </div>

      <div className="elements-container">
        <p
          className="title__organic"
          onClick={() => toggleSection(SocialMediaPlatform.Twitter)}
        >
          <span>Twitter Scrappers</span>
          <img
            src={dropdownArrow}
            alt="dropdownArrow"
            className={`dropdownArrow ${
              isOpen(SocialMediaPlatform.Twitter) ? "collapse" : ""
            }`}
          />
        </p>

        {isOpen(SocialMediaPlatform.Twitter) && (
          <Grid container spacing={2}>
            {twitterSelectBoxOptions.map((item: SelectBoxProps) => (
              <Grid
                lg={3}
                md={3}
                sm={12}
                xl={3}
                xs={12}
                item
                key={item.description}
              >
                <SelectCampaignBox
                  title={item.title}
                  action={validateBeforeAction(
                    item.action,
                    SocialMediaPlatform.Twitter
                  )}
                  actionText={item.actionText}
                  description={item.description}
                  platform={SocialMediaPlatform.Twitter}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </div>

      <div className="elements-container">
        <p
          className="title__organic"
          onClick={() => toggleSection(SocialMediaPlatform.Instagram)}
        >
          <span>Instagram Scrappers</span>
          <img
            src={dropdownArrow}
            alt="dropdownArrow"
            className={`dropdownArrow ${
              isOpen(SocialMediaPlatform.Instagram) ? "collapse" : ""
            }`}
          />
        </p>

        {isOpen(SocialMediaPlatform.Instagram) && (
          <Grid container spacing={2}>
            {instagramSelectBoxOptions.map((item: SelectBoxProps) => (
              <Grid
                lg={3}
                md={3}
                sm={12}
                xl={3}
                xs={12}
                item
                key={item.description}
              >
                <SelectCampaignBox
                  title={item.title}
                  action={validateBeforeAction(
                    item.action,
                    SocialMediaPlatform.Instagram
                  )}
                  actionText={item.actionText}
                  description={item.description}
                  platform={SocialMediaPlatform.Instagram}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </SelectCampaignStyles>
  );
};
