import axios from "axios";

const config = {
  headers: {
    Accept: "application/json",
    "X-Phantombuster-Key": process.env.REACT_APP_PHANTOM_API_KEY,
  },
};

const getLogs = async (id) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/containers/fetch-output?id=${id}`,
    config
  );

  return response.data;
};

export default getLogs;
