export const deduplicateData = async (
  startDate: string,
  endDate: string,
  platform: string,
  username: string
) => {
  const response = await fetch(
    `${process.env.REACT_APP_FUNCTIONS_BASE_URL}data-deduplicate`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ startDate, endDate, platform, username }),
    }
  );
  return await response.json();
};
