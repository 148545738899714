import React from "react";
import {
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import { useStylesNot } from "./styles";
import { INotification, NotificationStatus } from "interfaces/INotification";
import { updateNotificationStatus } from "helpers/notification/updateNotificationStatus";
import { updateNotificationArchive } from "helpers/notification/updateNotificationArchive";

interface IProp {
  anchorRef: any;
  isOpenNot: boolean;
  handleClickAway: (e) => void;
  handleListKeyDown: (e) => void;
  notification: INotification;
}

const NotificationAction = ({
  anchorRef,
  isOpenNot,
  handleClickAway,
  handleListKeyDown,
  notification,
}: IProp) => {
  const classes = useStylesNot({});

  return (
    <Popper
      open={isOpenNot}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper className={classes.popper}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <MenuList
                autoFocusItem={isOpenNot}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
                style={{ padding: "0px" }}
              >
                {notification.status === NotificationStatus.unread && (
                  <MenuItem
                    className={classes.li}
                    onClick={(e) => {
                      e.preventDefault();
                      updateNotificationStatus(
                        NotificationStatus.read,
                        notification
                      );
                    }}
                  >
                    Mark as read
                  </MenuItem>
                )}
                {notification.status === NotificationStatus.read && (
                  <MenuItem
                    className={classes.li}
                    onClick={(e) => {
                      e.preventDefault();
                      updateNotificationStatus(
                        NotificationStatus.unread,
                        notification
                      );
                    }}
                  >
                    Mark as unread
                  </MenuItem>
                )}
                {notification.archive === false && (
                  <MenuItem
                    className={classes.li}
                    onClick={(e) => {
                      e.preventDefault();
                      updateNotificationArchive(true, notification);
                    }}
                  >
                    Archive
                  </MenuItem>
                )}
                {notification.archive === true && (
                  <MenuItem
                    className={classes.li}
                    onClick={(e) => {
                      e.preventDefault();
                      updateNotificationArchive(false, notification);
                    }}
                  >
                    Unarchive
                  </MenuItem>
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default NotificationAction;
