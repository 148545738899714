export const theme = {
  container: {
    position: "relative",
    width: "94%",
    height: "45px",
  },
  input: {
    width: "100%",
    padding: "12.5px",
    border: "2px solid #ABADAD",
    boxSizing: "border-box",
    borderRadius: "10px",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    cursor: "pointer",
    fontFamily: "'Avenir Book', sans-serif",
    lineHeight: "20px",
    fontSize: "15px",

    "&:focus": {
      borderColor: "#000 !important",
      outline: "none",
    },
    "&:hover": {
      borderColor: "#000 !important",
    },
    "&:active": {
      borderColor: "#000 !important",
    },
    "&::placeholder": {
      fontFamily: "'Avenir Book', sans-serif",
      fontSize: "inherit",
      color: "#ABADAD",
    },
    "&::webkitInputPlaceholder": {
      fontFamily: "'Avenir Book', sans-serif",
      fontSize: "inherit",
      color: "#ABADAD",
    },
    "&:MsInputPlaceholder": {
      fontFamily: "'Avenir Book', sans-serif",
      fontSize: "inherit",
      color: "#ABADAD",
    },
  },
  inputFocused: {
    outline: "none",
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  suggestionsContainer: {
    display: "none",
  },
  suggestionsContainerOpen: {
    display: "block",
    position: "absolute",
    top: 41,
    width: 280,
    border: "1px solid #E8E8E8",
    backgroundColor: "#fff",
    fontFamily: "'Avenir Book', sans-serif",
    fontWeight: 300,
    fontSize: 16,
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
    zIndex: 2,
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  suggestion: {
    cursor: "pointer",
    padding: "10px 20px",
  },
  suggestionHighlighted: {
    backgroundColor: "#ddd",
  },
};
