import React, { FC } from "react";
import { Button } from "@material-ui/core";
import Cancel from "@material-ui/icons/Cancel";
import Vimeo from "@u-wave/react-vimeo";
import ReactPlayer from "react-player";

import { useStyles } from "./styles";

interface VideoProps {
  video: string;
  deleteVideo: (video, post, edit, setVideo, setLink, setLinkInput) => any;
  isEdit: any;
  platform: string;
  post: any;
  setVideo: any;
  setLink: any;
  setLinkInput: any;
  deleteIcon: any;
  isCarousel?: boolean;
  videoHeight?: string;
}
const Video: FC<VideoProps> = ({
  video,
  deleteVideo,
  isEdit,
  platform,
  post,
  setVideo,
  setLink,
  setLinkInput,
  deleteIcon,
  isCarousel,
  videoHeight = "470px",
}) => {
  const vimeoRegex = /^(http:\/\/|https:\/\/)(vimeo\.com)\/([\w]+)?$/;
  const youtubeRegex = /(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/;
  const classes = useStyles({ isCarousel });
  return (
    <div className={classes.container}>
      {video &&
        video.includes("http") &&
        (video.includes("firebasestorage") || video.includes("dropbox")) && (
          <>
            {deleteIcon && (
              <Button
                disableRipple
                style={{
                  color: "#FF5269",
                  minWidth: "20px",
                  marginLeft: "12px",
                  position: "absolute",
                  zIndex: 100,
                  right: 0,
                  top: 0,
                }}
                onClick={() =>
                  deleteVideo(
                    video,
                    post,
                    isEdit,
                    setVideo,
                    setLink,
                    setLinkInput
                  )
                }
              >
                <Cancel />
              </Button>
            )}

            <ReactPlayer
              url={video}
              controls
              height={videoHeight}
              width="100%"
            />
          </>
        )}
      {video && video.includes("http") && vimeoRegex.test(video) && (
        <>
          {deleteIcon && (
            <Button
              disableRipple
              style={{
                color: "#FF5269",
                minWidth: "20px",
                marginLeft: "12px",
                position: "absolute",
                right: 0,
                top: 0,
              }}
              onClick={() =>
                deleteVideo(
                  video,
                  post,
                  isEdit,
                  setVideo,
                  setLink,
                  setLinkInput
                )
              }
            >
              <Cancel />
            </Button>
          )}
          <Vimeo video={video} className={classes.vimeo} />
        </>
      )}
      {video && youtubeRegex.test(video) && (
        <div className={classes.vimeo} style={{ height: videoHeight }}>
          <iframe
            src={`https://www.youtube.com/embed/${
              video.split("v=")[1]
            }?autoplay=0&showinfo=0`}
            frameBorder="0"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
      )}
    </div>
  );
};

export default Video;
