import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  line: {
    position: "absolute",
    left: "0%",
    right: "0%",
    top: "69.25%",
    bottom: "30.37%",

    mixBlendMode: "normal",
    opacity: 0.3,
    border: "1px solid #979797",
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0px -32px",
    padding: "0 20px",
    height: 68,
    background: "#ABADAD",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    marginBottom: 30,
  },
  modal: {
    display: "flex",
    alignItems: "left",
    width: "496px",
    height: "240px",
    margin: "auto",
    justifyItems: "left",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 5px 45px rgba(0, 0, 0, 0.07)",
    borderRadius: "15px",
    flexDirection: "column",
    padding: "0px 32px 0 32px",
    outline: "none",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "absolute",
  },
  icon: {
    color: "#000",
    cursor: "pointer",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    left: "51.03%",
    right: "5.63%",
    top: "80.18%",
    bottom: "10.98%",
  },
  switchBase: {
    "&$checked": {
      "& + $track": {
        backgroundColor: "rgba(118, 236, 151, 0.4)",
      },
      "& $thumb": {
        backgroundColor: "#76EC97",
        color: "#76EC97",
      },
    },
  },
  thumb: {
    backgroundColor: "#FF5269",
  },
  track: {
    backgroundColor: "rgba(255, 82, 105, 0.4)",
  },
  checked: {},
  label: {
    height: "22px",
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: "15px",
    lineHeight: "22px",
    color: "#000000",
  },
  title: {
    height: "30px",
    fontFamily: "'Avenir Black', sans-serif",
    fontWeight: "bold",
    fontSize: "22px",
    lineHeight: "26px",
    color: "#313131",
  },
});
