import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  client: {
    "& legend": {
      width: 0,
    },
  },
  actions: {
    "@media screen and (max-width: 435px)": {
      display: "flex",
      flexDirection: "column-reverse",
    },
  },
  socialIcon: {
    display: "flex",
    alignItems: "center",
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: "15px",
    lineHeight: "20px",
    color: "#000000",
    maxWidth: "450px",
  },
  avatarBox: {
    marginTop: "20px",
    height: "114px",
  },
  img: {
    width: "40px",
    left: "35.67%",
    right: "60.36%",
    top: "15.64%",
    bottom: "18.79%",
    marginRight: "15px",
  },
  subTitle: {
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: "20px",
    lineHeight: "27px",
    color: "#000000",
    margin: "unset",
    width: "fit-content",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  inputLabel: {
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: 15,
    lineHeight: "20px",
    color: "#000000",
    margin: "unset",
  },
  bar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "40px 0 30px 0",

    "@media screen and (max-width: 435px)": {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  title: {
    fontFamily: "'Avenir Black', sans-serif",
    fontWeight: "bold",
    fontSize: "30px",
    lineHeight: "25px",
    color: "#000000",
  },
  button: {
    height: "45px !important",
    width: "192px !important",
    fontSize: "16px !important",
    textTransform: "none",
    borderRadius: "10px",
    marginBottom: 8,
    fontFamily: "'Avenir Black', sans-serif",
  },
  addButton: {
    height: "45px !important",
    width: "162px !important",
    fontSize: "16px !important",
    textTransform: "none",
    borderRadius: "10px",
    marginBottom: 8,
    fontFamily: "'Avenir Black', sans-serif",
  },
  error: {
    color: "#f44336",
    fontSize: "13px",
    textAlign: "center",
    width: "100%",
    zIndex: 5,
  },
  flex: {
    display: "flex",
  },
  switchBase: {
    "&$checked": {
      "& + $track": {
        backgroundColor: "rgba(118, 236, 151, 0.4)",
      },
      "& $thumb": {
        backgroundColor: "#76EC97",
        color: "#76EC97",
      },
    },
  },
  thumb: {
    backgroundColor: "#FF5269",
  },
  track: {
    backgroundColor: "rgba(255, 82, 105, 0.4)",
  },
  checked: {},
  socialTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  socialLabels: {
    display: "flex",
    alignItems: "center",
  },
  retry: {
    textDecoration: "underline",
    cursor: "pointer",
    marginLeft: "5px",
    fontSize: "13px",
    border: "none",
    fontFamily: "'Avenir Book', sans-serif",
  },
  navigate: {
    fontSize: "15px",
    fontFamily: "'Avenir Book', sans-serif",
    lineHeight: "22px",
    marginTop: "-60",
    display: "grid",
    gridGap: "0.5rem",
    width: "fit-content",

    "& > :last-child": {
      marginTop: 10,
    },
  },
  navigateLink: {
    lineHeight: "20px",
    marginTop: "15px",
    textDecoration: "none",
    marginRight: "20px",
    cursor: "pointer",
    color: "black",
    display: "flex",
    alignItems: "center",
  },
  linkIcon: {
    marginRight: "19px",
    fill: "#000",
  },
  notes: {
    border: "2px solid #ABADAD",
    width: "100%",
    minHeight: "140px",
    padding: "12.5px",
    boxSizing: "border-box",
    borderRadius: "10px",
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: "15px",
    resize: "vertical",
    overflow: "auto",
    marginTop: "7px",
    "&:focus": {
      outline: "none",
    },
    "&:empty:not(:focus):before": {
      content: "attr(placeholder)",
      color: "#979797",
      fontSize: "16px",
    },
  },
  notesDisclaimer: {
    fontSize: "13px",
    color: "#000000",
    width: "100%",
    textAlign: "right",
    fontFamily: "'Avenir Book', sans-serif",
  },
  link: {
    marginLeft: 10,
    backgroundColor: "#d6ecd2",
    color: "#49852e",
    textTransform: "capitalize",
    borderRadius: 32,
    padding: "3px 8px 2px",
    fontFamily: '"Avenir Black", sans-serif',
  },
  info: {
    fontFamily: "Avenir Book, sans-serif",
    fontSize: 12,
    backgroundColor: "slategrey",
  },
  arrow: {
    color: "slategrey !important",
  },
  checkboxLabel: {
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: 15,
  },
});
