import { Grid } from "@material-ui/core";
import React from "react";

interface IProp {
  impressions: string;
  likeCount: string;
  retweets: string;
}
const TwitterDataCard = ({ impressions, likeCount, retweets }: IProp) => {
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={4} md={4}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{
              color: "#000",
              fontFamily: "Avenir",
              fontSize: "16px",
              fontWeight: 900,
              lineHeight: "24px",
            }}
          >
            {numberWithCommas(impressions)}
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{
              color: "#000",
              fontFamily: "Avenir",
              fontSize: "12px",
            }}
          >
            Impressions
          </Grid>
        </Grid>
        <Grid item xs={4} md={4}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{
              color: "#000",
              fontFamily: "Avenir",
              fontSize: "16px",
              fontWeight: 900,
              lineHeight: "24px",
            }}
          >
            {numberWithCommas(likeCount)}
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{
              color: "#000",
              fontFamily: "Avenir",
              fontSize: "12px",
            }}
          >
            Likes
          </Grid>
        </Grid>
        <Grid item xs={4} md={4}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{
              color: "#000",
              fontFamily: "Avenir",
              fontSize: "16px",
              fontWeight: 900,
              lineHeight: "24px",
            }}
          >
            {numberWithCommas(retweets)}
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{
              color: "#000",
              fontFamily: "Avenir",
              fontSize: "12px",
            }}
          >
            Retweets
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default TwitterDataCard;
