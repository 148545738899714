import React, { FunctionComponent } from "react";
import { FormControlLabel, FormControl, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { errorChecker } from "../helpers/errorChecker";

const useStyles = makeStyles({
  fieldset: {
    "&:last-of-type": {
      "& label": {
        "&:last-of-type": {
          borderBottom: "none",
        },
      },
    },
  },
});

interface Props {
  categoryOptions: string[];
  selectedCategory: string;
  platform: string;
  handleChange: (event: any, platform: string) => void;
  setCategoryError: (categoryError: CategoryError) => void;
  customCheck: any;
  category: any;
  selectedChannels: any;
}
type CategoryError = {
  facebook: string;
  instagram: string;
  twitter: string;
  tiktok: string;
  youtube: string;
  custom: string;
};
const CategoryCheckboxes: FunctionComponent<Props> = ({
  categoryOptions,
  selectedCategory,
  handleChange,
  platform,
  setCategoryError,
  customCheck,
  category,
  selectedChannels,
}) => {
  const classes = useStyles({});

  const updateCategoryErrors = (e) => {
    const { errors } = errorChecker(
      selectedChannels,
      { ...category, [platform]: [e.currentTarget.value] },
      customCheck
    );

    setCategoryError(errors);
  };

  return (
    <FormControl
      component="fieldset"
      style={{ width: "100%" }}
      className={classes.fieldset}
    >
      {categoryOptions.map((category, i) => (
        <FormControlLabel
          key={`category-${i}`}
          value={category}
          control={
            <Checkbox
              key={`category-${i}`}
              checked={selectedCategory.includes(category)}
              style={{ color: "#000" }}
              onChange={(e) => {
                updateCategoryErrors(e);
                handleChange(e, platform);
              }}
            />
          }
          label={category}
        />
      ))}
    </FormControl>
  );
};

export default CategoryCheckboxes;
