import styled from "styled-components";

const BePlannedFeedV2 = styled.div`
  overflow: hidden;
  padding-bottom: 20px;

  .selector {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 15px;

    & > :nth-child(2),
    & > :nth-child(3) {
      margin-bottom: -5px;
      margin-right: 15px;
    }
  }

  #date-picker {
    margin-right: 15px;
  }

  #client-list > :nth-child(2) > :first-child > :first-child {
    margin-right: 0;
  }

  .selector-container {
    display: flex;
    align-items: baseline;
  }

  .icons {
    display: flex;
    align-items: baseline;
  }

  @media only screen and (max-width: 1130px) {
    .selector-container {
      display: flex;
      align-items: center !important;
      flex-direction: column !important;

      & > div > #print {
        margin-right: 0px !important;
      }
    }

    .be-planned-title #selector {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }
    #selector {
      display: flex;
      align-items: center !important;
      flex-direction: column-reverse;

      * {
        margin-right: 0px;
      }
      #client-list,
      #date-picker {
        margin-top: 10px;
      }
    }

    #create-post {
      margin-top: 15px;
    }
    #client-list {
      width: 100%;

      & > div {
        width: 100%;

        & > div {
          width: 100%;
        }
      }
    }

    #filter > svg {
      margin-right: 24px;
    }
  }
`;

export default BePlannedFeedV2;
