import React, { FC, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { DateTime } from "luxon";
import { navigate } from "@reach/router";
import { firestore } from "firebase-tools/firebase";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Box,
  Switch,
  LinearProgress,
  FormControlLabel,
} from "@material-ui/core";
import { CSVLink } from "react-csv";

import { ReactComponent as DoubleArrow } from "static/media/doublePlayArrow.svg";
import { useStyles } from "./styles";
import { SendButton, CancelButton } from "components/styled/Common";
import { EditIcon } from "components/icons/actionIcons";
import { ReactComponent as PlayIcon } from "static/media/whitePlay.svg";
import { ReactComponent as ExportIcon } from "static/media/export.svg";
import { ReactComponent as RunningIcon } from "static/media/runningIcon.svg";
import Charts from "../../Components/Charts";
import { getChartsData } from "utils/sanitizeCampaigns";
import { customHeaders, IGCustomHeaders } from "../../../constants";
import PageTitle from "components/Common/PageTitle";
import PhantomLogs from "../../Components/HistoryConsole";
import getLogs from "utils/getLogs";
import { SocialMediaPlatform } from "containers/BeAmplified/Campaigns/Organic/SelectCampaign";
import { getPlatformImage } from "../../Components/SelectCampaignBox";
import { useContainerOutput } from "helpers/instagramAutoFollow/useContainerOutput";

interface ICampaign {
  campaign: any;
  platform: SocialMediaPlatform;
  collectionName: string;
}

const BorderLinearProgress = withStyles({
  root: {
    height: 6,
    borderRadius: 7,
    "&:hover": {
      opacity: 0.5,
    },
  },
  colorPrimary: {
    background: "#F2F2F2",
  },
  bar: {
    borderRadius: 7,
    background: "#598CE1",
    animationDuration: "8s",
  },
})(LinearProgress);

const Campaign: FC<ICampaign> = ({ campaign, platform, collectionName }) => {
  const classes = useStyles({});
  const [showConsole, setShowConsole] = useState(true);
  const [logs, setLogs] = useState("");

  const toggle = () => {
    return setShowConsole(!showConsole);
  };

  useEffect(() => {
    const containerId =
      platform === SocialMediaPlatform.Instagram
        ? campaign.followContainerId === "pending" ||
          !campaign.followContainerId
          ? campaign.containerId
          : campaign.followContainerId
        : campaign.containerId;
    (async () => {
      let logs = await getLogs(containerId);

      if (!logs.output) {
        await new Promise((resolve) => setTimeout(resolve, 10000));
        logs = await getLogs(containerId);
      }

      setLogs(logs.output);
    })();

    // eslint-disable-next-line
  }, []);

  const metrics = getChartsData(campaign);
  const twitterHeaderDetails = [
    {
      title: "Start date",
      type: "date",
      value: campaign.createdAt || "",
    },
    {
      title: "End date",
      type: "date",
      value: campaign.endDate || "",
    },
    {
      title: "# of Tweets",
      value: campaign.tweetsCount || campaign.followsCount,
    },
    {
      title: "# of users",
      value: campaign.usersCount,
    },
    ...(campaign.campaignType === "search-scrapper"
      ? []
      : [
          {
            title: "# of follows sent",
            value: campaign?.followedProfilesCount || 0,
          },
        ]),
  ];

  const instagramHeaderDetails = [
    {
      title: "Start date",
      type: "date",
      value: campaign.createdAt || "",
    },
    {
      title: "End date",
      type: "date",
      value: campaign.endDate || "",
    },
    {
      title: "# of users",
      value: campaign.profiles?.length,
    },

    ...(campaign.campaignType === "hashtag-scrapper"
      ? []
      : [
          {
            title: "# of follows sent",
            value: campaign.profiles?.filter((profile) => profile.following)
              .length,
          },
        ]),
  ];

  const facebookHeaderDetails = [
    {
      title: "Start date",
      type: "date",
      value: campaign.createdAt || "",
    },
    {
      title: "End date",
      type: "date",
      value: campaign.endDate || "",
    },
    {
      title: "# of users",
      value: campaign.profiles?.length,
    },
    {
      title: "# of messages sent",
      value: campaign.profiles?.filter((profile) => profile.sent).length,
    },
  ];

  const platformHeaderDetails = {
    [SocialMediaPlatform.Facebook]: facebookHeaderDetails,
    [SocialMediaPlatform.Twitter]: twitterHeaderDetails,
    [SocialMediaPlatform.Instagram]: instagramHeaderDetails,
  };

  const headerDetails = platformHeaderDetails[platform] || twitterHeaderDetails;

  const HeaderDetails = (props) => {
    const { type, title, value } = props;
    return (
      <div>
        <div className={classes.headerTitle}>{title}</div>
        <div className={classes.headerContent}>
          {" "}
          {value
            ? type === "date"
              ? DateTime.fromJSDate(new Date(value.seconds * 1000)).toFormat(
                  "MM/dd/yyyy"
                )
              : value
            : type === "date"
            ? "--/--/----"
            : "--"}
        </div>
      </div>
    );
  };

  const updateCampaignStatus = async (status: string) => {
    const history = campaign.history || [];
    history.push({
      status: status,
      date: new Date(),
    });
    await firestore.collection(collectionName).doc(campaign.id).update({
      status: status,
      history,
    });
  };

  const exportData = useContainerOutput(campaign, platform);
  const campaignName = campaign.campaignType.includes("-scrapper")
    ? "hashtag search"
    : "auto follower";

  return (
    <div className="root">
      <div style={{ marginTop: 40 }}>
        <button
          onClick={() =>
            navigate(
              `/campaigns/organic${
                campaign.campaignType.includes("-scrapper")
                  ? "?flow=scrappers"
                  : ""
              }`
            )
          }
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            fontSize: "18px",
            fontWeight: 600,
            color: "#000000",
            fontFamily: "'Avenir Book', sans-serif",
            border: "none",
            cursor: "pointer",
          }}
        >
          <ArrowBackIcon />
          Back to all campaigns
        </button>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          height="120px"
        >
          <PageTitle
            title={
              <div className={classes.itemsCenter}>
                <span className={classes.itemsCenter}>
                  <img
                    src={getPlatformImage(platform)}
                    alt="platform"
                    className={classes.mainIcon}
                  />{" "}
                  <DoubleArrow />
                </span>{" "}
                <span
                  style={{
                    width: "400px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {campaign.campaignName}
                </span>
              </div>
            }
          />
          <div>
            {(campaign.status === "running" || campaign.status === "new") && (
              <SendButton
                className={classes.editButton}
                onClick={() => updateCampaignStatus("paused")}
                style={{ marginRight: 18 }}
              >
                <EditIcon color="#fff" />
                <span style={{ marginLeft: 10 }}>Pause Campaign</span>
              </SendButton>
            )}

            {campaign.status === "paused" && (
              <SendButton
                className={classes.editButton}
                onClick={() => updateCampaignStatus("running")}
                style={{ marginRight: 18 }}
              >
                <PlayIcon color="#fff" />
                <span style={{ marginLeft: 10 }}>Resume Campaign</span>
              </SendButton>
            )}

            <SendButton
              className={classes.editButton}
              onClick={() => navigate(`${campaign.id}/edit`)}
            >
              <EditIcon color="#fff" />
              <span style={{ marginLeft: 10 }}>Edit Campaign</span>
            </SendButton>
          </div>
        </Box>
      </div>

      <div className={`${classes.progress}`}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <div style={{ width: "75%" }}>
            <div style={{ marginBottom: 10 }} className={classes.label}>
              {`${platform} `} {campaignName}
            </div>
            {campaign.status === "running" || campaign.status === "new" ? (
              <BorderLinearProgress variant="indeterminate" />
            ) : (
              <BorderLinearProgress variant="determinate" value={50} />
            )}

            {(campaign.status === "running" || campaign.status === "new") && (
              <div className={classes.running}>
                <RunningIcon style={{ marginRight: 10 }} /> Running{" "}
              </div>
            )}
          </div>

          <div>
            <div className={classes.toggleLabel}>
              {showConsole ? "Hide console" : "Show Console"}
            </div>
            <FormControlLabel
              control={
                <Switch
                  checked={showConsole}
                  onChange={toggle}
                  name="Show console"
                  classes={{
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                  }}
                />
              }
              label=""
            />
          </div>
        </Box>
        <div>
          {showConsole && (
            <div className={classes.console}>
              {!campaign.history && (
                <PhantomLogs type="info" message="No Logs available" />
              )}
              {logs && <PhantomLogs type="info" message={logs} logs />}
            </div>
          )}
        </div>

        <div className={`${classes.progress} ${classes.itemsCenter}`}>
          <div
            className={classes.itemsCenter}
            style={{ width: "75%", justifyContent: "space-between" }}
          >
            {headerDetails.map((item) => (
              <HeaderDetails
                title={item.title}
                type={item.type}
                value={item.value}
              />
            ))}
          </div>
          {exportData && (
            <CSVLink
              data={exportData}
              headers={
                platform === "instagram" ? IGCustomHeaders : customHeaders
              }
              // headers={
              //   platform === SocialMediaPlatform.Twitter
              //     ? customHeaders
              //     : Object.keys(exportData[0]).map((key) => ({
              //         label: toStartCase(key),
              //         key,
              //       }))
              // }
              style={{
                textDecoration: "none",
              }}
              target="_blank"
              rel="noopener noreferrer"
              filename="results.csv"
            >
              <CancelButton className={classes.editButton}>
                <ExportIcon /> <span style={{ marginLeft: 10 }}>Export</span>
              </CancelButton>
            </CSVLink>
          )}
        </div>
      </div>
      {platform === SocialMediaPlatform.Twitter &&
        campaign.campaignType !== "search-scrapper" && (
          <Charts metrics={metrics} />
        )}
    </div>
  );
};

export default Campaign;
