export default {
  Monday: [
    {
      title: "Tweet",
      time: "03:00 PM",
      category: { twitter: ["Twitter Feed"] },
      channels: ["twitter"],
    },
    {
      title: "Tweet",
      time: "04:00 PM",
      category: { twitter: ["Twitter Feed"] },
      channels: ["twitter"],
    },
    {
      title: "IG Story",
      time: "04:00 PM",
      category: { instagram: ["Instagram Story"] },
      channels: ["instagram"],
    },
    {
      title: "High Quality Image Post with Engagement CTA",
      time: "06:00 PM",
      category: {
        twitter: ["Twitter Feed"],
        facebook: ["Facebook Feed"],
        instagram: ["Instagram Feed"],
      },
      channels: ["twitter", "facebook", "instagram"],
    },
  ],
  Tuesday: [
    {
      title: "Tweet",
      time: "11:00 AM",
      category: { twitter: ["Twitter Feed"] },
      channels: ["twitter"],
    },
    {
      title: "Tweet",
      time: "03:00 PM",
      category: { twitter: ["Twitter Feed"] },
      channels: ["twitter"],
    },
    {
      title: "Driver to DSPS/ Tour etc. with video",
      time: "06:00 PM",
      category: {
        twitter: ["Twitter Feed"],
        facebook: ["Facebook Feed"],
        instagram: ["Instagram Feed"],
      },
      channels: ["twitter", "facebook", "instagram"],
    },
    {
      title: "IG Story",
      time: "06:00 PM",
      category: { instagram: ["Instagram Story"] },
      channels: ["instagram"],
    },
  ],
  Wednesday: [
    {
      title: "IG Sticker /Q&A/Poll/Thread/GIF, Live",
      time: "11:00 AM",
      category: ["Twitter Feed", "Instagram Feed"],
      channels: ["twitter", "instagram"],
    },
    {
      title: "Tweet",
      time: "03:00 PM",
      category: { twitter: ["Twitter Feed"] },
      channels: ["twitter"],
    },
    {
      title: "TikTok/Reels Content",
      time: "03:00 PM",
      category: ["Instagram Reels", "TikTok Feed"],
      channels: ["instagram", "tiktok"],
    },
    {
      title: "UGC Content post",
      time: "06:00 PM",
      category: {
        twitter: ["Twitter Feed"],
        facebook: ["Facebook Feed"],
        instagram: ["Instagram Feed"],
      },
      channels: ["twitter", "facebook", "instagram"],
    },
  ],
  Thursday: [
    {
      title: "Tweet",
      time: "11:00 AM",
      category: { twitter: ["Twitter Feed"] },
      channels: ["twitter"],
    },
    {
      title: "Tweet",
      time: "03:00 PM",
      category: { twitter: ["Twitter Feed"] },
      channels: ["twitter"],
    },
    {
      title: "IG Story",
      time: "03:00 PM",
      category: { instagram: ["Instagram Story"] },
      channels: ["instagram"],
    },
    {
      title: "IG Carousel/ FB Gallery",
      time: "06:00 PM",
      category: {
        twitter: ["Twitter Feed"],
        facebook: ["Facebook Feed"],
        instagram: ["Instagram Feed"],
      },
      channels: ["twitter", "facebook", "instagram"],
    },
  ],
  Friday: [
    {
      title: "TikTok/Reels Content",
      time: "11:00 AM",
      category: ["Instagram Reels", "TikTok Feed"],
      channels: ["instagram", "tiktok"],
    },
    {
      title: "IG Sticker /Q&A/Poll/Thread/GIF, Live",
      time: "11:00 AM",
      category: ["Twitter Feed", "Instagram Feed"],
      channels: ["twitter", "instagram"],
    },
    {
      title: "YT Community Update",
      time: "11:00 AM",
      category: { youtube: ["YouTube Community Tab"] },
      channels: ["youtube"],
    },
    {
      title: "Tweet",
      time: "03:00 PM",
      category: { twitter: ["Twitter Feed"] },
      channels: ["twitter"],
    },
    {
      title: "IG Story",
      time: "03:00 PM",
      category: { instagram: ["Instagram Story"] },
      channels: ["instagram"],
    },
  ],
  Saturday: [
    {
      title: "Photo or video post",
      time: "02:00 PM",
      category: {
        twitter: ["Twitter Feed"],
        facebook: ["Facebook Feed"],
        instagram: ["Instagram Feed"],
      },
      channels: ["twitter", "facebook", "instagram"],
    },
  ],
  Sunday: [
    {
      title: "Personal post",
      time: "02:00 PM",
      category: {
        twitter: ["Twitter Feed"],
        facebook: ["Facebook Feed"],
        instagram: ["Instagram Feed"],
      },
      channels: ["twitter", "facebook", "instagram"],
    },
  ],
};
