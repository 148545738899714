import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import FbIcon from "../../assets/facebook.png";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { DeletePost } from "./DeletePost";
import ViewPost from "./ViewPost";

const columns = [
  { id: "clientName", label: "Client", minWidth: 100 },
  { id: "title", label: "Post Name", minWidth: 150 },
  { id: "platform", label: "Platform", minWidth: 100 },
  { id: "date", label: "Date & Time", minWidth: 150 },
  { id: "status", label: "Status", minWidth: 100 },
  { id: "actions", label: "", minWidth: 50 },
];
export const CustomPagination = ({
  count,
  page,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
}) => {
  const totalPages = Math.ceil(count / rowsPerPage);
  const startItem = page * rowsPerPage + 1;
  const endItem = Math.min((page + 1) * rowsPerPage, count);

  const handlePrevClick = () => {
    onChangePage(page - 1);
  };

  const handleNextClick = () => {
    onChangePage(page + 1);
  };

  const handlePageClick = (pageNumber) => {
    onChangePage(pageNumber);
  };

  return (
    <TableRow>
      <TableCell colSpan={columns.length}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={handlePrevClick}
              disabled={page === 0}
              style={{
                backgroundColor: "#F1F1F1",
                border: "1px",
                borderRadius: "8px",
                padding: "10px",
                height: "32px",
                width: "32px",
              }}
            >
              <NavigateBeforeIcon />
            </IconButton>
            {[...Array(Math.min(totalPages, 3)).keys()].map((pageNumber) => (
              <IconButton
                key={pageNumber}
                style={{
                  cursor: "pointer",
                  backgroundColor: pageNumber === page ? "#000000" : "#F1F1F1",
                  color: pageNumber === page ? "#F1F1F1" : "#000000",
                  border: "1px",
                  borderRadius: "8px",
                  padding: "10px",
                  height: "32px",
                  width: "32px",
                  marginLeft: "5px",
                  marginRight: "5px",
                  fontSize: "13px",
                  fontFamily: "Avenir",
                  fontWeight: 350,
                }}
                onClick={() => handlePageClick(pageNumber)}
              >
                {pageNumber + 1}
              </IconButton>
            ))}
            <IconButton
              onClick={handleNextClick}
              disabled={page >= totalPages - 1}
              style={{
                backgroundColor: "#F1F1F1",
                border: "1px",
                borderRadius: "8px",
                padding: "10px",
                height: "32px",
                width: "32px",
              }}
            >
              <NavigateNextIcon />
            </IconButton>
          </div>
          <div>
            <span style={{ marginRight: "10px" }}>Items per page</span>
            <Select
              variant="outlined"
              value={rowsPerPage}
              onChange={(e) => onChangeRowsPerPage(e.target.value)}
              displayEmpty
              style={{
                height: "42px",
                width: "70px",
              }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
            </Select>
            <span style={{ marginRight: "10px" }}>
              {`${startItem}-${endItem} of ${count} items`}
            </span>
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};
