import { fontFamily } from "html2canvas/dist/types/css/property-descriptors/font-family";
import React from "react";

const AvatarIntitials = ({ name }) => {
  // Function to generate initials from the name
  const getInitials = (name) => {
    const parts = name.split(" ");
    return parts
      .map((part) => part[0])
      .join("")
      .toUpperCase();
  };

  // Generate initials
  const initials = getInitials(name);

  // Inline styles for the avatar circle
  const circleStyle: any = {
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    backgroundColor: "#000000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "18px",
    fontWeight: 500,
    color: "#fff",
    fontFamily: "Avenir",
  };

  return (
    <div style={{ display: "inline-block" }}>
      <div style={circleStyle}>
        <span>{initials}</span>
      </div>
    </div>
  );
};

export default AvatarIntitials;
