import styled from "styled-components";

const HealthCheckStyled = styled.div`
  .choose-platform {
    margin-right: 70px;
  }

  .platform-checkbox {
    margin-right: 32px;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 450px) {
    .choose-platform {
      margin-top: 24px;
    }
    .platform-checkbox {
      min-width: 104px;
    }
  }
`;

export default HealthCheckStyled;
