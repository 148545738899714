import React from "react";
import { BeMeasuredV2 } from "./BeMeasuredV2";

// import { BeMeasured } from "./BeMeasured";

const areEqual = (prevProps, nextProps): any => {
  if (prevProps === nextProps) {
    return true;
  }
};
export default React.memo(BeMeasuredV2, areEqual);
