import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  tags: {
    fontSize: "14px",
    fontFamily: "'Avenir Book', sans-serif",
    border: "2px solid #ABADAD",
    borderRadius: "10px",
    padding: "5.5px 10.5px",
    marginTop: "5px",
    cursor: "pointer",
    background: "#FFF",
    height: "45px",
    "&.Mui-focused": {
      border: "2px solid #000",
    },
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      border: "2px solid #000",
    },
    "&:active": {
      border: "2px solid #000",
    },
    "&::placeholder": {
      fontFamily: "inherit",
      fontSize: "inherit",
      color: "#ABADAD",
    },
    "&::-webkit-input-placeholder": {
      fontFamily: "inherit",
      fontSize: "inherit",
      color: "#ABADAD",
    },
    "&:-ms-input-placeholder": {
      fontFamily: "inherit",
      fontSize: "inherit",
      color: "#ABADAD",
    },
  },
  tagsField: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#ABADAD",
        borderWidth: "thin",
      },
    },
    "&.MuiFormControl-marginNormal": {
      marginTop: 0,
      marginBottom: 0,
    },
    "& .MuiInputBase-input": {
      height: "8px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0",
    },
    "& .MuiInputAdornment-positionStart": {
      marginRight: "5.5px",
    },
  },
  helperText: {
    color: "rgb(209, 44, 0)",
    fontSize: "0.8rem",
    textAlign: "center",
  },
  suggestions: {
    border: "2px solid #ABADAD",
    borderRadius: "10px",
    borderTopWidth: "0",
    listStyle: "none",
    margin: "0",
    overflowY: "auto",
    paddingLeft: "0",
    width: "calc(300px + 1rem)",
    fontSize: "15px",
    "& li": {
      padding: "0.5rem",
      "&:hover": {
        backgroundColor: "#ABADAD",
        color: "rgba(0, 0, 0, 0.87)",
        cursor: "pointer",
        fontWeight: 700,
      },
    },
  },
  suggestionActive: {
    backgroundColor: "#ABADAD",
    color: "rgba(0, 0, 0, 0.87)",
    cursor: "pointer",
    fontWeight: 700,
  },
}));
