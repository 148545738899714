import React, { FC } from "react";
import { TableCell, CircularProgress } from "@material-ui/core";

import { CautionWarningIcon, useStyles } from "./styles";

interface CellProps {
  metric: any;
  loader: boolean;
  percentage?: boolean;
  name?: string;
  posts?: boolean;
  permission: any;
  username?: any;
}
export const DataCell: FC<CellProps> = ({
  metric,
  loader,
  percentage,
  name,
  posts,
  permission,
  username,
}) => {
  const classes = useStyles({});
  const setBackgroundColor = (metric: any) => {
    let backgroundColor: string = "FFFFFF";
    if (metric) {
      if (
        (metric.growthRate &&
          Number(metric.growthRate?.replace(/,/g, "")) < 0) ||
        (!metric.growth && metric === "0")
      ) {
        backgroundColor = "#FFDCE1";
      } else if (
        (metric.growthRate &&
          Number(metric.growthRate?.replace(/,/g, "")) >= 0) ||
        (!metric.growth && metric !== "0")
      ) {
        backgroundColor = "#B8FFCB";
      }
    }
    return backgroundColor;
  };
  const metricDisplay = metric?.growth ? metric?.growth : metric;

  return (
    <TableCell
      align="center"
      className={classes.metricCell}
      style={{
        backgroundColor: setBackgroundColor(metric),
        zIndex: 1,
        position: "relative",
      }}
    >
      {!loader ? (
        metric ? (
          <div className={classes.clientValue}>
            {Number(parseFloat(metricDisplay.replace(/,/g, ""))) > 0 &&
              !posts &&
              "+"}
            {/* {Number(parseFloat(metricDisplay.replace(/,/g, ""))) > 0 &&
              name !== "engagement" &&
              !posts &&
              "+"} */}
            {name === "engagement"
              ? metricDisplay.replace(/-/g, "")
              : metricDisplay}
            {percentage && percentage && "%"}
            <br />
            {metric.growthRate ? (
              metric.growthRate === "N/A" ? (
                <>
                  <span>(0.00%)</span>
                </>
              ) : (
                <span>
                  {"("}
                  {Number(parseFloat(metric.growthRate)) > 0.0 && "+"}
                  {Number(parseFloat(metric.growthRate).toFixed(2)) || 0.0}
                  {"%)"}
                </span>
              )
            ) : null}
          </div>
        ) : permission === false ? (
          <div className="">
            <CautionWarningIcon />
            <p>No permission</p>
          </div>
        ) : !username ? (
          <div className="">
            {/* <CautionWarningIcon /> */}
            <p>No Account</p>
          </div>
        ) : (
          "No Data"
        )
      ) : (
        <CircularProgress
          size={15}
          style={{
            color: "#1D4E89",
          }}
        />
      )}
    </TableCell>
  );
};
