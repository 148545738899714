import { Button } from "@material-ui/core";
import Axios from "axios";
import { IUser } from "interfaces/IUser";
import React from "react";

const YoutubeOAuth = ({ userInfo }: { userInfo: IUser }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { email } = userInfo || {};

  const buttonStyles: any = {
    borderRadius: "10px",
    color: "#000000",
    fontSize: "14px",
    fontWeight: "bold",
    borderColor: "#000000",
  };

  const disabledButtonStyles: any = {
    ...buttonStyles,
    opacity: 0.5,
    cursor: "not-allowed !important",
  };

  const initiateOAuth = async () => {
    try {
      setIsLoading(true);
      const { data } = await Axios.get(
        `${process.env.REACT_APP_FUNCTIONS_BASE_URL}/youtube-oauth?emailId=${email}`
      );
      window.location.href = data.authUrl;
    } catch (error) {
      setIsLoading(false);
      throw error;
    } finally {
    }
  };

  return (
    <>
      {!userInfo.youtube?.accessToken && (
        <Button
          variant="outlined"
          onClick={initiateOAuth}
          style={!email || isLoading ? disabledButtonStyles : buttonStyles}
          disabled={!email || isLoading}
        >
          {isLoading ? "Connecting..." : "Connect Account"}
        </Button>
      )}
      {userInfo.youtube?.accessToken && (
        <Button
          variant="outlined"
          style={!email || isLoading ? disabledButtonStyles : buttonStyles}
          disabled={!email || isLoading}
          onClick={initiateOAuth}
        >
          {isLoading ? "Connecting..." : "Connect new account"}
        </Button>
      )}
    </>
  );
};

export default YoutubeOAuth;
