import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    overflow: "hidden",
    paddingBottom: "20px",
  },
  noClient: {
    fontSize: "18px",
    lineHeight: "21px",
    color: "#000000",
    fontFamily: "'Avenir Book', sans-serif",
    paddingTop: "73px",
  },
  pageTitle: {
    height: "auto",
    fontStyle: "normal",
    fontSize: "30px",
    lineHeight: "25px",
    color: "#101010",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "40px 0 20px 0",
    fontFamily: "'Avenir Black', sans-serif",
  },

  icon: {
    width: "44.75px",
    height: "50px",
    marginRight: "10px",
    position: "relative",
    top: "15px",
  },

  cardContent: {
    fontFamily: "AvenirNext",
    fontSize: "15px",
    lineHeight: "25px",
  },

  title: {
    fontFamily: "AvenirNext-DemiBold",
    fontSize: "15px",
  },

  subheader: {
    fontFamily: "AvenirNext-Regular",
    fontSize: "12px",
  },
  selector: {
    display: "flex",
    alignItems: "flex-end",
    marginTop: 15,
  },
  BePlanned: {
    "& > :first-child": {
      display: "flex",
      alignItems: "flex-end",
      marginRight: "15px",
      marginBottom: "-5px",
    },
  },
  beplannedSearchbar: {
    marginTop: "30px",
    "@media screen and (max-width: 750px)": {
      marginTop: "0px",
    },
  },
  archiveLabel: {
    fontFamily: "'Avenir Book', sans-serif !important",
    lineHeight: "22px",
    textTransform: "capitalize",
    textDecoration: "underline",
    color: "#abadad",
  },
  smHide: {
    "@media screen and (max-width: 1050px)": {
      display: "none",
    },
  },
  mdHide: {
    "@media screen and (min-width: 1050px)": {
      display: "none",
    },
  },
  paginationPageInfo: {
    "@media screen and (min-width: 750px)": {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "20px",
    },
  },
});
