import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "'Avenir Book', sans-serif",
    borderRadius: "15px",
    boxShadow: "none",
    background: "#f2f2f2",
    overflowX: "initial",

    "@media screen and (max-width: 450px)": {
      overflowX: "auto",
    },
  },

  table: {
    minWidth: 650,
    background: "#fff",
    '& [class*= "stickyHeader"]': {
      top: "81px !important",
    },
  },
  clientValue: {
    fontFamily: "'Avenir Book', sans-serif",
    height: "25px",
    fontStyle: "normal",
    fontSize: "15px",
    lineHeight: "22px",
    color: "#000",
    alignItems: "center",
    justifyContent: "space-between",
  },
  pageTitle: {
    height: "auto",
    fontStyle: "normal",
    fontSize: "30px",
    lineHeight: "25px",
    color: "#101010",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "40px 0 20px 0",
    fontFamily: "'Avenir Black', sans-serif",
    fontWeight: 900,
  },
  tableCell: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    margin: "0 15px",
    height: "22px",
    top: "calc(50% - 22px/2 - 14px)",
    fontFamily: "'Avenir Black', sans-serif",
    fontWeight: "bold",
    fontSize: "15px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#000000",
  },
  image: {
    width: "25px",
  },
  row: {
    backgroundColor: "#ffffff",
    height: "75px",
  },
  bar: {
    display: "flex",
    justifyContent: "start",
    flexWrap: "wrap",
    alignItems: "center",
    fontSize: 15,
    lineHeight: "18px",
    marginBottom: "20px",
  },
  barPlatform: {
    "& > * > :first-child": {
      marginLeft: "-12px",
    },
  },
  actionLabel: {
    fontSize: 15,
    lineHeight: "22px",
    fontFamily: "'Avenir Black', sans-serif",
    fontWeight: "bold",
  },

  info: {
    fontFamily: "Avenir Book, sans-serif",
    fontSize: 12,
    backgroundColor: "slategrey",
    maxWidth: 308,
  },
  arrow: {
    color: "slategrey !important",
  },
}));
