import React, { FC } from "react";

import SocialPost from "./Post";
import { NoPosts } from "./Post/NoPosts";

interface IBePlanned {
  posts: () => any[];
  user: any;
}
export const BePlanned: FC<IBePlanned> = ({ posts, user }) => {
  const allPosts = posts().sort((a, b) => a.date - b.date);

  return (
    <div
      style={{
        marginBottom: "50px",
      }}
    >
      {/* Posts */}
      {allPosts && allPosts.length ? (
        allPosts.map((post, index) => (
          <SocialPost post={post} user={user} key={index} role="multichannel" />
        ))
      ) : (
        <NoPosts title="There are no posts in the selected period" />
      )}
    </div>
  );
};
