import React, { useState } from "react";
import {
  Button,
  Modal,
  Backdrop,
  Fade,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { useStyles } from "./styles";

import { SendButton, CancelButton } from "components/styled/Common";

interface IProp {
  handleChange: () => void;
  submitting: boolean;
}

const ActionPrompt = ({ handleChange, submitting }: IProp) => {
  const classes = useStyles({});
  const [isOpen, setIsOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState("");

  const handleClose = (e) => {
    if (e.key === "Escape") {
      setIsOpen(false);
    }
  };

  return (
    <Grid style={{ width: "100%" }}>
      <Button
        disableRipple
        type="button"
        className={`${classes.btn2} `}
        onClick={() => setIsOpen(!isOpen)}
        style={{
          width: "100%",
        }}
      >
        Auto Schedule
      </Button>
      <Modal
        open={isOpen}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.container}>
            <div className={classes.paper}>
              <div className={classes.top}>
                <div className={classes.print}>
                  Attention: Auto-Scheduled Post
                </div>
                <Close
                  className={classes.icon}
                  onClick={() => setIsOpen(!isOpen)}
                />
              </div>
              <div style={{ marginTop: "30px", marginBottom: "30px" }}>
                You are about to auto-schedule a post. Please note that it may
                take 5–10 minutes for your post to appear to your audience. Are
                you sure you want to proceed?
              </div>
            </div>
            <span className={classes.error}>{error}</span>

            <div className={classes.line} />
            <div
              className={classes.bottom}
              style={{ justifyContent: "flex-between" }}
            >
              <CancelButton disableRipple onClick={() => setIsOpen(!isOpen)}>
                Cancel
              </CancelButton>
              <SendButton onClick={() => handleChange()} disableRipple>
                {submitting ? (
                  <CircularProgress size={30} color="inherit" />
                ) : (
                  <span>Confirm</span>
                )}
              </SendButton>
            </div>
          </div>
        </Fade>
      </Modal>
    </Grid>
  );
};

export default ActionPrompt;
