import React from "react";

import FacebookIcon from "static/media/fb-vector.png";
import TwitterIcon from "static/media/twitter-vector.png";
import InstagramIcon from "static/media/instagram-vector.png";
import TiktokIcon from "static/media/tiktok.png";
import YouTubeIcon from "static/media/youtube.png";
// import AppleMusicIcon from "static/media/apple-music.png";
import SpotifyIcon from "static/media/spotify-logo.png";
import Image from "components/Common/Image/Image";

const styles = {
  image: {
    width: "25px",
  },
};
export const columns = [
  {
    title: "Client",
    icon: "",
  },
  {
    columnType: "Instagram",
    metrics: "Growth",
    title: "Followers Count Growth",
    icon: <Image src={InstagramIcon} alt="instagram" style={styles.image} />,
  },
  // {
  //   columnType: "Instagram",
  //   metrics: "Engagement",
  //   title: "Engagement",
  //   icon: <Image src={InstagramIcon} alt="instagram" style={styles.image} />,
  // },
  {
    columnType: "Instagram",
    metrics: "Posts",
    title: "Number of Posts",
    icon: <Image src={InstagramIcon} alt="instagram" style={styles.image} />,
  },
  {
    columnType: "Instagram",
    metrics: "Reels",
    title: "Number of Reels",
    icon: <Image src={InstagramIcon} alt="instagram" style={styles.image} />,
  },
  {
    columnType: "Facebook",
    metrics: "Growth",
    title: "Followers Count Growth",
    icon: <Image src={FacebookIcon} alt="facebook" style={styles.image} />,
  },
  // {
  //   columnType: "Facebook",
  //   metrics: "Engagement",
  //   title: "Engagement",
  //   icon: <Image src={FacebookIcon} alt="facebook" style={styles.image} />,
  // },
  {
    columnType: "Facebook",
    metrics: "Posts",
    title: "Number of Posts",
    icon: <Image src={FacebookIcon} alt="facebook" style={styles.image} />,
  },
  {
    columnType: "Twitter",
    metrics: "Growth",
    title: "Followers Count Growth",
    icon: <Image src={TwitterIcon} alt="twitter" style={styles.image} />,
  },
  // {
  //   columnType: "Twitter",
  //   metrics: "Engagement",
  //   title: "Engagement",
  //   icon: <Image src={TwitterIcon} alt="twitter" style={styles.image} />,
  // },
  {
    columnType: "Twitter",
    metrics: "Posts",
    title: "Number of Posts",
    icon: <Image src={TwitterIcon} alt="twitter" style={styles.image} />,
  },
  {
    columnType: "TikTok",
    metrics: "Growth",
    title: "Followers Count Growth",
    icon: <Image src={TiktokIcon} alt="tiktok" style={styles.image} />,
  },
  // {
  //   columnType: "TikTok",
  //   metrics: "Engagement",
  //   title: "Engagement",
  //   icon: <Image src={TiktokIcon} alt="tiktok" style={styles.image} />,
  // },
  {
    columnType: "TikTok",
    metrics: "Posts",
    title: "Number of Videos",
    icon: <Image src={TiktokIcon} alt="tiktok" style={styles.image} />,
  },
  {
    columnType: "YouTube",
    metrics: "Growth",
    title: "Subscribers Count Growth",
    icon: <Image src={YouTubeIcon} alt="YouTube" style={styles.image} />,
  },
  {
    columnType: "YouTube",
    metrics: "Posts",
    title: "Number of Videos",
    icon: <Image src={YouTubeIcon} alt="YouTube" style={styles.image} />,
  },
  // Disable for now
  // {
  //   columnType: "Apple Music",
  //   metrics: "Growth",
  //   title: "Growth",
  //   icon: <Image src={AppleMusicIcon} alt="Apple Music" style={styles.image} />,
  // },
  {
    columnType: "Spotify",
    metrics: "Growth",
    title: "Followers Count Growth",
    icon: <Image src={SpotifyIcon} alt="Spotify" style={styles.image} />,
  },
  {
    columnType: "Spotify",
    metrics: "Listeners",
    title: "Listeners Count Growth",
    icon: <Image src={SpotifyIcon} alt="Spotify" style={styles.image} />,
  },
];
