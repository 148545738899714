import React, { FC } from "react";
import Close from "@material-ui/icons/Close";

import { useStyles } from "./styles";
import { deleteEvents } from "../helpers/events";

export const extractFileName = (url) => {
  const match: any = RegExp(".*%2F(.*csv).*", "g").exec(url);
  return match ? match[1]?.replace(/%20/g, " ") : "";
};

const FileLink: FC<{ link: any; user?: any }> = ({ link, user }) => {
  const classes = useStyles({});

  return (
    <p className={classes.file}>
      <a
        href={`${link}`}
        style={{
          textDecoration: "none",
          marginLeft: "5px",
          fontFamily: "'Avenir Book', sans-serif",
        }}
      >
        {extractFileName(link) || link}
      </a>
      {user && (
        <Close
          style={{
            color: "rgba(49, 49, 49,0.7)",
            fontSize: "16px",
            cursor: "pointer",
          }}
          onClick={() => deleteEvents(link, user)}
        />
      )}
    </p>
  );
};

export default FileLink;
