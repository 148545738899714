import cuid from "cuid";

import { firestore } from "../../../firebase-tools/firebase";

export const saveNote = async (postId, note, collection) => {
  await firestore
    .collection(collection)
    .doc(postId)
    .collection("notes")
    .doc(cuid())
    .set(note);
};

export const filterNotes = (notes: Array<any>) => {
  const internalNotes = notes.filter((note) => note.type === "internal");
  const externalNotes = notes.filter((note) => note.type === "external");
  return { internalNotes, externalNotes };
};

export const deleteNote = async (postId, noteId: string, collection) => {
  await firestore
    .collection(collection)
    .doc(postId)
    .collection("notes")
    .doc(noteId)
    .delete();
};

export const updateNote = async (postId, noteId, data, collection) => {
  await firestore
    .collection(collection)
    .doc(postId)
    .collection("notes")
    .doc(noteId)
    .update(data);
};
