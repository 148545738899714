// AreaChart.js
import React from "react";
import ApexCharts from "react-apexcharts";
import "./styles.css"; // Make sure to import the CSS file
import { area } from "components/BeMeasured/ChartV2/chartOptions";

interface AreaChartProps {
  series: any[];
  categories: any[];
  title: string;
}

const AreaChart = ({ series, categories, title }: AreaChartProps) => {
  const options = {
    ...area.options,
    markers:
      categories.length > 30
        ? {}
        : {
            size: 5,
            colors: ["white"],
            strokeColor: "#5187E0",
            strokeWidth: 2,
          },
    xaxis: {
      categories: categories.map((dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        }).format(date);
      }),
      type: categories.length > 30 ? "datetime" : "date",
      labels: {
        datetimeUTC: false,
      },
    },
  };
  return (
    <div className="chart-container">
      <h2>{title}</h2>

      <ApexCharts options={options} series={series} type="area" height={350} />
    </div>
  );
};

export default AreaChart;
