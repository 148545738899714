import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  field: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    marginTop: "26px",
    transition: "all 0.3s ease",
  },
  notesAvatar: {
    background: "#000",
    marginRight: "15px",
    width: "37px",
    height: "37px",
  },
  avatarText: {
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: "15px",
    color: "#fff",
  },
  noteField: {
    display: "flex",
    marginTop: "10px",
  },
  note: {
    margin: "0px",
    whiteSpace: "pre-line",
    fontSize: "14px",
  },
  checkbox: {
    "&:hover": {
      background: "rgba(0,0,0,0.03) !important",
    },
    " & span svg": {
      width: "20px",
      height: "20px",
    },
    "& + span": {
      lineHeight: "10px !important",
    },
  },

  checkboxLabel: {
    color: "#000000",
    fontFamily: "'Avenir Book', sans-serif",
    fontWeight: 350,
    fontSize: "14px",
    lineHeight: "14px",
  },
  content: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.05)",
    borderRadius: "10px",
    width: "100%",
    padding: "10px 15px 5px",
  },
  body: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "15px",
    lineHeight: "13px",
    fontFamily: "'Avenir Book', sans-serif",
    color: "#000",
  },
  close: {
    width: "12px",
    height: "12px",
    color: "#ABADAD",
    cursor: "pointer",
  },
  timestamp: {
    fontSize: 10,
    color: "#ABADAD",
    lineHeight: "10px",
  },
  noteTitle: {
    display: "flex",
    marginTop: "20px",
    alignItems: "center",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 15,
    alignItems: "center",
  },
  archiveLabel: {
    lineHeight: "22px",
    textTransform: "initial",
    textDecoration: "underline",
    color: "#000000",
    fontFamily: "Avenir",
    fontSize: "14px",
    fontWeight: 350,
    "&:hover": {
      background: "#fff",
    },
  },
}));
