import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";

import CategoryCheckboxes from "./CategoryCheckboxes";

import { PostCategoryStyle } from "../../styled/Common";

export const allSelectedCategories = (categories, channels) => {
  const finalCategories = {};
  const categoriesList: Array<string> = [];
  channels = channels.map((v: string) => v.toLowerCase());

  const majorChannels = [
    "facebook",
    "instagram",
    "twitter",
    "tiktok",
    "youtubeshort",
    "youtube",
  ];

  const customChannels = channels.filter(
    (chan) => !majorChannels.includes(chan)
  );

  for (const key of Object.keys(categories)) {
    if (
      channels.includes(key.toLocaleLowerCase()) ||
      (customChannels.length && key.toLocaleLowerCase() === "custom")
    ) {
      finalCategories[key] = categories[key];
      if (!!categories[key].length) {
        categoriesList.push(` ${categories[key]}`);
      }
    } else {
      finalCategories[key] = [];
    }
  }

  return { finalCategories, categoriesList };
};

export const CategoryText = styled.div`
  .selected-text {
    background-color: #fff;
    padding: 12.5px;
    border: 2px solid #abadad;
    border-radius: 10px;
    height: 45px;
    font-size: 15px;
    line-height: 20px;
    text-align: left;
    cursor: pointer;
    display: flex;
    align-items: center;
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding-right: 8px;
    }
    :after {
      content: "";
      position: absolute;
      right: 41.5px;
      top: 47px;
      border: 4px solid transparent;
      border-color: #000 transparent transparent transparent;
    }
    :focus {
      border: 2px solid #000 !important;
      outline: none;
    }
    :hover {
      border: 2px solid #000 !important;
    }
    :active {
      border: 2px solid #000 !important;
    }
  }
  .selected-text-active {
    :after {
      top: 42px;
      border-color: transparent transparent #000 transparent;
    }
  }
`;

type CategoryError = {
  facebook: string;
  instagram: string;
  twitter: string;
  tiktok: string;
  youtube: string;
  custom: string;
};
const PostCategory: FunctionComponent<{
  selectedChannels: string[];
  category: any;
  setCategory: (event: any) => void;
  categoryError: CategoryError;
  customCheck: boolean;
  setCategoryError: (categoryError: CategoryError) => void;
}> = ({
  selectedChannels,
  category,
  setCategory,
  categoryError,
  customCheck,
  setCategoryError,
}) => {
  const errors = Object.values(categoryError).filter((err) => err !== "");
  const [open, setOpen] = useState(false);

  const manipulateArr = (arr, val) => {
    const i = arr.indexOf(val);
    if (i > -1) {
      arr.splice(i, 1);
    } else {
      arr.push(val);
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    platform
  ) => {
    if (platform === "facebook") {
      const FB: string[] = [...category.facebook];

      manipulateArr(FB, event.target.value);

      setCategory({
        ...category,
        facebook: FB,
      });
    }
    if (platform === "instagram") {
      const IG: string[] = [...category.instagram];
      manipulateArr(IG, event.target.value);

      setCategory({
        ...category,
        instagram: IG,
      });
    }
    if (platform === "twitter") {
      const TW: string[] = [...category.twitter];
      manipulateArr(TW, event.target.value);

      setCategory({
        ...category,
        twitter: TW,
      });
    }
    if (platform === "tiktok") {
      const tiktok: string[] = [...category.tiktok];
      manipulateArr(tiktok, event.target.value);

      setCategory({
        ...category,
        tiktok,
      });
    }
    if (platform === "youtube") {
      const youtube: string[] = [...category.youtube];
      manipulateArr(youtube, event.target.value);

      setCategory({
        ...category,
        youtube,
      });
    }
    if (platform === "custom") {
      const custom: string[] = [...category.custom];
      manipulateArr(custom, event.target.value);

      setCategory({
        ...category,
        custom,
      });
    }
  };

  const FBCategory = [
    "Facebook Feed",
    "Facebook Story",
    "Facebook Group Post",
    "Facebook Live",
  ];

  const IGCategory = [
    "Instagram Feed",
    "Instagram Carousel",
    "Instagram Story",
    "Instagram IGTV",
    "Instagram Reels",
    "Instagram Live",
  ];
  const TWCategory = [
    "Twitter Feed",
    "Twitter Thread",
    "Twitter Poll",
    "Tweet To Reveal",
    "Twitter Fleets",
    "Twitter Live",
    "Twitter Voice",
  ];
  const TiktokCategory = ["TikTok Feed", "TikTok Live"];
  const YoutubeCategory = [
    "Youtube Feed",
    "YouTube Community Tab",
    "YouTube Live",
  ];

  const handleToggle = () => {
    setOpen(!open);
  };

  const { categoriesList } = allSelectedCategories(category, selectedChannels);

  return (
    <div style={{ marginTop: 5, width: "94%" }}>
      <CategoryText>
        <div
          style={{
            borderBottomLeftRadius: open ? "0px" : "10px",
            borderBottomRightRadius: open ? "0px" : "10px",
            background: "#fff",
            border: open ? "2px solid #000" : "2px solid #ABADAD",
          }}
          className={
            open ? "selected-text selected-text-active" : "selected-text"
          }
          onClick={handleToggle}
        >
          <p style={{ color: !!categoriesList.length ? "#000" : "#ABADAD" }}>
            {!!categoriesList.length
              ? categoriesList.toString().replace(/,/g, ", ")
              : "Choose"}
          </p>
        </div>
      </CategoryText>
      {open && (
        <PostCategoryStyle>
          {selectedChannels.includes("facebook") && (
            <CategoryCheckboxes
              categoryOptions={FBCategory}
              handleChange={handleChange}
              selectedCategory={category.facebook}
              platform="facebook"
              customCheck={customCheck}
              setCategoryError={setCategoryError}
              category={category}
              selectedChannels={selectedChannels}
            />
          )}
          {selectedChannels.includes("instagram") && (
            <CategoryCheckboxes
              categoryOptions={IGCategory}
              handleChange={handleChange}
              selectedCategory={category.instagram}
              platform="instagram"
              customCheck={customCheck}
              setCategoryError={setCategoryError}
              category={category}
              selectedChannels={selectedChannels}
            />
          )}
          {selectedChannels.includes("twitter") && (
            <CategoryCheckboxes
              categoryOptions={TWCategory}
              handleChange={handleChange}
              selectedCategory={category.twitter}
              platform="twitter"
              customCheck={customCheck}
              setCategoryError={setCategoryError}
              category={category}
              selectedChannels={selectedChannels}
            />
          )}
          {selectedChannels.includes("tiktok") && (
            <CategoryCheckboxes
              categoryOptions={TiktokCategory}
              handleChange={handleChange}
              selectedCategory={category.tiktok}
              platform="tiktok"
              customCheck={customCheck}
              setCategoryError={setCategoryError}
              category={category}
              selectedChannels={selectedChannels}
            />
          )}
          {selectedChannels.includes("youtube") && (
            <CategoryCheckboxes
              categoryOptions={YoutubeCategory}
              handleChange={handleChange}
              selectedCategory={category.youtube}
              platform="youtube"
              customCheck={customCheck}
              setCategoryError={setCategoryError}
              category={category}
              selectedChannels={selectedChannels}
            />
          )}
          {customCheck && (
            <CategoryCheckboxes
              categoryOptions={["Custom Post"]}
              handleChange={handleChange}
              selectedCategory={category.custom}
              platform="custom"
              customCheck={customCheck}
              setCategoryError={setCategoryError}
              category={category}
              selectedChannels={selectedChannels}
            />
          )}
        </PostCategoryStyle>
      )}
      {errors.map((err) => (
        <div style={{ color: "#f44336", padding: "0 0 5px 0", fontSize: 15 }}>
          {err || ""}
        </div>
      ))}
    </div>
  );
};

export default PostCategory;
