import React, { FC } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";

import { useStyles } from "./styles";

const Info: FC<{ title: string }> = ({ title }) => {
  const classes = useStyles({});
  return (
    <Tooltip
      title={
        <span
          style={{ whiteSpace: "pre-line" }}
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        ></span>
      }
      arrow
      interactive
      classes={{
        tooltip: classes.info,
        arrow: classes.arrow,
      }}
    >
      <InfoIcon style={{ color: "slategrey" }} />
    </Tooltip>
  );
};

export default Info;
