/**
 * @prettier
 */

import React from "react";
import { Box, Typography, LinearProgress } from "@material-ui/core";

import { useStyles } from "./styles";

const LinearProgressWithLabel = (props) => {
  const classes = useStyles({});
  return (
    <>
      <Box style={{ width: "100%" }}>
        <Typography
          variant="body2"
          style={{ color: "#15891b", marginTop: 15 }}
        >{`${Math.round(
          props.completed
        )} Successfully sent emails`}</Typography>
      </Box>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <Box
          style={{ width: "100%", marginRight: 1 }}
          className={classes.progress}
        >
          <LinearProgress
            color="primary"
            variant="determinate"
            {...props}
            style={{
              height: "11px",
              borderRadius: "10px",
            }}
          />
        </Box>
        <Box style={{ minWidth: 35 }}>
          <Typography
            variant="body2"
            style={{ color: "#15891b" }}
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    </>
  );
};

export default LinearProgressWithLabel;
