import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dashboardRoot: {
    width: "100%",
    minHeight: "100vh",
    fontFamily: "Avenir Book",
    backgroundColor: "#f2f2f2",
  },
  container: {
    width: "85%",
    margin: "0 auto",
  },
  root: {
    flexGrow: 1,
    height: "81px",
    left: "0px",
    top: "-0.88px",
    fontFamily: "Avenir Black",

    "& h6": {
      fontFamily: "Avenir Black, sans-serif",
    },
  },
  menuButton: {
    marginRight: "10px",
  },
  navBar: {
    height: "81px",
    margin: "0 auto",
    position: "fixed",
    width: "100%",
    left: "0px",
    top: "-0.88px",
    backgroundColor: "#000",
    justifyContent: "center",
    fontFamily: "Avenir Black",
  },
  nav: {
    display: "flex",
    width: "83%",
    margin: "0 auto",
    justifyContent: "normal",
  },
  navTitle: {
    fontFamily: '"Avenir Book", sans-serif',
    height: "19px",
    left: "346px",
    top: "49.8px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#FFFFFF",
    marginRight: "58px",
    "&:last-child": {
      marginRight: "10px",
    },
  },
  links: {
    display: "flex",
    marginLeft: "50px",
    alignItems: "center",
  },

  logo: {
    width: "70px",
    height: "65px",
    cursor: "pointer",
  },
  label: {
    textTransform: "capitalize",
    fontFamily: "AvenirNext",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF",
  },
  logout: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    minWidth: "150px",
    cursor: "pointer",
  },
  avatar: {
    background: "#FFFFFF",
    marginRight: "15px",
  },
  avatarText: {
    fontFamily: "AvenirNext-Medium",
    fontSize: "16px",
    color: "#101010",
  },
  menu: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  toolbar: {
    padding: 0,
  },
  dropdown: {
    marginLeft: "5px",
  },
  menuRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "#000",
    fontFamily: "Avenir Black",

    "& > *:not(:last-child)": {
      marginBottom: 11,
    },
  },
  mobileSideBarContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    overflowY: "auto",
    backgroundColor: "#000",
    color: "#fff",
    zIndex: 10000,
    padding: "20px",
    boxSizing: "border-box",
    transition: "all 500ms ease",
    backgroundSize: "100vh 100vw",
  },
  mobileSideBarContainerClosed: {
    opacity: 0,
    pointerEvents: "none",
  },
  burger: { marginRight: "25px" },
  closeButton: {
    margin: "16px 0px",
  },
  bePlanned: {
    position: "absolute",
    color: "#313131",
    background: "#ffff",
    borderRadius: "2px",
    padding: "12px 24px",
    width: 250,
    marginLeft: "-21px",
    marginTop: "12px",
    fontSize: "14px",
    boxShadow:
      " 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",

    fontFamily: "Avenir Black, sans-serif",
    fontWeight: 600,
    lineHeight: "21px",

    "& > *": {
      marginRight: "-24px",
      marginLeft: "-24px",
      padding: "6px 24px",
      cursor: "pointer",
    },

    "& > *:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  healthCheckLink: {
    fontWeight: 600,
    font: "inherit",
    cursor: "pointer",
  },
  link: {
    fontWeight: 600,
    cursor: "pointer",
  },
  inActive: {
    color: "#7f7f7f",
    fontWeight: 800,
    fontFamily: "Avenir Black, sans-serif",
  },
  activeLink: {
    color: "#fff",
    fontFamily: "Avenir Black, sans-serif",
    "& > h6": {
      opacity: "1 !important",
    },
  },
  inActiveLink: { opacity: 0.5 },
  toPrint: {
    padding: "0 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 0px",
    },
  },
}));

export { useStyles };
