import styled from "styled-components";

const BePlannedMonthly = styled.div`
  height: 100vh;

  .be-planned-monthly {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;

    & > :first-child {
      font-family: "Avenir Black", sans-serif;
      font-weight: 900;
      font-size: 30px;
      line-height: 25px;
      color: #101010;
    }
  }

  .selector {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 15px;

    & > :nth-child(1) {
      margin-bottom: -5px;
      margin-right: 15px;
    }
  }

  #selector-buttons {
    display: flex;

    & > :first-child {
      margin-right: 15px;
    }
  }

  .icons {
    display: flex;
    align-items: baseline;
    margin-top: 56px;
  }

  .post {
    padding-bottom: 6vh;
  }

  .selector-container {
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: 1240px) {
    .be-planned-monthly {
      display: flex;
      flex-direction: column;

      & > :first-child {
        margin-bottom: 32px !important;
      }
    }
  }

  @media only screen and (max-width: 1110px) {
    .selector-container {
      display: flex;
      align-items: center;
      flex-direction: column;

      & > div > #print {
        margin-right: 0px !important;
      }
    }
    #selector-calendar {
      display: flex;
      align-items: center !important;
      flex-direction: column-reverse;

      & > :first-child,
      & > :nth-child(2) {
        margin-left: 0px;
        margin-bottom: 16px;
        margin-right: 0px;
      }
    }

    .selector > :nth-child(3) {
      margin-right: 0px !important;
    }
    #client-list {
      margin-top: 10px;
    }

    #selector-buttons {
      flex-direction: column;

      & > :first-child {
        margin-right: 0px;
      }
    }

    #retainer {
      margin-top: 8px;
    }
    .icons {
      margin-top: 0px;
    }
  }

  @media only screen and (max-width: 460px) {
    #client-list {
      width: 100%;

      & > div {
        width: 100%;

        & > div {
          width: 100%;
        }
      }
    }
  }
`;

export const Clients = styled.div`
  div[class*="autoCompleteField"] {
    margin-right: 0px;
  }
`;
export default BePlannedMonthly;
