import React, { useState } from "react";
import {
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Grid,
} from "@material-ui/core";
import { useStylesNot } from "./styles";
import { firestore } from "firebase-tools/firebase";
import useCollectionSubscriber from "hooks/useCollectionSubscriber";
import { INotification, NotificationStatus } from "interfaces/INotification";
import { navigate } from "@reach/router";
import AllNotifications from "containers/Notifications/AllNotifications";
import ArchivedNotifications from "containers/Notifications/ArchivedNotifications";
import { bulkMarkNotificationStatus } from "helpers/notification/updateNotificationStatus";

interface IProp {
  anchorRef: any;
  isOpenNot: boolean;
  handleClickAway: (e) => void;
}

const NotificationMenu = ({ anchorRef, isOpenNot, handleClickAway }: IProp) => {
  const classes = useStylesNot({});
  const { notifyType, activeNotifyType } = classes;
  const [showAll, setShowAll] = useState("all");

  const queries = [
    firestore
      .collection("notifications")
      .where("status", "==", "unread")
      .orderBy("created_at", "desc"),
  ];
  let notificationList = useCollectionSubscriber<INotification>({ queries })
    .documents;

  return (
    <Popper
      open={isOpenNot}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      className={classes.cardPlacement}
      // style={{ paddingTop: "35px", paddingRight: "35px" }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper className={classes.container}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <Grid item container>
                <Grid item container className={classes.universalSpace}>
                  <Grid item xs={6} md={6}>
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        fontWeight: 500,
                        fontSize: 20,
                      }}
                    >
                      Notifications
                    </p>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={6}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 900,
                        fontSize: 13,
                        cursor: "pointer",
                        ...(notificationList.length < 1
                          ? { opacity: 0.8, color: "#7F7F7F" }
                          : {}),
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (notificationList.length < 1) {
                          return false;
                        }
                        bulkMarkNotificationStatus(
                          NotificationStatus.read,
                          notificationList
                        );
                      }}
                    >
                      Mark all as read
                    </p>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  className={classes.universalSpaceleftRight}
                >
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: 14,
                      textAlign: "center",
                    }}
                    className={
                      showAll === "all" ? activeNotifyType : notifyType
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setShowAll("all");
                    }}
                  >
                    All{" "}
                    <span className={classes.allCount}>
                      {notificationList.length}
                    </span>
                  </span>

                  <span
                    style={{
                      fontWeight: 900,
                      fontSize: 13,
                      textAlign: "center",
                    }}
                    className={
                      showAll === "archived" ? activeNotifyType : notifyType
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setShowAll("archived");
                    }}
                  >
                    Archived
                  </span>
                </Grid>
                {showAll === "all" && (
                  <AllNotifications
                    pagination={false}
                    pageSize={3}
                    menu={true}
                  />
                )}
                {showAll === "archived" && (
                  <ArchivedNotifications
                    pagination={false}
                    pageSize={3}
                    menu={true}
                  />
                )}

                <Grid
                  container
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{ fontWeight: 900, fontSize: 14, cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleClickAway(e);
                      navigate("/notifications");
                    }}
                  >
                    View All
                  </p>
                </Grid>
              </Grid>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default NotificationMenu;
