/**
 * @prettier
 */
import React, { useState, FunctionComponent, Fragment } from "react";
import { Modal, Backdrop, Fade, CircularProgress } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { useStyles } from "./styles";

import { PostService } from "services/PostService";
import { mainMedia } from "./CreateEditPost";
import { SendButton, CancelButton } from "components/styled/Common";
import { ReactComponent as TrashCan } from "../../assets/trashcan.svg";

interface IDelete {
  id: any;
  role: string;
  handleclick?: (e: any) => void;
}

export const DeleteV2: FunctionComponent<IDelete> = ({
  id,
  role,
  handleclick: handleClick,
}) => {
  const classes = useStyles({});

  const [isOpen, setIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");

  const handleDelete = () => {
    console.log("handleDelete", "id", id, "role", role);

    let Post;
    if (mainMedia.includes(role.toLowerCase())) {
      Post = new PostService(`${role.toLocaleLowerCase()}-posts`, id);
    } else {
      Post = new PostService("custom-posts", id);
      if (role === "multichannel") {
        Post = new PostService("multichannel-posts", id);
      } else {
        Post = new PostService("multichannel-posts", id);
      }
    }
    setSubmitting(true);

    Post.deletePost()
      .then(() => {
        setSubmitting(false);
        setIsOpen(!isOpen);
        if (handleClick) {
          handleClick(true);
        }
      })
      .catch((e) => {
        setSubmitting(false);
        setIsOpen(!isOpen);
        return setError(e);
      });
  };

  const handleClose = (e) => {
    if (e.key === "Escape") {
      setIsOpen(false);
    }
  };

  return (
    <Fragment>
      {/* <Button
        disableRipple
        type="button"
        className={`${classes.btn} ${classes.delete}`}
        id="action-btn"
        onClick={() => setIsOpen(!isOpen)}
        endIcon={<Close />}
      >
        Delete
      </Button> */}

      <TrashCan
        onClick={() => setIsOpen(!isOpen)}
        style={{
          cursor: "pointer",
          marginTop: "10px",
        }}
      />

      <Modal
        open={isOpen}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.container}>
            <div className={classes.paper}>
              <div className={classes.top}>
                <div className={classes.print}>Confirmation Needed</div>
                <Close
                  className={classes.icon}
                  onClick={() => setIsOpen(!isOpen)}
                />
              </div>
              <div style={{ marginTop: "30px", marginBottom: "30px" }}>
                You are about to delete a post. Are you sure?
              </div>
            </div>
            <span className={classes.error}>{error}</span>

            <div className={classes.line} />
            <div
              className={classes.bottom}
              style={{ justifyContent: "flex-between" }}
            >
              <CancelButton disableRipple onClick={() => setIsOpen(!isOpen)}>
                cancel
              </CancelButton>
              <SendButton onClick={() => handleDelete()} disableRipple>
                {submitting ? (
                  <CircularProgress size={30} color="inherit" />
                ) : (
                  <span>Delete Post</span>
                )}
              </SendButton>
            </div>
          </div>
        </Fade>
      </Modal>
    </Fragment>
  );
};
