/**
 * @prettier
 */

import React, { FunctionComponent, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { navigate } from "@reach/router";
import ReactFileReader from "react-file-reader";
import papa from "papaparse";
import cuid from "cuid";

import { PageType } from "types/PageType";
import { useStyles } from "./styles";
import { CancelButton, SendButton } from "components/styled/Common";
import { firestore } from "firebase-tools/firebase";
import { Alert } from "@material-ui/lab";

const csvToArray = (csv) => {
  const columns = csv[0];
  csv.splice(0, 1);
  let array: any[] = csv;

  array = array
    .filter((res) => Array.isArray(res) && !(res.length < columns.length))
    .map((result) => {
      const obj = {
        error: "",
        isSent: false,
      };
      for (let i = 0; i < columns.length; i++) {
        obj[columns[i]] = result[i];
      }
      return obj;
    });

  return array;
};

export const CreateCampaign: FunctionComponent<PageType> = () => {
  const classes = useStyles({});

  const [campaignName, setCampaignName] = useState("");
  const [timeInterval, setTimeInterval] = useState("2");
  const [subject, setSubject] = useState("");
  const [template, setTemplate] = useState("");
  const [description, setDescription] = useState("");

  const [receivers, setReceivers]: any[] = useState([]);

  const [uploading, setUploading]: any[] = useState(false);
  const [completed, setCompleted]: any[] = useState(false);

  const [errors, setError] = useState({
    campaignName: "",
    timeInterval: "",
    subject: "",
    template: "",
    receivers: "",
    global: "",
  });

  const handleFiles = (files) => {
    if (files) {
      setCompleted(`Successfully Uploaded "${files[0].name}"`);
      setUploading(true);
      papa.parse(files[0], {
        complete: (results) => {
          setUploading(false);
          const receiversUpdate: any[] = csvToArray(results?.data);
          setReceivers(receiversUpdate);
          setError((errors) => ({
            ...errors,
            receivers: "",
          }));
        },
      });
    }
  };

  const removeFile = () => {
    setCompleted(false);
  };

  const setCampaign = async () => {
    const campaignObj = {
      campaignName,
      timeInterval,
      subject,
      template,
      description,
      receivers,
      created_at: new Date(),
      updated_at: new Date(),
      isFinished: false,
      isPaused: false,
      lastReceiverNumber: 0,
      lastSendDate: null,
      status: "Created",
    };

    if (!campaignName)
      return setError((errors) => ({
        ...errors,
        campaignName: "Campaign Name is required!",
      }));
    if (Number(timeInterval) < 2)
      return setError((errors) => ({
        ...errors,
        timeInterval: "Time interval should not be less than 2 minute!",
      }));
    if (!subject)
      return setError((errors) => ({
        ...errors,
        subject: "Email Subject is required!",
      }));
    if (!template)
      return setError((errors) => ({
        ...errors,
        template: "Email Template is required!",
      }));
    if (!receivers.length)
      return setError((errors) => ({
        ...errors,
        receivers: "Receivers CSV is required!",
      }));

    try {
      const id = cuid();
      await firestore.collection("campaigns").doc(id).set(campaignObj);
      navigate(`/mass-emailer/${id}`);
    } catch (error) {
      setError((errors) => ({
        ...errors,
        global: "An error occured creating the campaign!",
      }));
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.pageTitle} id="page-title">
        <span className={classes.title}>Create Campaign</span>
      </div>

      {errors.global && (
        <Alert
          severity="error"
          style={{
            marginBottom: 20,
            width: "fit-content",
          }}
        >
          {errors.global}
        </Alert>
      )}

      <Box>
        <Box className={classes.campaignRow}>
          <Typography className={classes.fieldName}>Campaign Name</Typography>
          <Box
            display="flex"
            flexDirection="column"
            className={classes.inputContainer}
          >
            <input
              type="text"
              className={classes.input}
              placeholder="Enter Campaign Name..."
              value={campaignName}
              onChange={(e) => {
                setError((errors) => ({
                  ...errors,
                  campaignName: "",
                }));
                setCampaignName(e.target.value);
              }}
            />

            {errors.campaignName && (
              <Box component="span" padding="5px 0" color="red" fontSize="14px">
                {errors.campaignName}
              </Box>
            )}
          </Box>
        </Box>
        <Box className={classes.campaignRow}>
          <Typography className={classes.fieldName}>Time Interval</Typography>
          <Box
            display="flex"
            flexDirection="column"
            className={classes.inputContainer}
          >
            <input
              type="text"
              className={`${classes.input}`}
              placeholder="0"
              value={timeInterval}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;

                // if value is not blank, then test the regex
                if (e.target.value === "" || re.test(e.target.value)) {
                  setTimeInterval(e.target.value);
                }
              }}
              disabled
            />
            {errors.timeInterval && (
              <Box component="span" padding="5px 0" color="red" fontSize="14px">
                {errors.timeInterval}
              </Box>
            )}
          </Box>
          <Typography className={classes.timeFrame}>minutes</Typography>
        </Box>
        <Box className={classes.campaignRow}>
          <Typography className={classes.fieldName}>Email Subject</Typography>
          <Box
            display="flex"
            flexDirection="column"
            className={classes.inputContainer}
          >
            <input
              type="text"
              className={classes.input}
              placeholder="Enter Email Subject..."
              value={subject}
              onChange={(e) => {
                setError((errors) => ({
                  ...errors,
                  subject: "",
                }));
                setSubject(e.target.value);
              }}
            />
            {errors.subject && (
              <Box component="span" padding="5px 0" color="red" fontSize="14px">
                {errors.subject}
              </Box>
            )}
          </Box>
        </Box>

        <Box className={classes.campaignRow}>
          <Typography className={classes.fieldName}>Email Template</Typography>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            className={classes.textAreaContainer}
          >
            <textarea
              className={`${classes.input}`}
              placeholder="Enter Email Template..."
              value={template}
              onChange={(e) => {
                setError((errors) => ({
                  ...errors,
                  template: "",
                }));
                setTemplate(e.target.value);
              }}
              style={{ minHeight: 100 }}
            />
            {errors.template && (
              <Box component="span" padding="5px 0" color="red" fontSize="14px">
                {errors.template}
              </Box>
            )}
          </Box>
        </Box>
        <Box className={classes.campaignRow}>
          <Typography className={classes.fieldName}>Description</Typography>
          <textarea
            className={`${classes.input} ${classes.textAreaContainer}`}
            placeholder="Enter Description..."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Box>
        <Box className={classes.campaignRow}>
          <Typography className={classes.fieldName}>
            Attach email list
          </Typography>
          {uploading ? (
            "Uploading file..."
          ) : completed ? (
            <div className="flex">
              {completed}
              <small onClick={() => removeFile()}>
                <span
                  role="img"
                  aria-label="image"
                  style={{ cursor: "pointer", marginLeft: "25px" }}
                >
                  ❌
                </span>
              </small>
            </div>
          ) : (
            <ReactFileReader handleFiles={handleFiles} fileTypes={".csv"}>
              <SendButton
                disableRipple
                style={{
                  fontWeight: "300",
                  width: "auto",
                  padding: "15px 30px",
                }}
              >
                <span>Upload CSV File</span>
              </SendButton>
            </ReactFileReader>
          )}
          {errors.receivers && (
            <Box component="span" padding="5px 0" color="red">
              {errors.receivers}
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          className={classes.btm}
        ></Box>
        <Typography
          className={classes.fieldName}
          style={{
            padding: "20px 0",
          }}
        >
          (P.S: The CSV file should have at least 2 columns, named "email" and
          "firstName", the other columns are arbitrary.
          <br />
          In each record, the cells should separate with a comma character.)
        </Typography>
        <Box className={classes.btm}>
          <CancelButton disableRipple onClick={() => navigate("/mass-emailer")}>
            cancel
          </CancelButton>

          <SendButton
            onClick={() => setCampaign()}
            disableRipple
            style={{ marginLeft: 24 }}
          >
            <span>Create</span>
          </SendButton>
        </Box>
      </Box>
    </div>
  );
};
