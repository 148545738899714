import React from "react";
import { Checkbox } from "@material-ui/core";

const color = "#000";
export const platforms = [
  {
    title: "Instagram",
    component: (
      checked: boolean,
      platform: string,
      handleChange: (checked: boolean, platform: string) => void
    ) => (
      <Checkbox
        checked={checked}
        style={{ color }}
        onChange={(e) => handleChange(e.target.checked, platform)}
        inputProps={{ "aria-label": "instagram" }}
      />
    ),
  },
  {
    title: "Facebook",
    component: (
      checked: boolean,
      platform: string,
      handleChange: (checked: boolean, platform: string) => void
    ) => (
      <Checkbox
        checked={checked}
        style={{ color }}
        onChange={(e) => handleChange(e.target.checked, platform)}
        inputProps={{ "aria-label": "facebook" }}
      />
    ),
  },
  {
    title: "Twitter",
    component: (
      checked: boolean,
      platform: string,
      handleChange: (checked: boolean, platform: string) => void
    ) => (
      <Checkbox
        checked={checked}
        style={{ color }}
        onChange={(e) => handleChange(e.target.checked, platform)}
        inputProps={{ "aria-label": "twitter" }}
      />
    ),
  },
  {
    title: "TikTok",
    component: (
      checked: boolean,
      platform: string,
      handleChange: (checked: boolean, platform: string) => void
    ) => (
      <Checkbox
        checked={checked}
        style={{ color }}
        onChange={(e) => handleChange(e.target.checked, platform)}
        inputProps={{ "aria-label": "tiktok" }}
      />
    ),
  },
  {
    title: "YouTube",
    component: (
      checked: boolean,
      platform: string,
      handleChange: (checked: boolean, platform: string) => void
    ) => (
      <Checkbox
        checked={checked}
        style={{ color }}
        onChange={(e) => handleChange(e.target.checked, platform)}
        inputProps={{ "aria-label": "YouTube" }}
      />
    ),
  },
  // Disable for now
  // {
  //   title: "Apple Music",
  //   component: (
  //     checked: boolean,
  //     platform: string,
  //     handleChange: (checked: boolean, platform: string) => void
  //   ) => (
  //     <Checkbox
  //       checked={checked}
  //       style={{ color }}
  //       onChange={(e) => handleChange(e.target.checked, platform)}
  //       inputProps={{ "aria-label": "Apple Music" }}
  //     />
  //   ),
  // },
  {
    title: "Spotify",
    component: (
      checked: boolean,
      platform: string,
      handleChange: (checked: boolean, platform: string) => void
    ) => (
      <Checkbox
        checked={checked}
        style={{ color }}
        onChange={(e) => handleChange(e.target.checked, platform)}
        inputProps={{ "aria-label": "Spotify" }}
      />
    ),
  },
];

export const metricsData = [
  {
    title: "Followers Count Growth",
    metric: "Growth",
    component: (
      checked: boolean,
      metric: string,
      handleChange: (checked: boolean, metric: string) => void
    ) => (
      <Checkbox
        checked={checked}
        style={{ color }}
        onChange={(e) => handleChange(e.target.checked, metric)}
        inputProps={{ "aria-label": "growth" }}
      />
    ),
  },
  {
    title: "Engagement Rate",
    metric: "Engagement",
    component: (
      checked: boolean,
      metric: string,
      handleChange: (checked: boolean, metric: string) => void
    ) => (
      <Checkbox
        checked={checked}
        style={{ color }}
        onChange={(e) => handleChange(e.target.checked, metric)}
        inputProps={{ "aria-label": "engagement" }}
      />
    ),
  },
  {
    title: "Number of Posts",
    metric: "Posts",
    component: (
      checked: boolean,
      metric: string,
      handleChange: (checked: boolean, metric: string) => void
    ) => (
      <Checkbox
        checked={checked}
        style={{ color }}
        onChange={(e) => handleChange(e.target.checked, metric)}
        inputProps={{ "aria-label": "posts" }}
      />
    ),
  },
  {
    title: "Listeners Count Growth",
    metric: "Listeners",
    component: (
      checked: boolean,
      metric: string,
      handleChange: (checked: boolean, metric: string) => void
    ) => (
      <Checkbox
        checked={checked}
        style={{ color }}
        onChange={(e) => handleChange(e.target.checked, metric)}
        inputProps={{ "aria-label": "Listeners" }}
      />
    ),
  },
  {
    title: "Number of Reels",
    metric: "Reels",
    component: (
      checked: boolean,
      metric: string,
      handleChange: (checked: boolean, metric: string) => void
    ) => (
      <Checkbox
        checked={checked}
        style={{ color }}
        onChange={(e) => handleChange(e.target.checked, metric)}
        inputProps={{ "aria-label": "Reels" }}
      />
    ),
  },
];
