import React, { FC, useEffect, useState } from "react";
import { Tooltip } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import { inviteLinks } from "./helpers/secondaryInvites";
import { isSecondarySignedUp } from "./helpers/isSecondarySignedUp";

interface IInviteLink {
  classes: any;
  primaryEmail: string;
  secEmail?: string;
  isSignedUp?: boolean;
  type?: string;
}

export const InviteLink: FC<IInviteLink> = ({
  classes,
  primaryEmail,
  secEmail,
  isSignedUp,
  type,
}) => {
  const [authenticated, setAuthenticated] = useState(false);

  const signedUp = async (email) => {
    const isAuthenticated = await isSecondarySignedUp(email);

    return isAuthenticated;
  };

  useEffect(() => {
    if (type !== "primary" && secEmail && !isSignedUp) {
      signedUp(secEmail).then((res) => setAuthenticated(res));
    }

    //eslint-disable-next-line
  }, [type, secEmail]);

  const inviteLink =
    type === "primary"
      ? inviteLinks(primaryEmail, null, "primary")
      : inviteLinks(primaryEmail, secEmail, "secondary");

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        fontFamily: '"Avenir Book", sans-serif',
        maxWidth: type === "primary" ? 450 : 330,
        justifyContent: "flex-end",
        fontSize: 14,
      }}
    >
      <span
        style={{
          display: "flex",
          alignItems: "center",
          color: "#00000061",
          textTransform: "capitalize",
          marginRight: 5,
        }}
      >
        {(isSignedUp || authenticated) && "Signed Up"}
      </span>

      <CopyToClipboard text={inviteLink}>
        <Tooltip
          title="Click to copy invite link"
          arrow
          interactive
          classes={{
            tooltip: classes.info,
            arrow: classes.arrow,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              width: "fit-content",
              color: "#00000061",
              textTransform: "capitalize",
              fontFamily: '"Avenir Book", sans-serif',
            }}
          >
            {isSignedUp || authenticated ? (
              ""
            ) : (
              <>
                <FileCopyIcon style={{ paddingRight: 5 }} /> Invite link
              </>
            )}
            {"  "}
          </div>
        </Tooltip>
      </CopyToClipboard>
    </div>
  );
};
