import React, { FC } from "react";
import { useStyles } from "./styles";

interface IErrorMessage {
  message: string;
}

const ErrorMessage: FC<IErrorMessage> = ({ message }) => {
  const classes = useStyles({});

  return (
    <div
      className={`${classes.chartContainer} ${classes.noData} ${classes.marginTop}`}
      style={{
        marginBottom: 34,
      }}
    >
      {message}
    </div>
  );
};

export default ErrorMessage;
