import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  box: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    backgroundColor: "#000",
    fontFamily: "'Avenir Black', sans-serif",
    fontWeight: "bold",
    height: "45px",
    width: "192px",
    margin: "0px 0px 10px",
    textTransform: "none",
    borderRadius: "10px",
    marginBottom: 8,
    fontSize: "15px",
    lineHeight: "22px",
    color: "#FFFFFF",
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
    "&:hover": {
      opacity: 0.8,
      backgroundColor: "#000",
    },
  },
});
