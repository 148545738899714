import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: "20px 0",
    overflowY: "scroll",
    width: "100%",
    height: "218px",
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0px -32px",
    padding: "0 20px",
    height: 68,
    background: "#ABADAD",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  icon: {
    color: "#000",
    cursor: "pointer",
  },
  cancel: {
    marginRight: "25px",
  },
  cancelLabel: {
    fontFamily: "AvenirNext-DemiBold",
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#000000",
    mixBlendMode: "normal",
    opacity: 0.5,
  },
  archiveLabel: {
    height: "22px",
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    textTransform: "capitalize",
    textDecorationLine: "underline",
    color: "#929292",
  },
  saveButton: {
    fontFamily: "AvenirNext-DemiBold",
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#1D4E89",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContainer: {
    display: "flex",
    maxWidth: "480px",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    height: 74,
    padding: "0px 32px",

    "@media screen and (max-width: 450px)": {
      padding: "0px 8px",
    },
  },
  title: {
    fontSize: "30px",
    lineHeight: "41px",
    color: "#000000",
    fontFamily: "'Avenir Black', sans-serif",
    fontWeight: 900,
  },
  formControlLabel: {
    height: "22px",
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    textTransform: "none",
    color: "#000000",
  },
  subTitle: {
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: "15px",
    lineHeight: "21px",
    color: "#000000",
    margin: "unset",
    marginTop: 26,
  },
  checkbox: {
    "&:hover": {
      background: "rgba(0,0,0,0.3) !important",
    },
    "&.Mui-checked": {
      color: "#000",
    },
  },
  line: {
    opacity: " 0.3",
    height: "1px",
    background: "#979797",
    width: "100%",
  },
}));
