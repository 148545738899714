import React, { FunctionComponent, useEffect, useState, memo } from "react";
import {
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Grid,
  Tooltip,
  Box,
  Typography,
  CardMedia,
} from "@material-ui/core";
import { Public } from "@material-ui/icons";
import linkifyHtml from "linkifyjs/html";

import { ReactComponent as PendingApproval } from "../../../assets/pendingapproval.svg";
import { ReactComponent as PendingInternalAmmends } from "../../../assets/pendinginternalamends.svg";
import { ReactComponent as PendingInternalReview } from "../../../assets/pendinginternalreview.svg";
import { ReactComponent as ApprovedIcon } from "../../../assets/approved.svg";
import { ReactComponent as RejectedIcon } from "../../../assets/rejected.svg";
import { ReactComponent as Draft } from "../../../assets/draft.svg";
import { ReactComponent as DraftMonth } from "../../../assets/Draftmonth.svg";
import { ReactComponent as ClockLoader } from "../../../assets/Clockloader.svg";
import { ReactComponent as CheckCircle } from "../../../assets/check_circle.svg";
import { ReactComponent as PlaceHolder } from "../../../assets/greybg.svg";

import userIcon from "static/media/name.svg";
import { useStyles } from "../styles";
import { useUser } from "UserContext";
import { IUser } from "interfaces/IUser";
// import Notes from "components/BePlanned/Notes";

import facebook from "static/media/fb-vector.png";
import twitter from "static/media/twitter-vector.png";
import instagram from "static/media/instagram-vector.png";
import pinterest from "static/media/pinterest.png";
import snapchat from "static/media/snapchat.png";
import youtubeshort from "static/media/youtube-shorts.png";
import tiktok from "static/media/tiktok.png";
import discord from "static/media/Discord.png";
import threads from "static/media/Threads.png";
import triller from "static/media/triller.png";
import tumblr from "static/media/tumblr.png";
import youtube from "static/media/youtube.png";
import { firestore } from "../../../firebase-tools/firebase";
import useCollectionSubscriber from "../../../hooks/useCollectionSubscriber";
import { filterNotes } from "../helpers/notes";
import { channelsList } from "../CreateEditPost";
// import Carousel from "components/Common/Carousel";
import { SocialPostContainer, Context } from "components/styled/SocialPost";
import { cancelReloadTabClose } from "utils/navigate";
import { useUnsaved } from "LinkContext";
import { FieldTitle } from "components/styled/CreateEditPost";
import {
  formatGraphicsBrief,
  getPostDate,
  generateScreenshot,
} from "../helpers/socialPost";
// import DownloadActions from "components/Common/DownloadActions/DownloadActions";

import { DeleteEvent } from "../DeleteEvent";
import EditEvent from "components/BePlannedMonthly/EditEvent";
import CheckForm from "../CheckForm";
import EventIcon, {
  getBackground,
} from "components/BePlannedMonthly/EventIcon";
import Notes from "components/BePlannedMonthly/Notes";

interface ImageWithTooltipProps {
  imagePath: string;
  tooltipText: string;
}

interface ISocialPost {
  event: any;
  user: any;
  role: string;
  uuid: string;
  dailyCheck: boolean;
  eventIds: any[];
  setEventIds: (date: any) => void;
  checkAll: Boolean;
  uncheckAll: Boolean;
}

const Event: FunctionComponent<ISocialPost> = ({
  event,
  user,
  role,
  uuid,
  dailyCheck,
  checkAll,
  eventIds,
  setEventIds,
  uncheckAll,
}) => {
  const classes = useStyles({});
  const [authUser] = useUser() as Array<IUser>;
  const [, setUnsaved] = useUnsaved() as any;
  const {
    publicIcon,
    avatar,
    AvatarIcon,
    AvatarSvg,
    cardContent,
    img,
  } = useStyles({});
  const [note, setNote] = useState("");
  const [noteType, setNoteType] = useState("internal");
  const eventId = event?.id;
  const notesQueries = [
    firestore
      .collection("events")
      .doc(event?.id || "no-Id")
      .collection("notes")
      .orderBy("created_at", "desc"),
  ];
  const notes: any = useCollectionSubscriber({
    queries: notesQueries,
    deps: [event.id],
  }).documents;

  const { internalNotes, externalNotes } = filterNotes(notes);
  const [eventDate, setEventDate] = useState(undefined);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getImages = () => {
    let images;
    if (typeof event.image_url === "string") {
      if (event.image_url !== "") {
        images = [event.image_url];
      } else {
        images = [];
      }
    } else {
      images = event.image_url;
    }
    return images;
  };
  const [images, setImages] = useState(getImages());
  const [tempNotes, setTempNotes]: Array<any> = useState([]);

  const icons = {
    facebook,
    twitter,
    instagram,
    pinterest,
    snapchat,
    tiktok,
    youtubeshort,
    triller,
    tumblr,
    discord,
    youtube,
    threads,
  };

  useEffect(() => {
    // const updatedImages = getImages();
    // setImages(updatedImages);
    //eslint-disable-next-line
  }, [getImages, event, event.image_url]);

  const displayCategory = (post, channel) => {
    if (post?.category) {
      const valid = Object.values(post?.category).every((ct) =>
        Array.isArray(ct)
      );

      if (valid && channelsList.includes(channel?.toLowerCase())) {
        return (
          <>
            {post?.category[channel.toLowerCase()] &&
              post?.category[channel.toLowerCase()]
                .filter((cat) => cat !== "")
                .map((option, i) => (
                  <div
                    style={{
                      display: "inline-block",
                      margin: "0 10px",
                      textAlign: "center",
                      color: "#000",
                    }}
                    key={`category-${i}`}
                  >
                    {option.split(" ").slice(1).join(" ")}
                  </div>
                ))}
          </>
        );
      } else if (Array.isArray(post?.category?.custom)) {
        return (
          <>
            {post?.category?.custom &&
              post?.category?.custom
                .filter((cat) => cat !== "")
                .map((option: any, i: number) => (
                  <span
                    key={`category-${i}`}
                    style={{ margin: "0 5px", color: "#000" }}
                  >
                    {i > 0 && ","} {option.split(" ").slice(1).join(" ")}
                  </span>
                ))}
          </>
        );
      }
    }
  };

  const getIcons = (status, type = "week") => {
    let icon = <PendingApproval />;

    if (status === "Pending Approval") {
      icon = <PendingApproval />;
    }

    if (["Client Approved", "Scheduled"].includes(status)) {
      icon = <ApprovedIcon />;
    }

    if (["Client Declined"].includes(status)) {
      icon = <RejectedIcon />;
    }
    if (status === "Draft") {
      icon = <Draft />;
    }

    if (status === "Pending Internal Review") {
      icon = <PendingInternalReview />;
    }

    if (type === "month" && status === "Pending Approval") {
      icon = <ClockLoader />;
    }
    if (type === "month" && status === "Draft") {
      icon = <DraftMonth />;
    }
    if (type === "month" && status === "Approved") {
      icon = <CheckCircle />;
    }

    if (["Pending Internal Amends"].includes(status)) {
      icon = <PendingInternalAmmends />;
    }

    if (status === "Custom Event") {
      icon = <Draft />;
    }
    return icon;
  };

  const getColor = (status) => {
    let color: any = "#FFD944";

    if (status === "Pending Internal") {
      color = "#FFD944";
    }
    if (status === "Pending Approval") {
      color = "#F17013";
    }

    if (status === "Pending Client Approval") {
      color = "#F17013";
    }

    if (["Client Approved", "Scheduled"].includes(status)) {
      color = "#24B025";
    }

    if (["Client Declined"].includes(status)) {
      color = "#EC220E";
    }
    if (status === "Draft") {
      color = "#ABADAD";
    }
    if (status === "Custom Event") {
      color = "#ABADAD";
    }

    if (status === "Pending Internal Review") {
      color = "#0688E2";
    }

    return color;
  };

  useEffect(() => {
    cancelReloadTabClose(note.length > 0);
    if (note.length > 0) setUnsaved(true);
    else setUnsaved(false);
    // eslint-disable-next-line
  }, [note]);

  const postChannel = {
    instagram: 1,
    facebook: 2,
    twitter: 3,
    tiktok: 4,
    youtube: 5,
    snapchat: 6,
    threads: 7,
    discord: 8,
    default: Number.MAX_VALUE,
  };

  const postChannels = event?.channels
    ?.filter(
      (val) =>
        !val?.toLowerCase().includes("all") &&
        !val?.toLowerCase().includes("youtube short")
    )
    ?.sort(
      (a, b) =>
        (postChannel[a] || postChannel.default) -
          (postChannel[b] || postChannel.default) ||
        a > b ||
        -(a < b)
    );

  const truncatedContent = (content, characterLimit) => {
    return `${content.slice(0, characterLimit)}...`;
  };

  const [externalOpen, setExternalOpen] = useState(false);

  return (
    <SocialPostContainer id="content-post">
      <Grid container>
        <Grid item xs={12} sm={8} container>
          <Grid xs={12} sm={1}>
            <CheckForm
              postIds={eventIds}
              postId={event.id}
              setPostIds={setEventIds}
              checkAll={checkAll}
              uncheckAll={uncheckAll}
              dailyCheck={dailyCheck}
            />
          </Grid>
          <div
            style={{
              backgroundColor: getBackground(event.event_type),
              padding: 5,
              height: "auto",
            }}
            className={classes.smHide}
          ></div>
          <Card className={classes.cardWidth}>
            <div
              style={{
                backgroundColor: getBackground(event.event_type),
                padding: 5,
                width: "100%",
              }}
              className={classes.mdHide}
            ></div>
            <CardContent id={`post-${uuid}`}>
              <Grid xs={12} md={12} container>
                <Grid item xs={12} md={12} container>
                  <Grid container>
                    <Grid item xs={12} md={7}>
                      <CardHeader
                        avatar={
                          user.avatarUrl !== "" ? (
                            <Avatar className={avatar} src={user.avatarUrl} />
                          ) : (
                            <Avatar
                              classes={{
                                root: AvatarSvg,
                                img: AvatarIcon,
                              }}
                              src={userIcon}
                            />
                          )
                        }
                        title={`${user.firstName} ${user.lastName}`}
                        classes={{
                          subheader: cardContent,
                          title: cardContent,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={5}
                      style={{
                        paddingTop: "12px",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "right",
                      }}
                      container
                    >
                      {authUser.role !== "viewerAdmin" && (
                        <>
                          <span
                            style={{
                              paddingLeft: 11,
                              paddingRight: 11,
                            }}
                          >
                            <DeleteEvent role={role} id={event.id} />
                          </span>
                          <span
                            style={{
                              paddingLeft: 11,
                              paddingRight: 11,
                            }}
                          >
                            <EditEvent
                              edit="edit"
                              event={event}
                              user={user}
                              externalOpen={externalOpen}
                              setExternalOpen={setExternalOpen}
                              externalDate={eventDate}
                              setExternalDate={setEventDate}
                            />
                          </span>
                        </>
                      )}
                    </Grid>
                  </Grid>

                  <Grid container>
                    <FieldTitle
                      style={{
                        paddingLeft: "calc(30px - 18px)",
                        fontWeight: 900,
                        fontSize: "16px",
                        fontFamily: "Avenir",
                        textTransform: "uppercase",
                      }}
                    >
                      {event.subject}
                    </FieldTitle>

                    <Grid
                      container
                      style={{ marginTop: "20px", padding: "12px" }}
                    >
                      <Grid xs={6} md={6}>
                        <p
                          style={{
                            fontWeight: 900,
                            fontSize: "15px",
                            color: "#000000",
                            fontFamily: "Avenir",
                          }}
                        >
                          Event Date
                        </p>
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "15px",
                            color: "#000000",
                          }}
                        >
                          {getPostDate(event.start_date.seconds)}
                        </p>
                      </Grid>

                      <Grid xs={6} md={6}>
                        <p
                          style={{
                            fontWeight: 900,
                            fontSize: "15px",
                            color: "#000000",
                            fontFamily: "Avenir",
                          }}
                        >
                          Event type
                        </p>

                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "15px",
                            color: "#000000",
                          }}
                        >
                          <span
                            style={{
                              paddingRight: 10,
                            }}
                          >
                            {/* {getIcons(event.event_type)} */}
                            {
                              <EventIcon
                                category={event.event_type}
                                width={24}
                                height={24}
                                borderRadius={4}
                              />
                            }
                          </span>
                          {event.event_type}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>{" "}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          id="actions-post"
          style={{
            padding: "25px",
          }}
        >
          <Context
            style={{ margin: authUser.role !== "viewerAdmin" ? 0 : "-26px" }}
          >
            {/* <Notes
              user={authUser}
              note={note}
              setNote={setNote}
              setNoteType={setNoteType}
              noteType={noteType}
              internalNotes={internalNotes}
              externalNotes={externalNotes}
              post={event}
            /> */}

            <Notes
              user={authUser}
              note={note}
              setNote={setNote}
              notes={notes}
              event={event}
              client={user}
              setTempNotes={setTempNotes}
              setNoteType={setNoteType}
              noteType={noteType}
            />
          </Context>
        </Grid>
      </Grid>
    </SocialPostContainer>
  );
};

export default Event;
