import React, { FunctionComponent } from "react";
import { Link } from "@reach/router";

import { useStyles } from "./styles";

export interface FFProps {
  path?: any;
}

const FormFooter: FunctionComponent<FFProps> = ({ path }) => {
  const classes = useStyles({});
  return (
    <div className={classes.footerContainer}>
      {path === "sign-up" && (
        <span className={classes.link}>
          Already have an account?
          <br />
          <Link
            to="/sign-in"
            className={`${classes.link} ${classes.signup} ${classes.footerLinks}`}
          >
            Sign In
          </Link>
        </span>
      )}
    </div>
  );
};

export default FormFooter;
