import { Grid } from "@material-ui/core";
import React from "react";

const YoutubeVideo = ({ url }) => {
  const videoId = new URL(url).searchParams.get("v");

  return (
    <Grid container xs>
      <iframe
        title="YouTube video player"
        height="315"
        width={"100%"}
        src={`https://www.youtube.com/embed/${videoId}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        allowFullScreen
      />
    </Grid>
  );
};

export default YoutubeVideo;
