/**
 * @prettierdesc
 */

import React, { FC, useState } from "react";
import {
  Box,
  Tooltip,
  CircularProgress,
  Select,
  MenuItem,
  Input,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import history from "history/browser";
import cuid from "cuid";

import CreateCampaignStyles from "components/styled/CreateCampaign";
import Image from "components/Common/Image/Image";

import twitter from "static/media/twitter-vector.png";
import defaultIcon from "static/media/universal-2.png";
import { SendButton } from "components/styled/Common";
import { PageType } from "types/PageType";
import { firestore } from "firebase-tools/firebase";
import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import GetCookies from "components/Common/GetCookies";
import CampaignName from "components/Common/CampaignName";
import { useUser } from "UserContext";
import { IUser } from "interfaces/IUser";
import { autoPoster } from "helpers/twitterAutoPoster/autoPoster";
import { SocialMediaPlatform } from "../../../../../../containers/BeAmplified/Campaigns/Organic/SelectCampaign";
import { getPlatformImage } from "components/BeAmplified/Campaigns/Organic/Components/SelectCampaignBox";
import showToast from "utils/toast";
import { capitalize } from "utils/stringFormat";
import { DateRange } from "materialui-daterange-picker";
import moment from "moment";
import DateSelector from "components/BeMeasured/DateSelector";
import { longDate } from "utils/dateFormat";
import { useStyles } from "components/BeMeasured/styles";

interface CreateAutoPosterProps extends PageType {
  uid?: string;
  toggleCreate?: Function;
}

export const CreateAutoPoster: FC<CreateAutoPosterProps> = ({
  toggleCreate,
  uid,
}) => {
  const [authUser] = useUser() as Array<IUser>;

  const [campaignName, setCampaignName] = useState("");
  const [sessionCookie, setSessionCookie] = useState("");
  const [spreadsheetUrl, setSpreadsheetUrl] = useState("");
  const [errors, setErrors] = useState({
    client: "",
    campaignName: "",
    spreadsheetUrl: "",
  });
  const [loading, setLoading] = useState(false);

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: moment().toDate(),
    endDate: moment().add(1, "days").toDate(),
  });

  const [interval, setInterval] = useState<{
    label: string;
    interval: string;
  }>({ label: "", interval: "" });

  const id = cuid();

  let userRef = firestore.collection("users").doc(authUser.id);
  let client =
    useDocumentSubscriber({ ref: userRef }).document?.lastClientSelection || "";

  const createCampaign = async () => {
    if (!client)
      return setErrors((errors: any) => ({
        ...errors,
        client: "Client is required!",
      }));
    if (!campaignName)
      return setErrors((errors: any) => ({
        ...errors,
        campaignName: "Campaign name is required!",
      }));
    if (!spreadsheetUrl)
      return setErrors((errors: any) => ({
        ...errors,
        spreadsheetUrl: "Spreadsheet Url is required!",
      }));

    if (!spreadsheetUrl.includes("https://docs.google.com/spreadsheets/d/")) {
      return setErrors((errors: any) => ({
        ...errors,
        spreadsheetUrl: "Enter a valid spreadsheet URL!",
      }));
    }
    if (sessionCookie === "") {
      showToast("Error: Add a session Cookie to proceed");
      return;
    }
    if (interval.interval === "") {
      showToast("Error: Select a campaign inerval to proceed");
      return;
    }

    const duration = moment.duration(
      moment(dateRange.endDate?.toString()).diff(
        dateRange.startDate?.toString()
      )
    );

    let error: string | null = null;
    switch (interval.interval) {
      case "Hours":
        if (duration.asHours() < 1) {
          error =
            "Error: Duration between start and end date should be atlist 1 hour";
        }
        break;

      case "Day":
        if (duration.asDays() < 1) {
          error =
            "Error: Duration between start and end date should be atlist 1 day";
        }
        break;

      case "Week":
        if (duration.asWeeks() < 1) {
          error =
            "Error: Duration between start and end date should be atlist 1 week";
        }
        break;

      case "Month":
        if (duration.as("months") < 1) {
          error =
            "Error: Duration between start and end date should be atlist 1 month";
        }
        break;

      default:
        break;
    }

    if (error) {
      showToast(error);
      return;
    }

    setLoading(true);
    await autoPoster({
      id,
      spreadsheetUrl,
      sessionCookie: client?.twitter?.sessionCookie || sessionCookie,
      campaignName,
      username: client?.twitter?.username,
      interval: interval.interval,
      intervalOptions: {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      },
    });
    setCampaignName("");
    setSessionCookie("");
    setSpreadsheetUrl("");
    setLoading(false);
  };

  const intervals = [
    { label: "Hourly", interval: "Hours" },
    { label: "Daily", interval: "Day" },
    { label: "Weekly", interval: "Week" },
    { label: "Monthly", interval: "Month" },
  ];

  const classes = useStyles({
    listLength: "auto",
    autoCompleteWidth: "100%",
  });

  return (
    <CreateCampaignStyles>
      <ToastContainer />
      <div>
        <span className="title__main">{uid ? "Edit" : "Create"} Campaign</span>

        <Box marginTop="50px">
          <div className="items-center">
            <Image className="img__main" src={twitter || defaultIcon} alt="" />
            <span className="title__sub">Twitter Auto Poster</span>
          </div>
        </Box>
        <p className="description__main">
          Post the same Tweet on a regular basis, to make a statement of what
          your brand stands for..
        </p>
      </div>

      <div
        style={{ margin: "20px 0px -20px 0px" }}
        className="campaign__container"
      >
        <span className="title__basic" style={{ marginBottom: 10 }}>
          Client name
        </span>
        <input
          type="text"
          value={`${authUser.lastClientSelection.firstName} ${authUser.lastClientSelection.lastName}`}
          style={{ color: "#606060" }}
          className="campaign__input description__sub campaign__input--disabled"
        />
      </div>

      <CampaignName
        campaignName={campaignName}
        setCampaignName={setCampaignName}
        error={errors.campaignName}
        setErrors={setErrors}
      />

      <GetCookies
        setSessionCookie={setSessionCookie}
        sessionCookie={sessionCookie}
        platform={SocialMediaPlatform.Twitter}
        title={`Your ${capitalize(SocialMediaPlatform.Twitter)} Session Cookie`}
        platformIcon={getPlatformImage(SocialMediaPlatform.Twitter)}
      />

      <div
        style={{ margin: "20px 0px -20px 0px" }}
        className="campaign__container"
      >
        <span className="title__basic" style={{ marginBottom: 10 }}>
          Campaign launch interval
        </span>
        <Select
          disableUnderline
          autoWidth={true}
          id="multi-select"
          value={interval}
          displayEmpty={true}
          renderValue={(value: any) => value?.label || ""}
          onChange={(event) => {
            setInterval({
              interval: event.target.value as string,
              label:
                intervals.find((i) => i.interval === event.target.value)
                  ?.label || "",
            });
          }}
          input={<Input />}
          className={"campaign__input"}
          style={{ backgroundColor: "#fff" }}
          inputProps={{
            className: classes.selectInput,
            classes: {
              icon: classes.selectIcon,
              iconOpen: classes.selectIconOpen,
            },
          }}
          MenuProps={{
            // classes: { paper: classes.selectOverrideChannels },
            PaperProps: {
              style: {
                // maxHeight: ITEM_HEIGHT * 5.2 + ITEM_PADDING_TOP,
                width: "40%",
                marginTop: "8px",
                borderRadius: 15,
              },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            getContentAnchorEl: null,
          }}
        >
          {intervals.map((val) => (
            <MenuItem key={val.interval} value={val.interval}>
              {val.label}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div style={{ marginTop: "40px" }} className="campaign__container">
        <span className="title__basic" style={{ marginBottom: 10 }}>
          Select campaign start and end date
        </span>
        <DateSelector
          handleDateFromChange={(date: Date) =>
            setDateRange((dates) => ({ ...dates, startDate: date }))
          }
          handleDateToChange={(date: Date) =>
            setDateRange((dates) => ({ ...dates, endDate: date }))
          }
          selectedDateFrom={dateRange.startDate}
          selectedDateTo={dateRange.endDate}
          startText={longDate(dateRange.startDate)}
          endText={longDate(dateRange.endDate)}
        />
      </div>

      {/* More info */}
      <Box marginTop="40px" className="connection-container">
        <div className="campaign__container">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <span className="title__basic">
              Spreadsheet URL of tweets to send
            </span>
            <Tooltip
              title={
                <Box>
                  <h1>
                    Specify the content of the Tweets you want to automatically
                    post.
                  </h1>
                  <Box fontSize={14}>
                    <div>
                      Create a spreadsheet on{" "}
                      <a
                        href="https://docs.google.com/spreadsheets/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Google Spreadsheet.
                      </a>{" "}
                      Fill it with your Tweet's content. One name per row, all
                      in column A:
                    </div>
                    <br />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/be-measured-admin-staging.appspot.com/o/images%2FScreenshot%202021-12-13%20at%2012.51.35.png?alt=media&token=692493ea-d8ce-461a-a715-f188fc11e57d"
                      alt="tweets"
                      width="88%"
                    />
                    <br />

                    <div>Make this spreadsheet public so we can access it:</div>
                    <br />

                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/be-measured-admin-staging.appspot.com/o/images%2Fshare%20spreadsheet.png?alt=media&token=d1aaa739-4d5a-40a0-9562-8b82a633d61b"
                      alt="share spreadsheet"
                      width="98%"
                    />
                    <br />

                    <div>And paste that spreadsheet's URL back here.</div>
                  </Box>
                </Box>
              }
              arrow
              interactive
            >
              <InfoIcon style={{ color: "#C4C4C4" }} />
            </Tooltip>
          </Box>
          <span className="description__sub">
            Must be a google spreadsheet URL.
          </span>
          <input
            type="text"
            value={spreadsheetUrl}
            placeholder="https://docs.google.com/spreadsheets/d/(...)"
            onChange={(e) => {
              setSpreadsheetUrl(e.target.value);
              setErrors((errors: any) => ({
                ...errors,
                spreadsheetUrl: "",
              }));
            }}
            className="campaign__input"
          />
          {errors.spreadsheetUrl && (
            <span style={{ color: "red", fontSize: 14, paddingLeft: 6 }}>
              {errors.spreadsheetUrl}
            </span>
          )}
        </div>
        <div className="campaign__container">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <span className="title__basic">Number Of Tweets Per Launch</span>
            <Tooltip title="Number Of Tweets Per Launch" arrow interactive>
              <InfoIcon style={{ color: "#C4C4C4" }} />
            </Tooltip>
          </Box>
          <span className="description__sub">Default is set to 50</span>
          <input
            type="text"
            value={50}
            className="campaign__input campaign__input--disabled"
            disabled
          />
        </div>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop="40px"
      >
        <div
          className="title__sub link"
          onClick={() => {
            toggleCreate && toggleCreate();

            !toggleCreate && history.back();
          }}
        >
          {"<"} Back
        </div>
        <SendButton
          onClick={(e) => {
            e.preventDefault();
            !loading && createCampaign();
          }}
          className="btn"
          disabled={loading}
        >
          {uid
            ? `Updat ${loading ? "ing" : "e"}`
            : `Launch ${loading ? "ing" : ""}`}{" "}
          Campaign
          {loading && <CircularProgress className="btn-progress" size={20} />}
        </SendButton>
      </Box>
    </CreateCampaignStyles>
  );
};
