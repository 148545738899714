import { navigate } from "@reach/router";
import axios from "axios";
import { SocialMediaPlatform } from "containers/BeAmplified/Campaigns/Organic/SelectCampaign";

export const facebookExtractor = async ({
  id,
  spreadsheetUrl,
  sessionCookie,
  sessionCookieXS,
  campaignName,
  username,
  membersCount,
  campaignType,
}) => {
  if (!process.env.REACT_APP_FUNCTIONS_BASE_URL) {
    console.log("Invalid url");
    return;
  }

  const response = await axios.post(
    `${process.env.REACT_APP_FUNCTIONS_BASE_URL}facebook-groupScrapper`,
    {
      id,
      spreadsheetUrl,
      sessionCookie,
      sessionCookieXs: sessionCookieXS,
      campaignName,
      username,
      membersCount,
      campaignType,
    }
  );

  const result = response.data;

  return result;
};

export const igCommentsExtractor = async ({
  spreadsheetUrl,
  sessionCookie,
  campaignName,
  username,
  numberOfProfilesPerLaunch,
}) => {
  if (!process.env.REACT_APP_FUNCTIONS_BASE_URL) {
    console.log("Invalid url");
    return;
  }

  const rawResponse = await fetch(
    `${process.env.REACT_APP_FUNCTIONS_BASE_URL}instagram-creareCommentsExtractor`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        spreadsheetUrl,
        sessionCookie,
        campaignName,
        campaignType: "commentsExtractor-scrapper",
        username,
        numberOfProfilesPerLaunch,
      }),
    }
  );
  const result = await rawResponse.json();

  navigate(
    `/campaigns/organic/extractor/${SocialMediaPlatform.Instagram.toLowerCase()}/${
      result?.id
    }`
  );

  return result;
};
