import React, { useEffect, useState } from "react";
import axios from "axios";
import { Grid } from "@material-ui/core";
import "./InstanceToggle.css"; // Import the CSS file for styling
import { warning } from "utils/notification";

enum ServerStatusType {
  PROVISIONING = "PROVISIONING",
  STAGING = "STAGING",
  RUNNING = "RUNNING",
  STOPPING = "STOPPING",
  TERMINATED = "TERMINATED",
  SUSPENDED = "SUSPENDED",
  DELETED = "DELETED",
  STARTING = "STARTING",
}

const InstanceToggle = ({ open }: { open: boolean }) => {
  const [isRunning, setIsRunning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [serverStatus, setServerStatus] = useState(
    ServerStatusType.PROVISIONING
  );

  const checkServerStatus = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_FUNCTIONS_BASE_URL}cloudCompute-getFastapiInstanceStatus`
      );
      if (res.data.status) {
        setServerStatus(res.data.status);
        setIsRunning(res.data.status === ServerStatusType.RUNNING);
        if (res.data.status === ServerStatusType.STOPPING) {
          warning("Instance is stopping. Please wait.");
        } else if (res.data.status === ServerStatusType.STARTING) {
          warning("Instance is starting. Please wait.");
        }
      }
    } catch (error) {
      console.error("Error fetching server status:", error);
      setError("Failed to fetch instance status.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      checkServerStatus();
    }
  }, [open]);

  const toggleInstance = async () => {
    setLoading(true);
    setError("");
    try {
      const action = isRunning ? "stop" : "start";
      const response = await axios.get(
        `${process.env.REACT_APP_FUNCTIONS_BASE_URL}cloudCompute-updateFastInstance?action=${action}`
      );
      if (response.status === 200) {
        checkServerStatus();
      }
    } catch (err) {
      setError("Failed to toggle instance.");
      console.error("Error toggling instance:", err);
      if (err.response) {
        const errorMsg = err.response.data.error;
        warning(
          errorMsg.includes("Invalid action")
            ? "Invalid action. Possible values: start, stop"
            : errorMsg
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const setColorBasedOnStatus = (status: ServerStatusType) => {
    switch (status) {
      case ServerStatusType.PROVISIONING:
        return "#FF9800";
      case ServerStatusType.STAGING:
        return "#FF9800";
      case ServerStatusType.RUNNING:
        return "#4CAF50";
      case ServerStatusType.STOPPING:
        return "#FF9800";
      case ServerStatusType.TERMINATED:
        return "#FF9800";
      case ServerStatusType.SUSPENDED:
        return "#FF9800";
      case ServerStatusType.DELETED:
        return "#FF9800";
      default:
        return "#FF9800";
    }
  };

  return (
    <Grid container className="toggle-container">
      <Grid item xs={12} md={6}>
        <label className="switch">
          <input
            type="checkbox"
            checked={isRunning}
            onChange={toggleInstance}
            disabled={loading}
          />
          <span className="slider"></span>
        </label>
        {loading && <p className="status">Loading...</p>}
        {error && <p className="error">{error}</p>}
      </Grid>

      <Grid item xs={12} md={6}>
        <p
          className="status"
          style={{ fontSize: 16, fontFamily: "Avenir Black" }}
        >
          <span style={{ fontWeight: 900 }}>Server Status:</span>{" "}
          <span style={{ color: setColorBasedOnStatus(serverStatus) }}>
            {loading ? "Loading..." : serverStatus}
          </span>
        </p>
      </Grid>
    </Grid>
  );
};

export default InstanceToggle;
