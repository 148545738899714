import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  avatar: {
    width: "80px",
    height: "80px",
    mixBlendMode: "normal",
  },
  icon: {
    width: "80px",
    height: "80px",
  },
  svg: {
    width: "40%",
    height: "40%",
  },
  box: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    marginLeft: "36px",
    color: "#FFFFFF",
    fontFamily: "'Avenir Black', sans-serif",
    fontWeight: "bold",
    fontSize: "15px",
    lineHeight: "22px",
    backgroundColor: "#000",
    "&:hover": {
      backgroundColor: "#000",
      opacity: 0.8,
    },
    width: "162px",
    height: "45px",
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
    borderRadius: "10px",
  },
  uploadImg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "108px",
    height: "107px",
    background: "#C5C6C6",
    borderRadius: "2px",
  },
});
