import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  logo: {
    margin: theme.spacing(2, 0, -2, 0),
    width: "180px",
    color: "red",
  },
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    height: "100vh",
    maxWidth: theme.spacing(52.5),

    "@media screen and (max-width: 450px)": {
      height: "100%",
    },
  },
  height: {
    height: "100vh",
  },
  link: {
    color: "#fff",
    opacity: 0.7,
    textDecoration: "none",
    fontFamily: "Avenir Book, sans-serif",
  },
  linkArea: {
    width: "75%",
    textAlign: "center",
  },
  tr: {
    zIndex: -1,
    position: "fixed",
    top: 0,
    right: 0,
    opacity: 0.3,
  },
  bl: {
    zIndex: -1,
    position: "fixed",
    bottom: 0,
    left: 0,
  },
  signup: {
    display: "block",
    fontSize: "16px",
  },
  backToLogin: {
    marginRight: "10px",
  },
  footerContainer: {
    marginTop: theme.spacing(4),
    fontSize: "16px",
    fontFamily: "Avenir Book, sans-serif",
  },
  footerLinks: {
    textDecoration: "underline",
  },
  pageHeader: {
    fontSize: "30px",
    lineHeight: "25px",
    fontFamily: "Avenir Book, sans-serif",
    fontWeight: 900,
    margin: "50px 0px 0px",
    width: 445,
    textAlign: "center",
  },
}));

export { useStyles };
