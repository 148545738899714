import { IStats } from "./PrintMode";

const getInitialState = (statsCount, audience) => ({
  fbStats: {
    statsCount: [...statsCount]
      .filter((stat) => stat.media.includes("fb"))
      .map((stat: IStats) => {
        return {
          ...stat,
          id: "fb-" + stat.id,
        };
      }),
    audience: [...audience].map((stat: IStats) => {
      return {
        ...stat,
        id: "fb-" + stat.id,
      };
    }),
    selected: [],
  },
  igStats: {
    statsCount: [
      ...[...statsCount].filter((stat) => stat.media.includes("ig")),
    ],
    audience: [...audience],
    selected: [],
  },
  twitterStats: {
    statsCount: [...statsCount]
      .filter((stat) => stat.media.includes("tw"))
      .map((stat: IStats) => {
        return {
          ...stat,
          id: "twitter-" + stat.id,
        };
      }),
    selected: [],
  },
  tikTokStats: {
    statsCount: [...statsCount]
      .filter((stat) => stat.media.includes("tikTok"))
      .map((stat: IStats) => {
        return {
          ...stat,
          id: "tikTok-" + stat.id,
        };
      }),
    selected: [],
  },
  youTubeStats: {
    statsCount: [...statsCount]
      .filter((stat) => stat.media.includes("YouTube"))
      .map((stat: IStats) => {
        return {
          ...stat,
          id: "YouTube-" + stat.id,
        };
      }),
    selected: [],
  },
  spotifyStats: {
    statsCount: [...statsCount]
      .filter((stat) => stat.media.includes("Spotify"))
      .map((stat: IStats) => {
        return {
          ...stat,
          id: "Spotify-" + stat.id,
        };
      }),
    audience: [...audience].map((stat: IStats) => {
      return {
        ...stat,
        id: "Spotify-" + stat.id,
      };
    }),
    selected: [],
  },
  appleMusicStats: {
    statsCount: [...statsCount]
      .filter((stat) => stat.media.includes("Apple Music"))
      .map((stat: IStats) => {
        return {
          ...stat,
          id: "Apple Music-" + stat.id,
        };
      }),
    audience: [...audience].map((stat: IStats) => {
      return {
        ...stat,
        id: "Apple Music-" + stat.id,
      };
    }),
    selected: [],
  },
});

export default getInitialState;
