import React, { useState, FC, useEffect } from "react";
import moment from "moment";
import { DateRange } from "materialui-daterange-picker";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import history from "history/browser";

import { PageType } from "types/PageType";
import DateRangePicker from "components/Common/DateRange";
import { ClientList } from "components/BeMeasured/ClientList";
import Select from "components/Common/Select";
import { useStyles } from "./styles";
import { BePlanned } from "components/BePlanned/BePlanned";
import CustomLoader from "components/CustomLoader";
import CreateEditPost from "components/BePlanned/CreateEditPost";

import useCollectionSubscriber from "../../hooks/useCollectionSubscriber";
import { firestore } from "../../firebase-tools/firebase";
import { IPost } from "interfaces/IPost";

import { useUser } from "UserContext";
import { IUser } from "interfaces/IUser";
import { UserService } from "services/UserService";

import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import { postsFilter } from "utils/postsFilter";
import { Toast } from "components/styled/Common";
import Filter from "components/BePlanned/Filter";
import PrintMode from "components/BePlanned/PrintMode";
import BePlannedFeed from "components/styled/BePlannedFeed";
import { urlDates } from "utils/dateFormat";
import PublicLink from "components/Common/PublicLink";

const channelOptions = [
  "Instagram",
  "Facebook",
  "Twitter",
  "TikTok",
  "YouTube",
  "Youtube Short",
  "Others",
  "All",
];

export const BePlannedPage: FC<PageType> = ({ location }) => {
  const classes = useStyles({});
  const [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: moment().startOf("day").toDate(),
    endDate: moment().add(6, "days").startOf("day").toDate(),
  });
  const [authUser] = useUser() as Array<IUser>;

  const [, setClient]: any = useState("");
  const [loading, setLoading] = useState(false);
  const [channels, setChannels]: any = useState([]);
  const [monthlyCategory, setMonthlyCategory] = useState([]);
  const [weeklyCategory, setWeeklyCategory] = useState([]);
  const [paramsChange, setParamsChange]: any = useState("");

  const from = new Date(dateRange.startDate as Date);
  let toDate = new Date(dateRange.endDate as Date);
  const to = moment(toDate).add(1, "days").toDate();

  let userRef = firestore.collection("users").doc(authUser.id);
  let client =
    useDocumentSubscriber({ ref: userRef }).document?.lastClientSelection || "";

  let postsQueries: any = [];
  if (client !== "") {
    postsQueries = [
      firestore
        .collection("multichannel-posts")
        .where("user_email", "==", client.email)
        .where("date", ">=", from)
        .where("date", "<=", to)
        .orderBy("date", "desc"),
    ];
  }

  let posts;

  posts = useCollectionSubscriber<IPost>({
    queries: postsQueries,
    deps: [dateRange, client],
  });
  const settClient = (e) => {
    const User = new UserService(authUser.id);

    return User.updateUser({
      lastClientSelection: e,
    })
      .then((res) => {
        setClient(e);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!!authUser?.lastClientSelection) {
      setClient(authUser?.lastClientSelection);
    } else {
      setClient("");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [posts.documents]);

  useEffect(() => {
    urlDates(dateRange, history, paramsChange, setDateRange, location);

    if (client !== "") {
      setLoading(true);
    }
  }, [dateRange, client, paramsChange, location]);

  return (
    <BePlannedFeed>
      <Toast>
        <ToastContainer progressClassName="toast-progressbar" />
      </Toast>
      <div id="analytics">
        <div
          className={`${classes.pageTitle} be-planned-title`}
          id="page-title"
        >
          <span style={{ marginTop: 48, fontWeight: 900 }}>
            Content Planner
          </span>
          <div className="selector-container">
            <div className="icons" id="icons">
              <PublicLink location={location} primaryEmail={client.email} />
              <PrintMode to={toDate} from={from} user={client} />
              <Filter
                setMonthlyCategory={setMonthlyCategory}
                setWeeklyCategory={setWeeklyCategory}
              />
            </div>
            <div className={`${classes.selector}`} id="selector">
              {authUser.role !== "viewerAdmin" && (
                <div className={classes.BePlanned} id="create-post">
                  <CreateEditPost
                    create="create"
                    role={"facebook"}
                    user={client}
                  />
                </div>
              )}
              <Select
                value={channels}
                setItems={setChannels}
                options={channelOptions}
                label="Choose Channels"
                multiple={true}
              />
              <DateRangePicker
                dateRange={dateRange}
                setDateRange={setDateRange}
                setParamsChange={setParamsChange}
              />
              <ClientList
                client={client}
                setClient={settClient}
                setLoading={setLoading}
              />
            </div>
          </div>
        </div>
        {!loading ? (
          client !== "" ? (
            <BePlanned
              posts={() =>
                postsFilter(channels, posts, monthlyCategory, weeklyCategory)
              }
              user={client}
            />
          ) : (
            <div className={classes.noClient} id="empty-analytics">
              To view the Be-Planned Feed of a client, please choose a client
              above.
            </div>
          )
        ) : (
          <CustomLoader />
        )}
      </div>
    </BePlannedFeed>
  );
};
