/**
 * @prettier
 */
import React, { useState, FunctionComponent } from "react";
import { Modal } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { InviteForm } from "./InviteForm";
import { useStyles } from "./styles";
import { navigate } from "@reach/router";
import { SendButton } from "components/styled/Common";

export const SendInvite: FunctionComponent<{}> = () => {
  const [isOpen, handleOpen] = useState(false);
  const rootRef = React.useRef(null);
  const classes = useStyles({});

  const handleClose = (): void => {
    return handleOpen(false);
  };

  const handleClick = (): void => {
    navigate("/client-info/create");
  };

  return (
    <div className={classes.root}>
      <SendButton
        type="button"
        classes={{
          root: classes.inviteButton,
        }}
        onClick={handleClick}
        disableRipple
        fullWidth
      >
        Create New Client
      </SendButton>
      <Modal
        open={isOpen}
        container={() => rootRef.current}
        onClose={(e: any) => {
          if (e.key === "Escape") {
            handleOpen(false);
          }
        }}
      >
        <div className={classes.modal}>
          <div className={classes.top}>
            <h2 className={classes.title}>Create New Client</h2>
            <CloseIcon className={classes.icon} onClick={handleClose} />
          </div>
          <p className={classes.subTitle}>
            Please enter the name of the client that you are creating. Note that
            the invite is not sent until you complete the next step.
          </p>
          <InviteForm handleClose={handleClose} />
        </div>
      </Modal>
    </div>
  );
};
