import React, { useState, memo } from "react";
import {
  Button,
  Modal,
  Backdrop,
  Fade,
  CircularProgress,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import moment from "moment";
import { DateRange } from "materialui-daterange-picker";

import { useStyles } from "./styles";
import { SendButton, CancelButton, PostPaper } from "components/styled/Common";
import Select from "components/Common/Select";
import DateRangePicker from "components/Common/DateRange";
import { success } from "../../../utils/notification";
import { historicalData } from "../requests/historicalData";

interface Props {
  user: any;
}
const platformOptions = ["Facebook", "Instagram", "Twitter", "Spotify"];

const Historical = (props: Props) => {
  const { user } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [platform, setPlatform] = useState("");
  const [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: moment().subtract(7, "days").startOf("day").toDate(),
    endDate: moment().subtract(1, "days").startOf("day").toDate(),
  });
  const [, setParamsChange]: any = useState("");
  const [error, setError] = useState({ submit: "", platform: "" });
  const availableChannels = (platforms: string[], user: any): string[] => {
    let channels: string[] = [];
    for (const item of platforms) {
      let channel = item?.toLowerCase();
      if (user[channel]?.username) {
        channels.push(item);
      }
    }
    return channels;
  };

  const classes = useStyles({});
  const from = new Date(dateRange.startDate as Date).toISOString();
  const to = new Date(dateRange.endDate as Date).toISOString();

  const handleSelect = (value: string) => {
    setPlatform(value);
    setError({ ...error, platform: "" });
  };

  const close = async (event?: any) => {
    if (event.key === "Escape") {
      setIsOpen(false);
    }
    setIsOpen(false);
  };
  const getDateDifference = (startDate: Date, endDate: Date): number => {
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);

    // Calculate the difference in days
    const diffInDays = endMoment.diff(startMoment, "days");

    return diffInDays;
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    setError({ submit: "", platform: "" });
    if (!platform) {
      setError({ ...error, platform: "Platform is required!" });
      return setSubmitting(false);
    }
    if (platform === "Spotify" && dateRange.startDate && dateRange.endDate) {
      const diff = getDateDifference(dateRange.startDate, dateRange.endDate);

      if (diff > 5) {
        setError({
          ...error,
          platform:
            "For Spotify, you can select a maximum of 5 days. Please adjust your date range accordingly.",
        });
        return setSubmitting(false);
      }
    }
    try {
      const channel = platform.toLowerCase();
      const res = await historicalData(
        from,
        to,
        channel,
        user[channel].username
      );
      setError({ ...error, submit: "" });
      if (res.message) {
        setIsOpen(false);
        success("Historical data added successfully!");
      } else {
        if (res?.error?.includes("last 28 days excluding")) {
          setError({ ...error, submit: res.error });
          return setSubmitting(false);
        }
        setError({
          ...error,
          submit: "An error occurred, please retry!",
        });
      }
    } catch (e) {
      setError({
        ...error,
        submit: "unable to add historical data at the moment, please retry!",
      });
      console.log("Historical error nnm", e);
    }
    setSubmitting(false);
  };

  return (
    <div>
      <Button
        disableRipple
        type="button"
        className={classes.btn}
        id="create-btn"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        Historical Data
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableBackdropClick
      >
        <Fade in={isOpen}>
          <div className={classes.container} id="historical-data">
            <PostPaper>
              <div className={classes.top}>
                <div className={classes.print}>Historical Data</div>
                <Close className={classes.icon} onClick={close} />
              </div>
              <div className={classes.postSection} id="post-section">
                <p style={{ marginTop: 30, fontSize: 15 }}>
                  When you have missing data on some dates - you can run this
                  feature.
                  <br />
                </p>
                <div className={`${classes.field} ${classes.channel}`}>
                  <Select
                    value={platform}
                    setItems={handleSelect}
                    options={availableChannels(platformOptions, user)}
                    label="Choose Channel"
                    multiple={false}
                  />
                  <span className={classes.error}>{error.platform}</span>
                </div>
                <div className={classes.field}>
                  <DateRangePicker
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    setParamsChange={setParamsChange}
                    datePosition={{
                      justify: "center",
                      datePickerClass: classes.datePicker,
                    }}
                  />
                  <span className={classes.error} style={{ marginTop: 20 }}>
                    {error.submit}
                  </span>
                </div>
              </div>
            </PostPaper>
            <div className={classes.line} />
            <div className={`${classes.bottom} ${classes.bottomRespCommon}`}>
              <CancelButton disableRipple onClick={close}>
                cancel
              </CancelButton>

              <SendButton
                onClick={handleSubmit}
                disabled={submitting}
                disableRipple
              >
                {submitting ? (
                  <CircularProgress size={30} color="inherit" />
                ) : (
                  <span>Submit</span>
                )}
              </SendButton>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default memo(Historical);
