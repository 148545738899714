/**
 * @prettier
 */
import React, { useState, FunctionComponent, Fragment } from "react";
import { Modal, Backdrop, Fade, CircularProgress } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { PostService } from "services/PostService";

import { useStyles } from "../BePlanned/styles";

import { EventService } from "services/EventService";
import { SendButton, CancelButton } from "components/styled/Common";
import { ReactComponent as DeleteIcon } from "../../static/media/deleteIcon.svg";

interface IDelete {
  id: any;
  type: string;
  setExternalOpen?: ((open: boolean) => void) | undefined;
  setOpen?: (bool) => void;
}

export const Delete: FunctionComponent<IDelete> = ({
  id,
  type,
  setExternalOpen,
  setOpen,
}) => {
  const classes = useStyles({});

  const [isOpen, setIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");

  const handleDelete = () => {
    if (type === "event") {
      const Event = new EventService(id);
      setSubmitting(true);
      Event.deleteEvent()
        .then(() => {
          setSubmitting(false);
          setIsOpen(!isOpen);
        })
        .catch((e) => {
          setSubmitting(false);
          return setError(e);
        });
    }

    if (type === "post") {
      const Post = new PostService("multichannel-posts", id);

      setSubmitting(true);

      Post.deletePost()
        .then(() => {
          setSubmitting(false);
          setExternalOpen ? setExternalOpen(false) : setOpen && setOpen(false);
          setIsOpen(!isOpen);
        })
        .catch((e) => {
          setSubmitting(false);
          setIsOpen(!isOpen);
          return setError(e);
        });
    }
  };

  const handleClose = (e) => {
    if (e.key === "Escape") {
      setIsOpen(false);
    }
  };

  return (
    <Fragment>
      <DeleteIcon
        style={{ cursor: "pointer", marginBottom: 5, height: 26 }}
        onClick={() => setIsOpen(!isOpen)}
      >
        Delete
      </DeleteIcon>
      <Modal
        open={isOpen}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.container}>
            <div className={classes.paper}>
              <div className={classes.top}>
                <div className={classes.print}>Confirmation Needed</div>
                <Close
                  className={classes.icon}
                  onClick={() => setIsOpen(!isOpen)}
                />
              </div>
              <div style={{ marginTop: "30px", marginBottom: "30px" }}>
                You are about to delete
                {type === "event" ? " an event" : " a post"}. Are you sure?
              </div>
            </div>
            <span className={classes.error}>{error}</span>

            <div className={classes.line} />
            <div
              className={classes.bottom}
              style={{ justifyContent: "flex-end" }}
            >
              <div>
                <CancelButton disableRipple onClick={() => setIsOpen(!isOpen)}>
                  cancel
                </CancelButton>
                <SendButton onClick={() => handleDelete()} disableRipple>
                  {submitting ? (
                    <CircularProgress size={30} color="inherit" />
                  ) : (
                    <span>Delete {type === "event" ? "Event" : "Post"}</span>
                  )}
                </SendButton>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </Fragment>
  );
};
