import { fetchFbIGMetrics, fetchMetrics } from "helpers/fetchMetrics";

export const getPreviousMetrics = async (
  user: any,
  prevFrom: any,
  prevTo: any,
  dateRange: any,
  previousDateRange: any,
  previousClient: any,
  setLoading: Function,
  setPrevMetrics: Function
) => {
  if (
    previousDateRange.startDate?.toLocaleDateString() ===
      dateRange.startDate?.toLocaleDateString() &&
    previousDateRange.endDate?.toLocaleDateString() ===
      dateRange.endDate?.toLocaleDateString() &&
    previousClient.email === user.email
  ) {
    return;
  }
  setLoading((loading) => ({ ...loading, prevLoading: true }));
  if (user?.facebook?.username) {
    const prevResults = await fetchFbIGMetrics(
      "facebook-metrics",
      user.facebook.username,
      prevFrom,
      prevTo
    );
    if (!!prevResults.length) {
      setPrevMetrics((prevMetrics) => ({
        ...prevMetrics,
        fbMetrics: prevResults,
      }));
    }
  } else {
    setPrevMetrics((prevMetrics) => ({ ...prevMetrics, fbMetrics: [] }));
  }
  if (user?.instagram?.username) {
    const prevResults = await fetchFbIGMetrics(
      "instagram-metrics",
      user.instagram.username,
      prevFrom,
      prevTo
    );
    const storiesPrevResults = await fetchMetrics(
      "instagram-story-metrics",
      user.instagram.username,
      prevFrom,
      prevTo
    );
    if (!!prevResults.length) {
      setPrevMetrics((prevMetrics) => ({
        ...prevMetrics,
        igMetrics: prevResults,
        igStoriesMetrics: storiesPrevResults,
      }));
    }
  } else {
    setPrevMetrics((prevMetrics) => ({
      ...prevMetrics,
      igMetrics: [],
      igStoriesMetrics: [],
    }));
  }
  if (user?.twitter?.username) {
    const prevResults = await fetchMetrics(
      "twitter-metrics",
      user.twitter.username,
      prevFrom,
      prevTo
    );
    if (!!prevResults.length) {
      setPrevMetrics((prevMetrics) => ({
        ...prevMetrics,
        twitterMetrics: prevResults,
      }));
    }
  } else {
    setPrevMetrics((prevMetrics) => ({ ...prevMetrics, twitterMetrics: [] }));
  }

  if (user?.tiktok?.username) {
    const prevResults = await fetchMetrics(
      "tiktok-metrics",
      user.tiktok.username,
      prevFrom,
      prevTo
    );
    if (!!prevResults.length) {
      setPrevMetrics((prevMetrics) => ({
        ...prevMetrics,
        tiktokMetrics: prevResults,
      }));
    }
  } else {
    setPrevMetrics((prevMetrics) => ({ ...prevMetrics, tiktokMetrics: [] }));
  }

  if (user?.youtube?.username) {
    const prevResults = await fetchMetrics(
      "youtube-metrics",
      user.youtube.username,
      prevFrom,
      prevTo
    );
    if (!!prevResults.length) {
      setPrevMetrics((prevMetrics) => ({
        ...prevMetrics,
        youtubeMetrics: prevResults,
      }));
    }
  } else {
    setPrevMetrics((prevMetrics) => ({ ...prevMetrics, youtubeMetrics: [] }));
  }
  if (user?.appleMusic?.username) {
    const prevResults = await fetchMetrics(
      "apple-music-metrics",
      user.appleMusic.username,
      prevFrom,
      prevTo
    );
    if (!!prevResults.length) {
      setPrevMetrics((prevMetrics) => ({
        ...prevMetrics,
        appleMusicMetrics: prevResults,
      }));
    }
  } else {
    setPrevMetrics((prevMetrics) => ({
      ...prevMetrics,
      appleMusicMetrics: [],
    }));
  }
  if (user?.spotify?.username) {
    const prevResults = await fetchMetrics(
      "spotify-metrics",
      user.spotify.username,
      prevFrom,
      prevTo
    );
    if (!!prevResults.length) {
      setPrevMetrics((prevMetrics) => ({
        ...prevMetrics,
        spotifyMetrics: prevResults,
      }));
    }
  } else {
    setPrevMetrics((prevMetrics) => ({ ...prevMetrics, spotifyMetrics: [] }));
  }
  setLoading((loading) => ({ ...loading, prevLoading: false }));
};
