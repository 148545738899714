import React, { FC, useState } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Checkbox from "@material-ui/core/Checkbox";

import { useStyles } from "./styles";
import { getBackground } from "components/BePlannedMonthly/EventIcon";

interface Props {
  options: Array<any>;
  selected: string;
  month?: boolean;
  setSelected: (selected: string) => void;
  applyColors: boolean;
}

const disabledList = ["Client Declined"];
const clientList = [
  "Client Declined",
  "Client Approved",
  "Pending Client Approval",
  "Scheduled",
];
export const noStyleList = ["Custom Event", "Draft", ""];
const CustomSelect: FC<Props> = ({
  options,
  selected,
  month,
  setSelected,
  applyColors,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles({ selected: selected, applyColors: applyColors });

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (value) => {
    setSelected(value);
    handleClose();
  };

  return (
    <div className={classes.selectContainer}>
      <div
        style={{
          borderBottomLeftRadius: open ? "0px" : "10px",
          borderBottomRightRadius: open ? "0px" : "10px",
          color:
            selected === "Choose Category"
              ? "#ABADAD"
              : !noStyleList.includes(selected) &&
                applyColors &&
                !!options.filter((option) => selected === option.name).length
              ? "#fff"
              : "#000",
          background: applyColors ? getBackground(selected) : "",
          border:
            !noStyleList.includes(selected) &&
            applyColors &&
            !!options.filter((option) => selected === option.name).length
              ? "none"
              : open
              ? "2px solid #000"
              : "2px solid #ABADAD",
        }}
        className={
          open
            ? `${classes.selectedText} ${classes.selectedTextActive}`
            : classes.selectedText
        }
        onClick={handleToggle}
      >
        {selected === "Client Approved" && month ? "Approved" : selected}{" "}
        {/* {clientList.includes(selected) ? (
          <span
            className={classes.statusVisibility}
            style={{ color: !applyColors ? "gray" : "#fff" }}
          >
            (Visible For Client)
          </span>
        ) : (
          ""
        )} */}
        {!selected && <span>Choose a status</span>}
      </div>
      {open && (
        <ClickAwayListener onClickAway={handleClose}>
          <ul className={classes.selectOptions}>
            {options.map((option) => {
              return (
                <li
                  data-name={option.name}
                  key={option.id}
                  onClick={() =>
                    (!disabledList.includes(option.name) || !applyColors) &&
                    handleSelect(option.name)
                  }
                  style={{
                    width: "100%",
                    backgroundColor:
                      selected === option.name ? "rgba(0, 0, 0, 0.08)" : "#fff",
                  }}
                >
                  <Checkbox
                    checked={selected === option.name}
                    className={classes.checkbox}
                    onClick={() => handleSelect(option.name)}
                    style={{
                      transform: "scale(0.8)",
                      background: applyColors ? getBackground(option.name) : "",
                      color:
                        noStyleList.includes(option.name) || !applyColors
                          ? "#ABADAD"
                          : getBackground(option.name),
                    }}
                    disabled={
                      disabledList.includes(option.name) || !applyColors
                    }
                  />
                  {option.name === "Client Approved" && month
                    ? "Approved"
                    : option.name}{" "}
                  {/* {clientList.includes(option.name) ? (
                    <span className={classes.statusVisibility}>
                      (Visible For Client)
                    </span>
                  ) : (
                    ""
                  )} */}
                </li>
              );
            })}
          </ul>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default CustomSelect;
