import React, { FC } from "react";
import { Avatar, Button, Grid } from "@material-ui/core";
import { DateTime } from "luxon";
import CloseIcon from "@material-ui/icons/Close";
import Linkify from "react-linkify";

import { IUser } from "interfaces/IUser";
import { useStyles } from "./styles";
import { deleteNote, updateNote } from "../helpers/notes";

interface Props {
  note: NoteType;
  noteType?: string;
  post: any;
  authUser: IUser;
  collection?: string;
  tempNotes?: Array<any>;
  setTempNotes?: (notes: Array<any>) => void;
}
export type NoteType = {
  id: string;
  message: string;
  created_at: any;
  user: IUser;
  type?: string;
  resolved: boolean;
};

export const componentDecorator = (href, text, key, note) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

const Note: FC<Props> = ({
  note,
  noteType,
  post,
  authUser,
  collection,
  tempNotes,
  setTempNotes,
}) => {
  const classes = useStyles({});
  const { user } = note;
  const email = user.email;

  return (
    <div
      className={classes.noteField}
      style={{
        flexDirection: noteType === "external" ? "row-reverse" : "unset",
      }}
    >
      <Avatar
        alt={user.firstName + " " + user.lastName}
        src={user.avatarUrl}
        className={classes.notesAvatar}
        style={{
          marginLeft: noteType === "external" ? "15px" : "0px",
          marginRight: noteType === "external" ? "0px" : "15px",
          background: user.avatarUrl ? "#fff" : "#000",
        }}
      >
        <p className={classes.avatarText}>
          {(user?.firstName &&
            user?.lastName &&
            user.firstName[0] + user.lastName[0]) ||
            (email &&
              email.split(".")[0][0].toUpperCase() +
                email.split(".")[1][0].toUpperCase())}
        </p>
      </Avatar>
      <div className={classes.content}>
        <div className={classes.body}>
          <div
            style={{ textDecoration: note.resolved ? "line-through" : "" }}
            className={classes.note}
          >
            <Linkify componentDecorator={componentDecorator}>
              {note.message}
            </Linkify>
          </div>
          {authUser.id === user.id && (
            <CloseIcon
              className={classes.close}
              onClick={() => {
                if (post) {
                  deleteNote(post.id, note.id, collection);
                } else {
                  const newNotes: any = tempNotes?.filter(
                    (item) => item.id !== note.id
                  );
                  setTempNotes && setTempNotes(newNotes);
                }
              }}
            />
          )}
        </div>
        <div>
          <div className={classes.footer}>
            {/* <span
              className={classes.timestamp}
              style={{
                marginRight: "2px",
                color: "#000000",
                fontFamily: "Avenir",
                fontSize: "14px",
                fontWeight: 350,
              }}
            >
              {user.email} -
            </span> */}
          </div>
          {post && (
            <Grid container>
              <Grid xs={6} md={6}>
                <span
                  className={classes.timestamp}
                  style={{
                    marginLeft: "2px",
                    color: "#757575",
                    fontFamily: "Avenir",
                    fontSize: "14px",
                    fontWeight: 350,
                  }}
                >
                  {note.created_at.seconds
                    ? DateTime.fromSeconds(note.created_at.seconds).toFormat(
                        "dd LLL yyyy T"
                      )
                    : DateTime.fromJSDate(note.created_at).toFormat(
                        "dd LLL yyyy T"
                      )}
                </span>
              </Grid>

              <Grid xs={6} md={6}>
                <div style={{ textAlign: "right" }}>
                  <Button
                    disableRipple
                    type="button"
                    classes={{
                      label: classes.archiveLabel,
                    }}
                    onClick={() =>
                      updateNote(
                        post.id,
                        note.id,
                        { resolved: !note.resolved },
                        collection
                      )
                    }
                    style={{
                      padding: 0,
                      color: "#000000",
                      fontFamily: "Avenir",
                      fontSize: "14px",
                      fontWeight: 350,
                    }}
                  >
                    Mark as {note.resolved ? "unresolved" : "resolved"}
                  </Button>
                </div>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    </div>
  );
};

export default Note;
