import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: "25px",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingBottom: "10px",
    "& label": {
      fontSize: "20px",
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "100%",
    backgroundColor: "#1D4E89",
    height: "46px",
    color: "#fff",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontFamily: "Avenir Book, sans-serif",
    fontSize: "20px",

    "&:hover": {
      opacity: 0.9,
      backgroundColor: "#1D4E89",
    },
  },
  backgroundColor: {
    backgroundColor: "#fff",
  },
  icon: {
    color: "gray",
  },
  error: {
    color: "#f44336",
    fontSize: "13px",
    textAlign: "center",
    width: "75%",
  },
  disabledButton: {
    backgroundColor: "#1D4E89 !important",
    color: "#fff !important",
  },
}));
