import styled from "styled-components";

const BlueScreenContainer = styled.div`
  width: 100%;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  background: #000;
  position: fixed;
  z-index: 3147483647;
  top: 0px;
  left: 0px;

  .header {
    color: #fff;
    font-family: "Avenir Black", sans-serif;
    padding: 12px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-top: 44px;
    margin-right: 58px;
    text-decoration: underline;

    &:first-child {
      margin-left: 58px;
    }
  }

  .home {
    color: #fff;
    font-family: "Avenir Black", sans-serif;
    padding: 12px;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    font-weight: 600;
    margin-top: 44px;
    margin-right: 58px;
    text-decoration: underline;
  }

  .content-container {
    height: 100%;

    .content {
      color: #fff;
      max-width: 500px;
      width: calc(100% - 40px);
      position: absolute;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .icon {
      font-size: 56px;
      height: 56px;
      width: 56px;
    }
  }

  p {
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    font-family: "Avenir Book", sans-serif;
  }

  h2 {
    color: #fff;
    font-size: 2.75rem;
    line-height: 50px;
    margin: 0;
    font-family: "Avenir Black", sans-serif;
  }

  @media (min-width: 1024px) {
    h1 {
      font-size: 2.0625rem;
      line-height: 40px;
    }
  }
`;

export default BlueScreenContainer;
