import React, { FunctionComponent } from "react";
import { LinearProgress } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { withStyles } from "@material-ui/core/styles";

import { useStyles } from "../styles";
import { numberWithCommas } from "../../../utils/numberFormat";

interface IProps {
  color: string;
  data: {
    name: string;
    value: number;
    pastValue: number;
  };
  compareValue: number;
}

const AudienceProgress: FunctionComponent<IProps> = ({
  color,
  data,
  compareValue,
}) => {
  const classes = useStyles({});
  const BorderLinearProgress = withStyles({
    root: {
      height: 6,
      borderRadius: 7,
    },
    colorPrimary: {
      background: "#D8D8D8",
    },
    bar: {
      borderRadius: 7,
      background: "#1D4E89",

      "&:hover": {
        opacity: 0.5,
      },
    },
  })(LinearProgress);
  const growth = ((data.value - data.pastValue) / data.pastValue) * 100;
  const growthString =
    data.pastValue > 0
      ? growth.toLocaleString("en", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : growth.toLocaleString("en", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

  let symbol = "";
  let isPositive = false;
  if (growth >= 0) {
    symbol = "+";
    isPositive = true;
  }
  const growthColor =
    data.pastValue > 0
      ? growth >= 0
        ? "green"
        : growth < 0
        ? "red"
        : "#101010"
      : "#101010";

  let percentage = (data.value / compareValue) * 100;
  percentage = percentage > 100 ? 100 : percentage;

  return (
    <div className={classes.indicator} style={{ marginTop: "7.6px" }}>
      <div className={classes.progress}>
        <div
          className={classes.metricLabel}
          style={{ fontSize: "12px", lineHeight: "14px" }}
        >
          <div style={{ minWidth: "40%", maxWidth: "60%" }}>{data.name}</div>
          <div
            style={{
              fontSize: "11px",
              fontFamily: "Avenir Book, sans-serif",
              color: "#101010",
            }}
          >
            {numberWithCommas(data.value)}
            {/* <span style={{ color: growthColor, marginLeft: 5 }}>
              {data.pastValue > 0 && growth >= 0 && isPositive && (
                <ArrowDropUpIcon className={classes.growthIcon} />
              )}
              {data.pastValue > 0 && growth !== 0 && !isPositive && (
                <ArrowDropDownIcon className={classes.growthIcon} />
              )}
              {data.pastValue > 0 && symbol + growthString + "% "}
              {!(data.pastValue > 0) && growthString}
            </span> */}
          </div>
        </div>
        <BorderLinearProgress
          variant="determinate"
          value={+percentage.toFixed(2)}
        />
      </div>
    </div>
  );
};

export default AudienceProgress;
