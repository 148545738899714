import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  datePickerContainer: {
    width: (props: any) => (props.fullWidth ? "100%" : "fit-content"),
    height: 75,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",

    "@media screen and (max-width: 580px)": {
      height: 95,
      display: "block",
    },
  },
  datePickerStyle: {
    width: "100%",
    height: "45px",
    "@media screen and (max-width: 580px)": {
      width: 300,
    },
  },
  dateLabel: {
    textAlign: "left",
    fontFamily: "Avenir",
    fontSize: "16px",
    fontWeight: 900,
    lineHeight: "20px",
    letterSpacing: "0em",
  },
  dateInput: {
    fontSize: "15px",
    fontFamily: "'Avenir Book', sans-serif",
    fontWeight: "normal",
    lineHeight: "20px",
    border: "1px solid #E8E8E8",
    borderRadius: "15px",
    padding: "11.5px 20.5px",
    marginTop: "8.5px",
    cursor: "pointer",
    background: "#FFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  dateRangePicker: {
    right: "-16%",
    position: "absolute",
    overflow: "auto",
    boxShadow: "0px 5px 45px rgba(0, 0, 0, 0.07)",
    borderRadius: "5px",
    zIndex: 2000,
  },
  datePicker: {
    boxShadow: "0px 5px 45px rgba(0, 0, 0, 0.07)",
    borderRadius: "5px",
    backgroundColor: "#fff",
    position: "absolute",
    right: "-20%",
    zIndex: 1000,
    marginTop: 2,
    overflow: "auto",

    "& > :first-child": {
      minWidth: 110,
      width: "unset",
    },

    "& > :first-child > .rdrInputRanges": {
      display: "none",
    },

    "& > :last-child > .rdrDateDisplayWrapper": {
      display: "none",
    },

    // "@media screen and (min-width: 1000px)": {
    //   right: "-100%",
    // },

    "@media screen and (max-width: 580px)": {
      width: 340,
      overflow: "auto",
      display: "flex",
      // flexDirection: "row-reverse",
      justifyContent: "end",
    },
  },

  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
});
