import React, { FunctionComponent } from "react";
import AmCharts from "@amcharts/amcharts3-react";

const GeoChart: FunctionComponent<{ audience: Array<any> }> = ({
  audience,
}) => {
  const config = {
    type: "map",
    theme: "light",
    colorSteps: 10,
    dataProvider: {
      map: "worldHigh",
      areas: audience,
    },
    areasSettings: {
      autoZoom: true,
    },
    valueLegend: {
      right: 10,
      minValue: "Low",
      maxValue: "High",
    },
    listeners: [
      {
        event: "descriptionClosed",
        method: function (ev: any) {
          ev.chart.selectObject();
        },
      },
    ],
  };
  return (
    <div>
      <AmCharts.React
        style={{ width: "100%", height: "370px" }}
        options={config}
      />
    </div>
  );
};

const areEqual = (prevProps, nextProps): any => {
  if (prevProps.audience === nextProps.audience) {
    return true;
  }
};

export default React.memo(GeoChart, areEqual);
