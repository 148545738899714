import React, { useState } from "react";
import { Box, IconButton, Modal, Tooltip } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  thumbnail: {
    width: 150,
    height: 150,
    cursor: "pointer",
    position: "relative",
    borderRadius: 4,
    border: "1px solid #ccc",
    "&:hover": {
      borderColor: "#000",
    },
  },
  checkIcon: {
    position: "absolute",
    top: 10,
    right: 10,
    color: "green",
    fontSize: 24,
  },
  previewIcon: {
    position: "absolute",
    bottom: 10,
    right: 10,
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    borderRadius: "50%",
    padding: 4,
    fontSize: 24,
    cursor: "pointer",
  },
}));

const PreviewImage = ({ src, alt, isSelected, onSelect }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  const handleClose = () => setIsOpen(false);

  return (
    <Box position="relative" display="inline-block" onClick={onSelect}>
      <img src={src} alt={alt} className={classes.thumbnail} />
      {isSelected && <CheckCircleIcon className={classes.checkIcon} />}
      <Tooltip title="Preview">
        <VisibilityIcon className={classes.previewIcon} onClick={handleOpen} />
      </Tooltip>
      <Modal open={isOpen} onClose={handleClose}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          bgcolor="rgba(0, 0, 0, 0.9)"
          onClick={handleClose}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              color: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={src}
            alt={alt}
            style={{ maxHeight: "90%", maxWidth: "90%" }}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default PreviewImage;
