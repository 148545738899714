import { Grid } from "@material-ui/core";
import React from "react";
import Image from "components/Common/Image/Image";
import { useStylesV2 } from "../stylesV2";

const MediaItemImageSection = ({ imageUrl }) => {
  const classes = useStylesV2({});

  return (
    <div>
      <Grid container xs>
        <Image
          src={imageUrl}
          className={classes.MediaItemsImageSection}
          // style={{
          //   width: 336,
          //   height: 336,
          //   objectFit: "cover",
          // }}
        />
      </Grid>
    </div>
  );
};

export default MediaItemImageSection;
