/**
 * @prettier
 */

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

(window as any).firebase = firebase;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const firestore = firebase.firestore();

/* Uncoment the lines below when testing localy */
// firebase
//   .functions()
//   .useFunctionsEmulator(
//     "http://localhost:5000/be-measured-admin-staging/europe-west2/"
//   );
const secondaryApp = firebase.initializeApp(firebaseConfig, "Secondary");
const storage = firebase.storage();

export { firebase, auth, firestore, secondaryApp, storage };
