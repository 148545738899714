import React, { FC, useEffect, useState } from "react";

import FadeStyle from "./FadeStyle";

interface IFade {
  show: boolean;
  children: any;
}

const Fade: FC<IFade> = ({ show, children }) => {
  const [render, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return (
    <FadeStyle>
      {render && (
        <div
          className={`${show ? "fadeIn" : "fadeOut"}`}
          onAnimationEnd={onAnimationEnd}
        >
          {children}
        </div>
      )}
    </FadeStyle>
  );
};

export default Fade;
