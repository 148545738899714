import React, { FC } from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Avatar from "@material-ui/core/Avatar";
import { Alert, AlertTitle } from "@material-ui/lab";

import { useStyles } from "components/UserManagement/styles";
import { FlexColumn, Cell } from "../styled/Common";
import Loader from "../Loader";
import { ReactComponent as AvatarIcon } from "static/media/avatarIcon.svg";

interface ActivityProps {
  users: any;
  isLoading: boolean;
}
const Activity: FC<ActivityProps> = ({ users, isLoading }) => {
  const classes = useStyles({});

  return !isLoading ? (
    <>
      {users.error && users.error !== "" && (
        <Alert severity="error">
          <AlertTitle>User List Error!</AlertTitle>
          Error fetching user activities
        </Alert>
      )}
      <TableBody>
        {users.documents?.map((user) => (
          <TableRow key={user.id} style={{ height: "120.25px" }}>
            <Cell>
              {user.avatarUrl && user.avatarUrl.length ? (
                <Avatar
                  alt="avatar"
                  src={user.avatarUrl}
                  className={classes.avatar}
                />
              ) : (
                <AvatarIcon style={{ width: "65px", height: "65px" }} />
              )}
            </Cell>
            <Cell>
              <FlexColumn className="tableData">
                <p>{`${user.firstName} ${user.lastName}`}</p>
              </FlexColumn>
            </Cell>
            <TableCell>
              <FlexColumn className="tableCell tableData">
                <p>{user.email}</p>
              </FlexColumn>
            </TableCell>
            <Cell>
              <FlexColumn className="tableCell tableData">
                <ul>
                  {user.page_visit
                    ?.reverse()
                    .slice(0, 100)
                    .map((date) => (
                      <li key={date}>{date}</li>
                    ))}
                </ul>
              </FlexColumn>
            </Cell>
          </TableRow>
        ))}
      </TableBody>
    </>
  ) : (
    <Loader />
  );
};

export default Activity;
