/**
 * @prettierdesc
 */

import React, { FC, useState } from "react";
import { Box, Tooltip, CircularProgress } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import history from "history/browser";

import CreateCampaignStyles from "components/styled/CreateCampaign";
import Image from "components/Common/Image/Image";
import instagram from "static/media/instagram-vector.png";
import defaultIcon from "static/media/universal-2.png";
import { SendButton } from "components/styled/Common";
import { PageType } from "types/PageType";
import GetCookies from "components/Common/GetCookies";
import CampaignName from "components/Common/CampaignName";
import { useUser } from "UserContext";
import { IUser } from "interfaces/IUser";
import { firestore } from "firebase-tools/firebase";
import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import { SocialMediaPlatform } from "containers/BeAmplified/Campaigns/Organic/SelectCampaign";
import showToast from "utils/toast";
import { igCommentsExtractor } from "helpers/useExtractors";

interface CommentsExtractorProps extends PageType {
  uid?: string;
  toggleCreate?: Function;
}

export const CommentsExtractor: FC<CommentsExtractorProps> = ({
  toggleCreate,
  uid,
}) => {
  const [campaignName, setCampaignName] = useState("");
  const [sessionCookie, setSessionCookie] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [numberOfProfilesPerLaunch] = useState(1);
  const [errors, setErrors] = useState({
    client: "",
    campaignName: "",
    instagramUrl: "",
    numberOfProfilesPerLaunch: "",
    numberOfRowsPerLaunch: "",
  });
  const [authUser] = useUser() as Array<IUser>;

  let userRef = firestore.collection("users").doc(authUser.id);
  let client =
    useDocumentSubscriber({ ref: userRef }).document?.lastClientSelection || "";

  const [loading, setLoading] = useState(false);

  const createCampaign = async () => {
    if (!client)
      return setErrors((errors: any) => ({
        ...errors,
        client: "Client is required!",
      }));
    if (!campaignName)
      return setErrors((errors: any) => ({
        ...errors,
        campaignName: "Campaign name is required!",
      }));
    if (!instagramUrl)
      return setErrors((errors: any) => ({
        ...errors,
        instagramUrl: "Spreadsheet Url is required!",
      }));

    if (!instagramUrl.includes("https://www.instagram.com/")) {
      return setErrors((errors: any) => ({
        ...errors,
        instagramUrl: "Enter a valid spreadsheet URL!",
      }));
    }
    if (!numberOfProfilesPerLaunch || numberOfProfilesPerLaunch > 12) {
      return setErrors((errors: any) => ({
        ...errors,
        numberOfProfilesPerLaunch:
          "Enter a valid value for number of likes per profile!",
      }));
    }
    if (sessionCookie === "") {
      showToast("Error: Add a session Cookie to proceed");
    } else {
      setLoading(true);
      await igCommentsExtractor({
        spreadsheetUrl: instagramUrl,
        sessionCookie: client?.instagram?.sessionCookie || sessionCookie,
        campaignName,
        username: client?.instagram?.username,
        numberOfProfilesPerLaunch,
      });
      setCampaignName("");
      setSessionCookie("");
      setInstagramUrl("");
      setLoading(false);
    }
  };

  return (
    <CreateCampaignStyles>
      <ToastContainer />
      <div>
        <span className="title__main">{uid ? "Edit" : "Create"} Campaign</span>
        <Box marginTop="50px">
          <div className="items-center">
            <Image
              className="img__main"
              src={instagram || defaultIcon}
              alt=""
            />
            <span className="title__sub">Instagram Comment Extractor</span>
          </div>
        </Box>
      </div>

      <CampaignName
        campaignName={campaignName}
        setCampaignName={setCampaignName}
        error={errors.campaignName}
        setErrors={setErrors}
      />
      <GetCookies
        setSessionCookie={setSessionCookie}
        sessionCookie={sessionCookie}
        title="Your Instagram Sessionid"
        platform={SocialMediaPlatform.Instagram}
        platformIcon={instagram}
        tokeType="sessionid"
      />
      {/* More info */}
      <Box marginTop="40px" className="connection-container">
        <div className="campaign__container">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <span className="title__basic">Instagram Posts To Extract</span>
            <Tooltip
              title={
                <span>
                  Should be a valid instagram profile link eg{" "}
                  <strong>https://www.instagram.com/test</strong>
                </span>
              }
              arrow
              interactive
            >
              <InfoIcon style={{ color: "#C4C4C4" }} />
            </Tooltip>
          </Box>
          <span className="description__sub">
            Must be a valid instagram profile URL.
          </span>
          <input
            type="text"
            value={instagramUrl}
            placeholder="https://www.instagram.com/(...)"
            onChange={(e) => {
              setInstagramUrl(e.target.value);
              setErrors((errors: any) => ({
                ...errors,
                instagramUrl: "",
              }));
            }}
            className="campaign__input"
          />
          {errors.instagramUrl && (
            <span style={{ color: "red", fontSize: 14, paddingLeft: 6 }}>
              {errors.instagramUrl}
            </span>
          )}
        </div>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop="40px"
      >
        <div
          className="title__sub link"
          onClick={() => {
            toggleCreate && toggleCreate();
            !toggleCreate && history.back();
          }}
        >
          {"<"} Back
        </div>
        <SendButton
          onClick={(e) => {
            e.preventDefault();
            !loading && createCampaign();
          }}
          className="btn"
          disabled={loading}
        >
          {uid
            ? `Updat${loading ? "ing" : "e"}`
            : `Launch${loading ? "ing" : ""}`}{" "}
          Campaign
          {loading && <CircularProgress className="btn-progress" size={20} />}
        </SendButton>
      </Box>
    </CreateCampaignStyles>
  );
};
