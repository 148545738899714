import React, { FC, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { navigate } from "@reach/router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Box,
  Switch,
  LinearProgress,
  FormControlLabel,
} from "@material-ui/core";
import { CSVLink } from "react-csv";

import { ReactComponent as DoubleArrow } from "static/media/doublePlayArrow.svg";
import { useStyles } from "./styles";
import { ReactComponent as RunningIcon } from "static/media/runningIcon.svg";
import { ReactComponent as RefreshIcon } from "static/media/refreshIcon.svg";
import { ReactComponent as CircledCheck } from "static/media/circledCheck.svg";
import { ReactComponent as ExportIcon } from "static/media/export.svg";
import PageTitle from "components/Common/PageTitle";
import getLogs from "utils/getLogs";
import { SocialMediaPlatform } from "containers/BeAmplified/Campaigns/Organic/SelectCampaign";
import { getPlatformImage } from "../../Components/SelectCampaignBox";
import { CancelButton } from "components/styled/Common";
import { DateTime } from "luxon";
import { useContainerOutput } from "helpers/instagramAutoFollow/useContainerOutput";

interface ICampaign {
  campaign: any;
  platform: SocialMediaPlatform;
}

const BorderLinearProgress = withStyles({
  root: {
    height: 6,
    borderRadius: 7,
    "&:hover": {
      opacity: 0.5,
    },
  },
  colorPrimary: {
    background: "#F2F2F2",
  },
  bar: {
    borderRadius: 7,
    background: "#598CE1",
    animationDuration: "8s",
  },
})(LinearProgress);

const History = (props) => {
  const { type = "info", message, logs } = props;

  if (logs) {
    return (
      <>
        {message.split("\n").map((msg) => (
          <div
            style={{
              marginLeft: 17,
              fontSize: 15,
              lineHeight: "22px",
              color: "#FFFFFF",
            }}
          >
            {msg}
          </div>
        ))}
      </>
    );
  }

  return (
    <div>
      {type === "success" ? (
        <CircledCheck />
      ) : (
        <RefreshIcon style={{ width: 15 }} />
      )}{" "}
      <span
        style={{
          marginLeft: 17,
          fontSize: 15,
          lineHeight: "22px",

          color: "#FFFFFF",
        }}
      >
        {message}
      </span>
    </div>
  );
};

export const csvHeaders = [
  { label: "Name", key: "name" },
  { label: "Group Name", key: "groupName" },
  { label: "Profile Url", key: "profileUrl" },
  { label: "Friendship Status", key: "friendshipStatus" },
  { label: "Group Url", key: "groupUrl" },
];
const igCSVHeaders = [
  { label: "Username", key: "username" },
  { label: "Comment", key: "comment" },
  { label: "Likes Count", key: "likeCount" },
  { label: "Reply Count", key: "replyCount" },
  { label: "Profile Url", key: "profileUrl" },
  { label: "Query", key: "query" },
];

const Extractor: FC<ICampaign> = ({ campaign, platform }) => {
  const classes = useStyles({});
  const [showConsole, setShowConsole] = useState(true);
  const [logs, setLogs] = useState("");

  const toggle = async () => {
    return setShowConsole(!showConsole);
  };

  useEffect(() => {
    (async () => {
      let logs = await getLogs(campaign.containerId);

      if (!logs.output) {
        await new Promise((resolve) => setTimeout(resolve, 10000));
        logs = await getLogs(campaign.containerId);
      }

      setLogs(logs.output);
    })();

    // eslint-disable-next-line
  }, [campaign.containerId]);

  const HeaderDetails = (props) => {
    const { type, title, value } = props;
    return (
      <div>
        <div className={classes.headerTitle}>{title}</div>
        <div className={classes.headerContent}>
          {" "}
          {value
            ? type === "date"
              ? DateTime.fromJSDate(new Date(value.seconds * 1000)).toFormat(
                  "MM/dd/yyyy"
                )
              : value
            : type === "date"
            ? "--/--/----"
            : "--"}
        </div>
      </div>
    );
  };

  const facebookHeaderDetails = [
    {
      title: "Start date",
      type: "date",
      value: campaign.createdAt || "",
    },
    {
      title: "End date",
      type: "date",
      value: campaign.endDate || "",
    },
    {
      title: "# of users",
      value: campaign?.usersCount,
    },
  ];

  const platformHeaderDetails = {
    [SocialMediaPlatform.Facebook]: facebookHeaderDetails,
    [SocialMediaPlatform.Instagram]: facebookHeaderDetails,
  };

  const campaignSurfix = {
    [SocialMediaPlatform.Facebook]: "Group Extractor",
    [SocialMediaPlatform.Instagram]: "Comment Extractor",
  };

  const headerDetails = platformHeaderDetails[platform];

  const exportData = useContainerOutput(campaign, `${platform}-scrappers`);

  return (
    <div className="root">
      <div style={{ marginTop: 40 }}>
        <button
          onClick={() =>
            navigate(
              `/campaigns/organic${
                campaign.campaignType.includes("-scrapper")
                  ? "?flow=scrappers"
                  : ""
              }`
            )
          }
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            fontSize: "18px",
            fontWeight: 600,
            color: "#000000",
            fontFamily: "'Avenir Book', sans-serif",
            border: "none",
            cursor: "pointer",
          }}
        >
          <ArrowBackIcon />
          Back to all campaigns
        </button>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          height="120px"
        >
          <PageTitle
            title={
              <div className={classes.itemsCenter}>
                <span className={classes.itemsCenter}>
                  <img
                    src={getPlatformImage(platform)}
                    alt="twitter"
                    className={classes.mainIcon}
                  />{" "}
                  <DoubleArrow />
                </span>{" "}
                {campaign.campaignName}
              </div>
            }
          />
        </Box>
      </div>

      <div className={`${classes.progress}`}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <div style={{ width: "75%" }}>
            <div
              style={{ marginBottom: 10, textTransform: "capitalize" }}
              className={classes.label}
            >
              {platform} {campaignSurfix[platform]}
            </div>
            {campaign.status === "running" ? (
              <BorderLinearProgress variant="indeterminate" />
            ) : (
              <BorderLinearProgress variant="determinate" value={100} />
            )}

            {campaign.status === "running" && (
              <div className={classes.running}>
                <RunningIcon style={{ marginRight: 10 }} /> Running{" "}
              </div>
            )}
          </div>

          <div>
            <div className={classes.toggleLabel}>
              {showConsole ? "Hide console" : "Show Console"}
            </div>
            <FormControlLabel
              control={
                <Switch
                  checked={showConsole}
                  onChange={toggle}
                  name="Show console"
                  classes={{
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                  }}
                />
              }
              label=""
            />
          </div>
        </Box>
        <div>
          {showConsole && (
            <div className={classes.console}>
              {!logs && <History type="info" message="No Logs available" />}
              {logs && <History type="info" message={logs} logs />}
            </div>
          )}
        </div>
        <div className={`${classes.progress} ${classes.itemsCenter}`}>
          <div
            className={classes.itemsCenter}
            style={{ width: "75%", justifyContent: "space-between" }}
          >
            {headerDetails.map((item) => (
              <HeaderDetails
                title={item.title}
                type={item.type}
                value={item.value}
              />
            ))}
          </div>
          {exportData && !campaign?.resultObject?.csvURL && (
            <CSVLink
              data={exportData}
              headers={
                platform === SocialMediaPlatform.Facebook
                  ? csvHeaders
                  : igCSVHeaders
              }
              style={{
                textDecoration: "none",
              }}
              target="_blank"
              rel="noopener noreferrer"
              filename="results.csv"
            >
              <CancelButton className={classes.editButton}>
                <ExportIcon /> <span style={{ marginLeft: 10 }}>Export</span>
              </CancelButton>
            </CSVLink>
          )}
          {campaign?.resultObject && campaign?.resultObject?.csvURL && (
            <a href={`${campaign?.resultObject?.csvURL}`}>
              <CancelButton className={classes.editButton}>
                <ExportIcon /> <span style={{ marginLeft: 10 }}>Export</span>
              </CancelButton>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Extractor;
