/**
 * @prettier
 */
import React, { useState, FunctionComponent, Fragment } from "react";
import { Modal, Backdrop, Fade, CircularProgress } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import firebase from "firebase/app";

import { useStyles } from "./styles";
import { useUser } from "UserContext";
import { signOut } from "hooks/AuthHook";

import { UserService } from "services/UserService";
import { SendButton, CancelButton } from "components/styled/Common";

interface IDeleteAdmin {
  user: any;
}

export const DeleteAdmin: FunctionComponent<IDeleteAdmin> = ({ user }) => {
  const classes = useStyles({});

  const [isOpen, setIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");

  const [authUser] = useUser() as any;

  const handleDelete = async () => {
    const User = new UserService(user.id);

    setSubmitting(true);
    const app = firebase.app();
    const functions = app.functions("europe-west2");
    const deleteUser = functions.httpsCallable("authentication-deleteUser");

    await deleteUser({
      userId: user.id,
    });

    User.deleteUser()
      .then(async () => {
        setSubmitting(false);
        setIsOpen(false);

        if (user.id === authUser.id) {
          signOut();
        }
      })
      .catch((e) => {
        setSubmitting(false);
        setIsOpen(true);
        return setError(e);
      });
  };

  const handleClose = (e) => {
    if (e.key === "Escape") {
      setIsOpen(false);
    }
  };

  return (
    <Fragment>
      <div onClick={() => setIsOpen(true)}>Delete Admin</div>
      <Modal
        open={isOpen}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        onClose={handleClose}
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.container}>
            <div className={classes.paper}>
              <div className={classes.top}>
                <div className={classes.print}>Confirmation Needed</div>
                <Close
                  className={classes.icon2}
                  onClick={() => setIsOpen(!isOpen)}
                />
              </div>
              <div style={{ marginTop: "30px" }}>
                You are about to delete an admin. Are you sure?
              </div>
            </div>
            <span className={classes.error}>{error}</span>

            <div className={classes.line} />
            <div className={classes.bottom}>
              <div>
                <CancelButton disableRipple onClick={() => setIsOpen(!isOpen)}>
                  cancel
                </CancelButton>
                <SendButton onClick={() => handleDelete()} disableRipple>
                  {submitting ? (
                    <CircularProgress size={30} color="inherit" />
                  ) : (
                    <span>Delete Admin</span>
                  )}
                </SendButton>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </Fragment>
  );
};
