import React, { FC } from "react";

interface Props {
  category: string;
  width?: number;
  height?: number;
  borderRadius?: number;
}

export const getBackground = (category) => {
  switch (category.toLowerCase()) {
    case "release":
      return "#E9352F";
    case "rehearsal/recording":
      return "#FCF200";
    case "rehear":
      return "#FCF200";
    case "recording":
      return "#FCF200";
    case "performance":
      return "#A0CC6A";
    case "tv/radio promo":
      return "#F6C150";
    case "tv promo":
      return "#F6C150";
    case "radio promo":
      return "#F6C150";
    case "viral events":
      return "#4DAFE8";
    case "tentpole idea":
      return "#7AC79F";
    case "community management":
      return "#B9C3E4";
    case "pending internal review":
      return "#0388E2";
    case "pending internal amends":
      return "#FFD944";
    case "pending client approval":
      return "#F17114";
    case "client declined":
      return "#EC220E";
    case "client approved":
      return "#23B025";
    case "scheduled":
      return "#23B025";
    case "partially approved by client":
      return "#0688E2";
    case "draft":
      return "#ABADAD";
    case "custom event":
      return "#ABADAD";
    default:
      return "#fff";
  }
};

const EventIcon: FC<Props> = ({
  category,
  height = 11,
  width = 11,
  borderRadius = 0,
}) => {
  return (
    <div
      style={{
        width,
        height,
        margin: "0px 3px",
        borderRadius,
        background: getBackground(category),
      }}
    ></div>
  );
};

export default EventIcon;
