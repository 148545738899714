import {
  INotification,
  NotificationActions,
  NotificationStatus,
  NotificationTypes,
} from "interfaces/INotification";
import { IUser } from "interfaces/IUser";
import moment from "moment";
import { NotificationService } from "services/NotificationService";
import { v4 as uuidv4 } from "uuid";

export const addPostNotification = async (
  action: NotificationActions,
  postStatus: any,
  postTitle: any,
  clientName: string,
  postId: string,
  client: IUser,
  authUser: IUser,
  selectedDate: Date
) => {
  const id = uuidv4();
  let Notification = new NotificationService("notifications", id);

  try {
    const resp = await Notification.addOne({
      action,
      initiatorEmail: authUser.email,
      initiatorName: `${authUser.firstName} ${authUser.lastName}`,
      initiatorId: authUser.id,
      notificationType: NotificationTypes.post,
      postId,
      postStatus,
      postTitle,
      status: NotificationStatus.unread,
      clientName,
      archive: false,
      date: moment(selectedDate).toDate(),
      created_at: new Date(),
      client,
      authUser,
    });
  } catch (error) {}
};
export const addEventNotification = async (
  action: NotificationActions,
  postTitle: any,
  clientName: string,
  postId: string,
  client: IUser,
  authUser: IUser,
  selectedDate: Date
) => {
  const id = uuidv4();
  let Notification = new NotificationService("notifications", id);

  try {
    const resp = await Notification.addOne({
      action,
      initiatorEmail: authUser.email,
      initiatorName: `${authUser.firstName} ${authUser.lastName}`,
      initiatorId: authUser.id,
      notificationType: NotificationTypes.event,
      postId,
      postStatus: "",
      postTitle,
      status: NotificationStatus.unread,
      clientName,
      archive: false,
      date: moment(selectedDate).toDate(),
      created_at: new Date(),
      client,
      authUser,
    });
  } catch (error) {}
};

export const addCommentNotification = async (
  action: NotificationActions,
  postTitle: string,
  clientName: string,
  postId: string,
  client: any,
  authUser: IUser,
  selectedDate: any,
  comment: string
): Promise<boolean> => {
  const id = uuidv4();
  const notification = new NotificationService("notifications", id);
  const milliseconds = selectedDate.seconds * 1000;

  const date = moment(milliseconds).toDate();

  try {
    const notificationData: INotification = {
      action,
      initiatorEmail: authUser.email,
      initiatorName: `${authUser.firstName} ${authUser.lastName}`,
      initiatorId: authUser.id,
      notificationType: NotificationTypes.comment,
      postId,
      postStatus: "",
      postTitle,
      status: NotificationStatus.unread,
      clientName,
      archive: false,
      date,
      created_at: new Date(),
      client,
      authUser,
      comment,
    };

    const response = await notification.addOne(notificationData);
    console.log("response: ", response);
    return response;
  } catch (error) {
    console.error("error: ", error);
    return false;
  }
};
export const addEventCommentNotification = async (
  action: NotificationActions,
  postTitle: string,
  clientName: string,
  postId: string,
  client: any,
  authUser: IUser,
  selectedDate: any,
  comment: string
): Promise<boolean> => {
  const id = uuidv4();
  const notification = new NotificationService("notifications", id);
  const milliseconds = selectedDate.seconds * 1000;

  const date = moment(milliseconds).toDate();

  try {
    const notificationData: INotification = {
      action,
      initiatorEmail: authUser.email,
      initiatorName: `${authUser.firstName} ${authUser.lastName}`,
      initiatorId: authUser.id,
      notificationType: NotificationTypes.event_comment,
      postId,
      postStatus: "",
      postTitle,
      status: NotificationStatus.unread,
      clientName,
      archive: false,
      date,
      created_at: new Date(),
      client,
      authUser,
      comment,
    };

    console.log("notificationData: ", notificationData);

    const response = await notification.addOne(notificationData);
    console.log("response: ", response);
    return response;
  } catch (error) {
    console.error("error: ", error);
    return false;
  }
};
