/**
 * @prettierdesc
 */

import React, { FC, useState } from "react";
import { Box, Tooltip, CircularProgress, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import history from "history/browser";
import cuid from "cuid";

import CreateCampaignStyles from "components/styled/CreateCampaign";
import Image from "components/Common/Image/Image";

import facebook from "static/media/fb-vector.png";
import defaultIcon from "static/media/universal-2.png";
import { SendButton } from "components/styled/Common";
import { PageType } from "types/PageType";
import GetCookies from "components/Common/GetCookies";
import CampaignName from "components/Common/CampaignName";
import { useUser } from "UserContext";
import { SocialMediaPlatform } from "containers/BeAmplified/Campaigns/Organic/SelectCampaign";
import { facebookAutoLiker } from "helpers/autoLiker";
import { IUser } from "interfaces/IUser";
import { firestore } from "firebase-tools/firebase";
import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import showToast from "utils/toast";

interface CreateAutoPosterProps extends PageType {
  uid?: string;
  toggleCreate?: Function;
}

export const Facebook: FC<CreateAutoPosterProps> = ({ toggleCreate, uid }) => {
  const [campaignName, setCampaignName] = useState("");
  const [sessionCookieCUser, setSessionCookieCUser] = useState("");
  const [sessionCookieXs, setserSessionCookieXs] = useState("");
  const [spreadsheetUrl, setSpreadsheetUrl] = useState("");
  const [numberOfLikesPerProfile, setNumberOfLikesPerProfile] = useState(1);
  const [numberOfPostsToLoad, setNumberOfPostsToLoad] = useState(10);
  const [numberOfInputsPerLaunch, setNumberOfInputsPerLaunch] = useState(10);
  const [errors, setErrors] = useState({
    client: "",
    campaignName: "",
    spreadsheetUrl: "",
    numberOfLikesPerProfile: "",
    numberOfPostsToLoad: "",
    numberOfInputsPerLaunch: "",
  });
  const [loading, setLoading] = useState(false);
  const id = cuid();

  const [authUser] = useUser() as Array<IUser>;

  let userRef = firestore.collection("users").doc(authUser.id);
  let client =
    useDocumentSubscriber({ ref: userRef }).document?.lastClientSelection || "";

  const createCampaign = async () => {
    if (!client)
      return setErrors((errors: any) => ({
        ...errors,
        client: "Client is required!",
      }));
    if (!campaignName)
      return setErrors((errors: any) => ({
        ...errors,
        campaignName: "Campaign name is required!",
      }));
    if (!spreadsheetUrl)
      return setErrors((errors: any) => ({
        ...errors,
        spreadsheetUrl: "Spreadsheet Url is required!",
      }));

    if (!spreadsheetUrl.includes("https://docs.google.com/spreadsheets/d/")) {
      return setErrors((errors: any) => ({
        ...errors,
        spreadsheetUrl: "Enter a valid spreadsheet URL!",
      }));
    }
    if (!numberOfLikesPerProfile || numberOfLikesPerProfile > 20) {
      return setErrors((errors: any) => ({
        ...errors,
        numberOfLikesPerProfile: "Enter a valid value for likes per profile!",
      }));
    }
    if (!numberOfPostsToLoad || numberOfPostsToLoad > 20) {
      return setErrors((errors: any) => ({
        ...errors,
        numberOfPostsToLoad: "Enter a valid value for likes to load!",
      }));
    }
    if (!numberOfInputsPerLaunch || numberOfInputsPerLaunch > 20) {
      return setErrors((errors: any) => ({
        ...errors,
        numberOfInputsPerLaunch:
          "Enter a valid value for number of inputs per launch!",
      }));
    }
    if (sessionCookieCUser === "") {
      showToast("Error: Add a C_user session Cookie to proceed");
    }
    if (sessionCookieXs === "") {
      showToast("Error: Add a xs session Cookie to proceed");

      return;
    } else {
      setLoading(true);
      await facebookAutoLiker({
        id,
        spreadsheetUrl,
        sessionCookieCUser:
          client?.facebook?.sessionCookieCUser || sessionCookieCUser,
        sessionCookieXs: client?.facebook?.sessionCookieXs || sessionCookieXs,
        campaignName,
        username: client?.facebook?.username,
        numberOfLikesPerProfile,
        numberOfPostsToLoad,
        numberOfInputsPerLaunch,
      });
      setCampaignName("");
      setserSessionCookieXs("");
      setSessionCookieCUser("");
      setSpreadsheetUrl("");
      setLoading(false);
    }
  };

  return (
    <CreateCampaignStyles>
      <ToastContainer />
      <div>
        <span className="title__main">{uid ? "Edit" : "Create"} Campaign</span>
        <Box marginTop="50px">
          <div className="items-center">
            <Image className="img__main" src={facebook || defaultIcon} alt="" />
            <span className="title__sub">Facebook Auto Liker</span>
          </div>
        </Box>
      </div>
      <br />
      <div
        style={{ margin: "20px 0px -20px 0px" }}
        className="campaign__container"
      >
        <span className="title__basic" style={{ marginBottom: 10 }}>
          Client name
        </span>
        <input
          type="text"
          value={`${authUser.lastClientSelection.firstName} ${authUser.lastClientSelection.lastName}`}
          style={{ color: "#606060" }}
          className="campaign__input description__sub campaign__input--disabled"
        />
      </div>
      <CampaignName
        campaignName={campaignName}
        setCampaignName={setCampaignName}
        error={errors.campaignName}
        setErrors={setErrors}
      />
      <GetCookies
        setSessionCookie={setSessionCookieCUser}
        sessionCookie={sessionCookieCUser}
        title="Your Facebook c_user session cookie"
        platform={SocialMediaPlatform.Facebook}
        platformIcon={facebook}
        tokeType="c_user"
      />
      <GetCookies
        setSessionCookie={setserSessionCookieXs}
        sessionCookie={sessionCookieXs}
        title="Your Facebook xs session cookie*"
        platform={SocialMediaPlatform.Facebook}
        platformIcon={facebook}
        tokeType="xs"
      />
      {/* More info */}
      <Box marginTop="40px" className="connection-container">
        <div className="campaign__container">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <span className="title__basic">Facebook Posts to like</span>
            <Tooltip
              title={
                <Box>
                  <h1>
                    Tell us which Facebook posts you would like it to like.
                  </h1>
                  <Box fontSize={14}>
                    <div>
                      Create a spreadsheet on{" "}
                      <a
                        href="https://docs.google.com/spreadsheets/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Google Spreadsheet.
                      </a>{" "}
                      Fill it with your facebook profiles. One name per row, all
                      in column A:
                    </div>
                    <br />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/be-measured-admin-staging.appspot.com/o/images%2Ffacebook-sheet.png?alt=media&token=f77526c8-2a66-4a19-bb51-2653ca1bf262"
                      alt="facebook-posts"
                      width="88%"
                    />
                    <br />

                    <div>Make this spreadsheet public so we can access it:</div>
                    <br />

                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/be-measured-admin-staging.appspot.com/o/images%2Fshare%20spreadsheet.png?alt=media&token=d1aaa739-4d5a-40a0-9562-8b82a633d61b"
                      alt="share spreadsheet"
                      width="98%"
                    />
                    <br />

                    <div>And paste that spreadsheet's URL back here.</div>
                  </Box>
                </Box>
              }
              arrow
              interactive
            >
              <InfoIcon style={{ color: "#C4C4C4" }} />
            </Tooltip>
          </Box>
          <span className="description__sub">
            Must be a google spreadsheet URL.
          </span>
          <input
            type="text"
            value={spreadsheetUrl}
            placeholder="https://docs.google.com/spreadsheets/d/(...)"
            onChange={(e) => {
              setSpreadsheetUrl(e.target.value);
              setErrors((errors: any) => ({
                ...errors,
                spreadsheetUrl: "",
              }));
            }}
            className="campaign__input"
          />
          {errors.spreadsheetUrl && (
            <span style={{ color: "red", fontSize: 14, paddingLeft: 6 }}>
              {errors.spreadsheetUrl}
            </span>
          )}
        </div>
        <div className="campaign__container">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography className="title__basic">
              Number of posts to like per profile
            </Typography>
            <Tooltip
              title={
                <Box>
                  <Typography variant="body1">
                    There's a limit to how much you can automate on Facebook.
                  </Typography>
                  <Typography variant="body1">
                    We recommend you process a maximum of{" "}
                    <strong>5 likes per hour</strong> in order to keep your
                    account safe.{" "}
                  </Typography>
                </Box>
              }
              arrow
              interactive
            >
              <InfoIcon style={{ color: "#C4C4C4" }} />
            </Tooltip>
          </Box>
          <span className="description__sub">
            If left empty, 1 post will be liked per profile.
          </span>
          <input
            type="number"
            value={numberOfLikesPerProfile}
            className="campaign__input campaign__input--disabled"
            onChange={(e) => {
              setNumberOfLikesPerProfile(Number(e.target.value));
              setErrors((errors: any) => ({
                ...errors,
                numberOfLikesPerProfile: "",
              }));
            }}
          />
          {errors.numberOfLikesPerProfile && (
            <span style={{ color: "red", fontSize: 14, paddingLeft: 6 }}>
              {errors.numberOfLikesPerProfile}
            </span>
          )}
        </div>
        <div className="campaign__container">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography className="title__basic">
              Number of posts to load per profile
            </Typography>
            <Tooltip
              title="Number of posts to load per profile per launch"
              arrow
              interactive
            >
              <InfoIcon style={{ color: "#C4C4C4" }} />
            </Tooltip>
          </Box>
          <span className="description__sub">
            If left empty, the 10 latest posts will be loaded. Maximum number of
            20 posts.{" "}
          </span>
          <input
            type="number"
            value={numberOfInputsPerLaunch}
            className="campaign__input campaign__input--disabled"
            onChange={(e) => {
              setNumberOfInputsPerLaunch(Number(e.target.value));
              setErrors((errors: any) => ({
                ...errors,
                numberOfInputsPerLaunch: "",
              }));
            }}
          />
          {errors.numberOfInputsPerLaunch && (
            <span style={{ color: "red", fontSize: 14, paddingLeft: 6 }}>
              {errors.numberOfInputsPerLaunch}
            </span>
          )}
        </div>
        <div className="campaign__container">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography className="title__basic">
              Number of spreadsheet rows to process per launch
            </Typography>
            <Tooltip
              title="Number of spreadsheet rows to process per launch"
              arrow
              interactive
            >
              <InfoIcon style={{ color: "#C4C4C4" }} />
            </Tooltip>
          </Box>
          <span className="description__sub">
            This only applies if you are using a spreadsheet as input. Default
            value is 10 rows per launch.
          </span>
          <input
            type="number"
            value={numberOfPostsToLoad}
            className="campaign__input campaign__input--disabled"
            onChange={(e) => {
              setNumberOfPostsToLoad(Number(e.target.value));
              setErrors((errors: any) => ({
                ...errors,
                numberOfPostsToLoad: "",
              }));
            }}
          />
          {errors.numberOfPostsToLoad && (
            <span style={{ color: "red", fontSize: 14, paddingLeft: 6 }}>
              {errors.numberOfPostsToLoad}
            </span>
          )}
        </div>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop="40px"
      >
        <div
          className="title__sub link"
          onClick={() => {
            toggleCreate && toggleCreate();
            !toggleCreate && history.back();
          }}
        >
          {"<"} Back
        </div>
        <SendButton
          onClick={(e) => {
            e.preventDefault();
            !loading && createCampaign();
          }}
          className="btn"
          disabled={loading}
        >
          {uid
            ? `Updat${loading ? "ing" : "e"}`
            : `Launch${loading ? "ing" : ""}`}{" "}
          Campaign
          {loading && <CircularProgress className="btn-progress" size={20} />}
        </SendButton>
      </Box>
    </CreateCampaignStyles>
  );
};
