import { DB } from "firebase-tools/db";
import { INotification } from "interfaces/INotification";

export class NotificationService extends DB {
  private _doc: string = "";
  private _collection: string = "";

  constructor(collection?: string, doc?: string) {
    super();
    if (doc) {
      this.doc = doc;
    }
    if (collection) {
      this.collection = collection;
    }
  }

  set doc(value: string) {
    this._doc = value;
  }

  set collection(value: string) {
    this._collection = value;
  }

  get doc() {
    return this._doc;
  }

  get collection() {
    return this._collection;
  }

  async getPostByDoc(): Promise<INotification> {
    return this.getDoc(this.collection, this.doc);
  }

  async getList(
    orderField: string = "created_at",
    orderDirection: firebase.firestore.OrderByDirection = "desc"
  ): Promise<Array<INotification>> {
    return this.get(this.collection, orderField, orderDirection);
  }

  async addOne(post: INotification): Promise<boolean> {
    return this.set(this.collection, this.doc, post);
  }

  async addMany(posts: INotification[]): Promise<boolean[]> {
    const results: boolean[] = [];

    // Using Promise.all to perform parallel execution
    await Promise.all(
      posts.map(async (post) => {
        const result = await this.set(this.collection, this.doc, post);
        results.push(result);
      })
    );

    return results;
  }

  async deletePost(): Promise<boolean> {
    return this.delete(this.collection, this.doc);
  }
  async updatePost(post: any): Promise<boolean> {
    return this.update(this.collection, this.doc, post);
  }
}
