/**
 * @prettierdesc
 */

import React, { FunctionComponent } from "react";
import { Box, Button } from "@material-ui/core";

import { firestore } from "firebase-tools/firebase";
import useCollectionSubscriber from "hooks/useCollectionSubscriber";

import MassEmailerComponent from "components/MassEmailer/MassEmailer";
import { PageType } from "types/PageType";
import { useStyles } from "./styles";
import { navigate } from "@reach/router";

export const MassEmailer: FunctionComponent<PageType> = () => {
  const classes = useStyles({});

  const campaignQueries = [
    firestore.collection("campaigns").orderBy("created_at", "desc"),
  ];

  let campaigns;

  campaigns = useCollectionSubscriber({
    queries: campaignQueries,
    deps: [],
  });

  return (
    <div className={classes.root}>
      <div className={classes.pageTitle} id="page-title">
        <span className={classes.title} style={{ marginBottom: "15px" }}>
          Mass Email Sending
        </span>
        <Button
          disableRipple
          type="button"
          className={classes.btn}
          id="create-btn"
          onClick={() => {
            navigate("/mass-emailer/new-campaign");
          }}
        >
          Create new campaign
        </Button>
      </div>

      <div>
        {campaigns.documents.length ? (
          <MassEmailerComponent campaigns={campaigns.documents} />
        ) : (
          <Box className={classes.noCampaigns} id="empty-analytics">
            No Campaigns have been created yet!
          </Box>
        )}
      </div>
    </div>
  );
};
