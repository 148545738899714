/**
 * @prettier
 */

import React, { useContext, FunctionComponent, useState } from "react";
type userType = Object;
type setUserType = (user: userType) => void;
type userContextType = Array<userType | setUserType>;

const UserContext = React.createContext([] as userContextType);

const useUser = (): userContextType => {
  const context: userContextType = useContext(UserContext);
  if (!context) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return context;
};

const UserProvider: FunctionComponent = (props) => {
  const [user, setUser] = useState({}) as userContextType;
  return <UserContext.Provider value={[user, setUser]} {...props} />;
};

export { useUser, UserProvider };
