import styled from "styled-components";

const Channels = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;

  .checkBoxContainer {
    position: relative;
    width: 30px;
    margin-right: 12px;
  }

  .checkBox {
    padding: 0;
    margin-right: 16px;

    &.muicheckbox-root {
      font-size: 2rem;
    }

    &.muicheckbox-colorprimary.mui-checked {
      color: #000;
    }
    // "&:hover": {
    //   background: "rgba(0;0;0;0.3) !important";
    // };
  }
  .circularIcon {
    fill: #abadad;
    width: 55px;
    height: 55px;
    cursor: pointer;
    padding: 3px;
  }
`;

export default Channels;
