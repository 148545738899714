import React, { FC, useState } from "react";
import { Button, Avatar, Tooltip } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
// import { DateTime } from "luxon";

import userIcon from "static/media/name.svg";
import { useStyles } from "./styles";
import printDocument from "utils/printDocument";

interface IPrintMode {
  user: any;
  date: any;
}

const PrintMode: FC<IPrintMode> = ({ user, date }) => {
  const classes = useStyles({});

  const [isOpen, setIsOpen] = useState(false);
  const [isPrintMode, setIsPrintMode] = useState(true);

  // const period = DateTime.fromJSDate(new Date(date)).toFormat("LLLL");

  const handlePrint = (): void => {
    // add padding to toPrintContainer
    const toPrintContainer: any = document.getElementById("to-print");
    toPrintContainer.style.padding = "0 20px";
    toPrintContainer.style.height = "280vh";

    // Hide header
    const header: any = document.getElementById("header");
    header.style.display = "none";

    // Hide retainer
    const retainer: any = document.getElementById("retainer");
    retainer.style.display = "none";

    // Hide PublicLink
    const PublicLink: any = document.getElementById("public-link");
    PublicLink.style.display = "none";

    // Un Hide popupContainer
    const popupContainer: any = document.getElementById("popup-container");
    popupContainer.style.display = "none";

    // Hide filter
    const filter: any = document.getElementById("filter");
    filter.style.display = "none";

    // Hide buttonActions
    const buttonActions: any = document.getElementById("selector-calendar");
    buttonActions.style.display = "none";

    setIsPrintMode(!isPrintMode);
    setIsOpen(!isOpen);

    const clientName = user.firstName + ` ${user.lastName}`;

    setTimeout(() => {
      printDocument(`${clientName}_Monthly Planner_${date}`, "to-print");
    }, 1000);
  };

  const handleNormalMode = (): void => {
    // remove padding to toPrintContainer
    const toPrintContainer: any = document.getElementById("to-print");
    toPrintContainer.style.padding = "0px";

    // Un hide header
    const header: any = document.getElementById("header");
    header.style.display = "block";

    // Un hide retainer
    const retainer: any = document.getElementById("retainer");
    retainer.style.display = "block";

    // Un hide filter
    const filter: any = document.getElementById("filter");
    filter.style.display = "flex";

    // Un Hide PublicLink
    const PublicLink: any = document.getElementById("public-link");
    PublicLink.style.display = "block";

    // Un Hide popupContainer
    const popupContainer: any = document.getElementById("popup-container");
    popupContainer.style.display = "block";

    // revert container height
    const container: any = document.getElementById("container");
    container.style.height = "100vh";

    // Un hide buttonActions
    const buttonActions: any = document.getElementById("selector-calendar");
    buttonActions.style.display = "flex";

    setIsPrintMode(!isPrintMode);
  };

  return (
    <div>
      {isPrintMode ? (
        <Tooltip
          title="Print Mode"
          arrow
          interactive
          classes={{ tooltip: classes.info, arrow: classes.arrow }}
        >
          <PrintIcon
            id="print"
            className={classes.archiveLabel}
            style={{ marginRight: 20, marginBottom: "-8px", cursor: "pointer" }}
            onClick={handlePrint}
          />
        </Tooltip>
      ) : (
        <div className={classes.printMode}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              width: "100%",
              marginTop: 32,
            }}
          >
            {user.avatarUrl === "" ? (
              <Avatar
                src={userIcon}
                classes={{
                  root: classes.AvatarSvg,
                  img: classes.AvatarIcon,
                }}
              />
            ) : (
              <Avatar className={classes.avatar} src={user.avatarUrl} />
            )}
            <div className={classes.name}>
              {user.firstName + " " + user.lastName}
            </div>
          </div>

          <Button
            disableRipple
            type="button"
            classes={{
              label: classes.archiveLabel,
            }}
            onClick={handleNormalMode}
            style={{
              position: "absolute",
              top: 98,
              right: 0,
            }}
          >
            Normal View
          </Button>
        </div>
      )}{" "}
    </div>
  );
};

export default PrintMode;
