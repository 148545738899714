/* eslint-disable jsx-a11y/img-redundant-alt */
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  TextField,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { FieldTitle } from "components/styled/CreateEditPost";
import { ReactComponent as StarIcon } from "../../../assets/Stars.svg";
import { ReactComponent as ChatGptIcon } from "../../../assets/chatgptIcon.svg";
import Axios from "axios";
import { SendButton } from "components/styled/Common";
import { UserService } from "services/UserService";
import PreviewImage from "./PreviewImage";

const useStyles = makeStyles((theme) => ({
  // Add any additional styles you may need
  formContainer: {
    backgroundColor: "white",
    padding: theme.spacing(2),
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
    outline: "none",
  },
  // Add any additional styles you may need for the form
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "auto",
  },
  form: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    width: "900px",
    height: "100%",
    minHeight: "109px", // Assuming "hug" means auto
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    outline: "none",
    overflowY: "auto",
    // add media queries here
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  closeIcon: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  chip: {
    margin: "4px 4px 0px 0px",
    maxWidth: "100%",
    fontFamily: "'Avenir Book', sans-serif",
  },
  choose: {
    display: "flex",
    alignItems: "center",
    border: "2px solid #ABADAD",
    // border: (props: any) =>
    //   props.dropdown ? "2px solid #000" : "2px solid #ABADAD",
    width: "100%",
    borderRadius: "10px",
    marginTop: "5px",
    "&:hover": {
      border: "2px solid #000",
    },
  },

  input: {
    width: "94%",
    padding: "12.5px",
    border: "2px solid #ABADAD",
    boxSizing: "border-box",
    borderRadius: "10px",
    cursor: "pointer",
    fontFamily: "'Avenir Book', sans-serif",
    lineHeight: "20px",
    fontSize: "15px",
    "&:focus": {
      border: "2px solid #000",
      outline: "none",
    },
  },
  field: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    // marginTop: "26px",
    transition: "all 0.3s ease",
  },
  uploadIcons: {
    display: "flex",
    // paddingTop: 5,
    alignItems: "end",
    // justifyContent: "flex-end",
    width: "94%",
    "& > *": {
      cursor: "pointer",
    },
  },
  error: {
    color: "#f44336",
    fontSize: "15px",
    textAlign: "center",
    width: "75%",
  },
  highlight: {
    border: "none",
    background: "none",
    padding: "0px",
    "&:active, &:focus": {
      border: "none",
      outline: "none",
      padding: "0px",
    },
  },

  uploadImgContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    // marginTop: 12,
    width: "94%",
  },

  icon: {
    color: "#000",
    cursor: "pointer",
    fontSize: "30px",
    fontWeight: "bold",
    transition: "all .3s ease",
  },
  loadingIconContainer: {
    display: "inline-block",
    animation: "$pulse 1.5s infinite alternate", // Reference the pulse animation
  },

  loadingIcon: {
    width: "30px", // Adjust the initial size of the icon
    height: "30px", // Adjust the initial size of the icon
    transition: "all 0.3s ease-in-out", // Smooth transition for size change
  },

  loadingText: {
    marginLeft: "8px", // Add some spacing between the icon and text
  },

  "@keyframes pulse": {
    to: {
      transform: "scale(1.2)", // Adjust the scale factor for the pulse effect
    },
  },
  savebtn: {
    marginTop: "10px",
    width: "150px",
    height: "45px",
    padding: "11.5px 8px 11.5px 8px",
    borderRadius: "15px",
    border: "1px solid",
    backgroundColor: "#000",
    cursor: "pointer",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    color: "white",
    fontSize: "15px",
    fontWeight: 900,
    fontFamily: "Avenir",
    alignItems: "center",
    "&:disabled": {
      backgroundColor: "#888",
      cursor: "not-allowed",
      color: "#666",
    },
  },
  chatgpticonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%", // Optional: Set height for full-height centering
  },
  "@media (max-width: 600px)": {
    chatgpticonContainer: {
      height: "50vh", // Adjust the height for smaller screens
    },
  },
  label: {
    marginTop: "5px",
  },
  imageContainer: {
    width: "100%",
    height: "auto",
    // add media queries here
    [theme.breakpoints.down("sm")]: {
      // width: "25%",
    },
  },
}));

interface IProp {
  setImages: (data: any) => void;
  images: any[];
}

const ChatGptPostGenerateImage = ({ setImages, images }: IProp) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  // const [selectedOption, setSelectedOption] = useState(2);

  const handleClose = () => {
    setOpen(false);
    setImageUrlsSelected([]);
    setPromptText("");
  };

  // const [postText, setPostText]: any = useState("");
  const [promptText, setPromptText]: any = useState("");
  // const [generatedPost, setGeneratedPost] = useState("");
  const [imageUrls, setImageUrls] = useState([]);
  const [imageUrlsSelected, setImageUrlsSelected] = useState([] as string[]);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const User = new UserService();

  const handleUploadSuccess = async (filename: string) => {
    try {
      const url = await User.uploadFile(filename, "images");
      console.log(url);
      return url;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const generateImage = async (e: Event) => {
    e.preventDefault();
    setLoading(true);
    try {
      let res = await Axios.post(
        `${process.env.REACT_APP_FUNCTIONS_BASE_URL}chatgpt-generateImage`,
        {
          message: promptText,
          imageCount: 4,
        }
      );
      setImageUrls(res.data.data);
    } catch (error) {
      // Handle error if needed
      console.error("Error generating post:", error);
      setErrorMessage("Unable to generate image. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleImageSelection = (url: string) => {
    if (imageUrlsSelected.includes(url)) {
      // If the image is already selected, remove it from the array
      setImageUrlsSelected(
        imageUrlsSelected.filter((selectedUrl) => selectedUrl !== url)
      );
    } else {
      // If the image is not selected, add it to the array
      setImageUrlsSelected([...imageUrlsSelected, url]);
    }
  };

  const uploadImages = async (urls: string[]) => {
    const response = await Axios.post(
      `${process.env.REACT_APP_FUNCTIONS_BASE_URL}/chatgpt-imageProxy`,
      { urls }
    );
    return response.data as { fileName: string; url: string }[];
  };

  const addImageToPost = async () => {
    setLoading2(true);
    let imagesData = await uploadImages(imageUrlsSelected);
    let uploads: any[] = [];
    for (let item of imagesData) {
      let { fileName } = item;
      let imageUrl = await handleUploadSuccess(fileName);
      if (imageUrl) {
        uploads.push(imageUrl);
      }
    }

    setImages([...images, ...uploads]);
    handleClose();
    setLoading2(false);
  };

  const formatDateTime = (date: Date) => {
    const options: any = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return date.toLocaleDateString("en-US", options);
  };

  return (
    <>
      <SendButton
        onClick={() => setOpen(true)}
        disabled={true}
        style={{
          width: "200px",
          marginTop: "10px",
          marginRight: "10px",
        }}
        disableRipple
      >
        + ChatGPT Images
      </SendButton>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        aria-labelledby="chatgpt-post-generator-title"
        aria-describedby="chatgpt-post-generator-description"
      >
        <div className={classes.formContainer}>
          <form
            className={classes.form}
            onSubmit={(e: any) => generateImage(e)}
          >
            <IconButton className={classes.closeIcon} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Grid
              container
              spacing={2}
              style={{
                padding: "10px",
              }}
            ></Grid>
            <br />
            <Grid container>
              <Grid
                xs={12}
                md={6}
                style={{
                  padding: "10px",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <FieldTitle>
                    What Image Would You Want To Generate?
                  </FieldTitle>
                  <div
                    className={classes.choose}
                    style={{ border: "none" }}
                    //   ref={postTitleErrorRef}
                  >
                    <TextField
                      label="Prompt"
                      variant="outlined"
                      value={promptText}
                      onChange={(e) => {
                        e.preventDefault();
                        setPromptText(e.target.value);
                        setErrorMessage("");
                      }}
                      fullWidth
                    />
                  </div>
                  {errorMessage && (
                    <p className={classes.error}>{errorMessage}</p>
                  )}
                </div>
                <button
                  type="submit"
                  style={{
                    marginTop: "10px",
                    width: "150px",
                    height: "45px",
                    padding: "11.5px 8px 11.5px 8px",
                    borderRadius: "15px",
                    border: "1px solid",
                    backgroundColor: "white",
                    cursor: "pointer",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  disabled={loading || !promptText}
                >
                  {loading && (
                    <CircularProgress
                      size={24}
                      style={{ marginRight: "8px" }}
                    />
                  )}
                  {!loading ? "Generate" : "Generating..."}
                </button>
              </Grid>
              <Grid
                xs={12}
                md={6}
                style={{
                  padding: "10px",
                }}
              >
                {imageUrls.length < 1 && (
                  <Grid container className={classes.chatgpticonContainer}>
                    <ChatGptIcon />
                  </Grid>
                )}
                {imageUrls.length > 0 && (
                  <>
                    <Grid container>
                      <Grid xs={12} md={3}></Grid>
                      <Grid
                        xs={12}
                        md={9}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          color: "rgba(0, 0, 0, 0.54)",
                          fontSize: "15px",
                          fontFamily: "Avenir",
                          fontWeight: 350,
                        }}
                      >
                        {formatDateTime(new Date())}
                      </Grid>
                    </Grid>
                    <FieldTitle>You</FieldTitle>
                    <p>{promptText}</p>

                    <br />
                    <Grid container style={{ marginBottom: "20px" }}>
                      <Grid xs={6} md={6}>
                        {" "}
                        <FieldTitle style={{ marginTop: "20px" }}>
                          {" "}
                          <span>
                            <StarIcon />
                          </span>
                          <span style={{ padding: "10px" }}>AI Generator</span>
                        </FieldTitle>
                      </Grid>
                      <Grid
                        xs={6}
                        md={6}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <button
                          type="submit"
                          className={classes.savebtn}
                          disabled={loading2 || imageUrlsSelected.length < 1}
                          onClick={(e) => {
                            e.preventDefault();
                            addImageToPost();
                          }}
                        >
                          {loading2 && (
                            <CircularProgress
                              size={24}
                              style={{ marginRight: "8px" }}
                            />
                          )}
                          {!loading2 ? "Add to Post" : "Uploading..."}
                        </button>
                      </Grid>
                    </Grid>

                    {loading && (
                      <Grid
                        container
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "140px",
                        }}
                      >
                        <div className={classes.loadingIconContainer}>
                          <StarIcon className={classes.loadingIcon} />
                        </div>
                        <span className={classes.loadingText}>
                          Generating Post...
                        </span>
                      </Grid>
                    )}

                    {imageUrls.length > 0 && (
                      <Grid container>
                        {imageUrls.map((url, index) => (
                          <Grid xs={12} sm={6} md={6} key={index}>
                            {/* <img
                              src={url}
                              alt="image"
                              className={classes.imageContainer}
                              style={{
                                cursor: "pointer",
                                border: imageUrlsSelected.includes(url)
                                  ? "2px solid green"
                                  : "none",
                              }}
                              onClick={() => handleImageSelection(url)}
                            /> */}
                            <PreviewImage
                              src={url}
                              alt={promptText}
                              isSelected={imageUrlsSelected.includes(url)}
                              onSelect={() => handleImageSelection(url)}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default ChatGptPostGenerateImage;
