import React, { FC } from "react";
import { PageType } from "types/PageType";
import { Instagram } from "./AutoCommenter/Instagram";

interface Props extends PageType {
  uid?: string;
  platform?: string;
  toggleCreate?: Function;
}
export const CreateAutoCommenter: FC<Props> = () => {
  return <Instagram />;
};
