import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    width: "100%",
    paddingBottom: "50px",
  },
  table: {
    minWidth: 650,
  },

  avatar: {
    width: "60px",
  },
  pageTitle: {
    minHeight: 85.5,
    fontSize: "30px",
    fontWeight: 900,
    fontStyle: "normal",
    lineHeight: "25px",
    color: "#101010",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "40px 0 20px 0",
    fontFamily: "Avenir Black, sans-serif",
  },
  socials: {
    display: "flex",
    maxWidth: "130px",
    justifyContent: "space-between",
  },
  tableHeader: {
    backgroundColor: "#ffffff",
    height: "76px",
  },
  header: {
    fontFamily: "Avenir Black, sans-serif !important",
    fontWeight: 900,
  },
  tableCell: {
    height: "22px",
    left: "0%",
    right: "94.24%",
    top: "calc(50% - 22px/2 - 0.04px)",
    fontFamily: "Avenir Book, sans-serif",
    fontSize: "15px",
    lineHeight: "22px",
    color: "#000000",
    width: "25%",
    "&.MuiTableCell-root": {
      "&:first-of-type": {
        padding: "16px 16px 16px 6rem",
      },
    },
  },
  archived: {
    width: "100%",
    padding: "0 15px 0 0",
  },
  modals: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  btn: {
    width: "170px",
    height: "46px",
    background: "#1D4E89",
    borderRadius: "2px",
    textTransform: "capitalize",
    fontFamily: "AvenirNext-Medium",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF",
    marginBottom: "5px",
    justifyContent: "space-around",
    float: "right",
    alignItems: "center",

    "&:hover": {
      backgroundColor: "#1D4E89",
      opacity: 0.9,
    },
  },
});
