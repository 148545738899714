import React, { FunctionComponent, useState } from "react";
import { Grid } from "@material-ui/core";
// import { Grid, LinearProgress, Tooltip } from "@material-ui/core";
// import { withStyles } from "@material-ui/core/styles";
import { fromEvent, Observable } from "rxjs";
// import InfoIcon from "@material-ui/icons/Info";

import { useStylesV2 } from "./stylesV2";
import { numberWithCommas } from "../../utils/numberFormat";
import Image from "components/Common/Image/Image";

export interface IndicatorProps {
  icon?: any;
  metrics?: any;
  role: string;
  name?: string;
  url?: string;
  hiddenSubscriber?: boolean;
  dateRange?: any;
}

const MetricsIndicatorV2: FunctionComponent<IndicatorProps> = ({
  icon,
  role,
  metrics,
  url,
  hiddenSubscriber,
}) => {
  const classes = useStylesV2({});

  const [mobileMenu, switchMobileMenu] = useState(
    !(document.documentElement.offsetWidth > 720)
  );

  const resize$: Observable<Event> = fromEvent(window, "resize");

  resize$.subscribe((res: any) => {
    if (res && res.currentTarget) {
      switchMobileMenu(!(res.currentTarget.innerWidth > 720));
    }
  });

  let compareValue = 0;

  const platformsArr = Object.entries(metrics);

  platformsArr.forEach((el: Array<any>) => {
    if (el[1] > compareValue) {
      compareValue = el[1];
    }
  });

  const largestPlatform = platformsArr.filter((el) => el[1] === compareValue);

  const igProgress =
    largestPlatform?.length > 0 &&
    largestPlatform[0].length > 0 &&
    largestPlatform[0][0] === "igFollowerCount"
      ? 80
      : ((80 * metrics?.igFollowerCount) / compareValue).toFixed(2);

  const fbProgress =
    largestPlatform?.length > 0 &&
    largestPlatform[0].length > 0 &&
    largestPlatform[0][0] === "fbFollowerCount"
      ? 80
      : ((80 * metrics?.fbFollowerCount) / compareValue).toFixed(2);

  const twitterProgress =
    largestPlatform?.length > 0 &&
    largestPlatform[0].length > 0 &&
    largestPlatform[0][0] === "twitterFollowerCount"
      ? 80
      : ((80 * metrics?.twitterFollowerCount) / compareValue).toFixed(2);

  const tiktokProgress =
    largestPlatform?.length > 0 &&
    largestPlatform[0].length > 0 &&
    largestPlatform[0][0] === "tiktokFollowerCount"
      ? 80
      : ((80 * metrics?.tiktokFollowerCount) / compareValue).toFixed(2);

  const youtubeProgress =
    largestPlatform?.length > 0 &&
    largestPlatform[0].length > 0 &&
    largestPlatform[0][0] === "youtubeFollowerCount"
      ? 80
      : ((80 * metrics?.youtubeFollowerCount) / compareValue).toFixed(2);
  const appleMusicProgress =
    largestPlatform?.length > 0 &&
    largestPlatform[0].length > 0 &&
    largestPlatform[0][0] === "appleMusicListenersCount"
      ? 80
      : ((80 * metrics?.appleMusicListenersCount) / compareValue).toFixed(2);

  const spotifyProgress =
    largestPlatform?.length > 0 &&
    largestPlatform[0].length > 0 &&
    largestPlatform[0][0] === "spotifyFollowerCount"
      ? 80
      : ((80 * metrics?.spotifyFollowerCount) / compareValue).toFixed(2);

  const returnIndicators = () => {
    if (role === "facebook") {
      return {
        label: mobileMenu ? "Followers" : "Facebook Followers",
        progress: fbProgress,
        barColor: "linear-gradient(179.36deg, #3457A0 20.16%, #3B5998 65.09%)",
        colorPrimary:
          "linear-gradient(179.71deg, rgba(52, 87, 160, 0.1) 20.16%, rgba(59, 89, 152, 0.1) 65.09%)",
        followersColor: "rgb(29, 78, 137)",
        followers: numberWithCommas((metrics && metrics?.fbFollowerCount) || 0),
      };
    }
    if (role === "instagram") {
      return {
        label: mobileMenu ? "Followers" : "Instagram Followers",
        progress: igProgress,
        barColor: "linear-gradient(178.93deg, #c4465d 20.16%, #d92d4c 65.09%)",
        colorPrimary: "rgba(192, 28, 108, 0.1)",
        followersColor: "rgb(29, 78, 137)",
        followers: numberWithCommas((metrics && metrics?.igFollowerCount) || 0),
      };
    }
    if (role === "twitter") {
      return {
        label: mobileMenu ? "Followers" : "X Followers",
        progress: twitterProgress,
        barColor: "linear-gradient(178.93deg, #00C3FF 20.16%, #00B0ED 65.09%)",
        colorPrimary:
          "linear-gradient(179.71deg, rgba(0, 195, 255, 0.1) 20.16%, rgba(0, 176, 237, 0.1) 65.09%)",
        followersColor: "rgb(29, 78, 137)",
        followers: numberWithCommas(
          (metrics && metrics?.twitterFollowerCount) || 0
        ),
      };
    }
    if (role === "tiktok") {
      return {
        label: mobileMenu ? "Followers" : "TikTok Followers",
        progress: tiktokProgress,
        barColor: "linear-gradient(178.93deg, #3e4041 20.16%, #071218 65.09%)",
        colorPrimary:
          "linear-gradient(179.71deg, rgba(0, 195, 255, 0.1) 20.16%, rgba(0, 176, 237, 0.1) 65.09%)",
        followersColor: "rgb(29, 78, 137)",
        followers: numberWithCommas(
          (metrics && metrics?.tiktokFollowerCount) || 0
        ),
      };
    }
    if (role === "YouTube") {
      return {
        label: mobileMenu ? "Subscribers" : "YouTube Subscribers",
        progress: youtubeProgress,
        barColor: "rgb(215 69 69)",
        colorPrimary: "rgb(215 69 69)",
        followersColor: "rgb(29, 78, 137)",
        followers: numberWithCommas(
          (metrics && metrics?.youtubeFollowerCount) || 0
        ),
      };
    }
    if (role === "apple-music") {
      return {
        label: mobileMenu ? "Apple Music" : "Apple Music Listeners",
        progress: appleMusicProgress,
        barColor: "rgb(215 69 69)",
        colorPrimary: "rgb(215 69 69)",
        followersColor: "rgb(29, 78, 137)",
        followers: numberWithCommas(
          (metrics && metrics?.appleMusicListenersCount) || 0
        ),
      };
    }
    if (role === "Spotify") {
      return {
        label: mobileMenu ? "Listeners" : "Spotify Followers",
        progress: spotifyProgress,
        barColor: "#1ed760",
        colorPrimary: "#1ed760",
        followersColor: "rgb(29, 78, 137)",
        followers: numberWithCommas(
          (metrics && metrics?.spotifyFollowerCount) || 0
        ),
      };
    }
  };
  let indicator: any = returnIndicators();
  //   const BorderLinearProgress = withStyles({
  //     root: {
  //       height: 6,
  //       borderRadius: 7,

  //       "&:hover": {
  //         opacity: 0.5,
  //       },
  //     },
  //     colorPrimary: {
  //       background: "transparent",
  //     },
  //     bar: {
  //       borderRadius: 7,
  //       background: indicator.barColor,
  //     },
  //   })(LinearProgress);

  //   const stat = {
  //     facebook: "The total number of people who have followed your page.",
  //     instagram: "The total number of people who have followed you.",
  //     twitter: "The total number of people who have followed your page.",
  //     tiktok: "The total number of people who have followed your page.",
  //     YouTube: "The total number of people who have subscribed to your channel.",
  //     "apple-music": "The total number listeners.",
  //     Spotify: "The total number of people who have followed you.",
  //   };

  return (
    <Grid
      container
      style={{
        padding: 10,
      }}
    >
      <Grid
        container
        spacing={2}
        className={classes.followerContainer}
        style={{
          textAlign: "center",
        }}
      >
        <span className={classes.iconContainer}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            <Image className={classes.icon} src={icon} alt={role} />
          </a>
        </span>

        <span className={classes.followersCount}>
          {hiddenSubscriber
            ? `${mobileMenu ? "Hidden" : "Subscribers Hidden"}`
            : indicator.followers}
        </span>
      </Grid>
      <Grid xs={12} md={12} className={classes.followers}>
        {indicator.label}
      </Grid>
      {/* <div className={classes.progress}> */}
      {/* <div className={classes.metricLabel}>
          <div>{indicator.label}</div>
          <div className={classes.followers} style={{}}>
            <div
              style={{
                fontSize: "18px",
                fontFamily: "Avenir Book, sans-serif",
                fontWeight: 900,
                color: indicator.followersColor,
                paddingRight: 5,
              }}
            >
              {hiddenSubscriber
                ? `${mobileMenu ? "Hidden" : "Subscribers Hidden"}`
                : indicator.followers}
            </div>
            <Tooltip
              title={stat[`${role}`] || role}
              arrow
              interactive
              classes={{ tooltip: classes.info, arrow: classes.arrow }}
            >
              <InfoIcon />
            </Tooltip>
          </div>
        </div> */}
      {/* <BorderLinearProgress
          variant="determinate"
          value={indicator.progress}
        /> */}
      {/* </div> */}
    </Grid>
  );
};

export default MetricsIndicatorV2;
