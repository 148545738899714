/**
 * @prettier
 */

import React, { FunctionComponent, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import { PageType } from "types/PageType";
import SendInvite from "components/InviteModal";
import { useStyles } from "./styles";
import ArchivedClients from "components/ArchivedClients";
import { IUser } from "interfaces/IUser";
import { useUser } from "UserContext";
import { Toast } from "components/styled/Common";
import { Grid, TableSortLabel } from "@material-ui/core";
import UserManagement from "components/UserManagement";
import ReactPaginate from "react-paginate";
import { firestore } from "../../firebase-tools/firebase";
import useCollectionSubscriber from "hooks/useCollectionSubscriber";

export const UserManagementPage: FunctionComponent<PageType> = () => {
  const classes = useStyles({});
  const [authUser] = useUser() as Array<IUser>;
  const [name, setName] = React.useState("");
  const [sortedBy, setSortedBy] = React.useState("createdAt");
  const [sortDirection, setSortDirection] = React.useState("desc");

  const handleNameChange = (e: any) => {
    setName(e.target.value);
  };

  const handleSort = (column) => {
    if (column === sortedBy) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedBy(column);
      setSortDirection("asc");
    }
  };

  const queries = [
    firestore
      .collection("users")
      .where("role", "==", "user")
      .orderBy("isActivated", "desc")
      .orderBy("createdAt", "desc"),
  ];
  const usersList = useCollectionSubscriber<IUser>({ queries });

  //   if name filter is not empty, filter the usersList
  if (name !== "") {
    usersList.documents = usersList.documents.filter((user: IUser) => {
      return (
        user.firstName.toLowerCase().includes(name.toLowerCase()) ||
        user.lastName.toLowerCase().includes(name.toLowerCase())
      );
    });
  }

  // remove users without firstName
  usersList.documents = usersList.documents.filter(
    (user: IUser) => !user.isArchived
  );

  //   if sortedBy is not empty, sort the usersList
  if (sortedBy) {
    usersList.documents = usersList.documents.sort((a: any, b: any) => {
      if (a[sortedBy] < b[sortedBy]) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (a[sortedBy] > b[sortedBy]) {
        return sortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });
  }

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Set the number of items per page

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  // Calculate the indices for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the subset of users for the current page
  const usersForCurrentPage = usersList.documents.slice(startIndex, endIndex);

  const allInvited = (client: any) => {
    const isInvited = (currentValue: any) => currentValue.isInvited;
    const emails = client.secondaryEmails || [];
    return client.isInvited && emails.every(isInvited);
  };

  return (
    <div className={classes.root}>
      <Toast>
        <ToastContainer progressClassName="toast-progressbar" />
      </Toast>
      <div className={classes.pageTitle} id="page-title">
        <span>Admin Panel</span>
        <div className={classes.modals}>
          <div className={classes.archived}>
            <ArchivedClients />
          </div>
          {authUser.role !== "viewerAdmin" && <SendInvite />}
        </div>
      </div>
      <div id="admin-table">
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}></Grid>

            <Grid item xs={12} md={6} style={{ padding: 36 }}>
              <input
                type="text"
                id="search"
                placeholder="Search"
                className={classes.searchBar}
                onChange={handleNameChange}
              />
            </Grid>
          </Grid>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow className={classes.tableHeader}>
                <TableCell className={classes.tableCell} align="center">
                  Logo
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  <TableSortLabel
                    active={sortedBy === "firstName"}
                    onClick={() => handleSort("firstName")}
                    direction={sortDirection === "asc" ? "desc" : "asc"}
                    className={classes.tableSortLabel}
                  />
                  Client Name
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Social Media Accounts
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Status
                </TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            {/* <UserManagement /> */}
            <UserManagement
              usersList={usersList}
              allInvited={allInvited}
              usersForCurrentPage={usersForCurrentPage}
            />
          </Table>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}></Grid>

            <Grid item xs={12} md={6} style={{ padding: 36 }}>
              <div className="container m-19 pagination-container">
                <ReactPaginate
                  pageCount={Math.ceil(
                    usersList.documents.length / itemsPerPage
                  )}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  onPageChange={(selected) =>
                    handlePageChange(selected.selected + 1)
                  }
                  containerClassName="paginationnew"
                  activeClassName="active"
                />
              </div>
            </Grid>
          </Grid>
        </TableContainer>
      </div>
    </div>
  );
};
