export const postsFilter = (
  channelsInput,
  multiChannel,
  monthlyCategory: Array<string>,
  weeklyCategory: Array<string>,
  sortDirection = "desc"
) => {
  let filteredPosts: any = [];
  let channels = channelsInput.map((channel) => channel?.toLowerCase());

  if (!channels.length || channels.includes("all")) {
    filteredPosts = multiChannel.documents;
  } else {
    if (channels?.map((val) => val?.toLowerCase())?.includes("facebook")) {
      const posts = multiChannel.documents.filter((ch) =>
        ch.channels?.map((val) => val?.toLowerCase())?.includes("facebook")
      );
      filteredPosts = filteredPosts.concat(posts);
    }
    if (channels.map((val) => val?.toLowerCase()).includes("instagram")) {
      const posts = multiChannel.documents.filter((ch) =>
        ch.channels.map((val) => val?.toLowerCase()).includes("instagram")
      );
      filteredPosts = filteredPosts.concat(posts);
    }
    if (channels?.map((val) => val?.toLowerCase())?.includes("twitter")) {
      const posts = multiChannel.documents.filter((ch) =>
        ch.channels?.map((val) => val?.toLowerCase())?.includes("twitter")
      );
      filteredPosts = filteredPosts.concat(posts);
    }
    if (channels?.map((val) => val?.toLowerCase())?.includes("tiktok")) {
      const posts = multiChannel.documents.filter(
        (ch) =>
          ch.channels.includes("tiktok") ||
          ch.channels?.map((val) => val?.toLowerCase())?.includes("tikTok")
      );
      filteredPosts = filteredPosts.concat(posts);
    }
    if (channels?.map((val) => val?.toLowerCase())?.includes("youtube")) {
      const posts = multiChannel.documents.filter((ch) =>
        ch.channels?.map((val) => val?.toLowerCase())?.includes("youtube")
      );
      filteredPosts = filteredPosts.concat(posts);
    }
    if (channels?.map((val) => val?.toLowerCase())?.includes("snapchat")) {
      const posts = multiChannel.documents.filter((ch) =>
        ch.channels?.map((val) => val?.toLowerCase())?.includes("snapchat")
      );
      filteredPosts = filteredPosts.concat(posts);
    }
    if (channels?.map((val) => val?.toLowerCase())?.includes("threads")) {
      const posts = multiChannel.documents.filter((ch) =>
        ch.channels?.map((val) => val?.toLowerCase())?.includes("threads")
      );
      filteredPosts = filteredPosts.concat(posts);
    }
    if (channels?.map((val) => val?.toLowerCase())?.includes("discord")) {
      const posts = multiChannel.documents.filter((ch) =>
        ch.channels?.map((val) => val?.toLowerCase())?.includes("discord")
      );
      filteredPosts = filteredPosts.concat(posts);
    }

    if (channels.includes("Others")) {
      const posts = multiChannel.documents.filter(
        (post) =>
          !!post.channels.filter(
            (x) =>
              ![
                "facebook",
                "twitter",
                "instagram",
                "tiktok",
                "youtube",
              ].includes(x.toLowerCase())
          ).length
      );
      filteredPosts = filteredPosts.concat(posts);
    }
  }

  if (monthlyCategory.length) {
    const posts = filteredPosts.filter((post) => {
      return monthlyCategory.includes(post.internal_status_monthly);
    });

    filteredPosts = posts;
  }

  if (weeklyCategory.length) {
    const posts = filteredPosts.filter((post) => {
      return weeklyCategory.includes(post.internal_status_weekly);
    });

    filteredPosts = posts;
  }

  return filteredPosts;
};
export const eventFilter = (filteredPosts, sortDirection = "desc") => {
  if (sortDirection) {
    const sortedDocuments = filteredPosts.sort((a, b) => {
      const dateA = a.start_date.seconds;
      const dateB = b.start_date.seconds;

      return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
    });

    filteredPosts = sortedDocuments;
  }

  return filteredPosts;
};
