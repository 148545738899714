export const sliceToBackslash = (s: any, c?: any) => {
  "clean string by everything starting from backslash";
  const backSlash = s.indexOf("/");
  s = s.substring(0, backSlash !== -1 ? backSlash : s.length);
  return c ? s : s.toLowerCase();
};

export const capitalize = (string: string) => {
  // capitalize first letter in string
  return string.charAt(0).toUpperCase() + string.slice(1);
};
