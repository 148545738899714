import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  avatar: {
    display: "inline-block",
    height: "65px",
    width: "65px",
    border: "solid 1px #f2f2f2",
  },
  icon: {
    background: "#000",
    height: "60px",
    width: "60px",
    margin: "0 auto",
  },
  icon2: {
    background: "#000",
    height: "60px",
    width: "60px",
  },
  svg: {
    width: "40%",
    height: "40%",
  },
  img: {
    width: "40px",
    left: "35.67%",
    right: "60.36%",
    top: "15.64%",
    bottom: "18.79%",
    marginRight: "15px",
  },
  row: {
    height: "120.25px",
  },
  clientName: {
    height: "22px",
    left: "0%",
    right: "0%",
    top: "calc(50% - 22px/2 - 14px)",
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: "15px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#000000",
  },
  client: {
    display: "flex",
    flexDirection: "column",
  },
  toggle: {
    height: "30px",
    fontSize: "12px",
    textTransform: "capitalize",
  },
  tableCell: {
    alignItems: "center",
    borderRadius: "15px",
  },
  chipLabel: {
    fontFamily: "AvenirNext-DemiBold",
    fontSize: "10px",
    lineHeight: "11px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#FFFFFF",
  },
  chipRoot: {
    background: "#FF5269",
    borderRadius: "2px",
    height: "22px",
  },
});
