import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    width: "100%",
    paddingBottom: "50px",
  },
  pageTitle: {
    minHeight: 85.5,
    fontSize: "30px",
    fontWeight: 900,
    fontStyle: "normal",
    lineHeight: "45px",
    color: "#101010",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "85px 0 20px 0",
    fontFamily: "Avenir Black, sans-serif",

    flexDirection: "column",
  },
  noCampaigns: {
    width: "80%",
    margin: "0 auto",
    padding: 70,
    marginBottom: 20,
    boxShadow: "0 5px 10px 4px rgb(0 0 0 / 10%)",
    borderRadius: 15,
    backgroundColor: "#fff",
    textAlign: "center",
  },
  title: {
    fontSize: 48,
    "@media (max-width: 767px)": {
      marginBottom: 15,
    },
  },
  btn: {
    background: "#000000",
    borderRadius: "15px",
    textTransform: "capitalize",
    fontFamily: "'Avenir Black', sans-serif",
    fontSize: "15px",
    fontWeight: 900,
    fontStyle: "normal",
    lineHeight: "22px",
    color: "#FFFFFF",
    justifyContent: "center",
    float: "right",
    alignItems: "center",
    padding: "15px 18px",

    "&:hover": {
      backgroundColor: "#000000",
      opacity: 0.85,
    },

    "&:disabled": {
      color: "#fff !important",
      opacity: 0.5,
      cursor: "not-allowed !important",
    },
  },
});
