import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  avatar: {
    width: "80px",
    height: "80px",
    mixBlendMode: "normal",
  },
  icon: {
    width: "80px",
    height: "80px",
    background: "#000",
  },
  svg: {
    width: "40%",
    height: "40%",
  },
  box: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    marginLeft: "36px",
    color: "#FFFFFF",
    fontFamily: "AvenirNext-DemiBold",
    fontSize: "16px",
    lineHeight: "22px",
    backgroundColor: "#1D4E89",
    "&:hover": {
      backgroundColor: "#1D4E89",
      opacity: 0.9,
    },
    width: "162px",
    height: "46px",
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
    borderRadius: "2px",
  },
  uploadImg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "108px",
    height: "107px",
    background: "#C5C6C6",
    borderRadius: "2px",
  },
  popper: {
    background: "#FFFFFF !important",
    border: "1px solid #E8E8E8",
    boxSizing: "border-box",
    zIndex: 10,
    width: 146,
    left: "8.96%",
    right: "33.58%",
    top: "calc(50% - 160px/2 + 23px)",
  },
  li: {
    display: "flex",
    fontFamily: "AvenirNext-Medium",
    fontSize: "11.5px",
    lineHeight: "26.5px",
    color: "#000000",
  },
  line: {
    left: "0.75%",
    right: "0.75%",
    top: "61.35%",
    bottom: "37.68%",
    border: "1px solid #E8E8E8",
  },
  videoBtn: {
    "&.MuiButton-root": {
      padding: 0,
      minWidth: "unset",
    },
    "&.MuiButton-text": {
      padding: 0,
    },
  },
});
