import React, { FC } from "react";

type CampaignNameProps = {
  campaignName: string;
  setCampaignName: any;
  error?: string;
  setErrors?: any;
};

const CampaignName: FC<CampaignNameProps> = ({
  campaignName,
  setCampaignName,
  error,
  setErrors,
}) => {
  return (
    <div
      style={{
        marginBottom: "20px",
      }}
      className="campaign__container"
    >
      <span className="title__basic">Campaign Name</span>
      <span className="description__sub">
        Choose any descriptive name that makes sense to you.
      </span>
      <input
        value={campaignName}
        onChange={(e) => {
          setCampaignName(e.target.value);
          setErrors &&
            setErrors((errors: any) => ({
              ...errors,
              campaignName: "",
            }));
        }}
        type="text"
        placeholder="Campaign title here..."
        className="campaign__input"
      />
      {error && (
        <span style={{ color: "red", fontSize: 14, paddingLeft: 6 }}>
          {error}
        </span>
      )}
    </div>
  );
};

export default CampaignName;
