import { Checkbox, FormControl } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ReactComponent as CheckedIcon } from "static/media/checkedicon2.svg";
import { ReactComponent as UncheckedIcon } from "static/media/uncheckicon2.svg";

interface IProps {
  postIds: any[];
  postId: string;
  setPostIds: (date: any) => void;
  checkAll: any;
  uncheckAll: any;
  dailyCheck: boolean;
}

const CheckForm = ({
  postId,
  postIds,
  setPostIds,
  checkAll,
  uncheckAll,
  dailyCheck,
}: IProps) => {
  const [formData, setFormData] = useState(false);

  const [selectAll, setSelectAll] = useState(false);
  const [skip, setSkip] = useState(false);

  useEffect(() => {
    if (checkAll && postIds.includes(postId) && !dailyCheck) {
      setSelectAll(checkAll);
      setFormData(checkAll);
    } else if (uncheckAll && !checkAll && !dailyCheck) {
      setSelectAll(checkAll);
      if (!postIds.includes(postId)) {
        setFormData(false);
      }
    } else if (postIds.includes(postId)) {
      setFormData(true);
    } else {
      setFormData(false);
    }
  }, [checkAll, uncheckAll, formData, postIds, postId, skip, dailyCheck]);

  const handleChange = (e) => {
    const { checked } = e.target;

    // Check if the postId is already in postIds array
    const isPostIdExist = postIds.includes(postId);

    // If checked, add postId to the array, otherwise remove it
    if (!isPostIdExist) {
      setPostIds([postId, ...postIds]);
    } else if (!checked) {
      const newPostIds = postIds.filter((id) => id !== postId);
      setPostIds(newPostIds);
    }

    setFormData(checked);
    setSelectAll(checked);
  };

  return (
    <>
      <FormControl>
        <Checkbox
          checked={formData}
          style={{ color: "#000", borderRadius: "1px" }}
          icon={<UncheckedIcon />} // SVG icon for unchecked state
          checkedIcon={<CheckedIcon />} // SVG icon for checked state
          onChange={handleChange}
        />
      </FormControl>
    </>
  );
};

export default CheckForm;
