import React, { FC, useState } from "react";
import { DateTime } from "luxon";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Tooltip, Checkbox, ClickAwayListener } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";

import "@fullcalendar/daygrid/main.css";
// import CreateEditPost from "components/BePlanned/CreateEditPost";
import CreateEditPostV2 from "components/BePlanned/CreateEditPostV2";
import CustomFullCalendarStyled, {
  EventTitle,
} from "components/styled/CustomFullCalendar";
import { ScrollToTop } from "components/ScrollToTop";
import { firestore } from "../../firebase-tools/firebase";
import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import facebook from "static/media/fb-vector.png";
import twitter from "static/media/twitter-vector.png";
import instagram from "static/media/instagram-vector.png";
import pinterest from "static/media/pinterest.png";
import snapchat from "static/media/snapchat.png";
import youtubeshort from "static/media/youtube-shorts.png";
import tiktok from "static/media/tiktok.png";
import youtube from "static/media/youtube.png";
import triller from "static/media/triller.png";
import tumblr from "static/media/tumblr.png";
import defaultIcon from "static/media/universal-2.png";
import { ReactComponent as DoubleTicks } from "static/media/blackTicks.svg";
import { ReactComponent as SingleTick } from "static/media/singleTick.svg";
import { ReactComponent as AddIcon } from "static/media/add.svg";
import { useUser } from "UserContext";
import { IUser } from "interfaces/IUser";
import EventIcon from "./EventIcon";
import CreateEditEvent from "./CreateEditEvent";
import { useStyles } from "./styles";
import StatusTooltip from "components/Common/StatusTooltip";
import { ReactComponent as DropDownIcon } from "static/media/dropDown.svg";
import Status from "components/BePlanned/Status";
import { PostService } from "services/PostService";
import { success } from "utils/notification";
import EditPost from "components/BePlanned/EditPost";

interface IBePlannedMonthly {
  posts: () => any[];
  events: any;
  user: any;
  handleDateChange: (date) => void;
  handleSelectedPosts: (post: any, clear?: boolean) => void;
  selectedPosts: any[];
}
const icons = {
  facebook,
  twitter,
  instagram,
  pinterest,
  snapchat,
  youtubeshort,
  tiktok,
  triller,
  tumblr,
  youtube,
};

const BePlannedMonthlyV2: FC<IBePlannedMonthly> = ({
  posts,
  events,
  user,
  handleDateChange,
  handleSelectedPosts,
  selectedPosts,
}) => {
  const [selectedPostId, setSelectedPostId] = useState("id");
  const [selectedEventId, setSelectedEventId] = useState("id");
  const [authUser] = useUser() as Array<IUser>;
  const [postOpen, setPostOpen] = useState(false);
  const [eventOpen, setEventOpen] = useState(false);
  const [openSelectPost, setSelectOpen] = useState(false);
  const [internalStatusMonthly, setInternalStatusMonthly] = useState("");
  const [internalStatusWeekly, setInternalStatusWeekly] = useState("");
  const [curUser, setUser]: any = useState({});

  const classes = useStyles({});
  const [eventDate, setEventDate] = useState(undefined);

  let allPosts: any = posts().map((post) => {
    if (post.user_email !== curUser?.email) setUser({ email: post.user_email });
    const date = DateTime.fromSeconds(post.date.seconds).toJSDate();

    return {
      title: post.title || "multiChannel Post",
      date,
      className: "event",
      id: post.id,
      post,
    };
  });

  const multiple: any = [];

  const eventsArr = events.filter(
    (v, i, a) =>
      a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
  );

  let eventsData = eventsArr.map((event) => {
    const start = DateTime.fromSeconds(event.start_date.seconds).toJSDate();
    const end = event.end_date
      ? DateTime.fromSeconds(event.end_date.seconds).toJSDate()
      : start;

    const eventObj = {
      title: event.subject,
      start,
      end,
      className: "event",
      id: event.id,
      event,
      type: "event",
    };

    if (start !== end) {
      multiple.push(eventObj);
      return {};
    }
    return eventObj;
  });

  const calendarData: any = allPosts.sort((a, b) => a.date - b.date);
  calendarData.unshift(...multiple, ...eventsData);

  const handleEventClick = (event: any) => {
    if (event.post) {
      setSelectedPostId(event.post.id);
      setUser({ email: event.post.user_email });
      setTimeout(() => {
        setPostOpen(true);
      }, 10);
    } else {
      setSelectedEventId(event.event.id);
      setEventOpen(true);
    }
  };

  let postRef = firestore.collection("multichannel-posts").doc(selectedPostId);
  let post =
    useDocumentSubscriber({
      ref: postRef,
      deps: [selectedPostId],
    }).document || "";

  let eventRef = firestore.collection("events").doc(selectedEventId);
  let event =
    useDocumentSubscriber({
      ref: eventRef,
      deps: [selectedEventId],
    }).document || "";

  const newEvent = (date) => {
    setEventOpen(true);
    setEventDate(date);
  };
  const newPost = (date) => {
    setPostOpen(true);
    setEventDate(date);
  };

  const eventsContent = (eventInfo) => {
    return (
      <div
        style={{
          display: eventInfo.event.extendedProps.event ? "block" : "flex",
          width: "100%",
          alignItems: "center",
          marginTop: eventInfo.event.extendedProps.event ? "-2px" : "-7px",
          marginBottom: eventInfo.event.extendedProps.event ? 5 : 0,
        }}
      >
        {!eventInfo.event.extendedProps.event && (
          <Checkbox
            checked={selectedPosts.includes(
              eventInfo.event.extendedProps.post?.id
            )}
            size="small"
            className={classes.checkbx}
            onChange={() =>
              handleSelectedPosts(eventInfo.event.extendedProps.post?.id)
            }
            inputProps={{ "aria-label": "growth" }}
          />
        )}

        <Tooltip
          title={
            <div className={classes.tooltipTitle}>
              <div
                style={{
                  width: "100%",
                  textOverflow: "ellipsis",
                  textAlign: "center",
                  fontSize: eventInfo.event.extendedProps.post && "12px",
                }}
              >
                {eventInfo.event.title}
              </div>
              {eventInfo.event.extendedProps.post && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ marginTop: "10px" }}>
                    <StatusTooltip
                      status={
                        eventInfo.event.extendedProps.post
                          .internal_status_weekly
                      }
                      type="Post"
                    />
                  </div>
                  {/* <div style={{ marginTop: "4px" }}>
                    <StatusTooltip
                      status={
                        eventInfo.event.extendedProps.post
                          .internal_status_monthly
                      }
                      type="Monthly"
                    />
                  </div> */}
                </div>
              )}
              {eventInfo.event.extendedProps.event && (
                <div className={classes.eventIcon} style={{ marginTop: 4 }}>
                  <EventIcon
                    category={
                      eventInfo.event.extendedProps.event?.event_type || ""
                    }
                  />
                  <span style={{ marginRight: 3 }}>
                    {eventInfo.event.extendedProps.event?.event_type}
                  </span>
                </div>
              )}
            </div>
          }
          arrow
          interactive
          classes={{ tooltip: classes.tooltip, arrow: classes.tipArrow }}
        >
          <EventTitle
            event={!eventInfo.event.extendedProps.post}
            multipleDate={!!eventInfo.event.extendedProps.event?.end_date}
            style={{
              opacity:
                eventInfo.event.extendedProps.post?.is_visible_to_client !==
                "Yes"
                  ? 0.3
                  : undefined,
            }}
            onClick={() => handleEventClick(eventInfo.event.extendedProps)}
          >
            <div
              className={classes.eventIcon}
              style={{
                margin: "0px 3px",
              }}
            >
              {eventInfo.event.extendedProps.post?.channels.map(
                (channel, i) => (
                  <>
                    {icons[channel.toLowerCase()] && (
                      <img
                        key={`${i}-channel`}
                        className={classes.channelIcon}
                        style={{
                          width:
                            eventInfo?.event?.extendedProps?.post?.channels
                              ?.length > 3
                              ? "14px"
                              : "",
                          height:
                            eventInfo?.event?.extendedProps?.post?.channels
                              ?.length > 3
                              ? "15px"
                              : "",
                        }}
                        src={icons[channel.toLowerCase()] || defaultIcon}
                        alt=""
                      />
                    )}
                  </>
                )
              )}
              {eventInfo.event.extendedProps.event && (
                <EventIcon
                  category={
                    eventInfo.event.extendedProps.event?.event_type || ""
                  }
                />
              )}
            </div>
            <span className={classes.eventTitle}>{eventInfo.event.title}</span>
            <div style={{ marginRight: 3 }}>
              {eventInfo.event.extendedProps.post?.internal_status_weekly ===
              "Scheduled" ? (
                <DoubleTicks className={classes.checkMarks} />
              ) : null}
              {eventInfo.event.extendedProps.post?.internal_status_weekly ===
              "Client Approved" ? (
                <SingleTick className={classes.checkMark} />
              ) : null}
            </div>
          </EventTitle>
        </Tooltip>
      </div>
    );
  };

  const bulkUpdate = async (type) => {
    if (!selectedPosts.length) return;
    if (type === "monthly") {
      if (!internalStatusMonthly) return;
      Promise.all(
        selectedPosts.map(async (id) => {
          const Post = new PostService("multichannel-posts", id);

          Post.updatePost({
            internal_status_monthly: internalStatusMonthly,
          });
        })
      ).then(() => {
        setInternalStatusMonthly("");
        setSelectOpen(!openSelectPost);
        success("Monthly status updated!");
      });
    } else {
      if (!internalStatusWeekly) return;
      Promise.all(
        selectedPosts.map(async (id) => {
          const Post = new PostService("multichannel-posts", id);

          Post.updatePost({
            internal_status_weekly: internalStatusWeekly,
          });
        })
      ).then(() => {
        setInternalStatusWeekly("");
        setSelectOpen(!openSelectPost);
        success("Post Approval updated!");
      });
    }
  };

  return (
    <ScrollToTop>
      <CustomFullCalendarStyled>
        <div className="calendar-container">
          <FullCalendar
            plugins={[dayGridPlugin]}
            events={calendarData}
            eventContent={eventsContent}
            eventOrder={(a: any, b: any) => {
              const categories = [
                "",
                "Custom Event",
                "Community Management",
                "TV/Radio Promo",
                "Performance",
                "Rehearsal/Recording",
                "Release",
                "Viral Events",
                "Tentpole Idea",
              ];
              const sortCategories = (a, b) => {
                const indexA = categories.indexOf(a.event?.event_type);
                const indexB = categories.indexOf(b.event?.event_type);
                return indexB - indexA;
              };
              return (
                a.type?.localeCompare(b.type) ||
                b.end - b.start - (a.end - a.start) ||
                (a.event && b.event && sortCategories(a, b))
              );
            }}
            nextDayThreshold={"23:59"}
            dayMaxEvents={6}
            fixedWeekCount={false}
            firstDay={1}
            dayHeaderFormat={{ weekday: "long" }}
            datesSet={(arg) => {
              handleDateChange(arg);
            }}
            height={1560}
            // eventClick={(info) => handleEventClick(info.event)}
            dayCellContent={(info) => {
              return (
                <div className="cellTop">
                  <a
                    href="##"
                    className="fc-daygrid-day-number"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    {info.dayNumberText}
                  </a>
                  <div className="cellActions">
                    <EventTitle
                      onClick={() => newPost(info.date)}
                      style={{
                        color: "#abadad",
                        justifyContent: "space-evenly",
                        marginRight: 16,
                      }}
                    >
                      <AddIcon style={{ width: "14px", height: "14px" }} /> Post
                    </EventTitle>
                    <EventTitle
                      onClick={() => newEvent(info.date)}
                      style={{
                        color: "#abadad",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <AddIcon style={{ width: "14px", height: "14px" }} />{" "}
                      Event
                    </EventTitle>
                  </div>
                </div>
              );
            }}
          />
        </div>

        <div className="popup-container" id="popup-container">
          {openSelectPost && (
            <ClickAwayListener
              onClickAway={() => setSelectOpen(!openSelectPost)}
            >
              <div
                style={{
                  position: "absolute",
                  bottom: 44,
                  marginLeft: 20,
                  background: "#fff",
                  borderRadius: 15,
                  padding: 20,
                  boxShadow: "0 5px 10px 4px rgb(0 0 0 / 10%)",
                }}
              >
                <Status
                  internalStatusMonthly={internalStatusMonthly}
                  setInternalStatusMonthly={setInternalStatusMonthly}
                  internalStatusWeekly={internalStatusWeekly}
                  setInternalStatusWeekly={setInternalStatusWeekly}
                  monthlyCalendar={true}
                  save={bulkUpdate}
                  disabled={!selectedPosts.length}
                  message={
                    !selectedPosts.length
                      ? "No checkboxes currently selected"
                      : !internalStatusMonthly || !internalStatusWeekly
                      ? "No status currently selected"
                      : ""
                  }
                />
              </div>
            </ClickAwayListener>
          )}

          <div
            className="status-popup"
            onClick={() => setSelectOpen(!openSelectPost)}
          >
            <span>Set Status</span>
            <div
              style={{
                transform: openSelectPost ? "rotate(180deg)" : "",
                marginTop: openSelectPost ? 1 : "-4px",
              }}
            >
              <DropDownIcon />
            </div>
          </div>
        </div>
      </CustomFullCalendarStyled>
      {authUser.role !== "viewerAdmin" && (
        <div>
          {post !== "" && (post.body || !!post.image_url || !!post.video_url)
            ? selectedPostId !== "id" &&
              !eventDate &&
              curUser.email && (
                <EditPost
                  edit="edit"
                  role={authUser.role}
                  post={post}
                  user={user}
                  externalOpen={postOpen}
                  setExternalOpen={setPostOpen}
                  calenderView={true}
                  clearRefresh={true}
                />

                // <CreateEditPostV2
                //   edit="edit"
                //   role={authUser.role}
                //   post={post}
                //   user={user}
                // />
                // <></>
              )
            : null}
          {event !== "" && event.subject
            ? selectedEventId !== "id" &&
              !eventDate && (
                <CreateEditEvent
                  edit="edit"
                  event={event}
                  user={user}
                  externalOpen={eventOpen}
                  setExternalOpen={setEventOpen}
                  externalDate={eventDate}
                  setExternalDate={setEventDate}
                />
              )
            : null}
          {eventDate && (
            <CreateEditEvent
              create="create"
              user={user}
              externalOpen={eventOpen}
              setExternalOpen={setEventOpen}
              externalDate={eventDate}
              setExternalDate={setEventDate}
            />
          )}
          {eventDate && (
            <CreateEditPostV2
              create="create"
              role={authUser.role}
              user={user}
              monthly={`monthly-${uuidv4()}`}
              externalOpen={postOpen}
              setExternalOpen={setPostOpen}
              externalDate={eventDate}
              setExternalDate={setEventDate}
              calenderView={true}
            />
          )}
        </div>
      )}
    </ScrollToTop>
  );
};

export { BePlannedMonthlyV2 };
