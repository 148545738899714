import { DateTime } from "luxon";

import { historicalData } from "../requests/historicalData";

export const fetchHistoricalData = async (data, errors, User) => {
  const facebook_username = data.facebook_username;
  const instagram_username = data.instagram_username;
  const twitter_username = data.twitter_username;
  let date: any = DateTime.fromJSDate(new Date());
  date = date.minus({ days: 1 });
  const startDate = new Date(date.minus({ days: 13 })).toISOString();
  const endDate = new Date(date).toISOString();

  if (facebook_username && instagram_username) {
    const res = await historicalData(
      startDate,
      endDate,
      "instagram",
      facebook_username
    );
    if (res.error) {
      errors.igError = "Getting historical data failed.";
    } else {
      errors.igError = "";
    }
  }
  if (instagram_username && !facebook_username) {
    const res = await historicalData(
      startDate,
      endDate,
      "instagram",
      instagram_username
    );
    if (res.error) {
      errors.igError = "Getting historical data failed.";
    } else {
      errors.igError = "";
    }
  }
  if (facebook_username) {
    const res = await historicalData(
      startDate,
      endDate,
      "facebook",
      facebook_username
    );
    if (res.error) {
      errors.fbError = "Getting historical data failed.";
    } else {
      errors.fbError = "";
    }
  }
  if (twitter_username) {
    const res = await historicalData(
      startDate,
      endDate,
      "twitter",
      twitter_username
    );
    if (res.error) {
      errors.twError = "Getting historical data failed.";
    } else {
      errors.twError = "";
    }
  }
  await User.updateUser({
    "facebook.error": facebook_username ? errors.fbError : "",
    "instagram.error": instagram_username ? errors.igError : "",
    "twitter.error": twitter_username ? errors.twError : "",
    "facebook.username": facebook_username || "",
    "instagram.username": instagram_username || "",
    "twitter.username": twitter_username || "",
  });
  return errors;
};
