import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { noStyleList } from "components/Common/SingleSelect";
import { getBackground } from "components/BePlannedMonthly/EventIcon";

export const useStyles = makeStyles({
  dot: {
    marginRight: "3px",
    width: "4px",
    height: "4px",
    borderRadius: "50%",
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
});

const StatusTooltip: FC<{
  status: any;
  type: string;
}> = ({ status, type }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <span style={{ color: "#101010", marginRight: "3px" }}>
        {type} Approval
      </span>
      <span
        style={{
          background:
            noStyleList.includes(status) || type === "Monthly"
              ? "#fff"
              : getBackground(status),
        }}
        className={classes.dot}
      ></span>
      <span style={{ color: "#fff" }}>
        {status === "Client Approved" && type === "Monthly"
          ? "Approved"
          : status}
      </span>
    </div>
  );
};

export default StatusTooltip;
