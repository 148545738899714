export const checkPermission = async (username: string, endpoint: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_FUNCTIONS_BASE_URL}${endpoint}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username }),
    }
  );
  return await response.json();
};
