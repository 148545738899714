import { Avatar, Grid } from "@material-ui/core";
import React from "react";
import { ReactComponent as OpenLink } from "static/media/majesticons_open.svg";
import { ReactComponent as Download } from "static/media/material-symbols_download.svg";
interface IMediaItemProfileSection {
  avatar: string;
  date: any;
  accountName: string;
  link: string;
  generateScreenshot: () => void;
}

const MediaItemProfileSection = ({
  avatar,
  date,
  accountName,
  link,
  generateScreenshot,
}: IMediaItemProfileSection) => {
  return (
    <div>
      <Grid
        container
        xs
        style={{
          marginTop: 16,
          marginBottom: 10,
        }}
      >
        <Grid xs={2} md={2}>
          <Avatar
            src={avatar}
            style={{
              width: 40,
              height: 40,
              borderRadius: 40,
            }}
          />
        </Grid>
        <Grid xs={8} md={8}>
          <Grid
            xs
            container
            style={{
              color: "#000",
              fontFamily: "Avenir",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 800,
              lineHeight: "20px",
            }}
          >
            {accountName}
          </Grid>
          <Grid
            xs
            container
            style={{
              overflow: "hidden",
              color: "#000",
              textOverflow: "ellipsis",
              fontFamily: "Avenir",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 350,
              lineHeight: "20px",
            }}
          >
            {date}
          </Grid>
        </Grid>
        <Grid
          xs={1}
          md={1}
          style={{
            padding: 10,
          }}
        >
          <a href={link} target="_blank" rel="noopener noreferrer">
            <OpenLink />
          </a>
        </Grid>
        <Grid
          xs={1}
          md={1}
          style={{
            padding: 10,
          }}
        >
          <Download
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setTimeout(() => {
                generateScreenshot();
              }, 500);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MediaItemProfileSection;
