import { Grid } from "@material-ui/core";
import React, { FC, useState } from "react";
import { PageType } from "types/PageType";
import useCollectionSubscriber from "hooks/useCollectionSubscriber";
import { INotification, NotificationStatus } from "interfaces/INotification";
import { useStylesNot } from "containers/Dashboard/Notification/styles";
import { firestore } from "firebase-tools/firebase";
import AllNotifications from "./AllNotifications";
import ArchivedNotifications from "./ArchivedNotifications";
import { bulkMarkNotificationStatus } from "helpers/notification/updateNotificationStatus";

const toolbarSpacing = { padding: "5px" };

const Notifications: FC<PageType> = ({ location }) => {
  const classes = useStylesNot({});

  const { notifyType2, activeNotifyType2 } = classes;
  const [showAll, setShowAll] = useState("all");

  const queries = [
    firestore
      .collection("notifications")
      .where("status", "==", "unread")
      .orderBy("created_at", "desc"),
  ];
  let notificationList = useCollectionSubscriber<INotification>({ queries })
    .documents;

  return (
    <div>
      <Grid container>
        <h1 style={toolbarSpacing}>Notifications</h1>
        <Grid item container style={{ backgroundColor: "#FFFFFF" }}>
          {/* <Grid item container className={classes.universalSpace}></Grid> */}
          <Grid item container className={classes.universalSpaceleftRight}>
            <Grid
              item
              xs={6}
              md={6}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontWeight: 500,
                  fontSize: 21,
                  textAlign: "center",
                }}
                className={showAll === "all" ? activeNotifyType2 : notifyType2}
                onClick={(e) => {
                  e.preventDefault();
                  setShowAll("all");
                }}
              >
                All{" "}
                <span className={classes.allCount}>
                  {notificationList.length}
                </span>
              </span>

              {/* <span
                style={{
                  fontWeight: 900,
                  fontSize: 21,
                  textAlign: "center",
                }}
                className={
                  showAll === "mentions" ? activeNotifyType2 : notifyType2
                }
                onClick={(e) => {
                  e.preventDefault();
                  setShowAll("mentions");
                }}
              >
                Mentions
              </span> */}
              <span
                style={{
                  fontWeight: 900,
                  fontSize: 21,
                  textAlign: "center",
                }}
                className={
                  showAll === "archived" ? activeNotifyType2 : notifyType2
                }
                onClick={(e) => {
                  e.preventDefault();
                  setShowAll("archived");
                }}
              >
                Archived
              </span>
            </Grid>

            <Grid
              item
              xs={6}
              md={6}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontWeight: 900,
                  fontSize: 19,
                  cursor: "pointer",
                  ...(notificationList.length < 1
                    ? { opacity: 0.8, color: "#7F7F7F" }
                    : {}),
                }}
                onClick={(e) => {
                  e.preventDefault();
                  if (notificationList.length < 1) {
                    return false;
                  }
                  bulkMarkNotificationStatus(
                    NotificationStatus.read,
                    notificationList
                  );
                }}
              >
                Mark all as read
              </p>
            </Grid>
          </Grid>
          {showAll === "all" && <AllNotifications />}
          {showAll === "archived" && <ArchivedNotifications />}
        </Grid>
      </Grid>
    </div>
  );
};

export default Notifications;
