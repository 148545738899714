import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: "20px",
  },

  pageTitle: {
    height: "auto",
    fontStyle: "normal",
    fontSize: "30px",
    lineHeight: "25px",
    color: "#101010",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontFamily: "'Avenir Black', sans-serif",
    padding: "40px 0 20px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 0 0px 0",
      width: "100%",
      alignItems: "left",
      marginTop: 40,
    },
  },
  pageTitleText: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  datePrint: {
    display: "flex",
    alignItems: "flex-end",

    "& > :first-child": {
      marginBottom: "4px",
    },
  },
  selector: {
    display: "flex",
    alignItems: "flex-end",
  },
  icon: {
    width: "44.75px",
    height: "50px",
    marginRight: "10px",
    position: "relative",
    top: "15px",
  },

  cardContent: {
    fontFamily: "AvenirNext-Regular",
    fontSize: "15px",
    lineHeight: "25px",
  },

  title: {
    fontFamily: "AvenirNext-DemiBold",
    fontSize: "15px",
  },

  subheader: {
    fontFamily: "AvenirNext-Regular",
    fontSize: "12px",
  },

  media: {
    height: 0,
    paddingTop: "56.25%",
  },

  avatar: {
    minWidth: "60px",
    minHeight: "60px",
    mixBlendMode: "normal",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "center",
      // width: "100%",
    },
  },

  AvatarIcon: {
    width: "80px",
    height: "80px",
    flexShrink: 0,
    background: "#000",
  },
  AvatarSvg: {
    width: "80px",
    height: "80px",
    flexShrink: 0,
    mixBlendMode: "normal",
    background: "#000",
  },
  avCon: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
  },
  userName: {
    color: "#000",
    fontFamily: "Avenir",
    fontSize: "27px",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "32px",
    letterSpacing: "0.5px",
    display: "flex",
    flexDirection: "column",
    paddingTop: 20,
    [theme.breakpoints.down("sm")]: {
      alignItems: "left",
      justifyContent: "center",
      width: "100%",
    },
  },
  dateLabel: {
    fontSize: "15px",
    lineHeight: "22px",
    fontFamily: "'Avenir Black', sans-serif",
    color: "#000000",
    marginRight: 15,
  },

  editIcon: {
    width: "15px",
    height: "15px",
    fill: "#5c5c5c",
  },
  editLink: {
    textDecoration: "none",
    marginLeft: "6px",
    cursor: "pointer",
  },
  profileUserToolbar: {
    display: "flex",
    alignItems: "right",
    justifyContent: "space-between",
    paddingTop: 20,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "normal",
    },
  },
  btn: {
    width: "159px",
    height: "45px",
    background: "#000000",
    borderRadius: "15px",
    textTransform: "capitalize",
    fontFamily: "'Avenir Black', sans-serif",
    fontSize: "15px",
    fontWeight: 900,
    fontStyle: "normal",
    lineHeight: "22px",
    color: "#FFFFFF",
    marginBottom: "5px",
    justifyContent: "center",
    alignItems: "center",

    "&:hover": {
      backgroundColor: "#000000",
      opacity: 0.85,
    },

    "&:disabled": {
      color: "#fff !important",
      opacity: 0.5,
      cursor: "not-allowed !important",
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "normal",
    },
  },
}));
