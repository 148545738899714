import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

import { ReactComponent as CautionWarning } from "static/media/cautionWarn.svg";

export const useStyles = makeStyles((theme) => ({
  clientValue: {
    height: "100%",
    top: "calc(50% - 22px/2 - 14px)",
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: "15px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#000",
  },
  row: {
    height: "75px",
  },
  metricCell: {
    width: "154px",
    border: "1px solid #9797971f",
  },
  clientName: {
    width: "230px",
    border: "1px solid #9797971f",
  },
  sticky: {
    left: 0,
    zIndex: 2,
    position: "sticky",
    background: "#fff",
    borderLeft: "0px",
  },
}));

export const CautionWarningIcon = styled(CautionWarning)``;
