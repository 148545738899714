import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

export default (name: string, className: string = "container") => {
  const page: any = document.getElementById(className);

  html2canvas(page, {
    allowTaint: true,
    backgroundColor: "#f2f2f2",
    scale: 2,
    useCORS: true,
  }).then((canvas) => {
    const canvasImageWidth = canvas.width;
    const canvasImageHeight = canvas.height;
    const topLeftMargin = 0;
    const pdfWidth = canvasImageWidth + topLeftMargin * 2;
    const pdfHeight = pdfWidth * 1.5 + topLeftMargin * 2;
    const totalPDFPages = Math.ceil(canvasImageHeight / pdfHeight) - 1;
    canvas.getContext("2d");
    const imgData = canvas.toDataURL("image/jpeg", 1.0);
    const pdf = new jsPDF("p", "pt", [pdfWidth, pdfHeight]);
    pdf.setFillColor(242, 242, 242);

    pdf.rect(
      topLeftMargin,
      topLeftMargin,
      canvasImageWidth,
      pdf.internal.pageSize.getHeight(),
      "F"
    );
    pdf.addImage(
      imgData,
      "JPG",
      topLeftMargin,
      topLeftMargin,
      canvasImageWidth,
      canvasImageHeight
    );
    for (let i = 1; i <= totalPDFPages; i++) {
      pdf.addPage([pdfWidth, pdfHeight], "p");
      pdf.setFillColor(242, 242, 242);

      pdf.rect(
        topLeftMargin,
        topLeftMargin,
        canvasImageWidth,
        pdf.internal.pageSize.getHeight(),
        "F"
      );
      pdf.addImage(
        imgData,
        "JPG",
        topLeftMargin,
        -(pdfHeight * i) + topLeftMargin * 4,
        canvasImageWidth,
        canvasImageHeight
      );
    }
    pdf.save(`${name}.pdf`);
  });
};
