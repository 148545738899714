import { Grid } from "@material-ui/core";
import React from "react";

interface IProp {
  role: string;
  impression: any;
  engagedUser: any;
  engagementRate: any;
  reach?: string | number;
  total_interactions?: number | string;
}
const MediaItemDataCard = ({
  role,
  impression,
  engagedUser,
  engagementRate,
  reach,
  total_interactions,
}: IProp) => {
  return (
    <div>
      {role !== "instagram" && (
        <Grid item xs container>
          <Grid xs={4} md={4}>
            <Grid
              xs
              container
              style={{
                color: "#000",
                textAlign: "center",
                fontFamily: "Avenir",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 900,
                lineHeight: "24px",
                letterSpacing: "0.5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {impression}
            </Grid>
            <Grid
              xs
              container
              style={{
                color: "#000",
                fontFamily: "Avenir",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 350,
                lineHeight: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {role === "tikTok" ? "Shares" : "Impressions"}
            </Grid>
          </Grid>
          <Grid xs={4} md={4}>
            <Grid
              xs
              container
              style={{
                color: "#000",
                textAlign: "center",
                fontFamily: "Avenir",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 900,
                lineHeight: "24px",
                letterSpacing: "0.5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {engagedUser}
            </Grid>
            <Grid
              xs
              container
              style={{
                color: "#000",
                fontFamily: "Avenir",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 350,
                lineHeight: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Engaged Users
            </Grid>
          </Grid>
          <Grid xs={4} md={4}>
            <Grid
              xs
              container
              style={{
                color: "#000",
                textAlign: "center",
                fontFamily: "Avenir",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 900,
                lineHeight: "24px",
                letterSpacing: "0.5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {engagementRate}
            </Grid>
            <Grid
              xs
              container
              style={{
                color: "#000",
                fontFamily: "Avenir",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 350,
                lineHeight: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Engagement Rate
            </Grid>
          </Grid>
        </Grid>
      )}
      {role === "instagram" && (
        <Grid item xs container>
          <Grid xs={4} md={4}>
            <Grid
              xs
              container
              style={{
                color: "#000",
                textAlign: "center",
                fontFamily: "Avenir",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 900,
                lineHeight: "24px",
                letterSpacing: "0.5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {impression}
            </Grid>
            <Grid
              xs
              container
              style={{
                color: "#000",
                fontFamily: "Avenir",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 350,
                lineHeight: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Impressions
            </Grid>
          </Grid>
          <Grid xs={4} md={4}>
            <Grid
              xs
              container
              style={{
                color: "#000",
                textAlign: "center",
                fontFamily: "Avenir",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 900,
                lineHeight: "24px",
                letterSpacing: "0.5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {engagementRate || 0}
            </Grid>
            <Grid
              xs
              container
              style={{
                color: "#000",
                fontFamily: "Avenir",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 350,
                lineHeight: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Engagement Rate
            </Grid>
          </Grid>
          <Grid xs={4} md={4}>
            <Grid
              xs
              container
              style={{
                color: "#000",
                textAlign: "center",
                fontFamily: "Avenir",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 900,
                lineHeight: "24px",
                letterSpacing: "0.5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {total_interactions || 0}
            </Grid>
            <Grid
              xs
              container
              style={{
                color: "#000",
                fontFamily: "Avenir",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 350,
                lineHeight: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Total Interactions
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default MediaItemDataCard;
