/**
 * @prettier
 */
import React, { useState, FunctionComponent, Fragment } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Modal, Switch } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { UserService } from "services/UserService";
import { useStyles } from "./styles";
import { IUser } from "interfaces/IUser";
import { useUser } from "UserContext";
import { SendButton, CancelButton } from "components/styled/Common";

interface Props {
  user: any;
  isActivated: boolean;
  type: string;
  email?: string;
  setEmails?: any;
}

export const ToggleActivate: FunctionComponent<Props> = (props) => {
  const [isActive, setActive] = useState(props.isActivated);
  const [isOpen, handleOpen] = useState(false);
  const classes = useStyles({});
  const rootRef = React.useRef(null);
  const [authUser] = useUser() as Array<IUser>;

  const updateIsActive = async (event: React.FormEvent, user: any) => {
    event.preventDefault();
    await setActive(!isActive);
    const userDoc = new UserService(user.id);
    handleOpen(!isOpen);
    const emails = user.secondaryEmails || [];
    if (props.type === "client") {
      const deactivatedEmails = emails.map((email: any) => {
        email.isActivated = !isActive;
        return email;
      });
      await userDoc.updateUser({
        isActivated: !isActive,
        secondaryEmails: deactivatedEmails,
      });
    }
    if (props.type === "email") {
      const deactivatedEmails = emails.map((email: any) => {
        if (email.email === props.email) {
          email.isActivated = !isActive;
        }
        return email;
      });
      props.setEmails(deactivatedEmails);
      await userDoc.updateUser({
        secondaryEmails: deactivatedEmails,
      });
    }
  };

  const handleChange = () => {
    return handleOpen(!isOpen);
  };

  const handleClose = (e) => {
    if (e.key === "Escape") {
      handleOpen(false);
    }
  };

  return (
    <Fragment>
      <FormControlLabel
        control={
          <Switch
            checked={isActive}
            onChange={handleChange}
            name="Activate"
            classes={{
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
            disabled={authUser.role === "viewerAdmin"}
          />
        }
        classes={{
          label: classes.label,
        }}
        label={isActive ? "Active" : "Inactive"}
      />
      <Modal
        open={isOpen}
        container={() => rootRef.current}
        onClose={handleClose}
      >
        <div className={classes.modal}>
          <div className={classes.top}>
            <h3 className={classes.title}>Confirmation needed</h3>
            <CloseIcon className={classes.icon} onClick={handleChange} />
          </div>
          <p>
            You are about to {isActive ? "deactivate" : "activate"}{" "}
            {props.type === "client" ? "a client " : "an authentication "}
            account. Are you sure?
          </p>
          <div className={classes.line}></div>
          <div className={classes.buttonContainer}>
            <CancelButton
              disableRipple
              type="button"
              onClick={handleChange}
              style={{ right: "13%" }}
            >
              Cancel
            </CancelButton>
            <SendButton
              type="button"
              onClick={(e) => updateIsActive(e, props.user)}
              disableRipple
            >
              {isActive ? "Deactivate" : "Activate"}
            </SendButton>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};
