import React, { FC } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import LoaderStyles from "./styles";

interface ILoader {
  width?: string;
  marginTop?: string;
}

export const Loader: FC<ILoader> = ({ width, marginTop }) => {
  return (
    <LoaderStyles width={width} marginTop={marginTop}>
      <CircularProgress
        style={{
          color: "#1D4E89",
        }}
      />
    </LoaderStyles>
  );
};
