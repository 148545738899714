import React, { FC } from "react";
import { navigate, RouteComponentProps } from "@reach/router";

import { useUser } from "UserContext";
import NotFound from "components/NotFound";

const PrivateRoute: FC<RouteComponentProps> = ({ children }) => {
  const [user]: any = useUser();

  if (!Object.keys(user)?.length) {
    navigate("/sign-in");
    return null;
  }

  if (window.location.pathname === "/admin-settings") {
    if (user?.role === "superAdmin") {
      return <div>{children}</div>;
    } else {
      return <NotFound />;
    }
  } else {
    if (user?.role && user?.role === "user") {
      return <NotFound />;
    } else {
      return <>{children}</>;
    }
  }
};

export default PrivateRoute;
