import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  channelIcon: {
    width: "24px",
    height: "25px",
    margin: "3px",
  },
  eventTitle: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "85%",
    textAlign: "left",
    fontSize: "14px",
  },
  checkMarks: {
    marginBottom: "-5px",
    width: "15px",
    height: "15px",
  },
  checkMark: {
    marginBottom: "-5px",
    height: "15px",
  },
  tooltip: {
    fontFamily: "Avenir Book, sans-serif",
    fontSize: 10,
    backgroundColor: "#ABADAD",
  },
  tipArrow: {
    color: "#ABADAD",
  },
  tooltipTitle: {
    whiteSpace: "pre-line",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 9,
    lineHeight: "10px",
    padding: "5px",
  },

  archiveLabel: {
    fontFamily: "'Avenir Book', sans-serif !important",
    lineHeight: "22px",
    textTransform: "capitalize",
    textDecoration: "underline",
    color: "#abadad",
  },
  printMode: {
    position: "relative",
    textAlign: "end",
  },
  avatar: {
    minWidth: "60px",
    minHeight: "60px",
    mixBlendMode: "normal",
  },
  name: {
    textAlign: "center",
    fontFamily: "Avenir Black, sans-serif",
    fontSize: "18px",
    marginLeft: 20,
  },
  AvatarIcon: {
    width: "40px",
    height: "40px",
    background: "#000",
  },
  AvatarSvg: {
    minWidth: "60px",
    minHeight: "60px",
    mixBlendMode: "normal",
    background: "#000",
  },
  eventIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  info: {
    display: "flex",
    justifyContent: "space-around",
    fontFamily: "Avenir Book, sans-serif",
    fontSize: 12,
    backgroundColor: "#ABADAD",
    minWidth: 80,
  },
  arrow: {
    color: "#ABADAD",
  },
  checkbx: {
    color: "#000 !important",
    marginLeft: "-5px",
    marginRight: "-2px",
    height: 30,
    width: 30,

    "&:hover": {
      background: "rgba(0,0,0,0.3) !important",
    },
  },
});
