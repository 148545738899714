export const autoDM = async ({ id }) => {
  const rawResponse = await fetch(
    `${process.env.REACT_APP_FUNCTIONS_BASE_URL}twitter-autoDMLaunch`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
      }),
    }
  );

  const result = await rawResponse.json();

  return result;
};
