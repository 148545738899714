import { firestore } from "firebase-tools/firebase";

const queries = (authUser) => {
  const twitterQueries = [
    firestore
      .collection("twitter-campaigns")
      .where(
        "username",
        "==",
        authUser.lastClientSelection?.twitter?.username || ""
      ),
    firestore
      .collection("twitter-auto-poster")
      .where(
        "username",
        "==",
        authUser.lastClientSelection?.twitter?.username || ""
      ),
    firestore
      .collection("twitter-auto-dm")
      .where(
        "username",
        "==",
        authUser.lastClientSelection?.twitter?.username || ""
      ),
    firestore
      .collection("twitter-auto-liker")
      .where(
        "username",
        "==",
        authUser.lastClientSelection?.twitter?.username || ""
      ),
  ];
  const instagramQueries = [
    firestore
      .collection("instagram-autofollow-campaigns")
      .where("client", "==", authUser.lastClientSelection?.id || ""),
    firestore
      .collection("instagram-auto-liker")
      .where(
        "username",
        "==",
        authUser.lastClientSelection?.instagram?.username || ""
      ),
    firestore
      .collection("instagram-autocommenter")
      .where(
        "username",
        "==",
        authUser.lastClientSelection?.instagram?.username || ""
      ),
  ];
  const facebookQueries = [
    firestore
      .collection("facebook-auto-liker")
      .where(
        "username",
        "==",
        authUser.lastClientSelection?.facebook?.username || ""
      ),
    firestore
      .collection("facebook-group-scrapper")
      .where(
        "username",
        "==",
        authUser.lastClientSelection?.facebook?.username || ""
      ),
    firestore
      .collection("facebook-auto-dm")
      .where(
        "username",
        "==",
        authUser.lastClientSelection?.facebook?.username || ""
      ),
  ];

  const twitterScrappersQueries = [firestore.collection("twitter-campaigns")];
  const instagramScrapperQueries = [
    firestore.collection("instagram-autofollow-campaigns"),
    firestore.collection("instagram-comments-extractor"),
  ];
  const facebookScrapperQueries = [
    firestore.collection("facebook-group-scrapper"),
  ];

  return {
    facebookQueries,
    twitterQueries,
    instagramQueries,
    facebookScrapperQueries,
    twitterScrappersQueries,
    instagramScrapperQueries,
  };
};

export default queries;
