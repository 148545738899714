import { Tooltip } from "@material-ui/core";
import React from "react";

interface LayoutProps {
  children: React.ReactElement<any, any>;
  title: string;
}

const CustomToolTip = ({ children, title }: LayoutProps) => {
  return (
    <>
      <Tooltip
        title={<span style={{ fontSize: "16px" }}> {title} </span>}
        arrow
        interactive
      >
        {React.cloneElement(children, {
          style: { cursor: "pointer" },
        })}
      </Tooltip>
    </>
  );
};

export default CustomToolTip;
