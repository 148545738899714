import styled from "styled-components";

const ClientInfo = styled.div`
  width: 100%;
  .navigate {
    font-size: 15px;
    font-family: "Avenir Book", sans-serif;
    line-height: 22px;
    margin-top: 15px;
    display: grid;
    grid-gap: 0.5rem;
    width: fit-content;
  }

  .socialMedia {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    height: fit-content;
  }

  .bePlannedContainer {
    display: flex;
    justify-content: flex-end;
  }

  @media only screen and (min-width: 1080px) {
    .bePlannedContainer {
      margin-top: 18px;
    }
  }

  @media only screen and (max-width: 1280px) {
    .navigate {
      margin-top: 20px !important;
    }

    .socialMedia,
    .bePlannedContainer {
      justify-content: flex-start;
    }

    form > :nth-child(2) > :nth-child(3) > :nth-child(1) {
      margin-right: 20px;
    }
  }
  @media only screen and (max-width: 450px) {
    form > :nth-child(2) > :nth-child(3) > :nth-child(1) {
      margin-right: 0px;
    }
  }
  @media only screen and (max-width: 400px) {
    form > :nth-child(1) button {
      width: 197px !important;
    }
  }
`;

export default ClientInfo;
