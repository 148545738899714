/**
 * @prettier
 */
import { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import { auth } from "firebase-tools/firebase";
import { useUser } from "UserContext";
import { LocationType } from "types/PageType";
import { UserService } from "services/UserService";

export type AuthHookType = {
  location?: LocationType;
};

enum fallbackUrl {
  APP = "/",
  SIGNIN = "/sign-in",
  USERMANAGEMENT = "/admin-panel",
  SIGNUP = "/sign-up",
  FORGOTPASS = "/forgot-password",
}

export const useAuthHook = ({ location }: AuthHookType) => {
  const { pathname } = location as LocationType;
  const [, setUser] = useUser() as any;
  const [isAllowRender, setIsAllowRender] = useState(false);

  useEffect(() => {
    auth.onAuthStateChanged(async (userAuth: any) => {
      if (userAuth) {
        const User = new UserService(userAuth.uid);
        const user: any = await User.getUserByDoc().catch(setIsAllowRender);

        if (!!!user?.email) {
          signOut();
        }

        if (user?.role === "user") {
          await signOut();
        }

        setUser(user);
      }
      setIsAllowRender(true);
      if (
        !userAuth &&
        pathname !== fallbackUrl.SIGNUP &&
        pathname !== fallbackUrl.FORGOTPASS
      ) {
        navigate(fallbackUrl.SIGNIN);
      } else if (
        userAuth &&
        userAuth.email &&
        (pathname === fallbackUrl.SIGNIN ||
          pathname === fallbackUrl.SIGNUP ||
          pathname === fallbackUrl.APP)
      ) {
        document.body.classList.remove("body-signin");
        navigate(fallbackUrl.USERMANAGEMENT);
      }
    });
  }, [pathname, setUser]);

  return isAllowRender;
};

export const signOut = async () => {
  await auth.signOut();
};
