import { IUser } from "interfaces/IUser";
import { UserService } from "services/UserService";

export const updateUserData = async (user: IUser) => {
  const User = new UserService(user.id);

  return User.updateUser(user)

    .then((res) => {})
    .catch((err) => {});
};
