/**
 * @prettier
 */

import React, { FunctionComponent, ChangeEvent } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ReactComponent as AvatarIcon } from "static/media/avatarIcon.svg";
import { makeStyles } from "@material-ui/core/styles";

type nameType = {
  id?: string;
  value?: string;
  autoFocus?: boolean;
  helperText?: any;
  name?: string;
  placeholder?: string;
  label?: string;
  color?: string;
  variant?: any;
  icon?: boolean;
  error?: any;
  borderColor?: string;
  marginTop?: string;
  spanMargin?: string;
  inputSpan?: string;
  width?: string;
  onChangeHandler?: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (e) => any;
  iconWidth?: string;
  readonly?: boolean;
};

const useStyles = makeStyles({
  text: {
    width: (props: any) => props.width,
    maxWidth: "450px",
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: (props: any) => props.borderColor,
        borderWidth: "1px",
      },
    },
    "&.MuiFormControl-marginNormal": {
      marginTop: (props: any) => props.marginTop,
    },
    "& .MuiInputBase-input": {
      height: "45px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0",
    },
    "& .MuiInputAdornment-positionStart": {
      marginRight: (props: any) => props.spanMargin,
    },
  },
  backgroundColor: {
    backgroundColor: "#fff",
    height: "45px",
    borderRadius: "10px",
    fontFamily: "'Avenir Book', sans-serif",
  },
  label: {
    color: (props: any) => props.color,
    top: -10,
    fontFamily: "'Avenir Book', sans-serif",
  },
  helperText: {
    color: "rgb(209, 44, 0)",
    fontSize: "0.8rem",
    textAlign: "center",
  },
  svg: {
    "& path": {
      fill: "#ABADAD !important",
    },
    "& text": {
      fill: "#ABADAD !important",
    },
    width: (props: any) => props.iconWidth,
    height: (props: any) => props.iconWidth,
  },
});

const Name: FunctionComponent<nameType> = ({
  value = "",
  id = "filled-textarea",
  autoFocus = false,
  helperText = "",
  name = "name",
  label = "Client Name",
  color = "#fff",
  width = "75%",
  icon = true,
  error = false,
  placeholder = "",
  inputSpan = "",
  onChangeHandler,
  handleBlur,
  borderColor = "#000",
  marginTop = "16px",
  spanMargin = "8px",
  variant = "standard",
  iconWidth = "29px",
  readonly = false,
}) => {
  const classes = useStyles({
    color,
    width,
    borderColor,
    marginTop,
    spanMargin,
    iconWidth,
  });
  return (
    <TextField
      id={name}
      name={name}
      label={label}
      margin="normal"
      value={value}
      required
      fullWidth
      error={error}
      placeholder={placeholder}
      helperText={helperText}
      FormHelperTextProps={{
        className: classes.helperText,
      }}
      className={classes.text}
      InputLabelProps={{
        className: classes.label,
      }}
      autoComplete="name"
      autoFocus={autoFocus}
      onChange={onChangeHandler}
      onBlur={handleBlur}
      variant={variant}
      InputProps={{
        className: classes.backgroundColor,
        startAdornment: (
          <InputAdornment position="start">
            {icon && <AvatarIcon className={classes.svg} />}
            {inputSpan}
          </InputAdornment>
        ),
        readOnly: readonly,
      }}
    />
  );
};

export default Name;
