import React, { FC, useState } from "react";
import { Menu } from "@material-ui/icons";
import styled from "styled-components";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

interface IDownloadActions {
  generateScreenshot: () => void;
  color?: string;
}

const DropDown = styled.div`
  background: #fff;
  position: absolute;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 3px;
  right: 6px;
  min-width: 110px;
  transition: 0.15s ease all;

  div {
    padding: 6px 7px;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      cursor: pointer;
      background: #eee;
    }
  }
`;

const DownloadActions: FC<IDownloadActions> = ({
  generateScreenshot,
  color = "",
}) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(!open);

  return (
    <div style={{ position: "relative" }}>
      <Menu
        style={{ cursor: "pointer", marginLeft: 6, color }}
        onClick={() => toggleOpen()}
      />
      {open && (
        <ClickAwayListener onClickAway={() => toggleOpen()}>
          <DropDown>
            <div
              onClick={() => {
                toggleOpen();

                setTimeout(() => {
                  generateScreenshot();
                }, 500);
              }}
            >
              Download PNG
            </div>
          </DropDown>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default DownloadActions;
