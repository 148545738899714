import React, { FC } from "react";

import { useStyles } from "./styles";

interface Props {
  title?: any;
}

const PageTitle: FC<Props> = ({ children, title }) => {
  const classes = useStyles({});
  return (
    <div className={classes.pageHeader} id="page-title">
      <span className={classes.title}>{title}</span>
      {children}
    </div>
  );
};

export default PageTitle;
