/**
 * @prettier
 */
import React, { useState, FunctionComponent, useEffect } from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { IUser } from "interfaces/IUser";
import { Alert, AlertTitle } from "@material-ui/lab";

import { Actions } from "./ActionsDropdown";
import Loader from "../Loader";
import { useStyles } from "./styles";
import useCollectionSubscriber from "../../hooks/useCollectionSubscriber";
import { firestore } from "../../firebase-tools/firebase";

export const AdminSettings: FunctionComponent<{}> = () => {
  const classes = useStyles({});
  const [isLoading, setIsLoading] = useState(false);

  const queries = [
    firestore
      .collection("users")
      .where("role", "in", ["superAdmin", "admin", "viewerAdmin"]),
  ];
  const users = useCollectionSubscriber<IUser>({ queries });

  useEffect(() => {
    setIsLoading(true);
  }, []);
  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [users.documents]);
  return !isLoading ? (
    <>
      {users.error !== "" && (
        <Alert severity="error">
          <AlertTitle>User List Error!</AlertTitle>
          Error fetching admins
        </Alert>
      )}
      <TableBody>
        {users.documents.map((user) => (
          <TableRow key={user.id} className={classes.row}>
            <TableCell className={classes.tableCell}>
              <div className={`${classes.client}  ${classes.tableData}`}>
                <p>{user.email}</p>
              </div>
            </TableCell>
            <TableCell>
              <div
                className={`${classes.client} ${classes.tableCell} ${classes.tableData}`}
              >
                <p>{`${user.firstName} ${user.lastName}`}</p>
              </div>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <div
                className={`${classes.client} ${classes.tableCell} ${classes.tableData}`}
              >
                {user.role === "admin" && <p>Admin </p>}
                {user.role === "viewerAdmin" && <p>Viewer </p>}
                {user.role === "superAdmin" && <p>Super Admin</p>}
              </div>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Actions user={user} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </>
  ) : (
    <Loader />
  );
};
