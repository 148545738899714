import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  vimeo: {
    width: "100% !important",
    display: "flex",
    justifyContent: "center",
    "& iframe": {
      width: "470px !important",
      height: (props: any) => props.isCarousel && "470px",
    },
  },
  container: {
    position: "relative",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
