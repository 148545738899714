import React from "react";

import { Container, Grid } from "@material-ui/core";

import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import { firestore } from "../../firebase-tools/firebase";
import { useUser } from "UserContext";
import { IUser } from "interfaces/IUser";
import firebase from "firebase/app";

import SchedulePost from "./SchedulePost";

export const channelsList = [
  "instagram",
  "facebook",
  "twitter",
  "tiktok",
  "youtubeshort",
  "youtube",
];

export const deleteMedia = async (media) => {
  const mediaRef = await firebase.storage().refFromURL(media);
  await mediaRef.delete();
};

// Custom styles for the modal and form

interface ICreateEditPostV2 {
  edit?: string;
  create?: string;
  role: string;
  user?: any;
  monthly?: string;
  post?: any;
  externalOpen: boolean;
  setExternalOpen: (open: boolean) => void;
  externalDate?: any;
  setExternalDate?: (date: any) => void;
  calenderView?: boolean;
  clearRefresh?: boolean;
}
const AddSchedule = ({
  edit,
  create = "",
  user,
  setExternalOpen,
  setExternalDate,
  externalDate,
  externalOpen,
  post,
  role,
  calenderView,
  clearRefresh,
}: ICreateEditPostV2) => {
  const [authUser] = useUser() as Array<IUser>;

  let userRef = firestore.collection("users").doc(authUser.id);

  let client =
    useDocumentSubscriber({ ref: userRef }).document?.lastClientSelection || "";

  return (
    <>
      <Grid container>
        <SchedulePost client={client} post={post} />
      </Grid>
    </>
  );
};

export default AddSchedule;
