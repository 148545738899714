import React, { FC, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import LinkIcon from "@material-ui/icons/Link";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Linkify from "react-linkify";

import ModalWrapper from "components/Common/Modal";
import Select from "components/Common/Select";
import { useStyles } from "./styles";
import { SendButton } from "components/styled/Common";
import { success } from "../../../utils/notification";
import { encryptWithAES } from "../../../utils/encryption";

interface Props {
  location: any;
  primaryEmail: string;
}

const selectOptions = ["7 days", "30 days", "90 days", "Never"];
export const componentDecorator = (href, text, key, note) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

const PublicLink: FC<Props> = ({ location, primaryEmail }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [expiryPeriod, setExpiryPeriod] = useState("30 days");
  const classes = useStyles({});
  const { pathname } = location;
  const baseUrl = process.env.REACT_APP_KIT_BASE_URL;

  const data = {
    primaryEmail,
    expiryDays: expiryPeriod.replace(" days", ""),
  };
  let objStr = JSON.stringify(data);
  const cipher = encryptWithAES(objStr);
  const link = `${baseUrl}public/${pathname.replace(
    "/",
    ""
  )}/${encodeURIComponent(cipher.toString())}${window.location.search}`;

  const handleSubmit = () => {
    setIsOpen(!isOpen);
    success("Link copied successfully");
  };

  const triggerButton = (
    <div onClick={() => setIsOpen(true)} id="public-link">
      <Tooltip
        title="Generate public link"
        arrow
        interactive
        classes={{ tooltip: classes.info, arrow: classes.arrow }}
      >
        <LinkIcon className={classes.linkIcon} />
      </Tooltip>
    </div>
  );

  const submitButton = (
    <div onClick={handleSubmit}>
      <CopyToClipboard text={link}>
        <SendButton disableRipple>Copy link</SendButton>
      </CopyToClipboard>
    </div>
  );

  return (
    <ModalWrapper
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleSubmit={handleSubmit}
      title="Generate public link"
      triggerNode={triggerButton}
      customSubmitButton={submitButton}
    >
      <div style={{ paddingTop: 17.5 }}>
        <div className={classes.field}>
          <Select
            value={expiryPeriod}
            setItems={setExpiryPeriod}
            options={selectOptions}
            multiple={false}
            defaultValue="Period..."
            label="Link expiration"
          />
        </div>
        <div className={classes.link}>
          <Linkify componentDecorator={componentDecorator}>{link}</Linkify>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default PublicLink;
