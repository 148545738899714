import React, { FunctionComponent, useState } from "react";
import { Grid, ClickAwayListener } from "@material-ui/core";
import clsx from "clsx";
import { DateRangePicker as DatePicker } from "react-date-range";
import { fromEvent, Observable } from "rxjs";
import styled from "styled-components";

import { useStyles } from "./styles";
import "./styles.css";
import { ReactComponent as DropDownIcon } from "static/media/dropDown.svg";
import { longDate } from "utils/dateFormat";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";

const DateRangeStyled = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify};
  .rdrDayToday > .rdrDayNumber {
    span::after {
      background: #000 !important;
    }
  }
`;
interface DateProps {
  dateRange?: any;
  setDateRange?: (range: any) => void;
  setParamsChange?: (val: any) => void;
  disableFuture?: boolean;
  container?: string;
  hidePeriod?: boolean;
  className?: string;
  datePosition?: any;
  fullWidth?: boolean;
  minDate?: Date;
  align?: any;
  label?: string;
  labelStyle?: any;
}

const DateRangePicker: FunctionComponent<DateProps> = ({
  dateRange,
  setDateRange,
  setParamsChange,
  disableFuture,
  container,
  hidePeriod,
  datePosition,
  minDate,
  align = "center",
  className = "",
  fullWidth = true,
  label = "Publish Date",
  labelStyle = null,
}) => {
  const classes = useStyles({ right: datePosition?.right, fullWidth });
  const [open, setOpen] = useState(false);
  const [dateClick, setDateClick] = useState(0);
  const [state, setState] = useState({
    startDate: dateRange.startDate
      ? moment(dateRange.startDate).toDate()
      : moment().toDate(),
    endDate: dateRange.endDate
      ? moment(dateRange.endDate).toDate()
      : moment().toDate(),
    key: "selection",
  });

  const [mobileMenu, switchMobileMenu] = useState(
    !(document.documentElement.offsetWidth > 1000)
  );

  const resize$: Observable<Event> = fromEvent(window, "resize");

  resize$.subscribe((res: any) => {
    if (res && res.currentTarget) {
      switchMobileMenu(!(res.currentTarget.innerWidth > 1000));
    }
  });
  const toggle = () => {
    setOpen(!open);
    setDateClick(0);
  };

  const handleChange = (range) => {
    const clickCount = dateClick + 1;
    setDateClick(clickCount);
    setState({ ...range.selection, key: "selection" });

    if (clickCount > 1) {
      setDateRange && setDateRange(range.selection);
      setParamsChange && setParamsChange("date-range");
      toggle();
    }
  };

  return (
    <div className={classes.datePickerContainer} id="date-picker">
      {!hidePeriod && (
        <span
          className={classes.dateLabel}
          style={
            labelStyle
              ? { textAlign: align as any, ...labelStyle }
              : { textAlign: align as any }
          }
        >
          {label}
        </span>
      )}

      <div className={classes.datePickerStyle}>
        <div onClick={toggle} className={classes.dateInput}>
          <div>{longDate(dateRange.startDate)}</div>
          <> - </>
          <div>{longDate(dateRange.endDate)}</div>
          <div
            style={{
              transform: open ? "rotate(180deg)" : "",
              marginLeft: "10px",
              marginTop: open ? 1 : "-4px",
            }}
          >
            <DropDownIcon />
          </div>
        </div>
        <Grid>
          {open && (
            <ClickAwayListener onClickAway={toggle}>
              <DateRangeStyled justify={datePosition?.justify}>
                <DatePicker
                  editableDateInputs={true}
                  className={clsx(
                    datePosition?.datePickerClass
                      ? datePosition.datePickerClass
                      : classes.datePicker,
                    className
                  )}
                  ranges={[state]}
                  onChange={handleChange}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  direction={mobileMenu ? "vertical" : "horizontal"}
                  weekStartsOn={1}
                  minDate={minDate ?? moment().toDate()}
                  maxDate={
                    disableFuture &&
                    (moment().subtract(1, "day").toDate() as any)
                  }
                  rangeColors={["#000000", "#0000008a"]}
                  color="#000000"
                  dragSelectionEnabled={false}
                />
              </DateRangeStyled>
            </ClickAwayListener>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default DateRangePicker;
