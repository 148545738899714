import React, { FunctionComponent, useEffect, useState, memo } from "react";
import {
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Grid,
  Tooltip,
  Box,
  Typography,
} from "@material-ui/core";
import { Public } from "@material-ui/icons";
import cuid from "cuid";
import linkifyHtml from "linkifyjs/html";

import userIcon from "static/media/name.svg";
import { useStyles } from "../styles";
import CreateEditPost from "../CreateEditPost";
import { Delete } from "../Delete";
import ReadMore from "components/ReadMore";
import { useUser } from "UserContext";
import { IUser } from "interfaces/IUser";
import Notes from "components/BePlanned/Notes";

import facebook from "static/media/fb-vector.png";
import twitter from "static/media/twitter-vector.png";
import instagram from "static/media/instagram-vector.png";
import pinterest from "static/media/pinterest.png";
import snapchat from "static/media/snapchat.png";
import youtubeshort from "static/media/youtube-shorts.png";
import tiktok from "static/media/tiktok.png";
import triller from "static/media/triller.png";
import tumblr from "static/media/tumblr.png";
import youtube from "static/media/youtube.png";
import defaultIcon from "static/media/universal-2.png";
import playButton from "static/media/placeholder-image.jpeg";
import { firestore } from "../../../firebase-tools/firebase";
import useCollectionSubscriber from "../../../hooks/useCollectionSubscriber";
import { filterNotes } from "../helpers/notes";
import { getBackground } from "components/BePlannedMonthly/EventIcon";
import { noStyleList } from "components/Common/SingleSelect";
import StatusTooltip from "components/Common/StatusTooltip";
import { channelsList } from "../CreateEditPost";
import Carousel from "components/Common/Carousel";
import {
  SocialPostContainer,
  Context,
  PostHeader,
} from "components/styled/SocialPost";
import { cancelReloadTabClose } from "utils/navigate";
import { useUnsaved } from "LinkContext";
import { FieldTitle } from "components/styled/CreateEditPost";
import Image from "components/Common/Image/Image";
import DownloadActions from "components/Common/DownloadActions/DownloadActions";
import {
  formatGraphicsBrief,
  getPostDate,
  generateScreenshot,
} from "../helpers/socialPost";
interface ISocialPost {
  post: any;
  user: any;
  role: string;
}

const SocialPost: FunctionComponent<ISocialPost> = ({ post, user, role }) => {
  const classes = useStyles({});
  const [authUser] = useUser() as Array<IUser>;
  const [, setUnsaved] = useUnsaved() as any;
  const {
    publicIcon,
    postCard,
    postCardContent,
    postCardContentBottom,
    postCardHeader,
    avatar,
    AvatarIcon,
    AvatarSvg,
    cardContent,
    img,
  } = useStyles({});
  const [note, setNote] = useState("");
  const [noteType, setNoteType] = useState("internal");

  const uuid: string = cuid();

  const notesQueries = [
    firestore
      .collection("multichannel-posts")
      .doc(post.id)
      .collection("notes")
      .orderBy("created_at", "desc"),
  ];
  const notes: any = useCollectionSubscriber({
    queries: notesQueries,
    deps: [post.id],
  }).documents;

  const { internalNotes, externalNotes } = filterNotes(notes);

  const getImages = () => {
    let images;
    if (typeof post.image_url === "string") {
      if (post.image_url !== "") {
        images = [post.image_url];
      } else {
        images = [];
      }
    } else {
      images = post.image_url;
    }
    return images;
  };
  const [images, setImages] = useState(getImages());

  const icons = {
    facebook,
    twitter,
    instagram,
    pinterest,
    snapchat,
    tiktok,
    youtubeshort,
    triller,
    tumblr,
    youtube,
  };

  useEffect(() => {
    const updatedImages = getImages();
    setImages(updatedImages);
    //eslint-disable-next-line
  }, [post.image_url]);

  const displayCategory = (post, channel) => {
    if (post?.category) {
      const valid = Object.values(post?.category).every((ct) =>
        Array.isArray(ct)
      );

      if (valid && channelsList.includes(channel)) {
        return (
          <>
            {post?.category[channel.toLowerCase()] &&
              post?.category[channel.toLowerCase()]
                .filter((cat) => cat !== "")
                .map((option, i) => (
                  <div
                    style={{ textAlign: "center", color: "#000" }}
                    key={`category-${i}`}
                  >
                    {option.split(" ").slice(1).join("")}
                  </div>
                ))}
          </>
        );
      } else if (Array.isArray(post?.category?.custom)) {
        return (
          <>
            {post?.category?.custom &&
              post?.category?.custom
                .filter((cat) => cat !== "")
                .map((option, i) => (
                  <div style={{ textAlign: "center" }} key={`category-${i}`}>
                    {option.split(" ")[0]}
                  </div>
                ))}
          </>
        );
      }
    }
  };

  useEffect(() => {
    cancelReloadTabClose(note.length > 0);
    if (note.length > 0) setUnsaved(true);
    else setUnsaved(false);
    // eslint-disable-next-line
  }, [note]);

  const postChannel = {
    instagram: 1,
    facebook: 2,
    twitter: 3,
    tiktok: 4,
    youtube: 5,
    default: Number.MAX_VALUE,
  };

  const postChannels = post?.channels?.sort(
    (a, b) =>
      (postChannel[a] || postChannel.default) -
        (postChannel[b] || postChannel.default) ||
      a > b ||
      -(a < b)
  );

  return (
    <SocialPostContainer id="content-post">
      <Grid item xs={12} sm={8}>
        <div className={classes.post} id={`post-${uuid}`}>
          <div
            style={{
              width: "calc(100% - 26px)",
              background: noStyleList.includes(post.internal_status_weekly)
                ? "#cccccc"
                : `${getBackground(post.internal_status_weekly)}`,
              borderRadius: "15px 0 0 15px",
            }}
          >
            <div className="content-header">
              <FieldTitle style={{ paddingLeft: "calc(30px - 18px)" }}>
                {post.title}
              </FieldTitle>
              <div className={classes.postIcons}>
                {user[post?.platform?.toLowerCase()]?.url ? (
                  post?.platform ? (
                    <a
                      href={user[post.platform.toLowerCase()].url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        className={img}
                        src={icons[post.platform.toLowerCase()] || defaultIcon}
                        alt=""
                      />
                    </a>
                  ) : (
                    post?.channels.map((channel, i) => (
                      <a
                        key={`${i}-channel`}
                        href={user[channel.toLowerCase()].url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          className={img}
                          src={icons[channel.toLowerCase()] || defaultIcon}
                          alt=""
                        />
                      </a>
                    ))
                  )
                ) : post?.platform ? (
                  <Image
                    className={img}
                    src={icons[post.platform.toLowerCase()] || defaultIcon}
                    alt=""
                  />
                ) : (
                  postChannels.map((channel, i) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                      key={`post-${i}`}
                    >
                      <a
                        href={
                          user[channel.toLowerCase()]
                            ? user[channel.toLowerCase()].url
                            : ""
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          className={img}
                          style={{ height: "20px", width: "20px" }}
                          src={icons[channel.toLowerCase()] || defaultIcon}
                          alt=""
                        />
                      </a>
                      <div
                        style={{
                          color: "#ABADAD",
                          fontSize: 8,
                          width: 34,
                        }}
                      >
                        {displayCategory(post, channel)}
                      </div>
                    </div>
                  ))
                )}

                <DownloadActions
                  generateScreenshot={() =>
                    generateScreenshot(post, images, uuid)
                  }
                />
              </div>
            </div>
            <Card
              className={postCard}
              style={{
                borderRadius: "unset",
              }}
              id="postCard"
            >
              <PostHeader>
                <CardHeader
                  avatar={
                    user.avatarUrl !== "" ? (
                      <Avatar className={avatar} src={user.avatarUrl} />
                    ) : (
                      <Avatar
                        classes={{
                          root: AvatarSvg,
                          img: AvatarIcon,
                        }}
                        src={userIcon}
                      />
                    )
                  }
                  title={`${user.firstName} ${user.lastName}`}
                  subheader={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span style={{ marginRight: 5 }}>
                        {getPostDate(post.date.seconds)}
                      </span>
                      <Public className={publicIcon} id="publicIcon" />
                    </div>
                  }
                  className={postCardHeader}
                  classes={{
                    subheader: cardContent,
                    title: cardContent,
                  }}
                />
              </PostHeader>
              <CardContent className={postCardContent} id="postCardContent">
                <div className={cardContent}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: linkifyHtml(
                        post.body?.trim() !== ""
                          ? post.body
                          : post.title || " ",
                        {
                          target: {
                            url: "_blank",
                          },
                        }
                      ),
                    }}
                  />
                </div>
              </CardContent>

              {!!post.video_url?.length || !!images?.length ? (
                <>
                  <div id={post.video_url && !images.length ? "carousel" : ""}>
                    <Carousel images={images} video={post.video_url} />
                  </div>
                  <div
                    className={classes.placeholderImg}
                    id={
                      post.video_url && !images.length
                        ? "image-placeholder"
                        : ""
                    }
                  >
                    <img src={playButton} alt="Screenshot Placeholder" />
                  </div>
                </>
              ) : (
                <>
                  {!!post.graphicsBrief?.length && formatGraphicsBrief(post) && (
                    <Box
                      className={classes.contentBriefContainer}
                      id="contentBriefContainer"
                    >
                      <Typography className={classes.briefTitle}>
                        Creative Content Brief
                      </Typography>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: formatGraphicsBrief(post),
                        }}
                      />
                    </Box>
                  )}
                </>
              )}
            </Card>
            <div className="post-footer">
              {post?.additional_info && (
                <>
                  <FieldTitle style={{ fontSize: 14 }}>Actions:</FieldTitle>
                  <span className={classes.cardContent}>
                    <ReadMore
                      text={post.additional_info}
                      post={post.additional_info}
                      role={""}
                      showLessButton={true}
                      readMoreCharacterLimit={500}
                    />
                  </span>
                </>
              )}
            </div>
          </div>

          <Tooltip
            title={
              <div className={classes.tooltipTitle}>
                <StatusTooltip
                  status={post.internal_status_weekly}
                  type="Weekly"
                />
                <div style={{ marginTop: "7px" }}>
                  <StatusTooltip
                    status={post.internal_status_monthly}
                    type="Monthly"
                  />
                </div>
              </div>
            }
            arrow
            interactive
            classes={{ tooltip: classes.tooltip, arrow: classes.tipArrow }}
          >
            <div
              className={postCardContentBottom}
              style={{
                background: noStyleList.includes(post.internal_status_weekly)
                  ? "#cccccc"
                  : `${getBackground(post.internal_status_weekly)}`,
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </div>
      </Grid>
      <Grid item xs={12} sm={4} id="actions-post">
        {authUser.role !== "viewerAdmin" && (
          <div className={classes.actionsPost}>
            <div className={classes.actionsbtns}>
              <Grid item xs={6} sm={6}>
                <CreateEditPost
                  edit="edit"
                  role={role}
                  post={post}
                  user={user}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <Delete role={role} id={post.id} />
              </Grid>
            </div>
          </div>
        )}
        <Context
          style={{ margin: authUser.role !== "viewerAdmin" ? 0 : "-26px" }}
        >
          <Notes
            user={authUser}
            note={note}
            setNote={setNote}
            setNoteType={setNoteType}
            noteType={noteType}
            internalNotes={internalNotes}
            externalNotes={externalNotes}
            post={post}
            client={user}
          />
        </Context>
      </Grid>
    </SocialPostContainer>
  );
};

export default memo(SocialPost);
