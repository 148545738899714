export const calculatePercentageGrowth = (values: number[]): number => {
  if (values.length < 2) {
    return 0;
  }

  const firstValue = values[0];
  const lastValue = values[values.length - 1];

  const growth = ((lastValue - firstValue) / firstValue) * 100;

  return growth;
};
