import React, { FunctionComponent } from "react";
import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import { useStyles } from "../styles";
import { IndicatorProps } from "../MetricsIndicator";
import Image from "components/Common/Image/Image";

const ChartTitle: FunctionComponent<IndicatorProps> = ({
  icon,
  role,
  name,
  url,
}) => {
  const classes = useStyles({});

  const movingAvgString = " (7-day moving average)";

  const stat = {
    facebook: {
      "Follower Count":
        "The total number of people who have followed your Page.",
      "Total Impressions":
        "The number of times any content from your Page or about your Page entered a person's screen. This includes posts, stories, check-ins, ads, social information from people who interact with your Page and more.",
      "Total Engagement":
        "The number of people who engaged with your Page's content. Engagement includes reactions, comments, shares and more.",
      "Engagement Rate":
        "The rate at which people have engaged with your posts through reactions, comments, shares and more. It is calculated as: Total Engagement / Total Impressions.",
      "Audience by Gender":
        "The number of people who saw any of your posts at least once, grouped by gender. People who do not list their gender are shown as 'Non Binary'.",
      "Audience by Age":
        "The number of people who saw any of your posts at least once, grouped by age. People who do not list their gender are shown as 'Non Binary'.",
      "Audience by City/Country":
        "The regions where the people you've reached are located. This is based on information such as a person's hometown, their current city and the geographical location where they tend to be when they visit Facebook." +
        "\n" +
        "You can see the numbers at the start of the period and the end of the period.",
      "Total Interactions":
        "The number of times people have interacted with your posts through reactions, shares and comments. \n\n" +
        "Note that the interactions are counted on the date when their underlying post was created. This is unlike the engagements, which are counted when the engagements have actually happened.",
      "Interaction Rate":
        "The rate at which people have interacted with your posts through reactions, shares and comments. It is calculated as: Total Interactions / Number of Posts / Total Followers.\n\n" +
        "Note that the interactions are counted on the date when their underlying post was created. This is unlike the engagements, which are counted when the engagements have actually happened.",
    },
    instagram: {
      "Follower Count": "The total number of people who have followed you.",
      "Total Impressions":
        "Total number of times the IG User's IG posts, stories and promotions have been viewed. This includes ad activity generated through the API, Facebook ads interfaces, and the Promote feature. Does not include profile views.",
      "Total Engagement":
        "The number of times people have engaged with your posts through likes and comments.",
      "Engagement Rate":
        "The rate at which people have engaged with your posts through likes and comments. It is calculated as: Total Engagement / Number of Posts / Total Followers",
      "Audience by Gender":
        "The gender distribution of the IG User's followers. People who do not list their gender are shown as 'Non Binary'.",
      "Audience by Age":
        "The age distribution of the IG User's followers. People who do not list their gender are shown as 'Non Binary'.",
      "Audience by City/Country":
        "The countries and cities of the IG User's followers.",
      "Total Interactions":
        "The number of times people have interacted with your posts through likes and comments. \n\n" +
        "Note that the interactions are counted on the date when their underlying post was created. This is unlike the engagements, which are counted when the engagements have actually happened.",
      "Interaction Rate":
        "The rate at which people have interacted with your posts through likes and comments. It is calculated as: Total Interactions / Number of Posts / Total Followers.\n\n" +
        "Note that the interactions are counted on the date when their underlying post was created. This is unlike the engagements, which are counted when the engagements have actually happened",
    },
    twitter: {
      "Follower Count":
        "The total number of people who follow the Twitter account of the user.",
      "Total Impressions":
        "Number of times users saw Tweets from the Twitter account.",
      "Total Engagement":
        "Total number of times a user has interacted with a Tweet. This includes all clicks anywhere on the Tweet (including hashtags, links, avatar, username, and Tweet expansion), retweets, replies, follows, and likes",
      "Engagement Rate":
        "The rate at which people have engaged with your tweets (this includes all clicks anywhere on your tweets). It is calculated as: Total Engagement / Total Impressions.",
    },
    tikTok: {
      "Follower Count":
        "The total number of people who have followed your Page.",
      "Video Views": "The total number of video views in the selected period.",
      "Total Engagement":
        "The number of times people have engaged with your posts through likes, shares and comments.",
      "Engagement Rate":
        "The rate at which people have engaged with your posts through likes and comments. It is calculated as: Total Engagements / Total Followers",
    },
    YouTube: {
      "Video Views": "The total number of video views in the selected period.",
      "Subscriber Count":
        "The total number of people who have subscribed to your channel.",
    },
    Spotify: {
      "Follower Count": "The total number of people who have followed you.",
      Streams:
        "The number of times that any of your songs was streamed (played) by a user. The metric is a 7-day moving average, meaning that each day the last 7-day period is taken and divided by 7 to produce a daily average.",
      Listeners:
        "The number of unique users who have streamed (played) at least one of your songs at least once. The metric is a 7-day moving average, meaning that each day the last 7-day period is taken and divided by 7 to produce a daily average.",
      "Streams Per Followers":
        "The rate at which your followers stream your songs. It is calculated as: Streams / Followers Count.",
      "Listeners by Gender":
        "Gender of people who have listened to your songs. The chart shows the last 28 days relative to the end date of your period selection.",
      "Listeners by Age":
        "Age of people who have listened to your songs. The chart shows the last 28 days relative to the end date of your period selection.",
      "Listeners by City/Country":
        "The country/city where the listeners you've reached are located. The chart shows the last 28 days relative to the end date of your period selection. The growth rate is calculated by comparing the end of the period relative to the start of the period.",
    },
    "Apple Music": {
      Listeners:
        "The number of unique users who have played at least one of your songs at least once.",
      Plays:
        "The number of times that any of your songs were played by a user.",
      Shazams:
        "Shazams are recorded anytime your song is recognized by a user in the media recognition app, Shazam.",
      "Song Purchases":
        "The number of song purchases made on a particular day.",
      "Audience by Gender":
        "Gender of the people who have played your songs (non-unique users but song plays).",
      "Audience by Age":
        "Age of people who have played to your songs (non-unique users but song plays).",
      "Audience by City/Country":
        "The countries and cities with the most song plays of your songs.",
    },
  };

  return (
    <div
      className={classes.socialLabel}
      style={{ padding: "20px 10px 20px 0px", justifyContent: "space-between" }}
    >
      <div className={classes.socialLabel}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <Image
            className={classes.icon}
            src={icon}
            alt={role}
            style={{
              width: "26px",
              height: "30px",
            }}
          />
        </a>
        <span className={classes.statsName} style={{ fontSize: "20px" }}>
          {name}
          {role === "Spotify" &&
            name &&
            ["Streams", "Listeners", "Streams Per Followers"].includes(name) &&
            movingAvgString}
        </span>
      </div>
      <div>
        <Tooltip
          title={
            <span style={{ whiteSpace: "pre-line" }}>
              {stat[`${role}`][`${name}`] || name}
            </span>
          }
          arrow
          interactive
          classes={{ tooltip: classes.info, arrow: classes.arrow }}
        >
          <InfoIcon style={{ color: "slategrey" }} />
        </Tooltip>
      </div>
    </div>
  );
};

export default ChartTitle;
