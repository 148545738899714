import React, { FunctionComponent, useEffect, useState, memo } from "react";
import {
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Grid,
  Tooltip,
  Box,
  Typography,
} from "@material-ui/core";
import { Public } from "@material-ui/icons";
import linkifyHtml from "linkifyjs/html";

import { ReactComponent as PendingApproval } from "../../../assets/pendingapproval.svg";
import { ReactComponent as PendingInternalAmmends } from "../../../assets/pendinginternalamends.svg";
import { ReactComponent as PendingInternalReview } from "../../../assets/pendinginternalreview.svg";
import { ReactComponent as ApprovedIcon } from "../../../assets/approved.svg";
import { ReactComponent as RejectedIcon } from "../../../assets/rejected.svg";
import { ReactComponent as Draft } from "../../../assets/draft.svg";
import { ReactComponent as DraftMonth } from "../../../assets/Draftmonth.svg";
import { ReactComponent as ClockLoader } from "../../../assets/Clockloader.svg";
import { ReactComponent as CheckCircle } from "../../../assets/check_circle.svg";

import userIcon from "static/media/name.svg";
import { useStyles } from "../styles";
import { useUser } from "UserContext";
import { IUser } from "interfaces/IUser";
import Notes from "components/BePlanned/Notes";

import facebook from "static/media/fb-vector.png";
import twitter from "static/media/twitter-vector.png";
import instagram from "static/media/instagram-vector.png";
import pinterest from "static/media/pinterest.png";
import snapchat from "static/media/snapchat.png";
import youtubeshort from "static/media/youtube-shorts.png";
import tiktok from "static/media/tiktok.png";
import discord from "static/media/Discord.png";
import threads from "static/media/Threads.png";
import triller from "static/media/triller.png";
import tumblr from "static/media/tumblr.png";
import youtube from "static/media/youtube.png";
import defaultIcon from "static/media/universal-2.png";
import playButton from "static/media/placeholder-image.jpeg";
import { firestore } from "../../../firebase-tools/firebase";
import useCollectionSubscriber from "../../../hooks/useCollectionSubscriber";
import { filterNotes } from "../helpers/notes";
import { channelsList } from "../CreateEditPost";
// import Carousel from "components/Common/Carousel";
import Carousel from "components/Common/CarouselV2";
import { SocialPostContainer, Context } from "components/styled/SocialPost";
import { cancelReloadTabClose } from "utils/navigate";
import { useUnsaved } from "LinkContext";
import { FieldTitle } from "components/styled/CreateEditPost";
import Image from "components/Common/Image/Image";
import {
  formatGraphicsBrief,
  getPostDate,
  generateScreenshot,
} from "../helpers/socialPost";
import { DeleteV2 } from "../DeleteV2";
import DownloadActions from "components/Common/DownloadActionsV2/DownloadActionsV2";
import CreateEditPostV2 from "../CreateEditPostV2";
// import DownloadActions from "components/Common/DownloadActions/DownloadActions";

import CheckForm from "../CheckForm";
import AddSchedule from "../AddSchedule";
import EventIcon, {
  getBackground,
} from "components/BePlannedMonthly/EventIcon";
import EditPost from "../EditPost";

interface ImageWithTooltipProps {
  imagePath: string;
  tooltipText: string;
}

interface ISocialPost {
  post: any;
  user: any;
  role: string;
  postIds: any[];
  setPostIds: (date: any) => void;
  checkAll: Boolean;
  uncheckAll: Boolean;
  uuid: string;
  dailyCheck: boolean;
}

const SocialPostV2: FunctionComponent<ISocialPost> = ({
  post,
  user,
  role,
  postIds,
  setPostIds,
  checkAll,
  uncheckAll,
  uuid,
  dailyCheck,
}) => {
  const classes = useStyles({});
  const [authUser] = useUser() as Array<IUser>;
  const [, setUnsaved] = useUnsaved() as any;
  const {
    publicIcon,
    avatar,
    AvatarIcon,
    AvatarSvg,
    cardContent,
    img,
  } = useStyles({});
  const [note, setNote] = useState("");
  const [noteType, setNoteType] = useState("internal");
  const notesQueries = [
    firestore
      .collection("multichannel-posts")
      .doc(post.id)
      .collection("notes")
      .orderBy("created_at", "desc"),
  ];
  const notes: any = useCollectionSubscriber({
    queries: notesQueries,
    deps: [post.id],
  }).documents;

  const { internalNotes, externalNotes } = filterNotes(notes);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getImages = () => {
    let images;
    if (typeof post.image_url === "string") {
      if (post.image_url !== "") {
        images = [post.image_url];
      } else {
        images = [];
      }
    } else {
      images = post.image_url;
    }
    return images;
  };
  const [images, setImages] = useState(getImages());

  const icons = {
    facebook,
    twitter,
    instagram,
    pinterest,
    snapchat,
    tiktok,
    youtubeshort,
    triller,
    tumblr,
    discord,
    youtube,
    threads,
  };

  useEffect(() => {
    const updatedImages = getImages();
    setImages(updatedImages);
    //eslint-disable-next-line
  }, [post, post.image_url]);

  const displayCategory = (post, channel) => {
    if (post?.category) {
      const valid = Object.values(post?.category).every((ct) =>
        Array.isArray(ct)
      );

      if (valid && channelsList.includes(channel?.toLowerCase())) {
        return (
          <>
            {post?.category[channel.toLowerCase()] &&
              post?.category[channel.toLowerCase()]
                .filter((cat) => cat !== "")
                .map((option, i) => (
                  <div
                    style={{
                      display: "inline-block",
                      margin: "0 10px",
                      textAlign: "center",
                      color: "#000",
                    }}
                    key={`category-${i}`}
                  >
                    {option.split(" ").slice(1).join(" ")}
                  </div>
                ))}
          </>
        );
      } else if (Array.isArray(post?.category?.custom)) {
        return (
          <>
            {post?.category?.custom &&
              post?.category?.custom
                .filter((cat) => cat !== "")
                .map((option: any, i: number) => (
                  <span
                    key={`category-${i}`}
                    style={{ margin: "0 5px", color: "#000" }}
                  >
                    {i > 0 && ","} {option.split(" ").slice(1).join(" ")}
                  </span>
                ))}
          </>
        );
      }
    }
  };

  const getIcons = (status, type = "week") => {
    let icon = <PendingApproval />;

    if (status === "Pending Approval") {
      icon = <PendingApproval />;
    }

    if (["Client Approved", "Scheduled"].includes(status)) {
      icon = <ApprovedIcon />;
    }

    if (["Client Declined"].includes(status)) {
      icon = <RejectedIcon />;
    }
    if (status === "Draft") {
      icon = <Draft />;
    }

    if (status === "Pending Internal Review") {
      icon = <PendingInternalReview />;
    }

    if (type === "month" && status === "Pending Approval") {
      icon = <ClockLoader />;
    }
    if (type === "month" && status === "Draft") {
      icon = <DraftMonth />;
    }
    if (type === "month" && status === "Approved") {
      icon = <CheckCircle />;
    }

    if (["Pending Internal Amends"].includes(status)) {
      icon = <PendingInternalAmmends />;
    }
    return icon;
  };

  const getColor = (status) => {
    let color: any = "#FFD944";

    if (status === "Pending Internal") {
      color = "#FFD944";
    }
    if (status === "Pending Approval") {
      color = "#F17013";
    }

    if (status === "Pending Client Approval") {
      color = "#F17013";
    }

    if (["Client Approved", "Scheduled"].includes(status)) {
      color = "#24B025";
    }

    if (["Client Declined"].includes(status)) {
      color = "#EC220E";
    }
    if (status === "Draft") {
      color = "#ABADAD";
    }

    if (status === "Pending Internal Review") {
      color = "#0688E2";
    }

    return color;
  };

  useEffect(() => {
    cancelReloadTabClose(note.length > 0);
    if (note.length > 0) setUnsaved(true);
    else setUnsaved(false);
    // eslint-disable-next-line
  }, [note]);

  const postChannel = {
    instagram: 1,
    facebook: 2,
    twitter: 3,
    tiktok: 4,
    youtube: 5,
    snapchat: 6,
    threads: 7,
    discord: 8,
    default: Number.MAX_VALUE,
  };

  const postChannels = post?.channels
    ?.filter(
      (val) =>
        !val?.toLowerCase().includes("all") &&
        !val?.toLowerCase().includes("youtube short")
    )
    ?.sort(
      (a, b) =>
        (postChannel[a] || postChannel.default) -
          (postChannel[b] || postChannel.default) ||
        a > b ||
        -(a < b)
    );

  const truncatedContent = (content, characterLimit) => {
    return `${content.slice(0, characterLimit)}...`;
  };

  const [externalOpen, setExternalOpen] = useState(false);

  return (
    <SocialPostContainer id="content-post" key={post.id}>
      <Grid container>
        <Grid item xs={12} sm={8} container>
          <Grid xs={12} sm={1}>
            <CheckForm
              postIds={postIds}
              postId={post.id}
              setPostIds={setPostIds}
              checkAll={checkAll}
              uncheckAll={uncheckAll}
              dailyCheck={dailyCheck}
            />
          </Grid>
          <div
            style={{
              backgroundColor: getBackground(post.internal_status_weekly),
              padding: 5,
              height: "auto",
            }}
            className={classes.smHide}
          ></div>
          <Card className={classes.cardWidth}>
            <div
              style={{
                backgroundColor: getBackground(post.internal_status_weekly),
                padding: 5,
                width: "100%",
              }}
              className={classes.mdHide}
            ></div>
            <CardContent id={`post-${uuid}`}>
              <Grid xs={12} md={12} container>
                <Grid item xs={12} md={12} container>
                  <Grid container>
                    <Grid item xs={12} md={7}>
                      <CardHeader
                        avatar={
                          user.avatarUrl !== "" ? (
                            <Avatar className={avatar} src={user.avatarUrl} />
                          ) : (
                            <Avatar
                              classes={{
                                root: AvatarSvg,
                                img: AvatarIcon,
                              }}
                              src={userIcon}
                            />
                          )
                        }
                        title={`${user.firstName} ${user.lastName}`}
                        subheader={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span style={{ marginRight: 5, fontSize: "13px" }}>
                              {getPostDate(post.date.seconds)}
                            </span>
                            <Public className={publicIcon} id="publicIcon" />
                          </div>
                        }
                        classes={{
                          subheader: cardContent,
                          title: cardContent,
                        }}
                      />

                      <div
                        className={classes.postIcons}
                        style={{
                          margin: "10px",
                        }}
                      >
                        {user[post?.platform?.toLowerCase()]?.url ? (
                          post?.platform ? (
                            <a
                              href={user[post.platform.toLowerCase()].url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Image
                                className={img}
                                src={
                                  icons[post.platform.toLowerCase()] ||
                                  defaultIcon
                                }
                                alt=""
                              />
                            </a>
                          ) : (
                            post?.channels.map((channel, i) => (
                              <a
                                key={`${i}-channel`}
                                href={user[channel.toLowerCase()].url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Image
                                  className={img}
                                  src={
                                    icons[channel.toLowerCase()] || defaultIcon
                                  }
                                  alt=""
                                />
                              </a>
                            ))
                          )
                        ) : post?.platform ? (
                          <Image
                            className={img}
                            src={
                              icons[post.platform.toLowerCase()] || defaultIcon
                            }
                            alt=""
                          />
                        ) : (
                          postChannels.map((channel, i) => (
                            <Tooltip
                              title={displayCategory(post, channel) as any}
                              arrow
                              classes={{ tooltip: classes.customTooltip }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                                key={`post-${i}`}
                              >
                                <a
                                  href={
                                    user[channel.toLowerCase()]
                                      ? user[channel.toLowerCase()].url
                                      : ""
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Image
                                    className={img}
                                    style={{ height: "30px", width: "30px" }}
                                    src={
                                      icons[channel.toLowerCase()] ||
                                      defaultIcon
                                    }
                                    alt=""
                                  />
                                </a>
                              </div>
                            </Tooltip>
                          ))
                        )}
                      </div>
                    </Grid>

                    {authUser.role !== "viewerAdmin" && (
                      <>
                        {/* desktop view */}
                        <Grid
                          item
                          xs={12}
                          md={4}
                          container
                          className={classes.smHide}
                        >
                          <Grid item xs={2} md={2}>
                            <DownloadActions
                              generateScreenshot={() =>
                                generateScreenshot(post, images, uuid)
                              }
                            />
                          </Grid>
                          <Grid item xs={2} md={2}>
                            <DeleteV2 role={role} id={post.id} />
                          </Grid>

                          <Grid item xs={2} md={2}>
                            <CreateEditPostV2
                              edit="edit"
                              role={role}
                              post={post}
                              user={user}
                              setExternalOpen={setExternalOpen}
                              externalOpen={externalOpen}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            md={2}
                            style={{
                              marginLeft: "0px",
                              marginRight: "20px",
                            }}
                          >
                            <AddSchedule
                              edit="edit"
                              role={role}
                              post={post}
                              user={user}
                              setExternalOpen={setExternalOpen}
                              externalOpen={externalOpen}
                            />
                          </Grid>
                        </Grid>

                        {/* mobile view */}
                        <Grid
                          item
                          xs={12}
                          md={12}
                          container
                          className={classes.mdHide}
                        >
                          <Grid item xs={2} md={2}>
                            <DownloadActions
                              generateScreenshot={() =>
                                generateScreenshot(post, images, uuid)
                              }
                            />
                          </Grid>
                          <Grid item xs={2} md={2}>
                            <DeleteV2 role={role} id={post.id} />
                          </Grid>

                          {post && (
                            <Grid item xs={2} md={2}>
                              <EditPost
                                edit="edit"
                                role={role}
                                post={post}
                                user={user}
                                setExternalOpen={setExternalOpen}
                                externalOpen={externalOpen}
                              />
                            </Grid>
                          )}
                          <Grid
                            item
                            xs={2}
                            md={2}
                            style={{
                              marginLeft: "0px",
                              marginRight: "20px",
                            }}
                          >
                            <AddSchedule
                              edit="edit"
                              role={role}
                              post={post}
                              user={user}
                              setExternalOpen={setExternalOpen}
                              externalOpen={externalOpen}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Grid container>
                    <FieldTitle
                      style={{
                        paddingLeft: "calc(30px - 18px)",
                        fontWeight: 900,
                        fontSize: "16px",
                        fontFamily: "Avenir",
                        textTransform: "uppercase",
                      }}
                    >
                      {post.title}
                    </FieldTitle>

                    {!!post.video_url?.length || !!images?.length ? (
                      <>
                        <div
                          id={
                            post.video_url && !images.length ? "carousel" : ""
                          }
                        ></div>
                        <div
                          className={classes.placeholderImg}
                          id={
                            post.video_url && !images.length
                              ? "image-placeholder"
                              : ""
                          }
                        >
                          <img src={playButton} alt="Screenshot Placeholder" />
                        </div>
                      </>
                    ) : (
                      <>
                        {!!post.graphicsBrief?.length &&
                          formatGraphicsBrief(post) && (
                            <Box
                              className={classes.contentBriefContainer}
                              id="contentBriefContainer"
                            >
                              <Typography className={classes.briefTitle}>
                                Creative Content Brief
                              </Typography>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: formatGraphicsBrief(post),
                                }}
                              />
                            </Box>
                          )}
                      </>
                    )}

                    {post.body && (
                      <div
                        className={cardContent}
                        style={{ padding: "12px", width: "100%" }}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: linkifyHtml(
                              post.body?.trim() !== ""
                                ? post.body
                                : post.title || " ",
                              {
                                target: {
                                  url: "_blank",
                                },
                              }
                            ),
                          }}
                        />
                      </div>
                    )}
                    {/* {post.body && (
                      <div
                        className={cardContent}
                        style={{ padding: "12px", width: "100%" }}
                      >
                        <ReadMore
                          readMoreCharacterLimit={100}
                          showLessButton={true}
                          text={post.body}
                          post={post}
                          role={""}
                        />
                      </div>
                    )} */}

                    <br />
                    {post?.tags?.length > 0 && (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          padding: "12px",
                        }}
                      >
                        {post?.tags?.length > 0 &&
                          post.tags.map((val, i) => (
                            <span
                              key={i}
                              style={{
                                fontSize: "15px",
                                fontFamily: "Avenir",
                                color: "#000000DE",
                                marginRight: 10,
                              }}
                            >
                              #{`${val} `}{" "}
                            </span>
                          ))}
                      </div>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      marginBottom: "20px",
                      paddingBottom: "20px",
                    }}
                    container
                  >
                    <Carousel
                      images={images.length > 1 ? images : images}
                      video={post.video_url}
                    />
                  </Grid>
                  <Grid
                    container
                    style={{ marginTop: "20px", padding: "12px" }}
                  >
                    <Grid xs={12} md={6}>
                      <p
                        style={{
                          fontWeight: 900,
                          fontSize: "15px",
                          color: "#000000",
                          fontFamily: "Avenir",
                        }}
                      >
                        Visible to Client
                      </p>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "15px",
                          color: "#000000",
                          width: "38px",
                          height: "24px",
                          top: "20px",
                          left: "20px",
                          padding: "4px 8px 4px 8px",
                          borderRadius: "8px",
                          gap: "8px",
                          backgroundColor:
                            post.is_visible_to_client === "Yes"
                              ? "#AEDBAD"
                              : "#EAA2AF",
                        }}
                      >
                        {post.is_visible_to_client ?? "No"}
                      </p>
                    </Grid>

                    <Grid xs={12} md={6}>
                      <p
                        style={{
                          fontWeight: 900,
                          fontSize: "15px",
                          color: "#000000",
                          fontFamily: "Avenir",
                        }}
                      >
                        Post Approval
                      </p>

                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "15px",
                          color: "#000000",
                        }}
                      >
                        <span
                          style={{
                            paddingRight: 10,
                          }}
                        >
                          {/* {getIcons(post.internal_status_weekly)} */}
                          {
                            <EventIcon
                              category={post.internal_status_weekly}
                              width={24}
                              height={24}
                              borderRadius={4}
                            />
                          }
                        </span>
                        {post.internal_status_weekly}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>{" "}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          id="actions-post"
          style={{
            padding: "25px",
          }}
        >
          <Context
            style={{ margin: authUser.role !== "viewerAdmin" ? 0 : "-26px" }}
          >
            <Notes
              user={authUser}
              note={note}
              setNote={setNote}
              setNoteType={setNoteType}
              noteType={noteType}
              internalNotes={internalNotes}
              externalNotes={externalNotes}
              post={post}
              client={user}
            />
          </Context>
        </Grid>
      </Grid>
    </SocialPostContainer>
  );
};

export default memo(SocialPostV2);
