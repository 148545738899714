/**
 * @prettier
 */
import React, { useState, FunctionComponent, Fragment } from "react";
import {
  Modal,
  Backdrop,
  Fade,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { useStyles } from "./styles";

import { SendButton, CancelButton } from "components/styled/Common";
import { ReactComponent as TrashCan } from "../../assets/trashcan.svg";
import { SchedulePostService } from "services/SchedulePostService";

interface IDelete {
  id: any;
  status: string;
}

export const DeletePost: FunctionComponent<IDelete> = ({ id, status }) => {
  const classes = useStyles({});

  const [isOpen, setIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState("");

  const handleDelete = () => {
    // Implement your delete logic here
    setSubmitting(true);

    const Post = new SchedulePostService("scheduled-posts", id);

    Post.deletePost()
      .then(() => {
        setSubmitting(false);
      })
      .catch((e) => {
        setSubmitting(false);

        console.log(e);
      });
  };

  const handleClose = (e) => {
    if (e.key === "Escape") {
      setIsOpen(false);
    }
  };

  const disabledStyles = {
    opacity: 0.5,
    cursor: "not-allowed",
    // Add any other styles you want for the disabled state
  };

  return (
    <Fragment>
      <IconButton
        aria-label="delete"
        color="secondary"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        disabled={status === "Posted"}
        style={status === "Posted" ? disabledStyles : undefined}
      >
        <TrashCan />
      </IconButton>

      <Modal
        open={isOpen}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.container}>
            <div className={classes.paper}>
              <div className={classes.top}>
                <div className={classes.print}>Confirmation Needed</div>
                <Close
                  className={classes.icon}
                  onClick={() => setIsOpen(!isOpen)}
                />
              </div>
              <div style={{ marginTop: "30px", marginBottom: "30px" }}>
                You are about to delete a post. Are you sure?
              </div>
            </div>
            <span className={classes.error}>{error}</span>

            <div className={classes.line} />
            <div
              className={classes.bottom}
              style={{ justifyContent: "flex-between" }}
            >
              <CancelButton disableRipple onClick={() => setIsOpen(!isOpen)}>
                cancel
              </CancelButton>
              <SendButton onClick={() => handleDelete()} disableRipple>
                {submitting ? (
                  <CircularProgress size={30} color="inherit" />
                ) : (
                  <span>Delete Post</span>
                )}
              </SendButton>
            </div>
          </div>
        </Fade>
      </Modal>
    </Fragment>
  );
};
