import firebase from "firebase/app";
import cuid from "cuid";

import { IAgent } from "interfaces/IAgent";
import { AgentService } from "services/AgentService";
import { sendSignUpInvite } from "../requests/sendSignUpEmail";
import { sendLoginInvite } from "../requests/sendSignInEmail";
import { success } from "../../../utils/notification";
import { isSecondarySignedUp } from "../helpers/isSecondarySignedUp";

const base64 = require("js-base64").Base64;

export const sendSecondaryInvites = async (user: any) => {
  /* if email is new : send a signup invite and add new agent 
    else send a login invite and update agent's clients */
  const updateEmails: Array<any> = [];
  if (user.secondaryEmails) {
    let invites = 0;
    for (const item of user.secondaryEmails) {
      item.email = item.email.toLowerCase();
      if (!item.isInvited) {
        invites++;
        const newAgent = new AgentService();
        const agents = await newAgent.getAgentByField("email", item.email);
        if (!!agents.length) {
          const Agent = new AgentService(agents[0].id);
          await Agent.updateAgent({
            clients: firebase.firestore.FieldValue.arrayUnion(user.email),
          })
            .then(async () => {
              item.isInvited = true;
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          const uuid: string = cuid();
          const newAgent = new AgentService(uuid);
          await newAgent
            .addAgent({
              email: item.email,
              clients: [user.email],
              created_at: new Date(),
            } as IAgent)
            .then(async () => {
              item.isInvited = true;
            })
            .catch((error) => {
              console.log(error);
            });
        }
        const signedUp = await isSecondarySignedUp(item.email);
        if (signedUp) {
          await sendLoginInvite(item.email, user.clientName, "user");
        } else {
          await sendSignUpInvite(
            item.email,
            user.clientName,
            "email",
            user.email
          );
        }
      }
      updateEmails.push(item);
    }
    if (invites > 0) {
      success("Invite sent successfully!");
    }
  }
  return updateEmails;
};

export const inviteLinks = (primaryEmail, secondaryEmail, type) => {
  const baseUrl = process.env.REACT_APP_KIT_BASE_URL;
  const encodedEmail = base64.encode(primaryEmail);
  const inviteLink =
    type === "primary"
      ? `${baseUrl}sign-up?q=${primaryEmail}`
      : `${baseUrl}sign-up?q=${secondaryEmail}&k=${encodedEmail}`;

  return inviteLink;
};
