import React, { FC, useState, useEffect } from "react";
import {
  Modal,
  Button,
  Backdrop,
  Fade,
  FormControl,
  Tooltip,
} from "@material-ui/core";
import { Close, Print as PrintIcon } from "@material-ui/icons";
import { DateTime } from "luxon";

import { useStyles } from "./styles";
import {
  audience,
  statsCount,
  fbSelectedLen,
  igSelectedLen,
  twitterSelectedLen,
  tikTokSelectedLen,
  youTubeSelectedLen,
  spotifySelectedLen,
  appleMusicSelectedLen,
} from "./constants";
import { SendButton, CancelButton } from "components/styled/Common";

import { handlePrint } from "./helpers/handlePrint";
import { handleNormalMode } from "./helpers/handleNormalMode";
import MediaPrintMode from "./MediaPrintMode";
import getInitialState from "./initialState";
import preventScroll from "utils/preventScroll";

export interface IStats {
  id: string;
  value: string;
  checked: boolean;
  media?: string[];
}

interface IPrintMode {
  to: Date;
  from: Date;
  user: any;
}

const PrintMode: FC<IPrintMode> = ({ to, from, user }) => {
  const classes = useStyles({});

  const [isOpen, setIsOpen] = useState(false);
  const [isPrintMode, setIsPrintMode] = useState(true);

  const initialState = getInitialState(statsCount, audience);

  const [selectedStats, setSelectedStats] = React.useState(initialState);
  const [windowOffset, setWindowOffset] = useState(window.scrollY);

  const periodStart = DateTime.fromJSDate(from).toFormat("dd LLL yyyy");
  const periodEnd = DateTime.fromJSDate(to).toFormat("dd LLL yyyy");

  const checkPlatform = (platform) => {
    switch (platform) {
      case "fbStats":
        return "facebook";
      case "twitterStats":
        return "twitter";
      case "igStats":
        return "instagram";
      case "tikTokStats":
        return "tikTok";
      case "youTubeStats":
        return "YouTube";
      case "spotifyStats":
        return "Spotify";
      case "appleMusicStats":
        return "Apple Music";

      default:
        return "";
    }
  };

  useEffect(() => {
    preventScroll(isOpen, windowOffset, setWindowOffset);
    // eslint-disable-next-line
  }, [isOpen]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    value: string,
    platform: string,
    entry: string
  ) => {
    const toUpdate = { ...selectedStats };
    const obj = toUpdate[platform];

    // toggle checked
    obj[entry][index]["checked"] = event.target.checked;

    const idName = `${checkPlatform(platform)}-${value}`;

    // update sections
    const i = obj.selected.indexOf(idName);
    if (i > -1) {
      obj.selected.splice(i, 1);
    } else {
      obj.selected.push(idName);
    }

    setSelectedStats(toUpdate);
  };

  const handleIsOpen = (): void => {
    return setIsOpen(!isOpen);
  };

  const handleClose = (e) => {
    if (e.key === "Escape") {
      setIsOpen(false);
    }
  };

  return (
    <div>
      {isPrintMode ? (
        <Tooltip
          title="Print Mode"
          arrow
          interactive
          classes={{ tooltip: classes.info, arrow: classes.arrow }}
        >
          <PrintIcon
            id="print"
            className={classes.archiveLabel}
            style={{ marginRight: 20, marginBottom: 9, cursor: "pointer" }}
            onClick={handleIsOpen}
          />
        </Tooltip>
      ) : (
        <div className={classes.printmode}>
          <Button
            disableRipple
            type="button"
            classes={{
              label: classes.archiveLabel,
            }}
            onClick={() =>
              handleNormalMode(
                selectedStats,
                fbSelectedLen,
                igSelectedLen,
                twitterSelectedLen,
                tikTokSelectedLen,
                youTubeSelectedLen,
                spotifySelectedLen,
                appleMusicSelectedLen,
                setIsPrintMode,
                isPrintMode
              )
            }
          >
            Normal View
          </Button>
          <div className={classes.period}>
            Period: <span>{periodStart}</span> - <span>{periodEnd}</span>
          </div>
        </div>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.container}>
            <div className={classes.paper}>
              <div className={classes.top}>
                <div className={classes.print}>Print Mode</div>
                <Close className={classes.icon} onClick={handleIsOpen} />
              </div>
              <div className={classes.body}>
                <div className={classes.description}>
                  What do you want to show in Print mode?
                </div>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <MediaPrintMode
                    selectedStats={selectedStats}
                    mediaType="fbStats"
                    mediaName="Facebook"
                    handleChange={handleChange}
                  />
                  <MediaPrintMode
                    selectedStats={selectedStats}
                    mediaType="igStats"
                    mediaName="Instagram"
                    handleChange={handleChange}
                  />
                  <MediaPrintMode
                    selectedStats={selectedStats}
                    mediaType="twitterStats"
                    mediaName="Twitter"
                    handleChange={handleChange}
                  />
                  <MediaPrintMode
                    selectedStats={selectedStats}
                    mediaType="tikTokStats"
                    mediaName="TikTok"
                    handleChange={handleChange}
                  />
                  <MediaPrintMode
                    selectedStats={selectedStats}
                    mediaType="youTubeStats"
                    mediaName="YouTube"
                    handleChange={handleChange}
                  />
                  <MediaPrintMode
                    selectedStats={selectedStats}
                    mediaType="spotifyStats"
                    mediaName="Spotify"
                    handleChange={handleChange}
                  />
                  <MediaPrintMode
                    selectedStats={selectedStats}
                    mediaType="appleMusicStats"
                    mediaName="Apple Music"
                    handleChange={handleChange}
                  />
                </FormControl>
              </div>
            </div>
            <div className={classes.line} />
            <div className={classes.bottom}>
              <div>
                <CancelButton disableRipple onClick={handleIsOpen}>
                  cancel
                </CancelButton>
                <SendButton
                  onClick={() =>
                    handlePrint(
                      selectedStats,
                      fbSelectedLen,
                      igSelectedLen,
                      twitterSelectedLen,
                      tikTokSelectedLen,
                      youTubeSelectedLen,
                      spotifySelectedLen,
                      appleMusicSelectedLen,
                      isPrintMode,
                      isOpen,
                      setIsPrintMode,
                      setIsOpen,
                      user,
                      periodStart,
                      periodEnd
                    )
                  }
                  disableRipple
                >
                  Print Mode
                </SendButton>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default PrintMode;
