import { Grid } from "@material-ui/core";
import React from "react";

const MediaItemDataCardVideo = ({ role, likes, comments }) => {
  return (
    <div>
      <Grid item xs container>
        <Grid xs={6} md={6}>
          <Grid
            xs
            container
            style={{
              color: "#000",
              textAlign: "center",
              fontFamily: "Avenir",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 900,
              lineHeight: "24px",
              letterSpacing: "0.5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {likes}
          </Grid>
          <Grid
            xs
            container
            style={{
              color: "#000",
              fontFamily: "Avenir",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 350,
              lineHeight: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Likes
          </Grid>
        </Grid>
        <Grid xs={6} md={6}>
          <Grid
            xs
            container
            style={{
              color: "#000",
              textAlign: "center",
              fontFamily: "Avenir",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 900,
              lineHeight: "24px",
              letterSpacing: "0.5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {comments}
          </Grid>
          <Grid
            xs
            container
            style={{
              color: "#000",
              fontFamily: "Avenir",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 350,
              lineHeight: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Comments
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default MediaItemDataCardVideo;
