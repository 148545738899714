/**
 * @prettier
 */

import React, { FunctionComponent, ChangeEvent } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ReactComponent as PASSWORD } from "static/media/password.svg";

import { makeStyles } from "@material-ui/core/styles";

type passwordType = {
  value?: string;
  autoFocus?: boolean;
  helperText?: any;
  error?: any;
  name?: string;
  label?: string;
  color?: string;
  marginTop?: string;
  onChangeHandler?: (e: ChangeEvent<HTMLInputElement>) => any;
};

const useStyles = makeStyles((theme) => ({
  text: {
    width: "100%",
    marginTop: (props: any) => props.marginTop,
  },
  backgroundColor: {
    backgroundColor: "#fff",
    height: "45px",
    borderRadius: "10px",
    fontFamily: "Avenir Book, sans-serif",
  },
  underline: {
    "&:after": {
      borderBottom: "1.5px solid #1D4E89",
    },
  },
  label: {
    color: (props: any) => props.color,
    top: -10,
    fontFamily: "Avenir Book, sans-serif",
    fontSize: 16,
  },
  icon: {
    color: "gray",
    marginLeft: "10px",
    width: "29px",
    height: "29px",
  },
  helperText: {
    color: "rgb(209, 44, 0)",
    fontSize: "13x",
    textAlign: "center",
  },
}));

const Password: FunctionComponent<passwordType> = ({
  value = "",
  autoFocus = false,
  helperText = "",
  error = false,
  color = "#fff",
  name = "password",
  label = "Password",
  onChangeHandler,
  marginTop,
}) => {
  const classes = useStyles({ color, marginTop });
  return (
    <TextField
      id="password"
      name={name}
      label={label}
      type="password"
      margin="normal"
      defaultValue={value}
      autoFocus={autoFocus}
      fullWidth
      placeholder="*******"
      error={error}
      helperText={helperText}
      FormHelperTextProps={{
        className: classes.helperText,
      }}
      className={classes.text}
      InputLabelProps={{
        className: classes.label,
      }}
      autoComplete="current-password"
      onChange={onChangeHandler}
      InputProps={{
        className: classes.backgroundColor,
        classes: { underline: classes.underline },
        startAdornment: (
          <InputAdornment position="start">
            <PASSWORD className={classes.icon} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Password;
