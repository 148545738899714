import { Box } from "@material-ui/core";
import React from "react";
type InfoDivProps = {
  tokenType?: string;
};
const FacebookInfo: React.FC<InfoDivProps> = ({ tokenType }: InfoDivProps) => {
  return (
    <Box fontSize={14}>
      <div>
        Open a new tab and go to the website "https://facebook.com" and login if
        you haven't. Right-click anywhere on the page and open "Inspect":
      </div>
      <br />
      <img
        src="https://firebasestorage.googleapis.com/v0/b/be-measured-admin-staging.appspot.com/o/images%2Finspect.jpg?alt=media&token=57df642b-848a-46f4-b955-1b748cdec85e"
        alt="inspect"
        width="174px"
        height="62px"
      />
      <br />

      <div>
        Locate the "Application" tab. Then select "Cookies"{" >"}
        "https://facebook.com". Locate the cookie you're looking for{" "}
        {tokenType || " c_user and xs"}:
      </div>
      <br />
      <img
        src="https://firebasestorage.googleapis.com/v0/b/be-measured-admin-staging.appspot.com/o/images%2Ffacebook-cookies.png?alt=media&token=67bcbdc8-f67b-49e9-85c1-600cc8e979b7"
        alt="auth_token"
        width="98%"
      />
      <br />

      <div>Copy the value of auth_token and paste it back below.</div>
    </Box>
  );
};

const TwitterInfo: React.FC<InfoDivProps> = ({ tokenType }: InfoDivProps) => {
  return (
    <Box fontSize={14}>
      <div>
        Open a new tab and go to the website "https://twitter.com" and login if
        you haven't. Right-click anywhere on the page and open "Inspect":
      </div>
      <br />
      <img
        src="https://firebasestorage.googleapis.com/v0/b/be-measured-admin-staging.appspot.com/o/images%2Finspect.jpg?alt=media&token=57df642b-848a-46f4-b955-1b748cdec85e"
        alt="inspect"
        width="174px"
        height="62px"
      />
      <br />

      <div>
        Locate the "Application" tab. Then select "Cookies"{" >"}
        "https://twitter.com". Locate the cookie you're looking for
        (auth_token):
      </div>
      <br />

      <img
        src="https://firebasestorage.googleapis.com/v0/b/be-measured-admin-staging.appspot.com/o/images%2Fauth_token.png?alt=media&token=f345a8b1-cc4c-4b19-b4b8-ad84d664d8fa"
        alt="auth_token"
        width="98%"
      />
      <br />

      <div>Copy the value of auth_token and paste it back below.</div>
    </Box>
  );
};
const InstagramInfo: React.FC<InfoDivProps> = ({ tokenType }: InfoDivProps) => {
  return (
    <Box fontSize={14}>
      <div>
        Open a new tab and go to the website "https://instagram.com" and login
        if you haven't. Right-click anywhere on the page and open "Inspect":
      </div>
      <br />
      <img
        src="https://firebasestorage.googleapis.com/v0/b/be-measured-admin-staging.appspot.com/o/images%2Finspect.jpg?alt=media&token=57df642b-848a-46f4-b955-1b748cdec85e"
        alt="inspect"
        width="174px"
        height="62px"
      />
      <br />

      <div>
        Locate the "Application" tab. Then select "Cookies"{" >"}
        "https://instagram.com". Locate the cookie you're looking for
        (sessionid):
      </div>
      <br />

      <img
        src="https://firebasestorage.googleapis.com/v0/b/be-measured-admin-staging.appspot.com/o/images%2Fnew-IG_session.png?alt=media&token=0ae09a67-713b-47b6-9add-21fa22c09c6b"
        alt="auth_token"
        width="98%"
      />
      <br />

      <div>Copy the value of sessionId and paste it back below.</div>
    </Box>
  );
};

export { FacebookInfo, TwitterInfo, InstagramInfo };
