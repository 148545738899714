/**
 * @prettierdesc
 */

import React, { FC, useState } from "react";
import { Box, Tooltip, CircularProgress } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import history from "history/browser";
import cuid from "cuid";
import { navigate } from "@reach/router";

import CreateCampaignStyles from "components/styled/CreateCampaign";
import Image from "components/Common/Image/Image";

import facebook from "static/media/fb-vector.png";
import defaultIcon from "static/media/universal-2.png";
import { SendButton } from "components/styled/Common";
import { PageType } from "types/PageType";
import { firestore } from "firebase-tools/firebase";
import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import GetCookies from "components/Common/GetCookies";
import CampaignName from "components/Common/CampaignName";
import { useUser } from "UserContext";
import { IUser } from "interfaces/IUser";

import { SocialMediaPlatform } from "containers/BeAmplified/Campaigns/Organic/SelectCampaign";
import { facebookExtractor } from "helpers/useExtractors";
import { getPlatformImage } from "components/BeAmplified/Campaigns/Organic/Components/SelectCampaignBox";
import showToast from "utils/toast";
import { capitalize } from "utils/stringFormat";
import { useQuery } from "hooks/useQuery";

interface CreateAutoPosterProps extends PageType {
  uid?: string;
  toggleCreate?: Function;
}

export const GroupExtractor: FC<CreateAutoPosterProps> = ({
  toggleCreate,
  uid,
  location,
}) => {
  const [authUser] = useUser() as Array<IUser>;

  const [campaignName, setCampaignName] = useState("");
  const [sessionCookie, setSessionCookie] = useState("");
  const [sessionCookieXS, setSessionCookieXS] = useState("");
  const [spreadsheetUrl, setSpreadsheetUrl] = useState("");
  const [membersCount, setMembersCount] = useState(5000);
  const [errors, setErrors] = useState({
    client: "",
    campaignName: "",
    spreadsheetUrl: "",
    membersCount: "",
  });
  const [loading, setLoading] = useState(false);

  const id = cuid();

  let userRef = firestore.collection("users").doc(authUser.id);
  let client =
    useDocumentSubscriber({ ref: userRef }).document?.lastClientSelection || "";

  const isScrapper = !!useQuery("flow")?.includes("scrappers");

  const createCampaign = async () => {
    if (!client)
      return setErrors((errors: any) => ({
        ...errors,
        client: "Client is required!",
      }));
    if (!campaignName)
      return setErrors((errors: any) => ({
        ...errors,
        campaignName: "Campaign name is required!",
      }));
    if (sessionCookie === "") {
      showToast("Error: Add a Facebook c_user session Cookie to proceed");
      return;
    }
    if (sessionCookieXS === "") {
      showToast("Error: Add a Facebook xs session Cookie to proceed");
      return;
    }
    if (!spreadsheetUrl)
      return setErrors((errors: any) => ({
        ...errors,
        spreadsheetUrl: "Spreadsheet Url is required!",
      }));

    if (!spreadsheetUrl.includes("https://docs.google.com/spreadsheets/d/")) {
      return setErrors((errors: any) => ({
        ...errors,
        spreadsheetUrl: "Enter a valid spreadsheet URL!",
      }));
    }
    if (!membersCount || membersCount > 5000 || membersCount < 1) {
      return setErrors((errors: any) => ({
        ...errors,
        membersCount:
          "Members to extract per group should be between 1 to 5000!",
      }));
    } else {
      setLoading(true);
      const campaignType = window.location.href.includes("scrappers")
        ? "groupScrapper-scrapper"
        : "groupScrapper";
      const res = await facebookExtractor({
        id,
        spreadsheetUrl,
        sessionCookie,
        sessionCookieXS,
        campaignName,
        username: authUser.lastClientSelection.facebook.username,
        membersCount,
        campaignType,
      });
      if (res?.error) {
        showToast("Error: An error occured try again later");
        setLoading(false);
        return;
      }
      setCampaignName("");
      setSessionCookie("");
      setSpreadsheetUrl("");
      setSessionCookieXS("");
      setLoading(false);
      navigate(
        `/campaigns/organic/extractor/${SocialMediaPlatform.Facebook.toLowerCase()}/${id}`
      );
    }
  };

  return (
    <CreateCampaignStyles>
      <ToastContainer />
      <div>
        <span className="title__main">{uid ? "Edit" : "Create"} Campaign</span>
        <Box marginTop="30px" marginBottom="30px">
          <div className="items-center">
            <Image className="img__main" src={facebook || defaultIcon} alt="" />
            <span className="title__sub">Facebook Group Extractor</span>
          </div>
        </Box>
      </div>
      <div
        style={{
          margin: "40px 0px -20px 0px",
          display: `${isScrapper ? "none" : "flex"}`,
        }}
        className="campaign__container"
      >
        <span className="title__basic" style={{ marginBottom: 10 }}>
          Client name
        </span>
        <input
          type="text"
          value={`${authUser.lastClientSelection.firstName} ${authUser.lastClientSelection.lastName}`}
          style={{ color: "#606060" }}
          className="campaign__input description__sub campaign__input--disabled"
        />
      </div>

      <CampaignName
        campaignName={campaignName}
        setCampaignName={setCampaignName}
        error={errors.campaignName}
        setErrors={setErrors}
      />

      <GetCookies
        setSessionCookie={setSessionCookie}
        sessionCookie={sessionCookie}
        platform={SocialMediaPlatform.Facebook}
        title={`Your ${capitalize(
          SocialMediaPlatform.Facebook
        )} c_user session cookie`}
        platformIcon={getPlatformImage(SocialMediaPlatform.Facebook)}
      />

      <GetCookies
        setSessionCookie={setSessionCookieXS}
        sessionCookie={sessionCookieXS}
        platform={SocialMediaPlatform.Facebook}
        title={`Your ${capitalize(
          SocialMediaPlatform.Facebook
        )} xs session cookie`}
        platformIcon={getPlatformImage(SocialMediaPlatform.Facebook)}
      />

      {/* More info */}
      <Box marginTop="40px" className="connection-container">
        <div className="campaign__container">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <span className="title__basic">
              Spreadsheet URL of Facebook groups you would like to scrape
            </span>
            <Tooltip
              title={
                <Box>
                  <Box component={"h1"} lineHeight={1.2}>
                    Specify the Facebook Group URLs you want to automatically
                    scrap.
                  </Box>
                  <Box fontSize={14}>
                    <div>
                      Create a spreadsheet on{" "}
                      <a
                        href="https://docs.google.com/spreadsheets/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Google Spreadsheet.
                      </a>{" "}
                      Fill it with your Facebook group URLs. One name per row,
                      all in column A:
                    </div>
                    <br />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/be-measured-admin-staging.appspot.com/o/images%2FScreenshot%202022-03-16%20at%2014.29.49.png?alt=media&token=4e918160-5a19-42e6-a817-8f76158f71ad"
                      alt="fb group urls"
                      width="88%"
                    />
                    <br />

                    <div>Make this spreadsheet public so we can access it:</div>
                    <br />

                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/be-measured-admin-staging.appspot.com/o/images%2Fshare%20spreadsheet.png?alt=media&token=d1aaa739-4d5a-40a0-9562-8b82a633d61b"
                      alt="share spreadsheet"
                      width="98%"
                    />
                    <br />

                    <div>And paste that spreadsheet's URL back here.</div>
                  </Box>
                </Box>
              }
              arrow
              interactive
            >
              <InfoIcon style={{ color: "#C4C4C4" }} />
            </Tooltip>
          </Box>
          <span className="description__sub">
            Must be a google spreadsheet URL.
          </span>
          <input
            type="text"
            value={spreadsheetUrl}
            placeholder="https://docs.google.com/spreadsheets/d/(...)"
            onChange={(e) => {
              setSpreadsheetUrl(e.target.value);
              setErrors((errors: any) => ({
                ...errors,
                spreadsheetUrl: "",
              }));
            }}
            className="campaign__input"
          />
          {errors.spreadsheetUrl && (
            <span style={{ color: "red", fontSize: 14, paddingLeft: 6 }}>
              {errors.spreadsheetUrl}
            </span>
          )}
        </div>
        <div className="campaign__container">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <span className="title__basic">
              Number of members to extract per group
            </span>
            <Tooltip
              title="Number of tweets to like per profile per launch"
              arrow
              interactive
            >
              <InfoIcon style={{ color: "#C4C4C4" }} />
            </Tooltip>
          </Box>
          <span className="description__sub">
            We recommend you process a maximum of 4000-5000 members per group in
            order to keep your account safe.{" "}
          </span>
          <input
            type="number"
            value={membersCount}
            className="campaign__input campaign__input--disabled"
            onChange={(e) => setMembersCount(Number(e.target.value))}
          />
          {errors.membersCount && (
            <span style={{ color: "red", fontSize: 14, paddingLeft: 6 }}>
              {errors.membersCount}
            </span>
          )}
        </div>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop="40px"
      >
        <div
          className="title__sub link"
          onClick={() => {
            toggleCreate && toggleCreate();

            !toggleCreate && history.back();
          }}
        >
          {"<"} Back
        </div>
        <SendButton
          onClick={(e) => {
            e.preventDefault();
            !loading && createCampaign();
          }}
          className="btn"
          disabled={loading}
        >
          {uid
            ? `Updat${loading ? "ing " : "e "}`
            : `Launch${loading ? "ing " : " "}`}{" "}
          Campaign
          {loading && <CircularProgress className="btn-progress" size={20} />}
        </SendButton>
      </Box>
    </CreateCampaignStyles>
  );
};
