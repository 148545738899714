/**
 * @prettier
 */

import React, { FunctionComponent } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import { PageType } from "types/PageType";

import { useStyles } from "./styles";
import AdminSettingsComponent from "components/AdminSettings/index";
import Popup from "components/AdminSettings/Popup";
import { Toast } from "components/styled/Common";

export const AdminSettings: FunctionComponent<PageType> = () => {
  const classes = useStyles({});
  return (
    <div className={classes.root}>
      <Toast>
        <ToastContainer progressClassName="toast-progressbar" />
      </Toast>
      <div className={classes.pageTitle} id="page-title">
        <span style={{ marginTop: 32 }} id="admin-settings">
          Admin Settings
        </span>
        <div className={classes.modals}>
          <Popup adminAdd="adminAdd" />
        </div>
      </div>
      <div
        id="admin-table"
        style={{
          boxShadow: "box-shadow: 0px 5px 45px rgba(0, 0, 0, 0.07) !important",
        }}
      >
        <TableContainer
          component={Paper}
          style={{ borderRadius: 15, boxShadow: "none" }}
        >
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow className={classes.tableHeader}>
                <TableCell className={`${classes.tableCell} ${classes.header}`}>
                  Email Address
                </TableCell>
                <TableCell className={`${classes.tableCell} ${classes.header}`}>
                  Full Name
                </TableCell>
                <TableCell className={`${classes.tableCell} ${classes.header}`}>
                  Permissions
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <AdminSettingsComponent />
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
