import React, { FunctionComponent } from "react";

import { useStylesV2 } from "../stylesV2";
// import MediaItem from "./MediaItem";
import ErrorMessage from "components/Error/ErrorMessage";
import MediaItemInstagramVideoV2 from "./MediaItemInstagramVideoV2";

interface MediaProps {
  posts: Array<any>;
  role: string;
  igMetadata?: any;
  fbMetadata?: any;
  avatar: any;
}
const InstagramMediaVideo: FunctionComponent<MediaProps> = ({
  posts,
  role,
  igMetadata,
  fbMetadata,
  avatar,
}) => {
  const classes = useStylesV2({});
  let postsMarkup;
  if (posts?.length > 0) {
    postsMarkup = posts.map((post, index) => (
      <MediaItemInstagramVideoV2
        post={post}
        key={index}
        role={role}
        igMetadata={igMetadata}
        fbMetadata={fbMetadata}
        avatar={avatar}
      />
    ));
  }

  return (
    <div
      style={{ marginBottom: "0px" }}
      id={`${role}-Top 3 highest engagement posts`}
    >
      {posts?.length === 0 ? (
        <ErrorMessage message=" No available posts for this period" />
      ) : (
        <div className={classes.media} id="media">
          {postsMarkup}
        </div>
      )}
    </div>
  );
};

const areEqual = (prevProps, nextProps): any => {
  if (prevProps.posts === nextProps.posts) {
    return true;
  }
};

export default React.memo(InstagramMediaVideo, areEqual);
