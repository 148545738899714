import React, { FC } from "react";

import { firestore } from "firebase-tools/firebase";
import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import AutoDmPage from "components/BeAmplified/Campaigns/Organic/View/AutoDm";
import { SocialMediaPlatform } from "../SelectCampaign";

type CampaignType = {
  uid?: string;
  platform?: SocialMediaPlatform;
  path: string;
};

const AutoDmCampaignPage: FC<CampaignType> = ({ uid, platform }) => {
  const campaignsRef = firestore
    .collection(
      platform?.toLocaleLowerCase() ===
        SocialMediaPlatform.Facebook.toLocaleLowerCase()
        ? "facebook-auto-dm"
        : "twitter-auto-dm"
    )
    .doc(uid);
  const campaign = useDocumentSubscriber({ ref: campaignsRef }).document;

  return (
    <div>
      {campaign?.status && (
        <AutoDmPage campaign={campaign} platform={platform} />
      )}
    </div>
  );
};

export default AutoDmCampaignPage;
