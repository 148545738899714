import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  noClient: {
    fontSize: "18px",
    lineHeight: "21px",
    color: "#000000",
    fontFamily: "'Avenir Book', sans-serif",
    paddingTop: "73px",
  },
});
