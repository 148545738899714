import { SocialMediaPlatform } from "containers/BeAmplified/Campaigns/Organic/SelectCampaign";
import { useEffect, useState } from "react";
import { getContainerPosts } from "./getContainerPosts";

export const useContainerOutput = (
  campaign: any,
  platform: SocialMediaPlatform | any
) => {
  const [data, setData] = useState<any | null>(null);

  useEffect(() => {
    const getPosts = async (platform, campaign) => {
      switch (platform) {
        case SocialMediaPlatform.Twitter:
          setData(
            campaign.followResult && campaign.followResult !== "[]"
              ? JSON.parse(campaign.followResult)
              : null
          );
          break;
        case SocialMediaPlatform.Instagram:
          const posts = await getContainerPosts(
            campaign.containerId,
            campaign.hashtags
          );

          setData(posts);
          break;
        case `${SocialMediaPlatform.Facebook}-scrappers`:
          setData(campaign?.resultObject);
          break;

        case `${SocialMediaPlatform.Instagram}-scrappers`:
          const igScrapperPosts = await getContainerPosts(
            campaign.containerId,
            [""]
          );

          setData(igScrapperPosts);
          break;
      }
    };
    getPosts(platform, campaign);
  }, [platform, campaign]);

  return data?.csvURL || data?.jsonUrl ? null : data;
};
