import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  pageHeader: {
    height: "auto",
    fontStyle: "normal",
    fontSize: "30px",
    lineHeight: "42px",
    color: "#000000",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "70px 0 40px 0",
    fontFamily: "Avenir Black, sans-serif",
  },
  title: {
    fontWeight: 900,
    lineHeight: "40px",
  },
});
