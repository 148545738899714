import styled from "styled-components";

const LoaderStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "200px")};
  width: ${(props) => (props.width ? props.width : "100%")};
`;

export default LoaderStyles;
