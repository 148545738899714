import React, { FC } from "react";
import Chart from "react-apexcharts";

import { useStyles } from "./styles";
// import { numberWithCommas } from "utils/numberFormat";

interface IChart {
  metrics: any;
  title: string;
}
const AreaChart: FC<IChart> = ({ metrics, title }) => {
  const classes = useStyles({});

  return (
    <div>
      <div className={classes.chartTitle}>{title}</div>
      <div className={classes.chartContainer}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "5px 38px 25px 38px",
          }}
        >
          <div className={classes.areaChartLegend}></div>
        </div>
        <Chart
          options={metrics.areaOptions}
          series={metrics.series}
          type="area"
          height={250}
        />
      </div>
    </div>
  );
};

const areEqual = (prevProps, nextProps): any => {
  if (prevProps.metrics === nextProps.metrics) {
    return true;
  }
};

export default React.memo(AreaChart, areEqual);
