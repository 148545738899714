import React, { FC, useState, useEffect } from "react";
import {
  Modal,
  Fade,
  Backdrop,
  Tooltip,
  ClickAwayListener,
} from "@material-ui/core";
import { Close, Pages as RetainerIcon } from "@material-ui/icons";
import styled from "styled-components";
import moment from "moment";
import { DateRange } from "materialui-daterange-picker";

import { useStyles } from "components/BePlanned/styles";
import { useStyles as styles } from "components/Common/SingleSelect/styles";
import DateRangePicker from "components/Common/DateRange";
import { CancelButton, SendButton, PostPaper } from "components/styled/Common";

// templates
import Light from "./templates/light";
import Basic from "./templates/basic";
import Standard from "./templates/standard";
import Premium from "./templates/premium";
import { firestore } from "../../firebase-tools/firebase";
import { FieldTitle } from "components/styled/CreateEditPost";

const TemplateSection = styled.div`
  max-height: 400px;
  overflow: auto;
  padding: 0 5px;

  dt {
    font-size: 16px;
  }

  dd {
    padding: 8px 0;
    margin-left: 18px;
    font-size: 14px;
  }
`;

const RetainerTemplate: FC<{ user: any }> = ({ user }) => {
  const classes = useStyles({});
  const classes2 = styles({});

  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selected, setSelected] = useState("Choose a Template");
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [error, setError] = useState("");

  const monthFallBack = moment().format("MMMM");
  const yearFallBack = moment().format("YYYY");

  let calendarDate = document.getElementsByClassName("fc-toolbar-title");
  let [month, year]: any = calendarDate[0]?.innerHTML.split(" ") || [
    `${monthFallBack} ${yearFallBack}`,
  ];
  month = Number(moment().month(month).format("M"));
  const startDate = moment([year, month - 1]).format("YYYY-MM-DD");

  let [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: moment().startOf("month").toDate(),
    endDate: moment().endOf("month").toDate(),
  });

  useEffect(() => {
    setDateRange({
      startDate: moment(new Date(startDate)).startOf("month").toDate(),
      endDate: moment(new Date(startDate)).endOf("month").toDate(),
    });
  }, [startDate]);

  const options = [
    { name: "Light", id: "light" },
    { name: "Basic", id: "basic" },
    { name: "Standard", id: "standard" },
    { name: "Premium", id: "premium" },
  ];

  const daysObj = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  };

  const selectedTemplateKeys = Object.keys(selectedTemplate);

  const templates = { Light, Basic, Standard, Premium };

  const handleIsOpen = () => setIsOpen(!isOpen);
  const handleToggle = () => {
    setDropdownOpen(!dropdownOpen);
    setError("");
  };
  const handleClose = (e) => {
    if (e.key === "Escape") {
      setIsOpen(false);
    }
  };

  const createRetainer = (currentDayObj, day, posts) => {
    currentDayObj.forEach((obj) => {
      const numberOfHours = Number(moment(obj.time, "hh:mm A").format("HH"));
      const date: any = moment(new Date(day))
        .add(numberOfHours, "hours")
        .toDate();

      const post = {
        date: new Date(date),
        title: obj.title,
        body: obj.title,
        image_url: "",
        video_url: "",
        user_email: user.email,
        internal_status_monthly: "Draft",
        internal_status_weekly: "Draft",
        client_status_weekly: "Draft",
        created_at: new Date(),
        category: obj.category,
        channels: obj.channels,
      };

      posts.push(post);
    });
  };

  const batchCreate = async (posts) => {
    const batchArray: any = [];
    batchArray.push(firestore.batch());
    let operationCounter = 0;
    let batchIndex = 0;
    const multichannelRef = firestore.collection("multichannel-posts");

    for (const post of posts) {
      const id = multichannelRef.doc().id;
      const ref = multichannelRef.doc(id);

      await batchArray[batchIndex].set(ref, {
        ...post,
      });

      operationCounter++;
      if (operationCounter === 499) {
        batchArray.push(firestore.batch());
        batchIndex++;
        operationCounter = 0;
      }
    }

    batchArray.forEach(async (batch: any) => await batch.commit());
    handleIsOpen();
    return;
  };

  const getDates = (startDate, endDate) => {
    let currentDate = new Date(startDate.getTime());
    let between: any = [];

    while (currentDate <= endDate) {
      between.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return between;
  };

  const insertRetainer = async () => {
    const days = getDates(dateRange.startDate, dateRange.endDate);

    if (!selectedTemplateKeys.length) return setError("Template is required!");

    const posts: any[] = [];

    days.forEach(async (day) => {
      const date = new Date(day);

      const dayOfWeek = moment(date).day();
      const currentDayObj = selectedTemplate[daysObj[dayOfWeek]];

      await createRetainer(currentDayObj, day, posts);
    });

    await batchCreate(posts);
  };

  return (
    <div id="retainer">
      <Tooltip
        title="Insert Retainer Template"
        arrow
        interactive
        classes={{ tooltip: classes.info, arrow: classes.arrow }}
      >
        <RetainerIcon
          id="retainer"
          className={classes.archiveLabel}
          style={{ marginRight: 20, marginBottom: -7, cursor: "pointer" }}
          onClick={handleIsOpen}
        />
      </Tooltip>

      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={isOpen}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={isOpen}>
            <div className={classes.container}>
              <PostPaper>
                <div className={classes.top}>
                  <div className={classes.print}>Insert Retainer Template</div>
                  <Close className={classes.icon} onClick={handleIsOpen} />
                </div>
                <div style={{ paddingTop: 20 }} />
                <FieldTitle>Choose Period</FieldTitle>
                <div
                  className={`${classes.datePicker} ${classes.retainerDatePicker}`}
                >
                  <DateRangePicker
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    hidePeriod={true}
                  />
                </div>
                <FieldTitle>Choose Template</FieldTitle>
                <div className={classes2.selectContainer}>
                  <div
                    style={{
                      borderBottomLeftRadius: dropdownOpen ? "0px" : "10px",
                      borderBottomRightRadius: dropdownOpen ? "0px" : "10px",
                      color:
                        selected === "Choose a Template" ? "#ABADAD" : "#000",
                      border: dropdownOpen
                        ? "2px solid #000"
                        : "2px solid #ABADAD",
                      marginBottom: !dropdownOpen ? 25 : 0,
                    }}
                    className={
                      dropdownOpen
                        ? `${classes2.selectedText} ${classes2.selectedTextActive}`
                        : classes2.selectedText
                    }
                    onClick={handleToggle}
                  >
                    {selected === "Choose a Template" ? (
                      <span>Choose a template</span>
                    ) : (
                      <span>{selected}</span>
                    )}
                  </div>
                  {dropdownOpen && (
                    <ClickAwayListener onClickAway={handleClose}>
                      <ul className={classes2.selectOptions}>
                        {options.map((option) => {
                          return (
                            <li
                              data-name={option.name}
                              key={option.id}
                              onClick={() => {
                                handleToggle();
                                setSelected(option.name);
                                setSelectedTemplate(templates[option.name]);
                                setError("");
                              }}
                              style={{
                                backgroundColor:
                                  selected === option.name
                                    ? "rgba(0, 0, 0, 0.08)"
                                    : "#fff",
                              }}
                            >
                              {option.name}
                            </li>
                          );
                        })}
                      </ul>
                    </ClickAwayListener>
                  )}{" "}
                  {error && <span style={{ color: "red" }}>{error}</span>}
                  {!!selectedTemplateKeys.length && (
                    <TemplateSection>
                      <h3 style={{ marginTop: 0 }}>Template: {selected}</h3>
                      {selectedTemplateKeys.map((day, i) => (
                        <>
                          <dl>
                            <dt>
                              <strong>{day}</strong>
                            </dt>
                            {selectedTemplate[day].map(
                              ({ title, time, category }) => (
                                <dd>
                                  - '{title}' at {time} on{" "}
                                  {Object.values(category).flat(1).join(", ")}
                                </dd>
                              )
                            )}
                          </dl>
                          {selectedTemplateKeys.length - 1 !== i && (
                            <div className={classes.line} />
                          )}
                        </>
                      ))}
                    </TemplateSection>
                  )}
                </div>
                <div style={{ paddingBottom: 20 }} />
                <div className={classes.line} />
                <div
                  className={classes.bottom}
                  style={{
                    margin: 0,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <CancelButton disableRipple onClick={handleIsOpen}>
                      Cancel
                    </CancelButton>
                    <SendButton
                      onClick={insertRetainer}
                      disableRipple
                      style={{ marginRight: 0 }}
                    >
                      Insert Template
                    </SendButton>
                  </div>
                </div>
              </PostPaper>
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  );
};

export default RetainerTemplate;
