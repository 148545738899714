import React, { ReactNode, FC } from "react";
import { Modal, Backdrop, Fade, CircularProgress } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { useStyles } from "./styles";
import { SendButton, CancelButton, PostPaper } from "components/styled/Common";

interface Props {
  children: ReactNode;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  submitting?: boolean;
  handleSubmit: () => void;
  title: string;
  triggerNode?: ReactNode;
  submitButtonText?: string;
  customSubmitButton?: ReactNode;
}

const ModalWrapper: FC<Props> = (props) => {
  const {
    children,
    isOpen,
    setIsOpen,
    submitting,
    handleSubmit,
    title,
    triggerNode,
    customSubmitButton,
    submitButtonText = "Submit",
  } = props;
  const classes = useStyles({});

  const close = () => {
    setIsOpen(false);
  };
  return (
    <div>
      {triggerNode}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableBackdropClick
      >
        <Fade in={isOpen}>
          <div className={classes.container}>
            <PostPaper>
              <div className={classes.top}>
                <div className={classes.print}>{title}</div>
                <Close className={classes.icon} onClick={close} />
              </div>
              <div className={classes.postSection} id="post-section">
                {children}
              </div>
            </PostPaper>
            <div className={classes.line} />
            <div className={`${classes.bottom} ${classes.bottomRespCommon}`}>
              <CancelButton disableRipple onClick={close}>
                cancel
              </CancelButton>
              {customSubmitButton ? (
                customSubmitButton
              ) : (
                <SendButton
                  onClick={handleSubmit}
                  disabled={submitting}
                  disableRipple
                >
                  {submitting ? (
                    <CircularProgress size={30} color="inherit" />
                  ) : (
                    <span>{submitButtonText}</span>
                  )}
                </SendButton>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ModalWrapper;
