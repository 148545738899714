import { INotification, NotificationStatus } from "interfaces/INotification";
import { NotificationService } from "services/NotificationService";

export const updateNotificationStatus = async (
  status: NotificationStatus,
  notification: INotification
) => {
  const Notification = new NotificationService(
    "notifications",
    notification.id
  );

  let payload = { ...notification, status };

  Notification.updatePost(payload)
    .then(async () => {})
    .catch((e) => {
      console.log("ERROR:", e);
      return;
    });
};
export const bulkMarkNotificationStatus = async (
  status: NotificationStatus,
  notifications: INotification[]
) => {
  for (let notification of notifications) {
    const Notification = new NotificationService(
      "notifications",
      notification.id
    );

    let payload = { ...notification, status };

    Notification.updatePost(payload)
      .then(async () => {})
      .catch((e) => {
        console.log("ERROR:", e);
        return;
      });
  }
};
