import React, { FC, useState } from "react";
import Collapse from "@material-ui/core/Collapse";

import { IUser } from "interfaces/IUser";
import { useStyles } from "components/BePlanned/styles";
import { useStyles as useNoteStyles } from "components/BePlanned/Notes/styles";
import { Title, ExpandButton } from "components/BePlanned/Notes";
import NoteField from "components/BePlanned/Notes/NoteField";
import Note, { NoteType } from "components/BePlanned/Notes/Note";

interface Props {
  user: IUser;
  note: string;
  setNote: (note: string) => void;
  notes: Array<any>;
  event?: any;
  setTempNotes: (notes: Array<any>) => void;
  setNoteType?: (type: string) => void;
  noteType?: string;
  client: IUser;
}

const Notes: FC<Props> = ({
  user,
  note,
  setNote,
  notes,
  event,
  setTempNotes,
  noteType,
  setNoteType,
  client,
}) => {
  const classes = useStyles({});
  const notesClass = useNoteStyles({});
  const [notesOpen, setNotesOpen] = useState(true);

  return (
    <div className={classes.field} style={{ width: "94%", marginTop: 30 }}>
      <Title>Write a Comment</Title>
      <NoteField
        user={user}
        note={note}
        setNote={setNote}
        post={event}
        setTempNotes={setTempNotes}
        tempNotes={notes}
        collection={"events"}
        noteType={noteType}
        setNoteType={setNoteType}
        client={client}
      />
      <div className={notesClass.noteTitle}>
        <Title style={{}}>Internal Comments {"(" + notes.length + ")"}</Title>
        <ExpandButton onClick={() => setNotesOpen(!notesOpen)}>
          {" "}
          {notesOpen ? "Show Less" : "Show More"}
        </ExpandButton>
      </div>
      <Collapse in={notesOpen} timeout="auto" unmountOnExit>
        {notes.map((noteObject: NoteType) => (
          <Note
            note={noteObject}
            post={event}
            key={noteObject.id}
            authUser={user}
            collection={"events"}
            tempNotes={notes}
            setTempNotes={setTempNotes}
          />
        ))}
      </Collapse>
    </div>
  );
};

export default Notes;
