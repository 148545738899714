import React, { FC, useState } from "react";
import styled from "styled-components";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

import { scrollInto } from "utils/navigate";
import {
  Image,
  Video,
  VimeoPlayer,
  PostContainer,
  YouTube,
} from "components/styled/Common";
import {
  Grid,
  MediaContainer,
  CarouselIcon,
  VideoIcon,
  Overlay,
} from "components/styled/BePlannedGrid";
import SocialPost from "components/BePlanned/Post";
import { firestore } from "../../firebase-tools/firebase";
import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import { NoPosts } from "../BePlanned/Post/NoPosts";

interface BePlannedProps {
  posts: any;
  user: any;
}

export const Container = styled.div<{ post: boolean }>`
  margin-bottom: ${(props) => (props.post ? "50px" : "95px")};
`;

export const BePlannedGrid: FC<BePlannedProps> = ({ posts, user }) => {
  const vimeoRegex = /^(http:\/\/|https:\/\/)(vimeo\.com)\/([\w]+)?$/;
  const youtubeRegex = /(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/;
  let [postId, setPostId]: any = useState("id");

  const handleClick = (postId) => {
    setPostId(postId);
    setTimeout(() => {
      scrollInto("post");
    }, 100);
  };

  let userRef = firestore.collection("multichannel-posts").doc(postId);

  const post =
    useDocumentSubscriber({
      ref: userRef,
      deps: [postId],
    }).document || "";

  return (
    <Container post>
      {posts && posts.length ? (
        <Grid>
          {posts.map((post) => {
            //some legacy posts have image_url as string
            let images;
            if (typeof post.image_url === "string") {
              if (post.image_url !== "") {
                images = [post.image_url];
              } else {
                images = [];
              }
            } else {
              images = post.image_url;
            }
            return !!post.video_url?.length ? (
              <MediaContainer
                onClick={() => handleClick(post.id)}
                key={post.id}
              >
                {post.video_url.includes("firebasestorage") && (
                  <Video preload="metadata">
                    <source src={post.video_url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </Video>
                )}
                {vimeoRegex.test(post.video_url) && (
                  <VimeoPlayer
                    video={post.video_url}
                    controls={false}
                    responsive={true}
                  />
                )}
                {youtubeRegex.test(post.video_url) && (
                  <YouTube
                    src={`https://www.youtube.com/embed/${
                      post.video_url.split("v=")[1]
                    }?autoplay=0&showinfo=0`}
                    frameBorder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                  />
                )}
                <VideoIcon />
                <Overlay id="overlay">
                  <PlayArrowIcon
                    style={{ fill: "#fff", width: "3rem", height: "3rem" }}
                  />
                </Overlay>
              </MediaContainer>
            ) : (
              !!images.length && images[0] && (
                <MediaContainer
                  onClick={() => handleClick(post.id)}
                  key={post.id}
                >
                  <Image src={images[0]} />
                  {(images.length > 1 ||
                    (!!images.length && !!post.video_url?.length)) && (
                    <CarouselIcon />
                  )}
                  <Overlay id="overlay"></Overlay>
                </MediaContainer>
              )
            );
          })}
        </Grid>
      ) : (
        <NoPosts title="There are no posts in the selected period" />
      )}

      <div id="post">
        {post.created_at ? (
          <PostContainer>
            <SocialPost
              post={post}
              user={user}
              role={post?.platform?.toLowerCase()}
            />
          </PostContainer>
        ) : null}
      </div>
    </Container>
  );
};
