import React, { FC } from "react";
import { Grid } from "@material-ui/core";

import Chart from "./Chart";
import { area } from "components/BeMeasured/Chart/chartOptions";
import { formatDate } from "utils/dateFormat";

interface ICharts {
  metrics: any;
}

const Charts: FC<ICharts> = ({ metrics }) => {
  const dateLength = metrics.dates.length;

  const areaOptions = {
    ...area.options,
    markers:
      dateLength > 30
        ? {}
        : {
            size: 5,
            colors: ["white"],
            strokeColor: "#5187E0",
            strokeWidth: 2,
          },
    xaxis: {
      categories: dateLength > 30 ? metrics.dates : formatDate(metrics.dates),
      type: dateLength > 30 ? "datetime" : "date",
      labels: {
        datetimeUTC: false,
      },
    },
  };
  const taggedTweets = {
    series: [
      {
        name: "Tweets with the tags/keywords",
        data: metrics.tweets || [],
      },
    ],
  };
  const tweetingUsers = {
    series: [
      {
        name: "Users posting the tweets",
        data: metrics.users || [],
      },
    ],
  };
  const followedUsers = {
    series: [
      {
        name: "Users Followed",
        data: metrics.follows || [],
      },
    ],
  };
  return (
    <div style={{ marginTop: 22 }}>
      <Grid id="metrics">
        <Chart
          title="Tweets with the tags/keywords"
          metrics={{ ...taggedTweets, areaOptions }}
        />
        <Chart
          title="Users posting the tweets"
          metrics={{ ...tweetingUsers, areaOptions }}
        />
        <Chart
          title="Users Followed"
          metrics={{ ...followedUsers, areaOptions }}
        />
      </Grid>
    </div>
  );
};

export default Charts;
