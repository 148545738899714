import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    fontFamily: "Avenir Book",
    borderRadius: 2,
    width: 440,
  },
  cardPlacement: {
    paddingTop: "35px",
    paddingRight: "35px",
    // media query
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
    },
  },
  universalSpace: {
    padding: "20px",
  },
  universalSpaceleftRight: {
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  universalSpaceTopBottom: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  notifyType: {
    // width: "76px",
    padding: 10,
    // margin: "10px",
  },
  activeNotifyType: {
    // width: "76px",
    padding: 10,
    borderBottom: "3px solid black",
    borderRadius: "4px, 4px, 0px, 0px",
    zIndex: 1,
  },
  notifyType2: {
    // width: "97px",
    padding: 10,
    fontWeight: 900,
    textAlign: "center",
    // margin: "10px",
  },
  activeNotifyType2: {
    // width: "97px",
    padding: 10,
    fontWeight: 900,
    borderBottom: "3px solid black",
    borderRadius: "4px, 4px, 0px, 0px",
    textAlign: "center",
    zIndex: 1,
  },
  allCount: {
    fontSize: 12,
    backgroundColor: "#F2F2F2",
    padding: "3px",
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: "50%",
    width: 23,
    zIndex: 0,
    boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.5)",
  },
  notificationItemRight: {
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "right",
    alignItems: "center",
  },
  notificationItemLeft: {
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "right",
    alignItems: "center",
  },
  notificationItemButton: {
    backgroundColor: "#000000",
    color: "#FFFFFF",
    width: "66px",
    height: "32px",
    padding: "4px 12px",
    borderRadius: "8px",
    opacity: "0px",
    "&:hover": {
      backgroundColor: "#000000",
      opacity: 0.8,
      cursor: "pointer", // Change cursor to pointer on hover
    },
  },
  notificationItemContainer: {
    paddingTop: "10px",
    paddingBottom: "10px",
    "&:hover": {
      backgroundColor: "#f0f0f0", // Define your hover background color here
      cursor: "pointer", // Change cursor to pointer on hover
    },
  },
  popper: {
    background: "#FFFFFF",
    border: "1px solid #E8E8E8",
    boxSizing: "border-box",
    zIndex: 10,
    width: "134px",
    left: "8.96%",
    right: "33.58%",
    top: "calc(50% - 160px/2 + 23px)",
  },
  li: {
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: "11.5px",
    lineHeight: "26.5px",
    color: "#000000",
  },
  line: {
    left: "0.75%",
    right: "0.75%",
    top: "61.35%",
    bottom: "37.68%",
    border: "1px solid #E8E8E8",
  },
}));

export { useStyles as useStylesNot };
