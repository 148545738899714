import React, { FunctionComponent } from "react";
import { Grid } from "@material-ui/core";

import ClientInfo from "components/ClientInfo";
import { firestore } from "../../firebase-tools/firebase";
import useDocumentSubscriber from "hooks/useDocumentSubscriber";

type ClientInfoType = {
  uid?: string;
  path: string;
};

export const ClientInfoPage: FunctionComponent<ClientInfoType> = ({ uid }) => {
  const create = uid === "create";

  let userRef = firestore.collection("users").doc(uid);

  let userInfo = useDocumentSubscriber({ ref: userRef }).document;

  return (
    <Grid container id="client-info">
      {userInfo?.id && <ClientInfo userInfo={userInfo} create={create} />}
    </Grid>
  );
};
