import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  info: {
    display: "flex",
    justifyContent: "space-around",
    fontFamily: "Avenir Book, sans-serif",
    fontSize: 12,
    backgroundColor: "#ABADAD",
    minWidth: 80,
  },
  arrow: {
    color: "#ABADAD",
  },
  linkIcon: {
    color: "#abadad",
    marginBottom: "-7px",
    marginRight: 20,
    cursor: "pointer",
  },
  field: {
    position: "relative",
    display: "flex",
    alignItems: "start",
    flexDirection: "column",
    transition: "all 0.3s ease",
    "& div span": {
      alignSelf: "start",
    },
  },
  link: {
    overflowWrap: "anywhere",
    marginTop: 15,
    wordBreak: "break-word",
  },
}));
