import React, { useState, memo } from "react";
import {
  Button,
  Modal,
  Backdrop,
  Fade,
  CircularProgress,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import moment from "moment";
import { DateRange } from "materialui-daterange-picker";

import { useStyles } from "./styles";
import { SendButton, CancelButton, PostPaper } from "components/styled/Common";
import Select from "components/Common/Select";
import DateRangePicker from "components/Common/DateRange";
import { success } from "../../../utils/notification";
import { deduplicateData } from "../requests/data";

interface Props {
  user: any;
}
const platformOptions = [
  "Facebook",
  "Instagram",
  "Twitter",
  "TikTok",
  "YouTube",
  "Spotify",
];

const Deduplicate = (props: Props) => {
  const { user } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [platform, setPlatform] = useState("");
  const [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: moment().subtract(7, "days").startOf("day").toDate(),
    endDate: moment().subtract(1, "days").startOf("day").toDate(),
  });
  const [, setParamsChange]: any = useState("");
  const [error, setError] = useState({ submit: "", platform: "" });

  const classes = useStyles({});
  const from = new Date(dateRange.startDate as Date).toISOString();
  const to = new Date(dateRange.endDate as Date).toISOString();

  const handleSelect = (value: string) => {
    setPlatform(value);
    setError({ ...error, platform: "" });
  };

  const close = async (event?: any) => {
    if (event.key === "Escape") {
      setIsOpen(false);
    }
    setIsOpen(false);
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    if (!platform) {
      setError({ ...error, platform: "Platform is required!" });
      return setSubmitting(false);
    }
    try {
      const channel = platform.toLowerCase();
      const res = await deduplicateData(
        from,
        to,
        channel,
        user[channel].username
      );
      setError({ ...error, submit: "" });
      if (res.message) {
        setIsOpen(false);
        success("Data deduplicated successfully!");
      } else {
        setError({ ...error, submit: "An error occurred, please retry!" });
      }
    } catch (e) {
      setError({ ...error, submit: "An error occurred, please retry!" });
      console.log("Dedupe error", e);
    }
    setSubmitting(false);
  };

  return (
    <div>
      <Button
        disableRipple
        type="button"
        className={classes.btn}
        id="create-btn"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        Deduplicate Data
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableBackdropClick
      >
        <Fade in={isOpen}>
          <div className={classes.container} id="deduplicate">
            <PostPaper>
              <div className={classes.top}>
                <div className={classes.print}>Delete Duplicates</div>
                <Close className={classes.icon} onClick={close} />
              </div>
              <div className={classes.postSection} id="post-section">
                <p style={{ marginTop: 30, fontSize: 15 }}>
                  When you have duplicated data (i.e. one date appearing more
                  than once in the charts) - you can run this Deduplication
                  feature.
                  <br />
                  You just need to run it for the given Client, select the
                  Social Media Channels, and the relevant Period. Then the data
                  deduplication feature will delete all other appearances (for
                  each date in the period), other than the first appearance.
                </p>
                <div className={`${classes.field} ${classes.channel}`}>
                  <Select
                    value={platform}
                    setItems={handleSelect}
                    options={platformOptions}
                    label="Choose Channel"
                    multiple={false}
                  />
                  <span className={classes.error}>{error.platform}</span>
                </div>
                <div className={classes.field}>
                  <DateRangePicker
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    setParamsChange={setParamsChange}
                    datePosition={{
                      justify: "center",
                      datePickerClass: classes.datePicker,
                    }}
                  />
                  <span className={classes.error} style={{ marginTop: 20 }}>
                    {error.submit}
                  </span>
                </div>
              </div>
            </PostPaper>
            <div className={classes.line} />
            <div className={`${classes.bottom} ${classes.bottomRespCommon}`}>
              <CancelButton disableRipple onClick={close}>
                cancel
              </CancelButton>

              <SendButton
                onClick={handleSubmit}
                disabled={submitting}
                disableRipple
              >
                {submitting ? (
                  <CircularProgress size={30} color="inherit" />
                ) : (
                  <span>Deduplicate</span>
                )}
              </SendButton>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default memo(Deduplicate);
