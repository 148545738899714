import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "calc(100vh - 400px)",
  },
});
