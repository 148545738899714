import cuid from "cuid";
import { navigate } from "@reach/router";

import { IUser, Roles } from "interfaces/IUser";
import { AgentService } from "services/AgentService";
import { UserService } from "services/UserService";
import { sendSignUpInvite } from "../requests/sendSignUpEmail";
import { checkSecondaryEmail } from "./checkSecondaryEmail";
import { fetchHistoricalData } from "./historicalData";
import { sendSecondaryInvites } from "./secondaryInvites";
import { success } from "utils/notification";

const uuid: string = cuid();

export const notify = (error, userInfo) => {
  if (!error && !userInfo.isInvited) {
    success("Invite sent successfully!");
  }
};

const fetchData = async (data, errors, User, setPermErrors) => {
  const _errors = await fetchHistoricalData(data, errors, User);
  setPermErrors(_errors);
};

const userExists = (user, agents, setError, setIsSaving, setIsInviting) => {
  if ((user && !!user.length) || !!agents.length) {
    setError("You already sent an invite to this email!");
    setIsSaving(false);
    setIsInviting(false);
    return true;
  }
  return false;
};

export const createUser = async (
  values,
  requestType,
  avatar,
  error,
  userInfo,
  setSecondaryUserExist,
  setIsInviting,
  setIsSaving,
  setError
) => {
  const User = new UserService();
  const user = await User.getUserByField("email", values.email);

  const { exists, message } = await checkSecondaryEmail(values.secondaryEmails);

  if (exists?.length) {
    setSecondaryUserExist(message);
    return;
  }
  values.email = values.email.toLowerCase();
  let secondaryEmails = values.secondaryEmails.map((item) => {
    item.email = item.email.toLowerCase();
    return item;
  });

  const Agent = new AgentService();
  const agents = await Agent.getAgentByField("email", values.email);

  const userExist = userExists(
    user,
    agents,
    setError,
    setIsSaving,
    setIsInviting
  );

  if (userExist) return;

  let isInvited = false;
  if (requestType === "invite") {
    setIsInviting(true);
    await sendSignUpInvite(values.email, values.clientName, "client");
    secondaryEmails = await sendSecondaryInvites(values);

    isInvited = true;
  }

  const NewUser = new UserService(uuid);

  await NewUser.addUser({
    id: uuid,
    email: values.email,
    firstName: values.clientName.split(" ").slice(0, 1).join(" "),
    lastName: values.clientName.split(" ").slice(1).join(" "),
    avatarUrl: avatar,
    role: Roles.user,
    isActivated: true,
    isInvited,
    isSignedUp: false,
    createdAt: new Date(),
    facebook: {
      url: `https://www.facebook.com/${values.facebook_username}` || "",
      username: values.facebook_username,
    },
    instagram: {
      url: `https://www.instagram.com/${values.instagram_username}` || "",
      username: values.instagram_username,
    },
    twitter: {
      url: `https://twitter.com/${values.twitter_username}` || "",
      username: values.twitter_username,
    },
    tiktok: {
      url: `https://tiktok.com/@${values.tiktok_username}` || "",
      username: values.tiktok_username,
    },
    youtube: {
      url: `https://youtube.com/channel/${values.channel_id}` || "",
      username: values.channel_id,
    },
    spotify: {
      url: `https://artists.spotify.com/c/artist/${values.spotifyId}` || "",
      username: values.spotifyId,
    },
    appleMusic: {
      url: `https://artists.apple.com/a/artist/${values.appleMusicId}` || "",
      username: values.appleMusicId,
    },
    secondaryEmails,
    notes: values.notes,
    isExternal: values.isExternal,
  } as IUser)
    .then(async (res) => {
      setIsSaving(false);
      setIsInviting(false);
      navigate("/admin-panel");
      let igError = "";
      let fbError = "";
      let twError = "";
      await fetchHistoricalData(values, { igError, fbError, twError }, User);
    })
    .catch((error) => {
      if (error) {
        console.log(error);
        setError("An error occurred. Try again!");
      }
    });

  let updateEmails;
  if (requestType === "invite") {
    notify(error, userInfo);
  } else {
    const userId = user[0].id;
    const User = new UserService(userId);

    updateEmails = secondaryEmails;

    !!user.length &&
      (await User.updateUser({
        secondaryEmails: updateEmails,
      }));

    setIsSaving(false);
  }
};

export const updateUser = async (
  userInfo,
  clientName,
  email,
  facebook_username,
  instagram_username,
  twitter_username,
  tiktok_username,
  channel_id,
  spotifyId,
  appleMusicId,
  secondaryEmails,
  notes,
  isExternal,
  requestType,
  setSecondaryUserExist,
  setIsInviting,
  setIsSaving,
  setError,
  retry,
  error,
  dataErrors,
  setDataErrors
) => {
  const User = new UserService(userInfo.id);

  const { exists, message } = await checkSecondaryEmail(secondaryEmails);

  if (exists?.length) {
    setSecondaryUserExist(message);
    if (!retry) {
      return;
    }
  }

  let updateEmails = secondaryEmails;
  if (requestType === "invite") {
    setIsInviting(true);
    if (!userInfo.isInvited) {
      await sendSignUpInvite(email, clientName, "client");
      await User.updateUser({
        isSignedUp: false,
        isInvited: true,
      });
    }
    const user = {
      email,
      clientName,
      secondaryEmails,
    };
    updateEmails = await sendSecondaryInvites(user);
  } else {
    setIsSaving(true);
  }

  await User.updateUser({
    firstName: clientName.split(" ").slice(0, 1).join(" "),
    lastName: clientName.split(" ").slice(1).join(" "),
    email: email,
    "facebook.url": `https://www.facebook.com/${facebook_username}` || "",
    "facebook.username": facebook_username || "",
    "instagram.url": `https://www.instagram.com/${instagram_username}` || "",
    "instagram.username": instagram_username || "",
    "twitter.url": `https://twitter.com/${twitter_username}` || "",
    "twitter.username": twitter_username || "",
    "tiktok.url": `https://tiktok.com/@${tiktok_username}` || "",
    "tiktok.username": tiktok_username || "",
    "youtube.url": `https://youtube.com/channel/${channel_id}` || "",
    "youtube.username": channel_id || "",
    "spotify.url": `https://artists.spotify.com/c/artist/${spotifyId}` || "",
    "spotify.username": spotifyId || "",
    "appleMusic.url":
      `https://artists.apple.com/a/artist/${appleMusicId}` || "",
    "appleMusic.username": appleMusicId || "",
    secondaryEmails: updateEmails,
    notes: notes,
    isExternal: isExternal,
  })
    .then(() => {
      setIsSaving(false);
      setIsInviting(false);
      navigate("/admin-panel");
    })
    .catch((error) => {
      if (error) {
        setError("An error occurred. Try again");
      }
    });

  if ((!userInfo.isInvited && requestType === "invite") || retry) {
    !retry && notify(error, userInfo);
    fetchData(
      { facebook_username, instagram_username, twitter_username },
      dataErrors,
      User,
      setDataErrors
    );
  }
};

export const handleCreateEdit = (
  values,
  userInfo,
  create,
  dupError,
  requestType,
  avatar,
  error,
  setSecondaryUserExist,
  setIsInviting,
  setIsSaving,
  setError,
  retry,
  dataErrors,
  setDataErrors,
  setToSave,
  setToNotify
) => {
  setToSave(false);
  setToNotify(false);
  if (dupError !== "") {
    return;
  }

  if (create) {
    createUser(
      values,
      requestType,
      avatar,
      error,
      userInfo,
      setSecondaryUserExist,
      setIsInviting,
      setIsSaving,
      setError
    );
    return;
  }
  updateUser(
    userInfo,
    values.clientName,
    values.email,
    values.facebook_username,
    values.instagram_username,
    values.twitter_username,
    values.tiktok_username,
    values.channel_id,
    values.spotifyId,
    values.appleMusicId,
    values.secondaryEmails,
    values.notes,
    values.isExternal,
    requestType,
    setSecondaryUserExist,
    setIsInviting,
    setIsSaving,
    setError,
    retry,
    error,
    dataErrors,
    setDataErrors
  );
};
