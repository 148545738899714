import { useEffect, useState } from "react";
import { docData } from "rxfire/firestore";
import { take } from "rxjs/operators";

interface useDocumentSubscriberParams {
  ref: any;
  deps?: Array<any>;
  idField?: string;
  subscribe?: boolean;
}

function useDocumentSubscriber<T>({
  ref,
  deps = [],
  idField = "id",
  subscribe = true,
}: useDocumentSubscriberParams) {
  const [document, setDocument]: any = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const subscribeToPublisherCollection = () => {
    const getDocument$ = docData(ref, idField);
    setLoading(true);
    const subscription = getDocument$
      .pipe(subscribe ? (_) => _ : take(1))
      .subscribe(
        (observedDocument) => {
          setLoading(false);
          return setDocument(observedDocument);
        },
        (err: any) => {
          setLoading(false);
          setError(err);
        }
      );

    const unsubscribe = () => subscription.unsubscribe();

    if (subscribe) return unsubscribe;
  };
  useEffect(subscribeToPublisherCollection, deps);
  return { document, error, loading };
}

export default useDocumentSubscriber;
