import React, { FC, useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";

import { PageType } from "types/PageType";
import Activity from "components/Activity";
import {
  PageTitle,
  TableWrapper,
  HeaderRow,
  HeaderCell,
} from "components/styled/Common";
import { firestore } from "../../firebase-tools/firebase";
import useCollectionSubscriber from "../../hooks/useCollectionSubscriber";
import { IUser } from "interfaces/IUser";

const ActivityPage: FC<PageType> = ({ location }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers]: Array<any> = useState([]);

  let queries;
  if (location?.pathname === "/kit-activity") {
    queries = [
      firestore
        .collection("users")
        .where("role", "==", "user")
        .orderBy("isActivated", "desc")
        .orderBy("firstName", "asc"),
    ];
  }
  if (location?.pathname === "/admin-activity") {
    queries = [
      firestore
        .collection("users")
        .where("role", "in", ["superAdmin", "admin", "viewerAdmin"]),
    ];
  }
  const userList = useCollectionSubscriber<IUser>({ queries });

  useEffect(() => {
    setIsLoading(true);
  }, []);
  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    }
    const allUsers: Array<any> = [];
    userList.documents?.map((user) => {
      !user.isArchived && allUsers.push(user);
      user.secondaryEmails?.map((item) => {
        const agentInfo = {
          firstName: user.firstName,
          lastName: user.lastName,
          email: item.email,
          page_visit: item.page_visit,
          isArchived: user.isArchived,
          isActivated: item.isActivated,
          id: item.email + user.id + user.email,
        };
        !agentInfo.isArchived &&
          agentInfo.isActivated &&
          allUsers.push(agentInfo);
      });
    });
    setUsers(allUsers);
    // eslint-disable-next-line
  }, [userList.documents]);
  return (
    <div
      style={{
        width: "100%",
        paddingBottom: "50px",
      }}
    >
      <PageTitle>
        <span style={{ marginTop: 32 }}>
          {location?.pathname === "/kit-activity" && "Kit Activity"}
          {location?.pathname === "/admin-activity" && "Admin Activity"}
        </span>
      </PageTitle>
      <TableWrapper className="table">
        <TableContainer
          component={Paper}
          style={{ boxShadow: "none", borderRadius: "15px" }}
        >
          <Table className="table" aria-label="simple table">
            <TableHead>
              <HeaderRow>
                <HeaderCell>Logo</HeaderCell>
                <HeaderCell>
                  {location?.pathname === "/kit-activity" && "Client Name"}
                  {location?.pathname === "/admin-activity" && "Admin Name"}
                </HeaderCell>
                <HeaderCell>User Email</HeaderCell>
                <HeaderCell>Activity Dates</HeaderCell>
              </HeaderRow>
            </TableHead>
            <Activity
              users={{ documents: users, error: users.error }}
              isLoading={isLoading}
            />
          </Table>
        </TableContainer>
      </TableWrapper>
    </div>
  );
};

export default ActivityPage;
