import printDocument from "utils/printDocument";

export const handlePrint = (
  selectedStats,
  fbSelectedLen,
  igSelectedLen,
  twitterSelectedLen,
  tikTokSelectedLen,
  youTubeSelectedLen,
  spotifySelectedLen,
  appleMusicSelectedLen,
  isPrintMode,
  isOpen,
  setIsPrintMode,
  setIsOpen,
  user,
  periodStart,
  periodEnd
): void => {
  // Hide header
  const header: any = document.getElementById("header");
  header.style.display = "none";

  // Hide date picker
  const date: any = document.getElementById("date-picker");
  date.style.display = "none";

  // Hide date client List
  const clientList: any = document.getElementById("client-list");
  clientList && (clientList.style.display = "none");

  // Hide PublicLink
  const PublicLink: any = document.getElementById("public-link");
  PublicLink.style.display = "none";

  // Un all hide twitter stats
  const igSelect: any = document.getElementById("ig-select");
  igSelect && (igSelect.style.display = "none");

  // Hide un selected fb stats
  if (selectedStats.fbStats.selected.length < fbSelectedLen) {
    selectedStats.fbStats.selected.forEach((sectionId) => {
      const section: any = document.getElementById(sectionId);
      section && (section.style.display = "none");
    });
  } else {
    const section: any = document.getElementById("fb-stats");
    section && (section.style.display = "none");
  }

  // Hide un selected ig stats
  if (selectedStats.igStats.selected.length < igSelectedLen) {
    selectedStats.igStats.selected.forEach((sectionId) => {
      const section: any = document.getElementById(sectionId);
      section && (section.style.display = "none");
    });
  } else {
    const section: any = document.getElementById("ig-stats");
    section && (section.style.display = "none");
  }

  // Hide un selected twitter stats
  if (selectedStats.twitterStats.selected.length < twitterSelectedLen) {
    selectedStats.twitterStats.selected.forEach((sectionId) => {
      const section: any = document.getElementById(sectionId);
      section && (section.style.display = "none");
    });
  } else {
    const section: any = document.getElementById("twitter-stats");
    section && (section.style.display = "none");
  }

  // Hide un selected tikTok stats
  if (selectedStats.tikTokStats.selected.length < tikTokSelectedLen) {
    selectedStats.tikTokStats.selected.forEach((sectionId) => {
      const section: any = document.getElementById(sectionId);
      section && (section.style.display = "none");
    });
  } else {
    const section: any = document.getElementById("tiktok-stats");
    section && (section.style.display = "none");
  }

  // Hide un selected youTube stats
  if (selectedStats.youTubeStats.selected.length < youTubeSelectedLen) {
    selectedStats.youTubeStats.selected.forEach((sectionId) => {
      const section: any = document.getElementById(sectionId);
      section && (section.style.display = "none");
    });
  } else {
    const section: any = document.getElementById("youtube-stats");
    section && (section.style.display = "none");
  }

  // Hide un selected spotify stats
  if (selectedStats.spotifyStats.selected.length < spotifySelectedLen) {
    selectedStats.spotifyStats.selected.forEach((sectionId) => {
      const section: any = document.getElementById(sectionId);
      section && (section.style.display = "none");
    });
  } else {
    const section: any = document.getElementById("spotify-stats");
    section && (section.style.display = "none");
  }

  // Hide un selected apple music stats
  if (selectedStats.appleMusicStats.selected.length < appleMusicSelectedLen) {
    selectedStats.appleMusicStats.selected.forEach((sectionId) => {
      const section: any = document.getElementById(sectionId);
      section && (section.style.display = "none");
    });
  } else {
    const section: any = document.getElementById("apple-music-stats");
    section && (section.style.display = "none");
  }

  const clientName = user.firstName + ` ${user.lastName}`;

  setIsPrintMode(!isPrintMode);
  setIsOpen(!isOpen);

  setTimeout(() => {
    printDocument(
      `${clientName}_Be-Measured_${periodStart} - ${periodEnd}`,
      "to-print"
    );
  }, 1500);
};
