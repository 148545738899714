import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  dateLabel: {
    fontSize: "15px",
    lineHeight: "22px",
    fontFamily: "'Avenir Black', sans-serif",
    fontWeight: 900,
    color: "#000000",
    marginRight: 15,
  },

  name: {
    textAlign: "center",
    marginTop: "23px",
    fontFamily: "Avenir Black, sans-serif",
    fontSize: "18px",
  },
  stats: {
    width: "78%",
    marginLeft: "62px",
  },
  icon: {
    width: "44.75px",
    height: "50px",
    marginRight: "15px",
  },

  indicator: {
    height: "62px",
    marginTop: "25px",
    display: "flex",
    "&:first-child": {
      marginTop: "0px",
    },
  },

  metricLabel: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "8px",
    fontSize: "15px",
    fontFamily: "Avenir Book, sans-serif",
    lineHeight: "22px",
    alignItems: "baseline",
  },
  statsWrapper: {
    margin: "62.5px 0px 0px 0px",
  },
  socialLabel: {
    display: "flex",
    alignItems: "center",

    "& > .MuiSvgIcon-root": {
      color: "slategrey",
    },
  },
  statsName: {
    color: "#101010",
    fontSize: "24px",
    lineHeight: "25px",
    fontFamily: "Avenir Book, sans-serif",
    fontWeight: 900,
    marginRight: "6px",
    display: "flex",

    "& > .MuiSvgIcon-root": {
      color: "slategrey",
    },
  },

  selectIcon: {
    paddingRight: "20.5px",
    color: "#000000",
    width: "17px",
    height: "28px",
  },
  selectIconOpen: {
    transform: "none",
  },
  selectInput: {
    minWidth: "148px",
    textAlign: "left",
  },
  selectOverride: {
    padding: "11.5px 20.5px",

    "& ul": {
      height: (props: any) => props.listLength,
      overflowX: "hidden",
    },
    "& li": {
      fontSize: "14px",
      fontFamily: "Avenir Book, sans-serif",
      color: "#000000",
    },
    "&.MuiSelect-select": {
      "&:focus": {
        backgroundColor: "#fff",
      },
      "&:active": {
        backgroundColor: "#fff",
      },
      "&:hover": {
        backgroundColor: "#fff",
      },
    },
  },

  editLink: {
    textDecoration: "none",
    marginLeft: "6px",
    cursor: "pointer",
  },
  editIcon: {
    width: "15px",
    height: "15px",
    fill: "#5c5c5c",
  },
  clientList: {
    flexDirection: "column",
    alignItems: "center",

    "@media screen and (max-width: 768px)": {
      width: "100%",

      "& > :last-child": {
        width: "100%",
      },
    },
  },
  autoCompleteField: {
    background: "#fff",
    borderRadius: 15,
    width: 224,
    height: 45,
    marginTop: 8.5,
    marginRight: 15,

    "& > :first-child": {
      fontFamily: "'Avenir Book', sans-serif",
      fontSize: "15px",
      padding: "0px 15px",
      height: 45,
    },

    "& > div > input": {
      padding: "5px !important",
    },

    "& > div > div > :first-child > span > svg": {
      color: "black",
      width: 17,
    },

    "& > div > div > :last-child > span > svg": {
      color: "black",
      width: 17,
    },

    "@media screen and (max-width: 768px)": {
      width: "100%",
      minWidth: 224,
    },
  },
  autoComplete: {
    "& > div > div > fieldset": {
      borderColor: "#E8E8E8",
      borderRadius: 15,
    },

    "& > div > .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E8E8E8",
    },

    "& > div > .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E8E8E8",
      borderWidth: 1,
    },
  },

  growthRate: {
    marginRight: 21,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.10)",
    },
  },
});
