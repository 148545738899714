import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  mainIcon: {
    width: 53,
    height: 63,
  },
  itemsCenter: {
    display: "flex",
    alignItems: "center",
  },
  info: {
    fontSize: 16,
    lineHeight: "22px",
    fontWeight: 700,
    margin: 0,
  },
  label: {
    fontSize: "24px",
    lineHeight: "33px",
    fontWeight: 600,
    color: "#000000",
    fontFamily: "'Avenir Book', sans-serif",
  },
  backArrow: {
    fontWeight: 900,
    fontSize: 16,
    lineHeight: "22px",
    marginTop: 37,
    cursor: "pointer",
  },
  selectedText: {
    backgroundColor: "#fff",
    padding: "11px",
    border: "1px solid #e8e8e8",
    borderRadius: "10px",
    height: "63px",
    width: "fit-content",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "11px",
    fontFamily: "'Avenir Book', sans-serif",
    fontWeight: 600,
    fontSize: 20,
    lineHeight: "27px",
  },
  dropIcon: {},
  separator: {
    height: "100%",
    width: "1px",
    background: "#C4C4C4",
    margin: "0px 0px 0px 30px",
  },
  selectedIcon: {
    width: "36px",
    height: "41px",
    marginRight: 11,
  },
  editButton: {
    height: "45px !important",
    width: "auto !important",
    padding: "0px 30px",
  },
  progress: {
    minHeight: 171,
    background: "#fff",
    boxShadow: "0px 5px 45px rgba(0, 0, 0, 0.07)",
    borderRadius: "15px",
    marginTop: 40,
    padding: "41px",
    fontFamily: "'Avenir Book', sans-serif",
    justifyContent: "space-between",
  },
  switchBase: {
    "&$checked": {
      "& + $track": {
        backgroundColor: "#EBEBEB !important",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
      },
      "&$thumb": {
        backgroundColor: "#F5F5F5",
        color: "#EBEBEB",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
      },
    },
  },
  thumb: {
    backgroundColor: "#F5F5F5",
    color: "#EBEBEB",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
  },
  track: {
    backgroundColor: "#EBEBEB !important",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
  },
  toggleLabel: {
    fontWeight: 900,
    fontSize: 15,
    lineHeight: "20px",
  },
  headerTitle: {
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "33px",
  },
  headerContent: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: "25px",
    fontFamily: "'Avenir Book', sans-serif",
    marginTop: 6,
  },
  running: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 15,
    lineHeight: "20px",
    marginTop: 15,
  },
  console: {
    background: "#000000",
    padding: "31px 18px 27px 18px",
    borderRadius: "10px",
    marginTop: 12,
  },
});
