import axios from "axios";

export const sendSignUpInvite = (
  email: string,
  name: string,
  type: string,
  userEmail?: string
): any => {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_FUNCTIONS_BASE_URL}email-signUp`,
    headers: { "Content-Type": "application/json" },
    data: {
      email: email,
      name: name,
      type: type,
      userEmail,
    },
  })
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      console.log(error);
    });
};
