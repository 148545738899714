import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  platform: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "50px",
    fontSize: "24px",
    fontFamily: "AvenirNext-Medium",
  },
  platform2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "50px",
    fontSize: "24px",
    fontFamily: "AvenirNext-Medium",
  },
  createPost: {
    width: "192px",
    height: "46px",
    background: "#1D4E89",
    "&:hover": {
      backgroundColor: "#1D4E89",
      opacity: 0.9,
    },
    borderRadius: "2px",
    textTransform: "capitalize",
    fontFamily: "AvenirNext-DemiBold",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF",
    marginBottom: "5px",
  },
  post: {
    display: "flex",
    marginRight: "60px",
    boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.1)",
    borderRadius: 15,
    background: "#cccccc",
  },
  postIcons: {
    display: "flex",
  },
  actionsPost: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  actionsbtns: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: 450,

    "& > :first-child": {
      maxWidth: "fit-content",
    },
  },
  btn: {
    width: "159px",
    height: "45px",
    background: "#000000",
    borderRadius: "15px",
    textTransform: "capitalize",
    fontFamily: "'Avenir Black', sans-serif",
    fontSize: "15px",
    fontWeight: 900,
    fontStyle: "normal",
    lineHeight: "22px",
    color: "#FFFFFF",
    marginBottom: "5px",
    justifyContent: "center",
    // float: "right",
    alignItems: "center",

    "&:hover": {
      backgroundColor: "#000000",
      opacity: 0.85,
    },

    "&:disabled": {
      color: "#fff !important",
      opacity: 0.5,
      cursor: "not-allowed !important",
    },

    "@media screen and (max-width: 750px)": {
      float: "right",
    },
  },
  btn2: {
    height: "45px",
    background: "#000000",
    borderRadius: "15px",
    textTransform: "capitalize",
    fontFamily: "'Avenir Black', sans-serif",
    fontSize: "15px",
    fontWeight: 900,
    fontStyle: "normal",
    lineHeight: "22px",
    color: "#FFFFFF",
    marginBottom: "5px",
    justifyContent: "center",
    // float: "right",
    alignItems: "center",

    "&:hover": {
      backgroundColor: "#000000",
      opacity: 0.85,
    },

    "&:disabled": {
      color: "#fff !important",
      opacity: 0.5,
      cursor: "not-allowed !important",
    },

    "@media screen and (max-width: 750px)": {
      float: "right",
    },
  },
  delete: {
    background: "linear-gradient(131.4deg, #ABADAD -9.75%, #ABADAD 84.22%)",
    width: "150px !important",
  },
  edit: {
    width: "150px !important",
  },
  context: {
    fontFamily: "'Avenix Book', sans-serif",
    marginTop: "0px",
    fontSize: "15px",
    lineHeight: "25px",
    "& > div": {
      width: "100% !important",
    },
  },
  yes: {
    color: "green",
    fontWeight: 900,
  },
  no: {
    color: "#FF5269",
    fontWeight: 900,
  },
  pending: {
    color: "#146dd9",
    fontWeight: 900,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  container: {
    width: "984px",
    color: "#313131",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "1px",
    boxShadow: theme.shadows[5],

    "&:focus": {
      outline: "none",
    },
  },
  paper: {
    padding: theme.spacing(0, 4, 0),
  },

  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0px -32px",
    padding: "0 20px",
    height: 68,
    // background: "#ABADAD",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  print: {
    fontFamily: "Avenir",
    fontSize: "30px",
    fontWeight: 900,
    lineHeight: "41px",
    letterSpacing: "0em",
    textAlign: "left",

    "@media screen and (max-width: 400px)": {
      fontSize: 26,
    },
  },
  icon: {
    color: "#000",
    cursor: "pointer",
    fontSize: "30px",
    fontWeight: "bold",
    transition: "all .3s ease",
  },

  img: {
    width: "24.75px",
    objectFit: "contain",
  },

  platformIcon: {
    width: "44.75px",
    height: "50px",
    marginRight: "10px",
  },
  line: {
    opacity: " 0.3",
    height: "1px",
    background: "#979797",
  },

  bottom: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "74px",
    margin: "0 16px",

    "& > div > *": {
      marginBottom: "5px",
    },
    "& > div > :last-child": {
      marginRight: "32px",
      marginLeft: 10,
    },
  },

  bottomResp: {
    "@media screen and (max-width: 430px)": {
      marginRight: 5,
      marginLeft: -8,
    },
  },
  custCreate: {
    padding: "12px",
    "@media screen and (max-width: 430px)": {
      padding: "12px",
      // marginLeft: -8,
    },
  },

  bottomRespCommon: {
    "@media screen and (max-width: 430px)": {
      margin: "0 10px",
    },
  },

  createBtn: {
    fontFamily: "AvenirNext-Medium",
  },

  submit: {
    color: "#1D4E89 !important",
  },
  postSection: {
    width: "100%",
    height: "560px",
    overflowY: "scroll",
    paddingBottom: "26px",
  },
  uploadImgContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 12,
    width: "94%",
  },
  uploadImg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "108px",
    height: "107px",
    background: "#C5C6C6",
    borderRadius: "2px",
    marginRight: "32px",
    marginBottom: "40px",
  },
  uploadAction: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  field: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    marginTop: "26px",
    transition: "all 0.3s ease",
  },
  textarea: {
    border: "2px solid #ABADAD !important",
  },
  reactMde: {
    width: "94%",
  },
  mdTextarea: {
    border: "none",
  },
  choose: {
    display: "flex",
    alignItems: "center",
    border: (props: any) =>
      props.dropdown ? "2px solid #000" : "2px solid #ABADAD",
    width: "94%",
    borderRadius: "10px",
    marginTop: "5px",
    "&:hover": {
      border: "2px solid #000",
    },
  },
  postText: {
    height: "140px",
    resize: "vertical",
  },
  input: {
    width: "94%",
    padding: "12.5px",
    border: "2px solid #ABADAD",
    boxSizing: "border-box",
    borderRadius: "10px",
    cursor: "pointer",
    fontFamily: "'Avenir Book', sans-serif",
    lineHeight: "20px",
    fontSize: "15px",
    "&:focus": {
      border: "2px solid #000",
      outline: "none",
    },
    "&:hover": {
      border: "2px solid #000",
    },
    "&:active": {
      border: "2px solid #000",
    },
    "&::placeholder": {
      fontFamily: "'Avenir Book', sans-serif",
      fontSize: "inherit",
      color: "#ABADAD",
    },
    "&::-webkit-input-placeholder": {
      fontFamily: "'Avenir Book', sans-serif",
      fontSize: "inherit",
      color: "#ABADAD",
    },
    "&:-ms-input-placeholder": {
      fontFamily: "'Avenir Book', sans-serif",
      fontSize: "inherit",
      color: "#ABADAD",
    },
  },
  socialInput: {
    border: "none",
    "&:focus": {
      border: "none",
      outline: "none",
    },
    "&:hover": {
      border: "none",
      outline: "none",
    },
    "&:active": {
      border: "none",
      outline: "none",
    },
  },
  dropdown: {
    position: "absolute",
    listStyleType: "none",
    margin: "0",
    padding: "0",
    border: (props: any) =>
      props.dropdown ? "2px solid #000" : "2px solid #ABADAD",
    background: "#fff",
    width: "94%",
    top: "70px",
    zIndex: 5,
    transition: "all 0.3s ease",

    "& > *": {
      padding: "10px",
      cursor: "pointer",
    },

    "& > *:not(:last-child)": {
      borderBottom: "1px solid #E8E8E8 ",
    },
  },

  cardContent: {
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: "15px",
    lineHeight: "25px",
    whiteSpace: "pre-line",
    wordBreak: "break-word",
  },

  title: {
    fontFamily: "AvenirNext-DemiBold",
    fontSize: "15px",
  },

  subheader: {
    fontFamily: "AvenirNext",
    fontSize: "12px",
  },

  media: {
    "&.MuiCardMedia-img": {
      objectFit: "contain",
      height: "300px",
      paddingBottom: "20px",
    },
  },
  imagePreview: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "280px",
    height: "200px",
    background: "#C5C6C6",
    borderRadius: "2px",
    paddingBottom: 12,
  },
  videoPreview: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 20,
  },
  avatar: {
    minWidth: "60px",
    minHeight: "60px",
    mixBlendMode: "normal",
    border: "solid 1px #f2f2f2",
  },

  AvatarIcon: {
    width: "30px",
    height: "30px",
    background: "#000",
  },

  AvatarSvg: {
    minWidth: "60px",
    minHeight: "60px",
    mixBlendMode: "normal",
    background: "#000",
  },

  postCard: {
    width: "100%",
    maxHeight: "fit-content",
    boxShadow: "none",
  },

  postCardHeader: {
    padding: "30px 30px 0px 30px",
  },

  postCardContent: {
    display: "flex",
    padding: "30px",
  },

  noPosts: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "15px",
    boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.1)",
    height: "315px",
  },

  postCardContentBottom: {
    width: 26,
    borderRadius: "0 15px 15px 0",
  },

  publicIcon: {
    fontSize: "15px",
  },
  dateInput: {
    fontSize: "15px",
    fontFamily: "'Avenir Book', sans-serif",
    lineHeight: "20px",
    border: "2px solid #ABADAD",
    borderRadius: "10px",
    padding: "12.5px",
    marginTop: "5px",
    width: "100%",
    maxHeight: "45px",
    cursor: "pointer",
    background: "#FFF",
    "&:hover": {
      border: "2px solid #000",
    },
    "&:active": {
      border: "2px solid #000",
    },
  },
  error: {
    color: "#f44336",
    fontSize: "15px",
    textAlign: "center",
    width: "75%",
  },
  gridRoot: {
    "&.MuiGrid-root": {
      width: "94%",
    },
    "&.MuiFormControl-root": {
      width: "100%",
    },
  },
  uploadIcons: {
    display: "flex",
    paddingTop: 5,
    alignItems: "end",
    justifyContent: "flex-end",
    width: "94%",
    "& > *": {
      marginRight: 10,
      cursor: "pointer",
    },
  },
  highlight: {
    border: "none",
    background: "none",
    padding: "0px",
    "&:active, &:focus": {
      border: "none",
      outline: "none",
      padding: "0px",
    },
  },
  toggleEmoji: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    height: "auto",
    padding: 0,
    marginBottom: 0,
    background: "transparent",
    cursor: "pointer",

    "&:focus": {
      outline: "none",
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
  },
  chip: {
    margin: "4px 4px 0px 0px",
    maxWidth: "100%",
    fontFamily: "'Avenir Book', sans-serif",
  },
  createIcon: {
    fontSize: "26px",
    "& svg": {
      width: "15px",
      height: "15px",
    },
  },

  filter: {
    marginRight: 15,
    marginBottom: -4,
    cursor: "pointer",
  },

  info: {
    display: "flex",
    justifyContent: "space-around",
    fontFamily: "Avenir Book, sans-serif",
    fontSize: 12,
    backgroundColor: "#ABADAD",
    minWidth: 80,
  },
  arrow: {
    color: "#ABADAD",
  },
  tooltip: {
    fontFamily: "Avenir Book, sans-serif",
    fontSize: 10,
    backgroundColor: "#ABADAD",
  },
  tipArrow: {
    color: "#ABADAD",
  },
  tooltipTitle: {
    whiteSpace: "pre-line",
    height: 50,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontSize: 9,
    lineHeight: "10px",
  },
  archiveLabel: {
    fontFamily: "'Avenir Book', sans-serif !important",
    lineHeight: "22px",
    textTransform: "capitalize",
    textDecoration: "underline",
    color: "#abadad",
  },
  printMode: {
    display: "flex",
    alignItems: "center",
    marginTop: 30,

    "& > :first-child": {
      marginRight: 50,
    },
  },
  name: {
    textAlign: "center",
    fontFamily: "Avenir Black, sans-serif",
    fontSize: "18px",
  },
  period: {
    fontFamily: "AvenirNext",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#000000",

    "& > :first-child": {
      marginLeft: "15px",
    },
  },

  statusBtnContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    width: "100%",
    marginTop: 8,
  },
  datePicker: {
    width: "94%",
    "& > div": {
      width: "100% !important",
    },
    "& > div > div": {
      width: "100%",
    },

    "& > div > div > div:first-child": {
      display: "flex",
      justifyContent: "space-between",
      border: "2px solid #ABADAD",
      borderRadius: 10,

      "&:hover": {
        border: "2px solid #000000",
      },
    },

    "& > div > div > div:nth-child(2) > div > div": {
      paddingTop: 4,

      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "fit-content",

      "@media screen and (max-width: 450px)": {
        width: "330px !important",
      },
    },
  },
  retainerDatePicker: {
    width: "100% !important",
    marginBottom: 10,
  },
  placeholderImg: {
    display: "none",
    justifyContent: "center",
    marginBottom: 10,
    height: 260,
  },
  contentBriefContainer: {
    display: "flex",
    flexDirection: "column",
    border: "2px solid #C4C4C4",
    borderRadius: "10px",
    margin: "10px 30px 30px 30px",
    padding: "20px",
    fontFamily: "'Avenix Book', sans-serif",
    fontSize: 15,
    minHeight: 120,
  },
  briefTitle: {
    fontWeight: 600,
    marginBottom: 5,
    fontSize: 16,
  },
  customTooltip: {
    backgroundColor: "white",
    color: "black",
    borderRadius: "5px",
    // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    // boxShadow: "0px 3px 14px 2px #0000001F",
    boxShadow: "0px 5px 5px -3px #00000033",

    // box-shadow: 0px 8px 10px 1px #00000024;

    // box-shadow: 0px 5px 5px -3px #00000033;

    padding: theme.spacing(1), // Adjust padding as needed
  },

  smHide: {
    "@media screen and (max-width: 1050px)": {
      display: "none",
    },
  },
  mdHide: {
    "@media screen and (min-width: 1050px)": {
      display: "none",
    },
  },
  cardWidth: {
    width: "100%",
    "@media screen and (min-width: 750px)": {
      width: "90%",
    },
  },
  mdShow: {
    display: "block",
  },
}));
