import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  container: {
    position: "absolute",
    background: "#FFFFFF",
    border: "1px solid #EAEBEE",
    boxSizing: "border-box",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: "7px",
    padding: "17px 0px 18px 0px",
    fontFamily: "'Avenir Black', sans-serif",
    right: 0,
    width: 155,
    zIndex: 10,
  },
  item: {
    fontSize: "13px",
    lineHeight: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#000",
    cursor: "pointer",
    padding: "7px 24px 6px 24px",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.08)",
    },
  },
  editButton: {
    height: "45px !important",
    width: "auto !important",
    padding: "0px 30px",
  },
});
