import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const useStyles = makeStyles({
  root: {
    width: "100%",
    paddingBottom: "50px",
  },
  selectors: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 30,
    "@media screen and (max-width: 500px)": {
      justifyContent: "center",
      "& > div": {
        flexDirection: "column",
        alignItems: "center",
        "& #client-list": {
          marginTop: "25px",
        },
      },
    },
  },
  selectItems: {
    display: "flex",
    alignItems: "baseline",
  },
  tableContainer: {
    boxShadow: "0px 5px 45px rgba(0, 0, 0, 0.07)",
    borderRadius: "15px",
    paddingBottom: "10%",
  },
  table: {
    minWidth: 650,
    padding: 17,
  },
  tableHeader: {
    backgroundColor: "#ffffff",
    height: "35px",
    "& th": {
      fontWeight: 900,
    },
  },
  tableCell: {
    height: "22px",
    left: "0%",
    right: "94.24%",
    top: "calc(50% - 22px/2 - 0.04px)",
    fontSize: "15px",
    lineHeight: "22px",
    color: "#000000",
  },
  dateLabel: {
    fontSize: "15px",
    lineHeight: "22px",
    fontFamily: "'Avenir Black', sans-serif",
    fontWeight: 900,
    color: "#000000",
    marginRight: 15,
  },
  noClient: {
    fontSize: "18px",
    lineHeight: "21px",
    color: "#000000",
    fontFamily: "'Avenir Book', sans-serif",
    paddingTop: "73px",
  },
});

export const CTAContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 5px 45px rgba(0, 0, 0, 0.07);
  border-radius: 15px;
  padding: 30px 25px;
  font-family: "Avenir Black", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 900;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: baseline;
    button {
      margin-top: 20px;
    }
  }
  p {
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 22px;
  }
  button {
    span {
      font-size: 18px;
      line-height: 25px;
    }
    width: auto;
    height: auto;
    padding: 10px 30px;
  }
`;
