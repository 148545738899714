import React, { FC, useState } from "react";
import { TextField, InputAdornment } from "@material-ui/core";

import { useStyles } from "./styles";

interface AutoTagsProps {
  suggestions: Array<string>;
  setTags: (tags) => void;
  tags: Array<any>;
}
const AutoTags: FC<AutoTagsProps> = ({ suggestions, setTags, tags }) => {
  const [activeSuggestion, setActive] = useState(0);
  const [filteredSuggestions, setFiltered] = useState([]);
  const [showSuggestions, setShow] = useState(false);
  const [userInput, setInput] = useState("");

  const classes = useStyles();

  // Event fired when the input value is changed
  const onChange = (e) => {
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    const _filteredSuggestions: any = suggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown
    setActive(0);
    setFiltered(_filteredSuggestions);
    setShow(true);
    setInput(e.currentTarget.value);
  };

  // Event fired when the user clicks on a suggestion
  const onClick = (e) => {
    // Update the user input and reset the rest of the state
    const tag = e.currentTarget.innerText.replace("#", "");
    if (!tags.includes(tag)) {
      setTags([...tags, tag]);
    }
    setActive(0);
    setFiltered([]);
    setShow(false);
    setInput("");
  };

  // Event fired when the user presses a key down
  const onKeyDown = (e) => {
    const tag = e.target.value.trim().replace("#", "").split(" ").join("");
    if (tag !== "") {
      if (e.key === " " || e.key === "Enter" || e.key === "Tab") {
        if (!tags.includes(tag)) {
          setTags([...tags, tag]);
        }
        e.target.value = "";
        setInput("");
        e.target.focus();
      }
    }

    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === 13) {
      setActive(0);
      setShow(false);
      setInput("");
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      setActive(activeSuggestion - 1);
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      setActive(activeSuggestion + 1);
    }
  };
  let suggestionsListComponent;

  if (showSuggestions && userInput) {
    if (filteredSuggestions.length) {
      suggestionsListComponent = (
        <ul className={classes.suggestions}>
          {filteredSuggestions.map((suggestion, index) => {
            let className;
            if (index === activeSuggestion) {
              className = classes.suggestionActive;
            }

            return (
              <li className={className} key={suggestion} onClick={onClick}>
                {`#${suggestion}`}
              </li>
            );
          })}
        </ul>
      );
    }
  }

  return (
    <>
      <TextField
        type="text"
        name="tags"
        placeholder="Start writing..."
        autoComplete="tags"
        onKeyDown={onKeyDown}
        onChange={onChange}
        FormHelperTextProps={{
          className: classes.helperText,
        }}
        className={classes.tagsField}
        helperText=""
        margin="normal"
        InputProps={{
          disableUnderline: true,
          className: classes.tags,
          startAdornment: <InputAdornment position="start">#</InputAdornment>,
        }}
        value={userInput}
        style={{
          width: "100%",
        }}
      />
      {suggestionsListComponent}
    </>
  );
};

export default AutoTags;
