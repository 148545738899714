/**
 * @prettier
 */

import React, { FunctionComponent } from "react";
import { Button, Grid } from "@material-ui/core";

import { firestore } from "firebase-tools/firebase";
import useDocumentSubscriber from "hooks/useDocumentSubscriber";

import CampaignReportComponent from "components/MassEmailer/CampaignReport";
import { useStyles } from "./styles";
import { navigate } from "@reach/router";

type CampaignReportType = {
  campaignId?: string;
  path: string;
};

export const CampaignReport: FunctionComponent<CampaignReportType> = ({
  campaignId,
}) => {
  const classes = useStyles({});

  let campaign = firestore.collection("campaigns").doc(campaignId);
  let campaignReport =
    useDocumentSubscriber({
      ref: campaign,
      deps: [campaignId],
    }).document || "";

  return (
    <div className={classes.root}>
      <div className={classes.pageTitle} id="page-title">
        <Grid container justify="space-between" alignItems="center">
          <span className={classes.title}>Campaign Report</span>
          <Button
            disableRipple
            type="button"
            style={{
              background: "transparent",
              color: "#000",
              border: "1px solid #000",
              borderRadius: 15,
              cursor: "pointer",
              fontSize: "14px",
            }}
            variant="outlined"
            onClick={() => {
              navigate("/mass-emailer");
            }}
          >
            Back to campaigns list
          </Button>
        </Grid>
      </div>

      <CampaignReportComponent campaignReport={campaignReport} />
    </div>
  );
};
