/**
 * @prettier
 */

import React, { FunctionComponent, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  Paper,
  // LinearProgress,
} from "@material-ui/core";

import { useStyles } from "./styles";
import { getDate } from "./MassEmailer";
import { SendButton } from "components/styled/Common";
import { startCampaign } from "utils/updateCampaign";
import LinearProgressWithLabel from "./LinearProgressWithLabel";
import { firestore } from "firebase-tools/firebase";

type CampaignReportType = {
  campaignReport: any;
};

const CampaignReport: FunctionComponent<CampaignReportType> = ({
  campaignReport,
}) => {
  const classes = useStyles({});
  const [resumeDisabled, setResumeDisabled] = useState(false);
  const [pauseDisabled, setPauseDisabled] = useState(false);

  const numberOfReceivers = campaignReport?.receivers?.length;
  const successfullReceivers = campaignReport?.receivers?.filter(
    (rec) => rec?.isSent
  ).length;
  const seccessRate = (successfullReceivers / numberOfReceivers) * 100;

  const pauseCampaign = async () => {
    setPauseDisabled(true);
    setResumeDisabled(false);
    await firestore.collection("campaigns").doc(campaignReport.id).update({
      status: "Paused",
      scriptStatus: "Paused",
      isPaused: true,
    });
    setPauseDisabled(false);
  };

  const runCampaign = async () => {
    setResumeDisabled(true);
    setPauseDisabled(false);
    await startCampaign(campaignReport.id);
    setResumeDisabled(false);
  };

  const campaignStatus:
    | "running"
    | "paused"
    | "finished"
    | "created"
    | undefined = campaignReport?.status?.toLowerCase();

  return (
    <Box className={classes.root} style={{ width: "100%" }}>
      <Box className={classes.campaignHeader}>
        <Typography className={classes.campaignTitle}>
          Campaign Information
        </Typography>
        <Box>
          <Typography>
            <strong>Title: </strong>
            {campaignReport.campaignName}
          </Typography>
          <Typography className={classes.campaignContent}>
            <strong>Status: </strong> This campaign is {campaignReport.status}.
          </Typography>
          <Typography className={classes.campaignContent}>
            <strong>Date Added: </strong> Created at{" "}
            {campaignReport?.created_at?.seconds &&
              getDate(campaignReport?.created_at?.seconds)}
          </Typography>
          <Typography className={classes.campaignContent}>
            <small>
              <strong>PS: </strong>Time interval between each email send is{" "}
              {campaignReport.timeInterval} minutes
            </small>
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2} className={classes.templateSection}>
        <Grid style={{ width: "100%" }}>
          <Typography className={classes.campaignTitle}>
            Email Information
          </Typography>
          <Typography className={classes.campaignContent}>
            <strong>Email Subject:</strong> {campaignReport.subject}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item className={classes.w50}>
          <p>Email Template</p>
          <textarea
            className={`${classes.input} ${classes.textAreaTemp}`}
            placeholder="Enter Email Template..."
            value={campaignReport.template}
            onChange={(e) => {}}
          />
        </Grid>
        <Grid item className={classes.w50}>
          <p>Description</p>
          <textarea
            className={`${classes.input} ${classes.textAreaTemp}`}
            placeholder=""
            value={campaignReport.description}
            onChange={(e) => {}}
          />
        </Grid>
      </Grid>
      <Box display="flex" alignItems="center" flexDirection="column">
        {campaignStatus === "paused" && (
          <SendButton
            disableRipple
            disabled={resumeDisabled}
            style={{ width: 164, marginTop: "2rem" }}
            onClick={runCampaign}
          >
            <span>RESUME</span>
          </SendButton>
        )}
        {campaignStatus === "created" && (
          <SendButton
            disableRipple
            disabled={resumeDisabled}
            style={{ width: 164, marginTop: "2rem" }}
            onClick={runCampaign}
          >
            <span>START</span>
          </SendButton>
        )}
        {campaignStatus === "running" && (
          <SendButton
            disableRipple
            disabled={pauseDisabled}
            style={{ width: 164, marginTop: "2rem" }}
            onClick={pauseCampaign}
          >
            <span>PAUSE</span>
          </SendButton>
        )}
        <Box style={{ width: "100%" }}>
          <LinearProgressWithLabel
            style={{ backgroundColor: "#15891b" }}
            value={seccessRate?.toFixed(2)}
            completed={successfullReceivers}
          />
        </Box>
      </Box>
      <TableContainer
        component={Paper}
        style={{
          boxShadow: "0px 5px 45px rgba(0, 0, 0, 0.07)",
          borderRadius: "15px",
          marginTop: 50,
        }}
      >
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell className={classes.tableCell} align="center">
                #
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                Send Date
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                Receiver Email
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                sent?
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                Error message
              </TableCell>
            </TableRow>
          </TableHead>
          {/* Table Body */}
          <TableBody>
            {(campaignReport?.receivers || []).map((receiver, i) => (
              <TableRow key={receiver?.email} className={classes.row}>
                <TableCell align="center">
                  <div className={`${classes.post} ${classes.tableCell}`}>
                    <span className={classes.postDetails}>{i + 1}</span>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={`${classes.post} ${classes.tableCell}`}>
                    <span className={classes.postDetails}>
                      {receiver?.sendDate?.seconds &&
                        getDate(receiver?.sendDate?.seconds)}
                    </span>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={`${classes.post} ${classes.tableCell}`}>
                    <span className={classes.postDetails}>
                      {receiver?.email}
                    </span>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={`${classes.post} ${classes.tableCell}`}>
                    <span
                      className={classes.postDetails}
                      style={{
                        color: receiver?.isSent ? "#0af50ad4" : "red",
                      }}
                    >
                      {receiver?.isSent ? "✔" : "❌"}
                    </span>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={`${classes.post} ${classes.tableCell}`}>
                    <span className={classes.postDetails}>
                      {receiver?.error}
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CampaignReport;
