export const tooltips = {
  facebook: `To get Facebook data on Be-Measured for this client, you need to find the link to the FB page\
of the client, and copy the part after &quot;facebook.com/&quot; and paste it here.<br/>
You also need to give Full Admin access from the client to Kat&#39;s Facebook.<br/>
FYI - The first time you save this page with Facebook URL, the software will get 14 days of\
historical data automatically. It takes a couple of minutes. Subsequently, every night the\
software will get the data for the previous day.<br/>
When you&#39;re ready, click the button &quot;Test permissions&quot; to get immediate feedback if the\
setup works successfully.`,
  instagram: `To get Instagram data on Be-Measured for this client, you need to find the link to the IG page\
of the client, and copy the part after &quot;instagram.com/&quot; and paste it here.\
You also need to give Full Admin access from the client to Kat&#39;s Facebook. Once this is\
done, for Instagram to pull through, the Facebook and Instagram accounts need to be\
connected in the Instagram app.<br/>
FYI - The first time you save this page with Instagram URL, the software will get 14 days of\
historical data automatically. It takes a couple of minutes. Subsequently, every night the\
software will get the data for the previous day.<br/>
When you&#39;re ready, click the button &quot;Test permissions&quot; to get immediate feedback if the\
setup works successfully.`,
  twitter: `To get Twitter data on Be-Measured for this client, you need to find the link to the TW page\
of the client, and copy the part after &quot;twitter.com/&quot; and paste it here.<br/>
You also need to add access to the client&#39;s Twitter account for &quot;@BeHookdAnalyst&quot; with\
&quot;Organic Analyst&quot; permissions. You can do this from the page \
<a href="https://analytics.twitter.com" target="_blank" rel="noopener noreferrer" style="color:#fff;">https://analytics.twitter.com</a> and then\
clicking &quot;Edit Access to account&quot; from the top right corner.<br/>
FYI - We cannot get historical data on the day of adding the Twitter URL. Subsequently,\
every night the software will get the data for the previous day.<br/>
When you&#39;re ready, click the button &quot;Test permissions&quot; to get immediate feedback if the\
setup works successfully.`,
  tiktok: `To get TikTok data on Be-Measured for this client, you need to find the link to the TikTok\
page of the client, and copy the part after &quot;tiktok.com/@&quot; and paste it here. That's all there is<br/>
FYI - We cannot get historical data on the day of adding the TikTok URL. Subsequently,\
every night the software will get the data for the previous day. Some of the TikTok metrics\
need 2 nights of data before they would show in the chart (because we are calculating the\
difference between two days to find the incremental change).<br/>
When you're ready, click the button &quot;Test permissions&quot; to get immediate feedback if the\
setup works successfully.`,
  youtube: `To get YouTube data on Be-Measured for this client, you need to find the link to the YT channel of the client,\
and copy the part after &quot;youtube.com/channel/&quot; and paste it here. That&#39;s all there is.<br/>
If your Youtube URL doesn&#39;t have the &quot;youtube.com/channel/&quot; part, you should use the Channel ID from this page: \
https://www.youtube.com/account_advanced <br/>

FYI - We cannot get historical data on the day of adding the Youtube URL. Subsequently, every night the software\
will get the data for the previous day. Some of the Youtube metrics need 2 nights of data before they would show in \
the chart (because we are calculating the difference between two days to find the incremental change). <br/>

When you&#39;re ready, click the button &quot;Test permissions&quot; to get immediate feedback if the\
setup works successfully.
`,
  spotify: `To get Spotify data on Be-Measured for this client, you need to find the link to the Spotify
page of the client under \
<a href="https://artists.spotify.com/c/roster" target="_blank" rel="noopener noreferrer" style="color:#fff;">https://artists.spotify.com/c/roster</a>, and copy the part after\
&quot;https://artists.spotify.com/c/artist/&quot; and before &quot;/home&quot;, and paste it here.<br/>
To have the client appearing under artists.spotify.com/c/roster - you also need to add access\
to the client&#39;s Spotify account for &quot;spotify@be-hookd.com&quot;. You can do this from this \
<a href="https://artists.spotify.com" target="_blank" rel="noopener noreferrer" style="color:#fff;">https://artists.spotify.com</a>.<br/>
FYI - We cannot get historical data on the day of adding the Spotify URL. Subsequently,\
every night the software will get the data for the previous day.<br/>
When you&#39;re ready, click the button &quot;Test permissions&quot; to get immediate feedback if the\
setup works successfully.`,
  appleMusic: `To get Apple Music data on Be-Measured for this client, you need to find the link to the\
Apple Music page of the client under artists.apple.com/a/artist, and copy the part after\
&quot;artists.apple.com/a/artist/&quot;, and paste it here.<br/>
To have the client appearing under artists.apple.com/a/artist - you also need to add access to\
the client&#39;s Apple Music account for &quot;assistant@be-hookd.com&quot;. You can do this from this \
<a href="https://artists.apple.com" target="_blank" rel="noopener noreferrer" style="color:#fff;">https://artists.apple.com</a>.<br/>
FYI - We cannot get historical data on the day of adding the Apple Music URL.\
Subsequently, every night the software will get the data for the previous day.<br/>
When you&#39;re ready, click the button &quot;Test permissions&quot; to get immediate feedback if the\
setup works successfully.`,
};
