export const customHeaders = [
  { label: "Handle", key: "handle" },
  { label: "username", key: "name" },
  { label: "Profile Url", key: "profileUrl" },
  { label: "query", key: "query" },
  { label: "Text", key: "text" },
  { label: "Time Stamp", key: "timestamp" },
  { label: "Tweet Date", key: "tweetDate" },
  { label: "Tweet Link", key: "tweetLink" },
  { label: "Twitter ID", key: "twitterId" },
];
export const IGCustomHeaders = [
  { label: "Post Url", key: "postUrl" },
  { label: "Profile Url", key: "profileUrl" },
  { label: "Username", key: "username" },
  { label: "Full Name", key: "fullName" },
  { label: "Comment Count", key: "commentCount" },
  { label: "Like Count", key: "likeCount" },
  { label: "Description", key: "description" },
];
