import { IEvent } from "./IEvent";
import { IUser } from "./IUser";

export interface INotification {
  id?: string;
  status: NotificationStatus;
  clientName: string;
  initiatorName: string;
  initiatorId: string;
  authUser: IUser;
  initiatorEmail: string;
  client: IUser;
  postTitle: string;
  postId: string;
  postStatus: string;
  action: NotificationActions;
  notificationType: NotificationTypes;
  comment?: string;
  event?: IEvent;
  archive: boolean;
  created_at: Date;
  date: Date;
}

export enum NotificationStatus {
  unread = "unread",
  read = "read",
}

export enum NotificationActions {
  created_post = "created post",
  updated_post = "updated post",
  create_event = "created event",
  update_event = "updated event",
  added_comment = "added a comment",
}

export enum NotificationTypes {
  post = "post",
  event = "event",
  comment = "comment",
  event_comment = "event comment",
}
