import { INotification } from "interfaces/INotification";
import { NotificationService } from "services/NotificationService";

export const updateNotificationArchive = async (
  archive: boolean,
  notification: INotification
) => {
  const Notification = new NotificationService(
    "notifications",
    notification.id
  );

  let payload = { ...notification, archive };

  Notification.updatePost(payload)
    .then(async () => {})
    .catch((e) => {
      console.log("ERROR:", e);
      return;
    });
};
