import { IUser } from "interfaces/IUser";
import { UserService } from "services/UserService";

export const updateActiveUser = async (user: IUser, authUser: IUser) => {
  const User = new UserService(authUser.id);

  return User.updateUser({
    lastClientSelection: user,
  })

    .then((res) => {})
    .catch((err) => {});
};
