import { makeStyles } from "@material-ui/core/styles";

import { noStyleList } from "./index";

export const useStyles = makeStyles({
  selectContainer: {
    display: "inline-block",
    minWidth: "250px",
    textAlign: "left",
    position: "relative",
    width: "100%",
    marginTop: "5px",
    "& ul": {
      margin: "0",
      padding: "0",
      textAlign: "left",
      borderLeft: "2px solid #ABADAD",
      borderRight: "2px solid #ABADAD",
    },
    "& li": {
      fontFamily: "'Avenir Book', sans-serif",
      listStyleType: "none",
      padding: "6px 15px",
      background: "#fff",
      borderBottom: "2px solid #ABADAD",
      fontSize: "15px",
      lineHeight: "20px",
      cursor: "pointer",
      height: "47px",
      display: "flex",
      alignItems: "center",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08) !important",
      },
      "&:last-of-type": {
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
      },
    },

    "@media only screen and (max-width: 400px)": {
      minWidth: "unset",
    },
  },
  selectedText: {
    backgroundColor: "#fff",
    padding: "12.5px",
    border: "2px solid #ABADAD",
    borderRadius: "10px",
    height: "45px",
    fontSize: "15px",
    lineHeight: "20px",
    textAlign: "left",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    fontFamily: "Avenir Book, sans-serif",

    "&:after": {
      content: "''",
      position: "absolute",
      right: "15.5px",
      top: "19px",
      border: "4px solid transparent",
      borderColor: (props: any) =>
        !noStyleList.includes(props.selected) && props.applyColors
          ? "#fff transparent transparent transparent"
          : "#000 transparent transparent transparent",
    },
    "&:focus": {
      border: "2px solid #000 !important",
      outline: "none",
    },
    "&:hover": {
      border: "2px solid #000 !important",
    },
    "&:active": {
      border: "2px solid #000  !important",
    },
  },
  selectedTextActive: {
    "&:after": {
      top: "16px",
      borderColor: "transparent transparent #000 transparent",
    },
  },
  selectOptions: {
    width: "100%",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
  },
  checkbox: {
    marginRight: "10px",
    width: "14px",
    height: "14px",
    borderRadius: "unset",

    "&.Mui-checked": {
      backgroundColor: "#fff !important",
    },
  },
  statusVisibility: {
    fontSize: 15,
    paddingLeft: 4,
    color: "gray",
  },
});
