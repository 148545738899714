import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  info: {
    fontFamily: "Avenir Book, sans-serif",
    fontSize: 12,
    backgroundColor: "slategrey",
  },
  arrow: {
    color: "slategrey !important",
  },
});
