import React, { FC, useState } from "react";
import { Button, Tooltip } from "@material-ui/core";
import { DateTime } from "luxon";
import PrintIcon from "@material-ui/icons/Print";

import { useStyles } from "./styles";
import printDocument from "utils/printDocument";

interface IPrintMode {
  to: Date;
  from: Date;
  user: any;
}

const PrintMode: FC<IPrintMode> = ({ to, from, user }) => {
  const classes = useStyles({});

  const [isOpen, setIsOpen] = useState(false);
  const [isPrintMode, setIsPrintMode] = useState(true);

  const periodStart = DateTime.fromJSDate(from).toFormat("dd LLL yyyy");
  const periodEnd = DateTime.fromJSDate(to).toFormat("dd LLL yyyy");

  const handlePrint = (): void => {
    // add padding to toPrintContainer
    const toPrintContainer: any = document.getElementById("to-print");
    toPrintContainer.style.padding = "0 20px";

    // Hide header
    const header: any = document.getElementById("header");
    header.style.display = "none";

    // Hide selector
    const selector: any = document.getElementById("selector");
    selector.style.display = "none";

    // Hide filter
    const filter: any = document.getElementById("filter");
    filter.style.display = "none";

    // Hide PublicLink
    const PublicLink: any = document.getElementById("public-link");
    PublicLink.style.display = "none";

    // Hide posts actions
    const postsAction: any = document.querySelectorAll("#actions-post");
    postsAction.forEach((pst) => (pst.style.display = "none"));

    // Hide posts carousel
    const postsCarousel: any = document.querySelectorAll("#carousel");
    postsCarousel.forEach((pst) => (pst.style.display = "none"));

    // Un hide posts placeholder
    const placeholder: any = document.querySelectorAll("#image-placeholder");
    placeholder.forEach((pst) => (pst.style.display = "flex"));

    // Remove box shadow
    const post: any = document.querySelectorAll("#postCard");
    post.forEach((pst) => (pst.style.boxShadow = "none"));

    setIsPrintMode(!isPrintMode);
    setIsOpen(!isOpen);

    const clientName = user.firstName + ` ${user.lastName}`;

    setTimeout(() => {
      printDocument(
        `${clientName}_Content Plan_${periodStart} - ${periodEnd}`,
        "to-print"
      );
    }, 1500);
  };

  const handleNormalMode = (): void => {
    // remove padding to toPrintContainer
    const toPrintContainer: any = document.getElementById("to-print");
    toPrintContainer.style.padding = "0px";

    // Un hide header
    const header: any = document.getElementById("header");
    header.style.display = "block";

    // Un hide selector
    const selector: any = document.getElementById("selector");
    selector.style.display = "flex";

    // Un hide filter
    const filter: any = document.getElementById("filter");
    filter.style.display = "flex";

    // Un Hide PublicLink
    const PublicLink: any = document.getElementById("public-link");
    PublicLink.style.display = "block";

    // Un hide posts carousel
    const postsCarousel: any = document.querySelectorAll("#carousel");
    postsCarousel.forEach((pst) => (pst.style.display = "block"));

    // Hide posts placeholder
    const placeholder: any = document.querySelectorAll("#image-placeholder");
    placeholder.forEach((pst) => (pst.style.display = "none"));

    // Un hide posts actions
    const postsAction: any = document.querySelectorAll("#actions-post");
    postsAction.forEach((pst) => (pst.style.display = "block"));

    // Add box shadow
    const post: any = document.querySelectorAll("#postCard");
    post.forEach(
      (pst) => (pst.style.boxShadow = "0 5px 10px 0 rgba(0, 0, 0, 0.1)")
    );

    setIsPrintMode(!isPrintMode);
  };

  return (
    <div>
      {isPrintMode ? (
        <Tooltip
          title="Print Mode"
          arrow
          interactive
          classes={{ tooltip: classes.info, arrow: classes.arrow }}
        >
          <PrintIcon
            id="print"
            className={classes.archiveLabel}
            style={{ marginBottom: "-7px", marginRight: 20, cursor: "pointer" }}
            onClick={handlePrint}
          />
        </Tooltip>
      ) : (
        <div className={classes.printMode}>
          <Button
            disableRipple
            type="button"
            classes={{
              label: classes.archiveLabel,
            }}
            onClick={handleNormalMode}
          >
            Normal View
          </Button>
          <div className={classes.period}>
            Period: <span>{periodStart}</span> - <span>{periodEnd}</span>
          </div>
        </div>
      )}{" "}
    </div>
  );
};

export default PrintMode;
