import { DB } from "firebase-tools/db";

export const getUsers = async (users) => {
  const db = new DB();
  let clients = await db.getbyArray("users", "email", users);

  clients = clients.map((client) => client.email);

  return clients;
};

export const checkSecondaryEmail = async (secondaryEmails) => {
  const unInvited = secondaryEmails
    .filter((usr) => !usr.isInvited)
    .map((usr) => usr.email);

  const users = unInvited.length ? await getUsers(unInvited) : [];

  const exists = unInvited.filter((email) => users.includes(email));

  const message = !!exists.length
    ? `You already sent an invite to this email${
        exists.length > 1 ? "s" : ""
      }!  ${exists.join(", ")}`
    : "";
  return { exists, message };
};
