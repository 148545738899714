import React, { FunctionComponent, useState, useEffect } from "react";
import { TableCell, TableRow } from "@material-ui/core";
import moment from "moment";

// utils
import {
  getFbData,
  getIgData,
  getTiktokData,
  getTwitterData,
  getYouTubeData,
  // getAppleMusicData, // Disable for now
  getSpotifyData,
} from "../../utils/metrics";
import { numberWithCommas } from "utils/numberFormat";

import { IUser } from "interfaces/IUser";
import { useStyles } from "./styles";
import { DataCell } from "./DataCell";
import {
  fetchFbIGMetrics,
  fetchMetrics,
  fetchTiktokMetrics,
  fetchTwitterMetrics,
} from "helpers/fetchMetrics";

interface HealthCheckProps {
  user: IUser;
  selectedDateFrom: any;
  selectedDateTo: any;
  platform: {
    Facebook: boolean;
    Instagram: boolean;
    Twitter?: boolean;
    TikTok: boolean;
    YouTube: boolean;
    // "Apple Music": boolean; // Disable for now
    Spotify: boolean;
  };
  metricsType: {
    Posts: boolean;
    Growth: boolean;
    Engagement: boolean;
    Reels: boolean;
    Stories: boolean;
    Listeners: boolean;
  };
}

const reduce = (toReduce: Array<any>) =>
  toReduce?.reduce((a: number, b: number) => a + b, 0) || 0;

const formatEngagementRate = (
  curPeriodArr: Array<any>,
  prevPeriodArr: Array<any>,
  platform?: string
) => {
  let data = curPeriodArr;
  let prevData = prevPeriodArr;

  if (!curPeriodArr?.length && !prevPeriodArr?.length) return [];

  if (platform === "twitter") {
    data = curPeriodArr?.map((val) => val?.slice(0, -1));
    prevData =
      prevPeriodArr &&
      prevPeriodArr?.filter(Boolean)?.map((val) => val?.slice(0, -1));
  }

  const curData =
    data?.reduce((a: number, b: number) => Number(a) + Number(b), 0) || 0;
  const curDataAverage = curData / data.length;

  const prevDt =
    prevData?.reduce((a: number, b: number) => Number(a) + Number(b), 0) || 0;
  const prevDataAverage = prevDt / prevData.length;

  return [curDataAverage, prevDataAverage];
};

export const HealthCheck: FunctionComponent<HealthCheckProps> = ({
  user,
  selectedDateFrom,
  selectedDateTo,
  platform,
  metricsType,
}) => {
  const classes = useStyles({});
  const [fbLoading, setFbLoading] = useState(false);
  const [igLoading, setIGLoading] = useState(false);
  const [, setLoading] = useState(false);
  const [prevLoading, setPrevLoading] = useState(false);
  const [twitterLoading, setTwitterLoading] = useState(false);
  const [tiktokLoading, setTiktokLoading] = useState(false);
  const [youtubeLoading, setYouTubeLoading] = useState(false);
  const [, setAppleMusicLoading] = useState(false);
  const [spotifyLoading, setSpotifyLoading] = useState(false);
  const [fbMetrics, setFbMetrics]: any = useState([]);
  const [igMetrics, setIgMetrics]: any = useState([]);
  const [igStoriesMetrics, setIgStoriesMetrics]: any = useState([]);
  const [twitterMetrics, setTwitterMetrics]: any = useState([]);
  const [tiktokMetrics, setTiktokMetrics]: any = useState([]);
  const [youtubeMetrics, setYouTubeMetrics]: any = useState([]);
  const [, setAppleMusicMetrics]: any = useState([]);
  const [spotifyMetrics, setSpotifyMetrics]: any = useState([]);

  const [prevFbMetrics, setPrevFbMetrics]: any = useState([]);
  const [prevIgMetrics, setPrevIgMetrics]: any = useState([]);
  const [prevTwitterMetrics, setPrevTwitterMetrics]: any = useState([]);
  const [prevTiktokMetrics, setPrevTiktokMetrics]: any = useState([]);
  const [fbPermission, setFbPermission] = useState(false);

  useEffect(() => {
    if (user?.facebook?.username) {
      setFbPermission(
        user?.facebook?.permission || user?.facebook?.accessToken ? true : false
      );
    }
  }, [user.facebook?.username]);

  let from = new Date(selectedDateFrom);
  from = moment(from).add(1, "days").toDate();
  let to = new Date(selectedDateTo);

  const diff = moment(to).diff(moment(from), "days");

  const getMetrics = async (user: any) => {
    setLoading(true);
    if (user?.facebook?.username) {
      setFbLoading(true);
      const results = await fetchFbIGMetrics(
        "facebook-metrics",
        user.facebook.username,
        from,
        moment(to).add(1, "days").toDate()
      );
      if (!!results.length) {
        setFbMetrics(results);
      }
      setFbLoading(false);
    }
    if (user?.instagram?.username) {
      setIGLoading(true);
      const results = await fetchFbIGMetrics(
        "instagram-metrics",
        user.instagram.username,
        from,
        moment(to).add(1, "days").toDate()
      );

      if (!!results.length) {
        setIgMetrics(results);
      }
      setIGLoading(false);
    }
    if (user?.twitter?.username && user?.twitter?.accessToken) {
      console.log("fetching twitter metrics", user.twitter.username);
      setTwitterLoading(true);
      const results = await fetchTwitterMetrics(
        user.twitter.username,
        from,
        moment(to).add(1, "days").toDate()
      );
      console.log("twitter results", results);
      if (!!results.length) {
        setTwitterMetrics(results);
      }
      setTwitterLoading(false);
    }
    if (user?.tiktok?.username && user?.tiktok?.accessToken && user?.id) {
      setTiktokLoading(true);
      const results = await fetchTiktokMetrics(
        user.tiktok.username,
        user.id,
        from,
        moment(to).add(1, "days").toDate()
      );
      if (!!results.length) {
        setTiktokMetrics(results);
      }
      setTiktokLoading(false);
    } else {
      setTiktokMetrics([]);
    }
    if (user?.youtube?.username) {
      setYouTubeLoading(true);
      const results = await fetchMetrics(
        "youtube-metrics",
        user.youtube.username,
        from,
        moment(to).add(1, "days").toDate()
      );
      if (!!results.length) {
        setYouTubeMetrics(results);
      }
      setYouTubeLoading(false);
    } else {
      setYouTubeMetrics([]);
    }
    if (user?.appleMusic?.username) {
      setAppleMusicLoading(true);
      const results = await fetchMetrics(
        "apple-music-metrics",
        user.appleMusic.username,
        from,
        to
      );
      if (!!results.length) {
        setAppleMusicMetrics(results);
      }
      setAppleMusicLoading(false);
    } else {
      setAppleMusicMetrics([]);
    }
    if (user?.spotify?.username) {
      setSpotifyLoading(true);
      const results = await fetchMetrics(
        "spotify-metrics",
        user.spotify.username,
        moment(from).subtract(1, "days").toDate(),
        moment(to).add(2, "days").toDate()
      );
      if (!!results.length) {
        setSpotifyMetrics(results);
      }
      setSpotifyLoading(false);
    } else {
      setSpotifyMetrics([]);
    }
    setLoading(false);
  };

  const prevFrom = moment(from).subtract(diff, "days").toDate();
  const prevTo = moment(to).subtract(diff, "days").toDate();

  const getPrevMetrics = async (user: any) => {
    setPrevLoading(true);
    if (user?.facebook?.username) {
      const prevResults = await fetchFbIGMetrics(
        "facebook-metrics",
        user.facebook.username,
        prevFrom,
        prevTo
      );
      if (!!prevResults.length) {
        setPrevFbMetrics(prevResults);
      }
    }
    if (user?.instagram?.username) {
      const prevResults = await fetchFbIGMetrics(
        "instagram-metrics",
        user.instagram.username,
        prevFrom,
        prevTo
      );
      if (!!prevResults.length) {
        setPrevIgMetrics(prevResults);
      }
    }
    if (user?.twitter?.username) {
      const prevResults = await fetchMetrics(
        "twitter-metrics",
        user.twitter.username,
        prevFrom,
        prevTo
      );
      if (!!prevResults.length) {
        setPrevTwitterMetrics(prevResults);
      }
    }
    if (user?.tiktok?.username && user?.tiktok?.accessToken && user?.id) {
      const prevResults = await fetchTiktokMetrics(
        user.tiktok.username,
        user.id,
        prevFrom,
        prevTo
      );

      if (!!prevResults.length) {
        setPrevTiktokMetrics(prevResults);
      }
    }
    setPrevLoading(false);
  };

  const facebookMetrics = fbMetrics.filter(
    (metric) => metric.followers?.followers_count
  );
  const fbData = getFbData(facebookMetrics);
  const fbPrevData = getFbData(prevFbMetrics);
  const igFollowers =
    igMetrics.length > 0 && igMetrics[0].metadata?.followers_count;

  const igMeta = {
    engagements: [],
  };
  const igData = getIgData(igMetrics, "timeline");
  const igPrevData =
    igFollowers >= 100 ? getIgData(prevIgMetrics, "timeline") : igMeta;

  const twitterData = getTwitterData(twitterMetrics);
  const twPrevData = getTwitterData(prevTwitterMetrics);

  const tiktokData = getTiktokData(tiktokMetrics, user?.firstName);
  const tiktokPrevData = getTiktokData(prevTiktokMetrics);

  const youtubeData = getYouTubeData(youtubeMetrics);
  // const appleMusicData = getAppleMusicData(appleMusicMetrics); // Disable for now
  const spotifyData = getSpotifyData(spotifyMetrics);

  const calculatePercentageGrowth = (
    oldValue: number,
    newValue: number
  ): number => {
    if (oldValue === 0) {
      // Handle the case where the old value is 0 to avoid division by zero
      return newValue === 0 ? 0 : Infinity;
      // return newValue === 0 ? 0 : 0;
    }

    const percentageGrowth = ((newValue - oldValue) / Math.abs(oldValue)) * 100;
    return percentageGrowth;
  };

  const getGrowth = (arr: Array<any>, platform?: string) => {
    let growthRate;
    if (arr.length) {
      const lastValue = arr[arr.length - 1];
      const firstValue = arr[0];
      let growth = numberWithCommas(lastValue - firstValue);

      // Formula to use is
      // (End-Start) / Start = (1100 - 1000) / 1000 = 0.1
      // Then convert to percentage ie 0.1 * 100 = 10%
      let value;
      if (firstValue === 0 && lastValue === 0) {
        value = 0;
      } else if (firstValue === 0 && (lastValue > 0 || lastValue < 0)) {
        value = "N/A";
      } else {
        value = parseFloat(
          (((lastValue - firstValue) / firstValue) * 100).toFixed(2)
        );
      }
      if (value > 0) {
        growthRate = value.toLocaleString("en", { minimumFractionDigits: 2 });
      } else if (value === 0) {
        growthRate = "0.00";
      } else if (value === "N/A") {
        growthRate = "N/A";
      } else {
        growthRate = value.toLocaleString("en", { minimumFractionDigits: 2 });
      }

      if (platform === "tiktok-engagements") {
        const growthWithoutDecimal = Number(Number(growth).toFixed(0)) || 0;
        growth = numberWithCommas(growthWithoutDecimal);
        const total = lastValue + firstValue;
        const percentage = (lastValue / total) * 100;

        growthRate = percentage.toLocaleString("en", {
          minimumFractionDigits: 2,
        });
      }
      if (platform === "facebook") {
        growthRate = calculatePercentageGrowth(
          firstValue,
          lastValue
        )?.toLocaleString("en", {
          minimumFractionDigits: 2,
        });
        growth = numberWithCommas(lastValue - firstValue);
      }
      return {
        growth,
        growthRate,
      };
    }
  };

  useEffect(() => {
    getMetrics(user);
    getPrevMetrics(user);
    // eslint-disable-next-line
  }, [user]);

  const fbGrowth = getGrowth(fbData.followers, "facebook");
  // const igGrowth =
  //   igFollowers >= 100
  //     ? getGrowth(igData?.followers)
  //     : getGrowth(
  //         igMetrics.map((metric: any) => {
  //           return metric.metadata?.followers_count;
  //         })
  //       );

  const igGrowth = getGrowth(igData?.followers);
  const twitterGrowth = getGrowth(twitterData.followers);
  const tiktokGrowth = getGrowth(tiktokData.followers);
  const youtubeGrowth = getGrowth(youtubeData.followers);
  // const appleMusicGrowth = getGrowth(appleMusicData.followers); // Disable for now
  const spotifyGrowth = getGrowth(spotifyData.followers);
  const spotifyListeners = getGrowth(spotifyData.listeners);

  const fbPostsCount =
    fbData.postsCount?.length > 0 ? reduce(fbData.postsCount) : "";

  // const twitterEngagements = getGrowth(
  //   formatEngagementRate(
  //     twPrevData.engagementRate,
  //     twitterData.engagementRate,
  //     "twitter"
  //   ),
  //   "rate"
  // );

  const twitterPostsCount =
    twitterData.postsCount?.length > 0
      ? twitterData.postsCount[twitterData.postsCount.length - 1]
      : "";

  if (igData.postsCount) {
    console.log("igData.postsCount", igData.postsCount);
  }

  const igPostsCount =
    igData.postsCount?.length > 0 ? reduce(igData.postsCount) : "";
  const igReelsCount =
    igData.reelsCount?.length > 0 ? reduce(igData.reelsCount) : "";

  const tiktokPostsCount =
    tiktokData?.postsCount?.length &&
    Number(tiktokData?.postsCount[tiktokData?.postsCount?.length - 1]) > 0
      ? tiktokData?.postsCount[tiktokData?.postsCount?.length - 1]
      : "";
  const youtubePostsCount =
    youtubeData.postsCount?.length > 0 ? reduce(youtubeData.postsCount) : "";

  return (
    <TableRow key={user.id} className={classes.row}>
      <TableCell
        align="center"
        className={`${classes.clientName} ${classes.sticky}`}
      >
        <p
          className={classes.clientValue}
          style={{ height: "100%  !important", width: "160px" }}
        >
          {user.firstName} {user.lastName}
        </p>
      </TableCell>

      {platform.Instagram && metricsType.Growth && (
        <DataCell
          metric={igGrowth}
          loader={igLoading}
          permission={user?.instagram?.permission}
          username={user?.instagram?.username}
        />
      )}
      {/* {platform.Instagram && metricsType.Engagement && (
        <DataCell
          metric={igEngagements}
          loader={igLoading || prevLoading}
          percentage={true}
          name="engagement"
          permission={user?.instagram?.permission}
          username={user?.instagram?.username}
        />
      )} */}
      {platform.Instagram && metricsType.Posts && (
        <DataCell
          metric={igPostsCount.toString()}
          loader={igLoading}
          posts={true}
          permission={user?.instagram?.permission}
          username={user?.instagram?.username}
        />
      )}
      {platform.Instagram && metricsType.Reels && (
        <DataCell
          metric={igReelsCount.toString()}
          loader={igLoading}
          posts={true}
          permission={user?.instagram?.permission}
          username={user?.instagram?.username}
        />
      )}
      {/* {platform.Instagram && metricsType.Stories && (
        <DataCell
          metric={igStoriesCount.toString()}
          loader={igLoading}
          posts={true}
          permission={user?.instagram?.permission}
          username={user?.instagram?.username}
        />
      )} */}
      {platform.Facebook && metricsType.Growth && (
        <DataCell
          metric={fbGrowth}
          loader={fbLoading}
          permission={fbPermission}
          username={user?.facebook?.username}
        />
      )}
      {/* {platform.Facebook && metricsType.Engagement && (
        <DataCell
          metric={fbEngagements}
          loader={fbLoading || prevLoading}
          percentage={true}
          name="engagement"
          permission={user?.facebook?.permission}
          username={user?.facebook?.username}
        />
      )} */}

      {platform.Facebook && metricsType.Posts && (
        <DataCell
          metric={fbPostsCount.toString()}
          loader={fbLoading}
          posts={true}
          permission={user?.facebook?.permission}
          username={user?.facebook?.username}
        />
      )}
      {platform.Twitter && metricsType.Growth && (
        <DataCell
          metric={twitterGrowth}
          loader={twitterLoading}
          permission={user?.twitter?.permission}
          username={user?.twitter?.username}
        />
      )}
      {/* {platform.Twitter && metricsType.Engagement && (
        <DataCell
          metric={twitterEngagements}
          loader={twitterLoading || prevLoading}
          percentage={true}
          name="engagement"
          permission={user?.twitter?.permission}
          username={user?.twitter?.username}
        />
      )} */}
      {platform.Twitter && metricsType.Posts && (
        <DataCell
          metric={twitterPostsCount.toString()}
          loader={twitterLoading}
          posts={true}
          permission={user?.twitter?.permission}
          username={user?.twitter?.username}
        />
      )}
      {platform.TikTok && metricsType.Growth && (
        <DataCell
          metric={tiktokGrowth}
          loader={tiktokLoading}
          permission={user?.tiktok?.permission}
          username={user?.tiktok?.username}
        />
      )}
      {/* {platform.TikTok && metricsType.Engagement && (
        <DataCell
          metric={tiktokEngagements}
          loader={tiktokLoading || prevLoading}
          percentage={false}
          name="engagement"
          permission={user?.tiktok?.permission}
          username={user?.tiktok?.username}
        />
      )} */}
      {platform.TikTok && metricsType.Posts && (
        <DataCell
          metric={tiktokPostsCount?.toString()}
          loader={tiktokLoading}
          posts={true}
          permission={user?.tiktok?.permission}
          username={user?.tiktok?.username}
        />
      )}
      {platform.YouTube && metricsType.Growth && (
        <DataCell
          metric={youtubeGrowth}
          loader={youtubeLoading}
          permission={user?.youtube?.permission}
          username={user?.youtube?.username}
        />
      )}
      {platform.YouTube && metricsType.Posts && (
        <DataCell
          metric={youtubePostsCount.toString()}
          loader={youtubeLoading}
          posts={true}
          permission={user?.youtube?.permission}
          username={user?.youtube?.username}
        />
      )}

      {/* // Hide for now */}
      {/* {platform["Apple Music"] && metricsType.Growth && (
        <DataCell metric={appleMusicGrowth} loader={appleMusicLoading} />
      )} */}
      {platform.Spotify && metricsType.Growth && (
        <DataCell
          metric={spotifyGrowth}
          loader={spotifyLoading}
          permission={user?.spotify?.permission}
          username={user?.spotify?.username}
        />
      )}
      {platform.Spotify && metricsType.Listeners && (
        <DataCell
          metric={spotifyListeners}
          loader={spotifyLoading}
          permission={user?.spotify?.permission}
          username={user?.spotify?.username}
        />
      )}
    </TableRow>
  );
};
