import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

const SortButton = ({ onSortToggle, sortDirection }) => {
  return (
    <div onClick={onSortToggle} style={{ cursor: "pointer", color: "#ABADAD" }}>
      {sortDirection === "desc" ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
    </div>
  );
};

export default SortButton;
