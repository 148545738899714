import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  select: {
    fontSize: "15px",
    fontFamily: "'Avenir Book', sans-serif",
    lineHeight: "22px",
    border: "1px solid #E8E8E8",
    borderRadius: "15px",
    padding: "5px 18.5px 5px 20.5px",
    marginTop: "8.5px",
    marginRight: "15px",
    width: "171px",
    height: "45px",
    cursor: "pointer",
    background: "#FFF",
  },
  selectIcon: {
    marginRight: "18.5px !important",
    color: "#000000",
    width: "17px",
    height: "28px",
    top: "unset",
  },
  selectIconOpen: {
    marginRight: "0px",
  },
  selectInput: {
    textAlign: "left",
  },
  selectOverrideChannels: {
    "& ul": {
      overflowX: "hidden",
    },

    "& li": {
      fontSize: "15px",
      fontFamily: "'Avenir Book', sans-serif",
      color: "#000000",
    },

    "&.MuiSelect-select": {
      "&:focus": {
        backgroundColor: "#fff",
      },
      "&:active": {
        backgroundColor: "#fff",
      },
      "&:hover": {
        backgroundColor: "#fff",
      },
    },
  },
});
