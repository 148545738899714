/**
 * @prettier
 */

import React, { FC } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { useStyles } from "./styles";

import { DateTime } from "luxon";
import { navigate } from "@reach/router";

export const getDate = (dateInSeconds) =>
  DateTime.fromSeconds(dateInSeconds).toFormat("dd LLL yyyy',' HH:mm");

const MassEmailer: FC<{ campaigns: any[] }> = ({ campaigns }) => {
  const classes = useStyles({});

  return (
    <Box className={classes.root}>
      {campaigns.map((data, index) => (
        <Box className={classes.campaign} key={index}>
          <Box className={classes.campaignHeading}>
            <Typography className={classes.header}>
              {data?.campaignName}
            </Typography>
            <Typography className={classes.header}>
              {data?.status === "Finished" ? "✅" : "🟨"}
            </Typography>
          </Box>
          <Box className={classes.campaignBody}>
            <Typography className={classes.content}>
              <strong>Campaign ID:</strong> {data?.id}
            </Typography>
            <Typography className={classes.content}>
              Created at {getDate(data?.created_at.seconds)}
            </Typography>
            <Button
              disableRipple
              type="button"
              className={classes.btn}
              variant="outlined"
              onClick={() => {
                navigate(`/mass-emailer/${data?.id}`);
              }}
            >
              Show Report
            </Button>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default MassEmailer;
