import { navigate } from "@reach/router";
import axios from "axios";
import { SocialMediaPlatform } from "containers/BeAmplified/Campaigns/Organic/SelectCampaign";

export const instagramAutoCommenter = async ({
  id,
  spreadsheetUrl,
  sessionCookie,
  campaignName,
  username,
  numberOfPostsPerLaunch,
  delayBetween,
}) => {
  if (!process.env.REACT_APP_FUNCTIONS_BASE_URL) {
    console.log("Invalid url");
    return;
  }
  let returnValue: any;
  await axios
    .post(
      `${process.env.REACT_APP_FUNCTIONS_BASE_URL}instagram-autoCommenter`,
      {
        id,
        spreadsheetUrl,
        sessionCookie,
        campaignName,
        username,
        numberOfPostsPerLaunch,
        delayBetween,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then(async (result: any) => {
      navigate(
        `/campaigns/organic/auto-commenter/${SocialMediaPlatform.Instagram.toLowerCase()}/${
          result?.data?.id
        }`
      );
      return result.data;
    })
    .catch((error) => {
      returnValue = {
        message: error?.response?.data?.message || "Something went wrong",
      };
    });

  return returnValue;
};
