import firebase from "firebase/app";
import moment from "moment";

import { firestore } from "../firebase-tools/firebase";
import { longDate } from "./dateFormat";

export const updateUserPageVisit = async (user: any) => {
  try {
    let today: any = longDate(new Date());

    if (today.includes("Invalid")) {
      today = moment().format("DD MMM YYYY");
    }
    const userRef = firestore.collection("users").doc(user.id);
    await userRef.update({
      page_visit: firebase.firestore.FieldValue.arrayUnion(today),
    });
    return true;
  } catch (err) {
    throw new Error(err);
  }
};
