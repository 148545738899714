import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    display: "flex",
    zIndex: 5,
  },
  label: {
    textTransform: "capitalize",
    height: "22px",
    left: "17.16%",
    right: "46.27%",
    top: "calc(50% - 22px/2 - 81px)",
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: "15px",
    lineHeight: "22px",
    color: "#000000",
  },
  line: {
    left: "0.75%",
    right: "0.75%",
    top: "61.35%",
    bottom: "37.68%",
    border: "1px solid #E8E8E8",
  },
  button: {
    zIndex: 1,
    left: "0.37%",
    width: "134px",
    right: "0.37%",
    top: "0%",
    height: "45px",
    bottom: "78.26%",
    border: "1px solid #E8E8E8",
  },
  popper: {
    background: "#FFFFFF",
    border: "1px solid #E8E8E8",
    boxSizing: "border-box",
    zIndex: 10,
    width: "134px",
    height: "160px",
    left: "8.96%",
    right: "33.58%",
    top: "calc(50% - 160px/2 + 23px)",
  },
  li: {
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: "11.5px",
    lineHeight: "26.5px",
    color: "#000000",
  },
});
