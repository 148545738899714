import React, { FunctionComponent } from "react";
import { useStyles } from "./styles";
import { BlockingLink } from "./NavLink";

interface LinkProps {
  duration: string;
  toggleMenuStatus: (bool) => void;
}
export const CalenderLink: FunctionComponent<LinkProps> = ({
  duration,
  toggleMenuStatus,
}) => {
  const classes = useStyles({});
  return (
    <span
      onClick={() => {
        toggleMenuStatus(false);
      }}
    >
      <div
        style={{
          fontWeight: 600,
          cursor: "pointer",
          textTransform: "capitalize",
          fontFamily: "Avenir Black, sans-serif",
        }}
        className={
          window.location.href.includes(duration)
            ? classes.activeLink
            : classes.inActiveLink
        }
      >
        <BlockingLink
          to={"/monthly-conversation-planner"}
          style={{
            textDecoration: "none",
            color: "unset",
          }}
        >
          {" "}
          Be-Planned {duration}
        </BlockingLink>
      </div>
    </span>
  );
};
