export const scrollInto = (id) => {
  const element: any = document.getElementById(id);
  element?.scrollIntoView({ behavior: "smooth" });
};

export const cancelReloadTabClose = (toSave) => {
  window.onbeforeunload = (event) => {
    if (toSave) {
      const e = event || window.event;
      // Cancel the event
      e.preventDefault();
      if (e) {
        e.returnValue = "";
      }
      return "";
    }
  };
};
