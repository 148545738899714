import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "'Avenir Black', sans-serif",
    marginRight: "24px",
    position: "relative",
  },
  label: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 900,
    color: "#000000",
  },
  platformHeader: {
    fontWeight: 900,
    fontSize: "18px",
    lineHeight: "25px",
    display: "flex",
    alignItems: "center",
    padding: "22px 0px",
  },
  icon: {
    width: "27px",
    height: "31px",
  },
  checkbox: {
    "&.MuiCheckbox-colorPrimary.Mui-checked": {
      color: "#000 !important",
    },
    "&:hover": {
      background: "rgba(0,0,0,0.3) !important",
    },
  },
});

export const SelectedText = styled.div`
  .selected-text {
    background-color: #fff;
    padding: 14.5px;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    height: 45px;
    font-size: 15px;
    min-width: 163px;
    line-height: 20px;
    text-align: left;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8.5px;
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding-right: 8px;
    }
    :after {
      content: "";
      position: relative;
      border: 4px solid transparent;
      border-color: #000 transparent transparent transparent;
    }
  }
  .selected-text-active {
    :after {
      border-color: transparent transparent #000 transparent;
    }
  }
`;

export const SelectItemsContainer = styled.div`
  margin-bottom: 5px;
  border-top: none;
  position: absolute;
  top: 80px;
  right: 0px;
  background: #ffffff;
  box-shadow: 0px 5px 45px rgba(0, 0, 0, 0.07);
  border-radius: 15px;
  min-width: 313px;
  padding: 20px;
  z-index: 10;
  .content {
    overflow-y: auto;
    min-height: 100px;
    max-height: 393px;
    padding-right: 11px;
  }
  label {
    display: flex;
    align-items: center;
    padding: 5px 0px;
    margin: 0;
    font-weight: 800;
    font-size: 14px;
    line-height: 15px;
    color: #606060;
    :hover {
      background: rgba(0, 0, 0, 0.08);
    }
    span {
      font-family: "Avenir Black", sans-serif;
    }
    span:first-child {
      padding: 0 6px 0 0;

      span {
        margin: 0;
      }
    }
  }
  * {
    scrollbar-width: 5px;
    scrollbar-color: #ecf3f7 #000000;
  }
  *::-webkit-scrollbar {
    width: 5px;
    margin-left: 11px;
  }
  *::-webkit-scrollbar-track {
    background: #ecf3f7;
    border-radius: 3px;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-radius: 3px;
  }
`;
