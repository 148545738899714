import { checkPermission } from "../requests/permissions";

export const checkChange = (e, fieldName, userInfo, setToSave) => {
  const getValue = () => {
    switch (fieldName) {
      case "name":
        return `${userInfo?.firstName} ${userInfo?.lastName}`;
      case "facebook":
        return userInfo?.facebook?.username;
      case "instagram":
        return userInfo?.instagram?.username;
      case "twitter":
        return userInfo?.twitter?.username;
      case "tiktok":
        return userInfo?.tiktok?.username;
      case "youtube":
        return userInfo?.youtube?.username;
      case "spotify":
        return userInfo?.spotify?.username;
      case "Apple Music":
        return userInfo?.appleMusic?.username;
      case "notes":
        return userInfo?.notes;
    }
  };

  e.target.value !== getValue() && setToSave(true);
};

export const checkPermissions = async (
  type: string,
  username: string,
  state: { errors: any; messages }
) => {
  const res = await checkPermission(username, `${type}-permission`);
  if (res.error) {
    const errors = state.errors.permissionErrors;
    errors[type] = res.error;
    state.errors.setPermErrors(errors);
    const messages = state.messages.permissionMessages;
    messages[type] = "";
    state.messages.setPermissionMessages(messages);
  } else if (res.message) {
    const errors = state.errors.permissionErrors;
    errors[type] = "";
    state.errors.setPermErrors(errors);
    const messages = state.messages.permissionMessages;
    messages[type] = "Permissions okay";
    state.messages.setPermissionMessages(messages);
  } else {
    const errors = state.errors.permissionErrors;
    errors[type] = "An occurred, retry";
    state.errors.setPermErrors(errors);
    const messages = state.messages.permissionMessages;
    messages[type] = "";
    state.messages.setPermissionMessages(messages);
  }
};
