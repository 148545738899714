import { firebase, firestore } from "../../../firebase-tools/firebase";
import { PostService } from "services/PostService";

export const deleteMedia = async (media) => {
  const mediaRef = await firebase.storage().refFromURL(media);
  await mediaRef.delete();
};

export const handleDeleteVideo = async (
  video,
  post,
  edit,
  setVideo,
  setLink,
  setLinkInput,
  type?: boolean
) => {
  const isFirebase = video.includes("firebasestorage");
  if (isFirebase && !type) {
    await deleteMedia(video);
  }

  if (!edit) {
    !type && setVideo("");
    setLink("");
    setLinkInput(false);
    return;
  }

  if (edit && post.video_url !== "" && !type) {
    const Post = new PostService("multichannel-posts", post.id);
    Post.updatePost({ video_url: "" });
  }

  !type && setVideo && setVideo("");
  setLink && setLink("");
  setLinkInput && setLinkInput(false);
};

export const handleDeleteImage = async (
  post,
  image,
  images,
  setImages,
  edit
) => {
  await deleteMedia(image);
  const newItems = images.filter((item) => item !== image);
  setImages && setImages(newItems);

  if (edit && post.image_url.includes(image)) {
    const Post = new PostService("multichannel-posts", post.id);
    Post.updatePost({ image_url: newItems });
  }
};

export const updateSuggestions = (
  oldTags,
  tags,
  platforms,
  platform,
  docId
) => {
  const plats = platforms.map((v) => v.toLowerCase());
  const _oldTags = oldTags.map((v) => v.toLowerCase());
  const _tags = tags.filter((tag) => !_oldTags.includes(tag.toLowerCase()));
  const newTags = [...oldTags, ..._tags];
  if (!plats.includes(platform.toLowerCase())) {
    const updatedMedia = [
      ...platforms,
      platform.charAt(0).toUpperCase() + platform.slice(1),
    ];
    firestore.collection("social-media").doc(docId).update({
      platforms: updatedMedia,
      tags: newTags,
    });
  } else {
    if (oldTags.length !== newTags.length) {
      firestore.collection("social-media").doc(docId).update({
        tags: newTags,
      });
    }
  }
};
