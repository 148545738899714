import React, { FC } from "react";
import Button from "@material-ui/core/Button";

import { FieldTitle } from "components/styled/CreateEditPost";
import SingleSelect from "components/Common/SingleSelect";
import { useStyles } from "./styles";

interface Props {
  internalStatusMonthly: string;
  setInternalStatusMonthly: (status: string) => void;
  internalStatusWeekly: string;
  setInternalStatusWeekly: (status: string) => void;
  monthlyCalendar?: boolean;
  save?: (status: string) => void;
  disabled?: boolean;
  message?: string;
}

const Status: FC<Props> = ({
  internalStatusMonthly,
  setInternalStatusMonthly,
  internalStatusWeekly,
  setInternalStatusWeekly,
  monthlyCalendar,
  save,
  disabled,
  message,
}) => {
  const classes = useStyles({});

  const monthlyOptions = [
    { id: 1, name: "Draft" },
    { id: 2, name: "Pending Approval" },
    { id: 3, name: "Client Approved" },
  ];
  const weeklyOptions = [
    { id: 2, name: "Pending Client Approval" },
    { id: 5, name: "Partially Approved by Client" },
    { id: 3, name: "Client Declined" },
    { id: 4, name: "Client Approved" },
    { id: 1, name: "Draft" },
  ];
  return (
    <div>
      {/* <FieldTitle>Monthly Status</FieldTitle>
      <SingleSelect
        options={monthlyOptions}
        setSelected={setInternalStatusMonthly}
        selected={internalStatusMonthly}
        month={true}
        applyColors={false}
      />
      {monthlyCalendar && (
        <div className={classes.statusBtnContainer}>
          <Button
            style={{
              width: 122,
              height: 35,
            }}
            className={classes.btn}
            onClick={() => save && save("monthly")}
            disabled={disabled || !internalStatusMonthly}
          >
            Save Monthly
          </Button>
          {(disabled || !internalStatusMonthly) && (
            <span
              style={{
                color: "red",
                fontSize: 14,
                fontFamily: "Avenir Book, sans-serif",
              }}
            >
              {message}
            </span>
          )}
        </div>
      )} */}

      <div style={{ marginTop: 26 }}>
        <FieldTitle>Post Approval</FieldTitle>
        <SingleSelect
          options={weeklyOptions}
          setSelected={setInternalStatusWeekly}
          selected={internalStatusWeekly}
          applyColors={true}
        />
      </div>
      {monthlyCalendar && (
        <div className={classes.statusBtnContainer}>
          <Button
            style={{
              width: 122,
              height: 35,
            }}
            className={classes.btn}
            onClick={() => save && save("weekly")}
            disabled={disabled || !internalStatusWeekly}
          >
            Save
          </Button>
          {(disabled || !internalStatusWeekly) && (
            <span
              style={{
                color: "red",
                fontSize: 14,
                fontFamily: "Avenir Book, sans-serif",
              }}
            >
              {message}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default Status;
