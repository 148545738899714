//this file will need refactoring <Tooltip></Tooltip> has been repeated
import React, { FC } from "react";
import { Checkbox } from "@material-ui/core";
import {
  CheckCircleOutlineSharp,
  RadioButtonUncheckedSharp,
  ControlPointSharp,
} from "@material-ui/icons";

import ChannelsStyled from "components/styled/Channels";

import facebook from "static/media/fb-vector.png";
import twitter from "static/media/twitter-vector.png";
import instagram from "static/media/instagram-vector.png";
import tiktok from "static/media/tiktok.png";
import defaultIcon from "static/media/universal-2.png";
import youtube from "static/media/youtube.png";
import linkedin from "static/media/linkedin.png";
import youtubeShort from "static/media/youtube-shorts.png";
import CustomToolTip from "./CustomToolTip";
interface IChannels {
  addChannel: boolean;
  setAddChannel: (boolean) => void;
  selectedChannels: Array<string>;
  setSelectedChannels: (value) => void;
}

const channelsList = [
  "instagram",
  "facebook",
  "twitter",
  "tiktok",
  "youtube",
  "linkedin",
  "youtubeshort",
];

const Channels: FC<IChannels> = ({
  addChannel,
  setAddChannel,
  selectedChannels,
  setSelectedChannels,
}) => {
  const handleChannelsChange = (channel) => {
    const channels = [...selectedChannels];

    const index = channels.indexOf(channel);
    if (index > -1) {
      channels.splice(index, 1);
      setSelectedChannels(channels);
      return;
    }

    channels.push(channel);

    setSelectedChannels(channels);
  };

  const customChannels = selectedChannels.filter(
    (channel) => !channelsList.includes(channel)
  );

  const socialStyle = {
    width: "26px",
    height: "27px",
    position: "absolute",
    top: "-2px",
    right: 0,
    zIndex: 5,
    cursor: "pointer",
  };

  return (
    <ChannelsStyled>
      <CustomToolTip title="Instagram">
        <div className="checkBoxContainer">
          <img
            style={socialStyle as React.CSSProperties}
            src={instagram || defaultIcon}
            alt=""
            onClick={() => handleChannelsChange("instagram")}
          />
          <Checkbox
            className="checkBox"
            color="default"
            checkedIcon={<CheckCircleOutlineSharp className="circularIcon" />}
            icon={<RadioButtonUncheckedSharp className="circularIcon" />}
            checked={selectedChannels.includes("instagram")}
            onChange={() => handleChannelsChange("instagram")}
          />
        </div>
      </CustomToolTip>

      <CustomToolTip title="Facebook">
        <div className="checkBoxContainer">
          <img
            style={socialStyle as React.CSSProperties}
            src={facebook || defaultIcon}
            alt=""
            onClick={() => handleChannelsChange("facebook")}
          />
          <Checkbox
            className="checkBox"
            color="primary"
            checkedIcon={<CheckCircleOutlineSharp className="circularIcon" />}
            icon={<RadioButtonUncheckedSharp className="circularIcon" />}
            checked={selectedChannels.includes("facebook")}
            onChange={() => handleChannelsChange("facebook")}
          />
        </div>
      </CustomToolTip>

      <CustomToolTip title="Twitter">
        <div className="checkBoxContainer">
          <img
            style={socialStyle as React.CSSProperties}
            src={twitter || defaultIcon}
            alt=""
            onClick={() => handleChannelsChange("twitter")}
          />
          <Checkbox
            className="checkBox"
            color="primary"
            checkedIcon={<CheckCircleOutlineSharp className="circularIcon" />}
            icon={<RadioButtonUncheckedSharp className="circularIcon" />}
            checked={selectedChannels.includes("twitter")}
            onChange={() => handleChannelsChange("twitter")}
          />
        </div>
      </CustomToolTip>

      <CustomToolTip title="TikTok">
        <div className="checkBoxContainer">
          <img
            style={socialStyle as React.CSSProperties}
            src={tiktok || defaultIcon}
            alt=""
            onClick={() => handleChannelsChange("tiktok")}
          />
          <Checkbox
            className="checkBox"
            color="primary"
            checkedIcon={<CheckCircleOutlineSharp className="circularIcon" />}
            icon={<RadioButtonUncheckedSharp className="circularIcon" />}
            checked={selectedChannels.includes("tiktok")}
            onChange={() => handleChannelsChange("tiktok")}
          />
        </div>
      </CustomToolTip>

      <CustomToolTip title="YouTube">
        <div className="checkBoxContainer">
          <img
            style={socialStyle as React.CSSProperties}
            src={youtube || defaultIcon}
            alt=""
            onClick={() => handleChannelsChange("youtube")}
          />
          <Checkbox
            className="checkBox"
            color="primary"
            checkedIcon={<CheckCircleOutlineSharp className="circularIcon" />}
            icon={<RadioButtonUncheckedSharp className="circularIcon" />}
            checked={selectedChannels.includes("youtube")}
            onChange={() => handleChannelsChange("youtube")}
          />
        </div>
      </CustomToolTip>

      <CustomToolTip title="LinkedIn">
        <div className="checkBoxContainer">
          <img
            style={socialStyle as React.CSSProperties}
            src={linkedin || defaultIcon}
            alt=""
            onClick={() => handleChannelsChange("linkedin")}
          />
          <Checkbox
            className="checkBox"
            color="primary"
            checkedIcon={<CheckCircleOutlineSharp className="circularIcon" />}
            icon={<RadioButtonUncheckedSharp className="circularIcon" />}
            checked={selectedChannels.includes("linkedin")}
            onChange={() => handleChannelsChange("linkedin")}
          />
        </div>
      </CustomToolTip>

      <CustomToolTip title="Youtube Short">
        <div className="checkBoxContainer">
          <img
            style={socialStyle as React.CSSProperties}
            src={youtubeShort || defaultIcon}
            alt=""
            onClick={() => handleChannelsChange("youtubeshort")}
          />
          <Checkbox
            className="checkBox"
            color="primary"
            checkedIcon={<CheckCircleOutlineSharp className="circularIcon" />}
            icon={<RadioButtonUncheckedSharp className="circularIcon" />}
            checked={selectedChannels.includes("youtubeshort")}
            onChange={() => handleChannelsChange("youtubeshort")}
          />
        </div>
      </CustomToolTip>

      {customChannels.map((otherChannel, i) => (
        <CustomToolTip title={otherChannel} key={`${i}-channel`}>
          <div className="checkBoxContainer">
            <img
              style={{
                width: "16px",
                height: "17px",
                position: "absolute",
                top: "-2px",
                right: 0,
                zIndex: 5,
              }}
              src={defaultIcon}
              alt=""
            />
            <Checkbox
              className="checkBox"
              color="primary"
              checkedIcon={<CheckCircleOutlineSharp className="circularIcon" />}
              icon={<RadioButtonUncheckedSharp className="circularIcon" />}
              checked={true}
              onChange={() => handleChannelsChange(otherChannel)}
            />
          </div>
        </CustomToolTip>
      ))}

      {
        <CustomToolTip title="Add custom channel">
          <ControlPointSharp
            className="circularIcon"
            onClick={() => setAddChannel(!addChannel)}
          />
        </CustomToolTip>
      }
    </ChannelsStyled>
  );
};

export default Channels;
