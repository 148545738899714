/**
 * @prettierdesc
 */

import React, { FC, useState } from "react";
import { Box, Tooltip, CircularProgress } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import history from "history/browser";
import cuid from "cuid";
import CreateCampaignStyles from "components/styled/CreateCampaign";
import Image from "components/Common/Image/Image";
import twitter from "static/media/twitter-vector.png";
import defaultIcon from "static/media/universal-2.png";
import { SendButton } from "components/styled/Common";
import { PageType } from "types/PageType";
import GetCookies from "components/Common/GetCookies";
import CampaignName from "components/Common/CampaignName";
import { useUser } from "UserContext";
import { twitterAutoLiker } from "helpers/autoLiker";
import { IUser } from "interfaces/IUser";
import { firestore } from "firebase-tools/firebase";
import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import { SocialMediaPlatform } from "containers/BeAmplified/Campaigns/Organic/SelectCampaign";
import showToast from "utils/toast";

interface CreateAutoPosterProps extends PageType {
  uid?: string;
  type?: string;
  toggleCreate?: Function;
}

export const Twitter: FC<CreateAutoPosterProps> = ({
  toggleCreate,
  uid,
  type,
}) => {
  const [campaignName, setCampaignName] = useState("");
  const [sessionCookie, setSessionCookie] = useState("");
  const [spreadsheetUrl, setSpreadsheetUrl] = useState("");
  const [likesCountPerProfile, setLikesCountPerProfile] = useState(1);
  const [errors, setErrors] = useState({
    client: "",
    campaignName: "",
    spreadsheetUrl: "",
    likesCountPerProfile: "",
  });
  const [loading, setLoading] = useState(false);

  const id = cuid();

  const [authUser] = useUser() as Array<IUser>;

  let userRef = firestore.collection("users").doc(authUser.id);
  let client =
    useDocumentSubscriber({ ref: userRef }).document?.lastClientSelection || "";

  const createCampaign = async () => {
    if (!client)
      return setErrors((errors: any) => ({
        ...errors,
        client: "Client is required!",
      }));
    if (!campaignName)
      return setErrors((errors: any) => ({
        ...errors,
        campaignName: "Campaign name is required!",
      }));
    if (!spreadsheetUrl)
      return setErrors((errors: any) => ({
        ...errors,
        spreadsheetUrl: "Spreadsheet Url is required!",
      }));

    if (!spreadsheetUrl.includes("https://docs.google.com/spreadsheets/d/")) {
      return setErrors((errors: any) => ({
        ...errors,
        spreadsheetUrl: "Enter a valid spreadsheet URL!",
      }));
    }
    if (!likesCountPerProfile || likesCountPerProfile > 200) {
      return setErrors((errors: any) => ({
        ...errors,
        likesCountPerProfile: "Enter a valid value for likes per profile!",
      }));
    }
    if (sessionCookie === "") {
      showToast("Error: Add a session Cookie to proceed");
      return;
    } else {
      setLoading(true);
      await twitterAutoLiker({
        id,
        spreadsheetUrl,
        sessionCookie: client?.twitter?.sessionCookie || sessionCookie,
        campaignName,
        username: client?.twitter?.username,
        likesCountPerProfile,
      });
      setCampaignName("");
      setSessionCookie("");
      setSpreadsheetUrl("");
      setLoading(false);
    }
  };

  return (
    <CreateCampaignStyles>
      <ToastContainer />
      <div>
        <span className="title__main">{uid ? "Edit" : "Create"} Campaign</span>
        <Box marginTop="50px">
          <div className="items-center">
            <Image className="img__main" src={twitter || defaultIcon} alt="" />
            <span className="title__sub">Twitter Auto Liker</span>
          </div>
        </Box>
      </div>
      <br />

      <div
        style={{ margin: "20px 0px -20px 0px" }}
        className="campaign__container"
      >
        <span className="title__basic" style={{ marginBottom: 10 }}>
          Client name
        </span>
        <input
          type="text"
          value={`${authUser.lastClientSelection.firstName} ${authUser.lastClientSelection.lastName}`}
          style={{ color: "#606060" }}
          className="campaign__input description__sub campaign__input--disabled"
        />
      </div>

      <CampaignName
        campaignName={campaignName}
        setCampaignName={setCampaignName}
        error={errors.campaignName}
        setErrors={setErrors}
      />
      <GetCookies
        setSessionCookie={setSessionCookie}
        sessionCookie={sessionCookie}
        platform={SocialMediaPlatform.Twitter}
        title="Your Twitter session cookie"
        platformIcon={twitter}
      />

      {/* More info */}
      <Box marginTop="40px" className="connection-container">
        <div className="campaign__container">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <span className="title__basic">
              Spreadsheet URL of tweets to like
            </span>
            <Tooltip
              title={
                <Box>
                  <h1>Specify the Tweets you want to automatically like.</h1>
                  <Box fontSize={14}>
                    <div>
                      Create a spreadsheet on{" "}
                      <a
                        href="https://docs.google.com/spreadsheets/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Google Spreadsheet.
                      </a>{" "}
                      Fill it with your Tweets. One name per row, all in column
                      A:
                    </div>
                    <br />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/be-measured-admin-staging.appspot.com/o/images%2FScreenshot%202021-12-13%20at%2012.51.35.png?alt=media&token=692493ea-d8ce-461a-a715-f188fc11e57d"
                      alt="tweets"
                      width="88%"
                    />
                    <br />

                    <div>Make this spreadsheet public so we can access it:</div>
                    <br />

                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/be-measured-admin-staging.appspot.com/o/images%2Fshare%20spreadsheet.png?alt=media&token=d1aaa739-4d5a-40a0-9562-8b82a633d61b"
                      alt="share spreadsheet"
                      width="98%"
                    />
                    <br />

                    <div>And paste that spreadsheet's URL back here.</div>
                  </Box>
                </Box>
              }
              arrow
              interactive
            >
              <InfoIcon style={{ color: "#C4C4C4" }} />
            </Tooltip>
          </Box>
          <span className="description__sub">
            Must be a google spreadsheet URL.
          </span>
          <input
            type="text"
            value={spreadsheetUrl}
            placeholder="https://docs.google.com/spreadsheets/d/(...)"
            onChange={(e) => {
              setSpreadsheetUrl(e.target.value);
              setErrors((errors: any) => ({
                ...errors,
                spreadsheetUrl: "",
              }));
            }}
            className="campaign__input"
          />
          {errors.spreadsheetUrl && (
            <span style={{ color: "red", fontSize: 14, paddingLeft: 6 }}>
              {errors.spreadsheetUrl}
            </span>
          )}
        </div>
        <div className="campaign__container">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <span className="title__basic">
              Number of tweets to like per profile per launch
            </span>
            <Tooltip
              title="Number of tweets to like per profile per launch"
              arrow
              interactive
            >
              <InfoIcon style={{ color: "#C4C4C4" }} />
            </Tooltip>
          </Box>
          <span className="description__sub">
            Default is set to 1, maximum is 200
          </span>
          <input
            type="number"
            value={likesCountPerProfile}
            className="campaign__input campaign__input--disabled"
            onChange={(e) => setLikesCountPerProfile(Number(e.target.value))}
          />
          {errors.likesCountPerProfile && (
            <span style={{ color: "red", fontSize: 14, paddingLeft: 6 }}>
              {errors.likesCountPerProfile}
            </span>
          )}
        </div>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop="40px"
      >
        <div
          className="title__sub link"
          onClick={() => {
            toggleCreate && toggleCreate();

            !toggleCreate && history.back();
          }}
        >
          {"<"} Back
        </div>
        <SendButton
          onClick={(e) => {
            e.preventDefault();
            !loading && createCampaign();
          }}
          className="btn"
          disabled={loading}
        >
          {uid
            ? `Updat${loading ? "ing" : "e"}`
            : `Launch${loading ? "ing" : ""}`}{" "}
          Campaign
          {loading && <CircularProgress className="btn-progress" size={20} />}
        </SendButton>
      </Box>
    </CreateCampaignStyles>
  );
};
