import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    width: "100%",
    paddingBottom: "50px",
  },
  table: {
    minWidth: 650,
  },

  avatar: {
    width: "60px",
  },
  pageTitle: {
    minHeight: 85.5,
    fontStyle: "normal",
    fontSize: "30px",
    lineHeight: "25px",
    color: "#101010",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "40px 0 20px 0",
    fontFamily: "'Avenir Black', sans-serif",
    fontWeight: 900,

    "@media screen and (max-width: 450px)": {
      "& > span:first-child": {
        paddingTop: 32,
      },
    },
  },
  socials: {
    display: "flex",
    maxWidth: "130px",
    justifyContent: "space-between",
  },
  tableHeader: {
    backgroundColor: "#ffffff",
    height: "76px",
    "& th": {
      fontWeight: 900,
      fontFamily: "'Avenir Black', sans-serif",
    },
  },
  tableCell: {
    height: "22px",
    left: "0%",
    right: "94.24%",
    top: "calc(50% - 22px/2 - 0.04px)",
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: "15px",
    lineHeight: "22px",
    color: "#000000",
  },
  tableSortLabel: {
    color: "#000000",
  },
  archived: {
    width: "100%",
    padding: "0 15px 0 0",

    "@media screen and (max-width: 400px)": {
      width: "fit-content",
      padding: 0,
    },
  },
  modals: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",

    "@media screen and (max-width: 400px)": {
      flexDirection: "column-reverse",
    },
  },
  searchBar: {
    width: "100%",
    height: "40px",
    borderColor: "#E5E5E5",
    borderRadius: "40px",
    padding: "5px",
    boxShadow: "0px 5px 45px rgba(0, 0, 0, 0.07)",
    fontSize: "15px",
  },
  tableContainer: {
    boxShadow: "0px 5px 45px rgba(0, 0, 0, 0.07)",
    borderRadius: "15px",
    // remove scrollbar
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
});
