import { navigate } from "@reach/router";

export const autoPoster = async ({
  id,
  spreadsheetUrl,
  sessionCookie,
  campaignName,
  username,
  interval,
  intervalOptions,
}) => {
  const rawResponse = await fetch(
    `${process.env.REACT_APP_FUNCTIONS_BASE_URL}twitter-autoPoster`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
        spreadsheetUrl,
        sessionCookie,
        campaignName,
        username,
        interval,
        intervalOptions,
      }),
    }
  );
  const result = await rawResponse.json();
  navigate(`/campaigns/organic/autoposter/${id}`);

  return result;
};
