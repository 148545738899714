import { Facebook } from "components/BeAmplified/Campaigns/Organic/Create/Autoliker/Facebook";
import { Twitter } from "components/BeAmplified/Campaigns/Organic/Create/Autoliker/Twitter";
import { Instagram } from "components/BeAmplified/Campaigns/Organic/Create/Autoliker/Instagram";
import React, { FC } from "react";
import { PageType } from "types/PageType";
import { SocialMediaPlatform } from "../SelectCampaign";

interface Props extends PageType {
  uid?: string;
  platform?: string;
  toggleCreate?: Function;
}

export const CreateAutoLiker: FC<Props> = ({ toggleCreate, uid, platform }) => {
  if (SocialMediaPlatform.Facebook.toUpperCase() === platform?.toUpperCase()) {
    return <Facebook />;
  }
  if (SocialMediaPlatform.Instagram.toUpperCase() === platform?.toUpperCase()) {
    return <Instagram />;
  }
  if (SocialMediaPlatform.Twitter.toUpperCase() === platform?.toUpperCase()) {
    return <Twitter toggleCreate={toggleCreate} uid={uid} />;
  }
  return <Twitter toggleCreate={toggleCreate} uid={uid} />;
};
