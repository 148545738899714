import React, { FC, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";

import { useStyles } from "./styles";
import { getBackground } from "components/BePlannedMonthly/EventIcon";

interface Props {
  options: Array<any>;
  status: string;
  selected: Array<string>;
  setSelected: (val: any) => void;
}

const MultiSelectCollapsible: FC<Props> = ({
  options,
  selected,
  status,
  setSelected,
}) => {
  const [open, setOpen] = useState(true);
  const classes = useStyles({ selected: selected });

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleSelect = (value) => {
    const selectedOptions = [...selected];

    const index = selectedOptions.indexOf(value);
    if (index > -1) {
      selectedOptions.splice(index, 1);
      setSelected(selectedOptions);
      return;
    }

    selectedOptions.push(value);
    setSelected(selectedOptions);
  };

  return (
    <div className={classes.selectContainer}>
      <div
        style={{
          borderBottomLeftRadius: open ? "0px" : "10px",
          borderBottomRightRadius: open ? "0px" : "10px",
          color: "#313131",
          fontWeight: 900,
        }}
        className={
          open
            ? `${classes.selectedText} ${classes.selectedTextActive}`
            : classes.selectedText
        }
        onClick={handleToggle}
      >
        {status}
      </div>
      {open && (
        <ul className={classes.selectOptions}>
          {options.map((option) => {
            return (
              <li
                data-name={option.name}
                key={option.id}
                onClick={() => handleSelect(option.name)}
                style={{
                  backgroundColor: selected.includes(option.name)
                    ? "rgba(0, 0, 0, 0.08)"
                    : "#fff",
                  color: "#313131",
                }}
              >
                <Checkbox
                  checked={selected.includes(option.name)}
                  className={classes.checkbox}
                  onClick={() => handleSelect(option.name)}
                  style={{
                    transform: "scale(0.8)",
                    background:
                      status === "Monthly Statuses" || option.name === "Draft"
                        ? "#fff"
                        : getBackground(option.name),
                    color:
                      status === "Monthly Statuses" || option.name === "Draft"
                        ? "#ABADAD"
                        : getBackground(option.name),
                  }}
                />
                {option.name === "Client Approved" &&
                status === "Monthly Statuses"
                  ? "Approved"
                  : option.name}{" "}
                {option.info && (
                  <span style={{ fontSize: 12, paddingLeft: 4 }}>
                    ({option.info})
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default MultiSelectCollapsible;
