import React, { FunctionComponent } from "react";
import { Box, FormControl, Select, MenuItem } from "@material-ui/core";

import { useStyles } from "./styles";
import useCollectionSubscriber from "hooks/useCollectionSubscriber";
import { firestore } from "firebase-tools/firebase";
import { BootstrapInput } from "components/Common/BootstrapInput";

interface CampaignSelectProps {
  campaign: any;
  client: any;
  setCampaign: (e: any) => void;
}

export const CampaignSelect: FunctionComponent<CampaignSelectProps> = ({
  client,
  campaign,
  setCampaign,
}) => {
  const queries = [
    firestore
      .collection("twitter-campaigns")
      .where("username", "==", client.twitter.username)
      .where("campaignType", "==", "autofollow"),
  ];
  const campaignsRef = useCollectionSubscriber<any[]>({
    queries,
    deps: [client],
  }).documents;
  const campaigns: any[] = campaignsRef.map((doc) => ({ ...doc }));

  const classes = useStyles({
    listLength: campaigns.length > 11 ? "400px" : "auto",
  });

  return (
    <FormControl style={{ width: "100%", marginTop: 30 }}>
      <Box component="span" className={classes.dateLabel} mb={1}>
        Choose Campaign
      </Box>
      <Select
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        value={campaign}
        onChange={(event) => {
          event.preventDefault();
          setCampaign(event.target.value);
        }}
        input={<BootstrapInput />}
      >
        <MenuItem value="">
          {campaigns.length === 0 ? (
            <em>No campaigns for the selected client</em>
          ) : (
            <em>Select</em>
          )}
        </MenuItem>
        {(campaigns || []).map((doc) => {
          return (
            <MenuItem key={doc.id} value={doc.id}>
              {doc?.campaignName}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default CampaignSelect;
