import React, { FC } from "react";

import { useStyles } from "./styles";

const formatDoc = (cmd: string, fmtValue?: string) => {
  const textBox = document.getElementById("text");
  document.execCommand(cmd, false, fmtValue);
  (textBox as any).focus();
  let sel: any = document.getSelection();
  sel.removeAllRanges();
};

export const highlightDoc = () => {
  formatDoc("backcolor", "yellow");
};
export const unHighlight = () => {
  formatDoc("removeFormat");
};
interface EditableProps {
  setContent: (content: any) => void;
  setShowEmojiPicker: (content: boolean) => void;
  postHtml: string;
  input: any;
  placeholder: string;
}

export const Editable: FC<EditableProps> = ({
  setContent,
  postHtml,
  input,
  placeholder,
  setShowEmojiPicker,
}) => {
  const classes = useStyles({});

  const pasteAsPlainText = (event) => {
    event.preventDefault();

    const text = event.clipboardData.getData("text/plain");
    document.execCommand("insertHTML", false, text);
  };

  return (
    <div
      placeholder={placeholder}
      className={classes.editBox}
      contentEditable={true}
      onPaste={pasteAsPlainText}
      onInput={(e) => {
        setShowEmojiPicker(false);
        setContent(e.currentTarget.innerHTML);
      }}
      dangerouslySetInnerHTML={{
        __html: postHtml,
      }}
      ref={input}
      id="text"
    ></div>
  );
};
