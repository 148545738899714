import styled from "styled-components";

const Carousel = styled.div`
  margin-bottom: 20px;

  .carousel.carousel-slider {
    .control-arrow {
      justify-content: center;
      // top: 50%;
      // font-size: 26px;
      padding: 5px;
      display: flex;
      align-items: center;
      // background: #fff;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      // opacity: 1;
      // margin: 0 10%;
      // transition: background 0.3s ease;

      &:hover {
        background: #ddd;
      }
    }

    .control-prev.control-arrow:before {
      border-right: 12px solid #000;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      margin: 0 4px 0 0;
    }

    .control-next.control-arrow:before {
      border-left: 12px solid #000;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      margin: 0 0 0 4px;
    }

    .control-dots {
      margin: 0;
    }

    .dot {
      opacity: 1;
      border: 1px solid #fff;
      box-shadow: 2px 2px 3px rgba(0, 0, 0, 1);
      // width: 14px !important;
      // height: 14px !important;
      transition: background 0.3s ease;

      &:focus {
        outline: none;
      }

      &.selected,
      &:hover {
        background: #000 !important;
      }
    }
  }

  .carousel.carousel-slider .control-arrow {
    top: 42% !important;
    transform: translateY(-50%);
  }

  video {
    width: 100% !important;
    height: 288px !important;
    object-fit: contain;
    pointer-events: unset;

    @media screen and (max-width: 550px) {
      width: 100% !important;
      object-fit: contain;
    }
  }

  .vimeo {
    & iframe {
      width: 100% !important;
      height: 288px !important;
    }
  }

  img {
    width: 100% !important;
    object-fit: contain;
    height: 288px !important;
    pointer-events: unset;

    @media screen and (max-width: 550px) {
      width: 100% !important;
      object-fit: contain;
    }
  }
`;

export default Carousel;
