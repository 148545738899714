/**
 * @prettier
 */

import React, { useContext, FunctionComponent, useState } from "react";
type unsavedType = boolean;
type setUnsavedType = (unsaved: unsavedType) => void;
type unsavedContextType = Array<unsavedType | setUnsavedType>;

const UnsavedContext = React.createContext([] as unsavedContextType);

const useUnsaved = (): unsavedContextType => {
  const context: unsavedContextType = useContext(UnsavedContext);
  if (!context) {
    throw new Error(`useUnsaved must be used within a UnsavedProvider`);
  }
  return context;
};

const UnsavedProvider: FunctionComponent = (props) => {
  const [unsaved, setUnsaved] = useState(false) as unsavedContextType;
  return <UnsavedContext.Provider value={[unsaved, setUnsaved]} {...props} />;
};

export { useUnsaved, UnsavedProvider };
