/**
 * @prettier
 */
import React, { FunctionComponent, useState, useEffect } from "react";
import moment from "moment";
import { DateRange } from "materialui-daterange-picker";
import history from "history/browser";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { UserService } from "services/UserService";
import { PageType } from "types/PageType";
import Analytics from "components/BeMeasured";
import { useStyles } from "./styles";
import DateRangePicker from "components/Common/DateRange";
import { DB } from "firebase-tools/db";
import { ClientList } from "components/BeMeasured/ClientList";
import userIcon from "static/media/name.svg";
import { ReactComponent as EditIcon } from "static/media/edit.svg";
import { ReactComponent as LightBulb } from "assets/lightbulb.svg";

import {
  appleMusicMedia,
  fbMedia,
  igMedia,
  twitterMedia,
  youtubeMedia,
  tiktokMedia,
} from "../../utils/fetchMetrics";
import PrintMode from "components/PrintMode/PrintMode";
import { useUser } from "UserContext";
import { IUser } from "interfaces/IUser";

import { firestore } from "../../firebase-tools/firebase";
import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import { urlDates } from "utils/dateFormat";
import { getPreviousMetrics } from "./helpers/getPreviousMetrics";
import { getMetrics } from "./helpers/getMetrics";
import PublicLink from "components/Common/PublicLink";
import { Toast } from "components/styled/Common";
import { Grid, Avatar, Button } from "@material-ui/core";
import { navigate } from "@reach/router";

export const BeMeasuredPage: FunctionComponent<PageType> = ({ location }) => {
  const classes = useStyles({});
  const [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: moment().subtract(7, "days").startOf("day").toDate(),
    endDate: moment().subtract(1, "days").startOf("day").toDate(),
  });
  const [authUser, setAuthUser] = useUser() as [IUser, any];

  const [, setClient]: any = useState("");
  const defaultMetricState = {
    fbMetrics: [],
    fbOlderMetrics: [],
    igMetrics: [],
    twitterMetrics: [],
    tiktokMetrics: [],
    youtubeMetrics: [],
    appleMusicMetrics: [],
    spotifyMetrics: [],
    igStoriesMetrics: [],
  };

  // metrics
  const [metrics, setMetrics]: any = useState(defaultMetricState);

  // prev metrics
  const [prevMetrics, setPrevMetrics]: any = useState(defaultMetricState);

  // loading states
  const [loading, setLoading]: any = useState({
    fbLoading: false,
    fbMetricsLoading: false,
    igLoading: false,
    igMetricsLoading: false,
    twitterLoading: false,
    twitterMetricsLoading: false,
    tiktokLoading: false,
    tiktokMetricsLoading: false,
    youtubeLoading: false,
    youtubeMetricsLoading: false,
    spotifyLoading: false,
    spotifyMetricsLoading: false,
    appleMusicLoading: false,
    pageLoading: false,
    prevLoading: true,
  });

  const checkLoading = (): boolean =>
    Object.values(loading).some((value) => value);

  // post states
  const [posts, setPosts]: any = useState({
    fbPosts: [],
    igPosts: [],
    twitterPosts: [],
    appleMusicPosts: [],
    tiktokPosts: [],
    youtubePosts: [],
    youtubePosts2: [],
    spotifyPosts: [],
  });

  const [igType, setIg] = useState("timeline");
  const [fbMetadata, setFbMeta] = useState({});
  const [igMetadata, setIgMeta] = useState({});
  const [youtubeTopPosts, setYoutubeTopPosts] = useState([]);
  const [previousDateRange, setPreviousDateRange]: any = useState({});
  const [previousClient, setPreviousClient]: any = useState("");
  const [paramsChange, setParamsChange]: any = useState("");

  const handSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setIg(event.target.value);
  };

  const from = new Date(dateRange.startDate as Date);
  let to = new Date(dateRange.endDate as Date);
  to = moment(to).add(1, "days").toDate();

  // find the days between from and to

  const differenceInDays = moment(to).diff(from, "days");
  // const olderFrom = moment(from).su
  const newStartDate = moment(from).subtract(differenceInDays, "days");
  // const newEndDate = moment(from);
  const setClientSelection = (e) => {
    const User = new UserService(authUser.id);

    return User.updateUser({
      lastClientSelection: e,
    })
      .then((res) => {
        setClient(e);
        setAuthUser((user) => ({ ...user, lastClientSelection: e }));
      })
      .catch((err) => {});
  };

  let userRef = firestore.collection("users").doc(authUser.id);
  let client =
    useDocumentSubscriber({ ref: userRef }).document?.lastClientSelection || "";

  const getFbMedia = async (user: any) => {
    setLoading((loading) => ({ ...loading, fbLoading: true }));
    try {
      if (user?.facebook?.username) {
        let incomingPosts = await fbMedia(user.facebook.username, from, to);
        incomingPosts = incomingPosts[user.facebook.username];
        setPosts((posts) => ({ ...posts, fbPosts: incomingPosts }));
      }
    } catch (e) {
      setPosts((posts) => ({ ...posts, fbPosts: [] }));
      setLoading((loading) => ({ ...loading, fbLoading: false }));
    }
    setLoading((loading) => ({ ...loading, fbLoading: false }));
  };

  const getIgMedia = async (user: any) => {
    setLoading((loading) => ({ ...loading, igLoading: true }));
    try {
      if (user?.instagram?.username) {
        let incomingPosts = await igMedia(user.instagram.username, from, to);
        incomingPosts = incomingPosts[user.instagram.username];
        setPosts((posts) => ({ ...posts, igPosts: incomingPosts }));
      }
    } catch (e) {
      console.error(e);
      setPosts((posts) => ({ ...posts, igPosts: [] }));
      setLoading((loading) => ({ ...loading, igLoading: false }));

      return;
    }
    setLoading((loading) => ({ ...loading, igLoading: false }));
  };

  const getTwitterMedia = async (user: any, options: any) => {
    setLoading((loading) => ({ ...loading, tiktokLoading: true }));
    try {
      if (user?.twitter?.username) {
        const incomingPosts = await twitterMedia(
          user.twitter.username,
          from,
          to,
          options
        );
        setPosts((posts) => ({
          ...posts,
          twitterPosts: incomingPosts[user.twitter.username],
        }));
      }
    } catch (e) {
      console.error("Tw Media Error", e);
      if (!e.message.includes("user aborted a request")) {
        setLoading((loading) => ({ ...loading, tiktokLoading: false }));
      }
      setPosts((posts) => ({ ...posts, twitterPosts: [] }));
      return;
    }
    setLoading((loading) => ({ ...loading, tiktokLoading: false }));
  };

  const getTikTokMedia = async (user: any, options: any) => {
    setLoading((loading) => ({ ...loading, tiktokLoading: true }));
    try {
      if (user?.tiktok?.username) {
        const initialPosts = await tiktokMedia(
          user.id,
          user.tiktok.username,
          from,
          to,
          options
        );
        setPosts((posts) => ({
          ...posts,
          tiktokPosts: initialPosts[user.tiktok.username],
        }));
      }
    } catch (e) {
      console.error("TikTok Media Error", e);
      if (!e.message.includes("user aborted a request")) {
        setLoading((loading) => ({ ...loading, tiktokLoading: false }));
      }
      setPosts((posts) => ({ ...posts, tiktokPosts: [] }));
      return;
    }
    setLoading((loading) => ({ ...loading, tiktokLoading: false }));
  };

  const convertToNumericArray = (data) => {
    // Filter objects with numerical keys
    const filteredData = Object.entries(data)
      .filter(([key]) => !isNaN(Number(key)))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});

    // Convert to array
    const dataArray = Object.values(filteredData);

    return dataArray?.slice(0, 3);
  };

  const getYouTubeMedia = async (user: any) => {
    if (!user) return;
    if (!user.youtube) return;
    if (!user.youtube.username) return;

    setLoading((loading) => ({ ...loading, youtubeLoading: true }));
    try {
      const posts = await youtubeMedia(user.youtube.username, from, to);
      // let topVideo: any = convertToNumericArray(posts.topVideo);
      let topVideo: any = posts.topVideo;

      setPosts((previousPosts) => ({
        ...previousPosts,
        youtubePosts: posts[user.youtube.username] || [],
        youtubePosts2: topVideo || [],
      }));
      setYoutubeTopPosts(topVideo || []);
    } catch (e) {
      console.error("YouTube Media Error", e);
    } finally {
      setLoading((loading) => ({ ...loading, youtubeLoading: false }));
    }
  };

  const getAppleMusicMedia = async (user: any) => {
    setLoading((loading) => ({ ...loading, appleMusicLoading: true }));
    try {
      if (user?.appleMusic?.username) {
        const initialPosts = await appleMusicMedia(
          user?.appleMusic?.username,
          from,
          to
        );

        setPosts((posts) => ({
          ...posts,
          appleMusicPosts: initialPosts[user?.appleMusic?.username],
        }));
      }
    } catch (e) {
      console.error(e);
      setPosts((posts) => ({ ...posts, appleMusicPosts: [] }));
      setLoading((loading) => ({ ...loading, appleMusicLoading: false }));
      return;
    }
    setLoading((loading) => ({ ...loading, appleMusicLoading: false }));
  };

  const getSpotifySongs = async (user: any) => {
    setLoading((loading) => ({ ...loading, spotifyLoading: true }));
    try {
      if (user?.spotify?.username) {
        const db = new DB();
        const spotifyTracks: any = await db.metricsByDate(
          "spotify-top-tracks",
          user.spotify.username,
          from,
          to
        );
        setPosts((posts) => ({
          ...posts,
          spotifyPosts: spotifyTracks[spotifyTracks.length - 1]?.tracks || [],
        }));
      }
    } catch (e) {
      console.error(e);
      setPosts((posts) => ({ ...posts, spotifyPosts: [] }));
      setLoading((loading) => ({ ...loading, spotifyLoading: false }));
      return;
    }
    setLoading((loading) => ({ ...loading, spotifyLoading: false }));
  };

  const prevFrom = moment(from).subtract(7, "days").toDate();
  const prevTo = moment(to).subtract(7, "days").toDate();

  useEffect(() => {
    if (!!authUser?.lastClientSelection) {
      setClient(authUser?.lastClientSelection);
    } else {
      setClient("");
    }
  }, [authUser]);

  useEffect((): any => {
    urlDates(dateRange, history, paramsChange, setDateRange, location);
    setMetrics(defaultMetricState);
    setPrevMetrics(defaultMetricState);

    const abortCtrl = new AbortController();
    const opts = { signal: abortCtrl.signal };
    if (client !== "") {
      getMetrics(
        client,
        newStartDate,
        to,
        dateRange,
        previousDateRange,
        previousClient,
        setMetrics,
        setFbMeta,
        setIgMeta,
        setLoading,
        setPreviousDateRange,
        setPreviousClient
      );
      getFbMedia(client);
      getIgMedia(client);
      getTwitterMedia(client, opts);
      getTikTokMedia(client, opts);
      getYouTubeMedia(client);
      getAppleMusicMedia(client);
      getSpotifySongs(client);
      getPreviousMetrics(
        client,
        prevFrom,
        prevTo,
        dateRange,
        previousDateRange,
        previousClient,
        setLoading,
        setPrevMetrics
      );
    } else {
      setMetrics((metrics) => ({
        ...metrics,
        fbMetrics: [],
        igMetrics: [],
        twitterMetrics: [],
        tiktokMetrics: [],
        youtubeMetrics: [],
        spotifyMetrics: [],
      }));
      setPrevMetrics((prevMetrics) => ({
        ...prevMetrics,
        fbMetrics: [],
        igMetrics: [],
        twitterMetrics: [],
        youtubeMetrics: [],
        spotifyMetrics: [],
      }));
      setPosts((posts) => ({
        ...posts,
        fbPosts: [],
        igPosts: [],
        twitterPosts: [],
      }));
    }
    return () => abortCtrl.abort();
    // eslint-disable-next-line
  }, [dateRange, client, paramsChange, location]);

  return (
    <div className={classes.root} id="root-analytics">
      <Toast>
        <ToastContainer progressClassName="toast-progressbar" />
      </Toast>
      <div id="analytics">
        <div className={classes.pageTitle} id="page-title">
          <Grid container>
            <Grid
              xs={12}
              md={5}
              item
              style={{ marginTop: 32, fontWeight: 900 }}
              className={classes.pageTitleText}
            >
              {/* <span style={{ marginTop: 32, fontWeight: 900 }} className={classes.pageTitleText}> */}
              Be-Measured
              {/* </span> */}
            </Grid>

            <Grid
              xs={12}
              md={7}
              item
              className={client !== "" ? classes.datePrint : classes.selector}
              // id="selector"
              style={{
                marginTop: 15,
              }}
              container
            >
              <Grid xs={12} md={4}>
                <ClientList client={client} setClient={setClientSelection} />
              </Grid>

              <Grid xs={12} md={5} item>
                <DateRangePicker
                  disableFuture
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  setParamsChange={setParamsChange}
                  fullWidth={true}
                  isLoading={checkLoading()}
                />
              </Grid>

              <Grid xs={12} md={3} item className={classes.btnContainer}>
                <Button
                  className={classes.btn}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("ai/dashboard");
                  }}
                  // disabled={true}
                >
                  <LightBulb />
                  Get AI Insight
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <Grid
          container
          style={{
            margin: 24,
            marginLeft: 0,
          }}
        >
          <Grid xs={12} md={1} item className={classes.avCon}>
            {client.avatarUrl === "" ? (
              <Avatar
                src={userIcon}
                classes={{
                  root: classes.AvatarSvg,
                  img: classes.AvatarIcon,
                }}
                style={{
                  width: "80px",
                  height: "80px",
                  flexShrink: 0,
                }}
              />
            ) : (
              <Avatar className={classes.avatar} src={client.avatarUrl} />
            )}
          </Grid>
          <Grid xs={12} md={10} item>
            <div className={classes.userName}>
              {client.firstName && client.firstName + " " + client.lastName}
            </div>
          </Grid>
          <Grid xs={12} md={1} item className={classes.profileUserToolbar}>
            <span>
              <PublicLink location={location} primaryEmail={client.email} />
            </span>
            <span>
              {client !== "" ? (
                <PrintMode to={to} from={from} user={client} />
              ) : null}
            </span>
            <span style={{ marginRight: 20, marginTop: 3, cursor: "pointer" }}>
              {client && (
                <a
                  className={classes.editLink}
                  href={`/client-info/${client.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <EditIcon className={classes.editIcon} />
                </a>
              )}{" "}
            </span>
          </Grid>
        </Grid>
        {/* <div className={classes.pageTitle} id="page-title">
          <span style={{ marginTop: 32, fontWeight: 900 }}>Be-Measured</span>
          <div
            className={client !== "" ? classes.datePrint : classes.selector}
            id="selector"
            style={{
              marginTop: 15,
            }}
          >
            <div style={{ marginBottom: "16px" }}>
              <PublicLink location={location} primaryEmail={client.email} />
            </div>
            {client !== "" ? (
              <PrintMode to={to} from={from} user={client} />
            ) : null}

            <ClientList client={client} setClient={setClientSelection} />

            <DateRangePicker
              disableFuture
              dateRange={dateRange}
              setDateRange={setDateRange}
              setParamsChange={setParamsChange}
            />
          </div>
        </div> */}

        {client !== "" ? (
          <Analytics
            metrics={{
              ...metrics,
              igMetrics:
                igType === "timeline"
                  ? metrics.igMetrics
                  : metrics.igStoriesMetrics,
            }}
            youtubeTopPosts={youtubeTopPosts}
            loading={{ ...loading }}
            posts={{ ...posts }}
            igType={igType}
            igHandler={handSelectChange}
            client={client}
            fbMetadata={fbMetadata}
            igMetadata={igMetadata}
            dateRange={dateRange as any}
            newIgMetrics={metrics.igMetrics}
            newIgStoriesMetrics={metrics.igStoriesMetrics}
            prevMetrics={{
              facebook: prevMetrics.fbMetrics,
              twitter: prevMetrics.twitterMetrics,
              tiktok: prevMetrics.tiktokMetrics,
              youtube: prevMetrics.youtubeMetrics,
              appleMusic: prevMetrics.appleMusicMetrics,
              spotify: prevMetrics.spotifyMetrics,
              instagram:
                igType === "timeline"
                  ? prevMetrics.igMetrics
                  : prevMetrics.igStoriesMetrics,
            }}
          />
        ) : (
          <div
            style={{
              fontSize: "18px",
              lineHeight: "21px",
              color: "#313131",
              fontFamily: "'Avenir Black', sans-serif",
              paddingTop: "73px",
            }}
            id="empty-analytics"
          >
            To view the Be-Measured of a client, please choose a client above.
          </div>
        )}
      </div>
    </div>
  );
};
