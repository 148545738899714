import styled from "styled-components";

export const Context = styled.div`
  font-family: "Avenir Book", sans-serif;
  margin-top: ${(props) => props.margin};
  font-size: 15px;
  line-height: 25px;
  display: flex;
  justify-content: center;

  & > div {
    width: 100% !important;
    max-width: 450px;
  }
`;

export const PostHeader = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1070px) {
    & > div:first-of-type {
      flex-direction: column;
      & > div:nth-of-type(2) span {
        text-align: center;
      }
      & > div:first-of-type {
        margin-right: 0px;
      }
    }
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    & > div:nth-of-type(2) {
      margin-right: 0px;
      margin-left: 30px;
    }
    & > div:first-of-type {
      flex-direction: row;
      padding: 22px 30px 0px 30px;

      & > div:nth-of-type(2) span {
        text-align: unset;
      }
      & > div:first-of-type {
        margin-right: 20px;
      }
    }
  }
`;

export const SocialPostContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  width: 100%;

  .content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 18px;
  }

  .post-footer {
    display: flex;
    flex-direction: column;
    padding: 25px;
  }

  @media screen and (max-width: 500px) {
    #action-btn {
      width: 150px !important;
    }
  }

  @media screen and (max-width: 420px) {
    .content-header {
      padding: 5px 0 5px 6px;
    }

    #postCardContent {
      padding: 30px 18px;
    }

    #contentBriefContainer {
      margin: 10px 30px 30px 18px;
    }

    .post-footer {
      padding: 25px 18px;
    }

    #publicIcon {
      display: none;
    }
  }

  @media screen and (max-width: 360px) {
    #action-btn {
      width: 100px !important;
    }
  }
`;
