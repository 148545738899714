export const audience = [
  {
    id: "AudiencebyGender",
    value: "Audience by Gender",
    checked: true,
  },
  {
    id: "AudiencebyAge",
    value: "Audience by Age",
    checked: true,
  },
  {
    id: "AudiencebyCity/Country",
    value: "Audience by City/Country",
    checked: true,
  },
];

export const statsCount = [
  {
    id: "Top3",
    value: "Top 3 highest engagement posts",
    checked: true,
    media: ["fb", "ig", "tw", "tikTok"],
  },
  {
    id: "Top10 YT",
    value: "Top 10 Most Viewed Videos",
    checked: true,
    media: ["YouTube"],
  },
  {
    id: "Top10 Spotify",
    value: "Top 10 Most Streamed Songs",
    checked: true,
    media: ["Spotify"],
  },
  {
    id: "Top10 Apple Music",
    value: "Top 10 Most Listened Songs",
    checked: true,
    media: ["Apple Music"],
  },
  {
    id: "FollowerCount",
    value: "Follower Count",
    checked: true,
    media: ["fb", "ig", "tw", "tikTok", "Spotify"],
  },
  {
    id: "TotalImpressions",
    value: "Total Impressions",
    checked: true,
    media: ["fb", "ig", "tw"],
  },
  {
    id: "SubscriberCount",
    value: "Subscriber Count",
    checked: true,
    media: ["YouTube"],
  },
  {
    id: "VideoViews",
    value: "Video Views",
    checked: true,
    media: ["tikTok", "YouTube"],
  },

  {
    id: "TotalEngagements",
    value: "Total Engagement",
    checked: true,
    media: ["fb", "ig", "tw", "tikTok"],
  },
  {
    id: "EngagementRate",
    value: "Engagement Rate",
    checked: true,
    media: ["fb", "ig", "tw", "tikTok"],
  },
  {
    id: "Streams",
    value: "Streams",
    checked: true,
    media: ["Spotify"],
  },
  {
    id: "Listeners",
    value: "Listeners",
    checked: true,
    media: ["Spotify", "Apple Music"],
  },
  {
    id: "StreamsPerFollowers",
    value: "Streams Per Followers",
    checked: true,
    media: ["Spotify"],
  },
  {
    id: "Plays",
    value: "Plays",
    checked: true,
    media: ["Apple Music"],
  },
  {
    id: "Shazams",
    value: "Shazams",
    checked: true,
    media: ["Apple Music"],
  },
  {
    id: "SongPurchases",
    value: "Song Purchases",
    checked: true,
    media: ["Apple Music"],
  },
];

export const fbSelected = [...statsCount]
  .filter((stat) => stat.media.includes("fb"))
  .map((item) => `facebook-${item.value}`)
  .concat([...audience].map((item) => `facebook-${item.value}`));

export const igSelected = [...statsCount]
  .filter((stat) => stat.media.includes("ig"))
  .map((item) => `instagram-${item.value}`)
  .concat([...audience].map((item) => `instagram-${item.value}`));

export const twitterSelected = [...statsCount]
  .filter((stat) => stat.media.includes("tw"))
  .map((item) => `twitter-${item.value}`);

export const tikTokSelected = [...statsCount]
  .filter((stat) => stat.media.includes("tikTok"))
  .map((item) => `tikTok-${item.value}`);

export const youTubeSelected = [...statsCount]
  .filter((stat) => stat.media.includes("YouTube"))
  .map((item) => `YouTube-${item.value}`);

export const spotifySelected = [...statsCount]
  .filter((stat) => stat.media.includes("Spotify"))
  .map((item) => `Spotify-${item.value}`);

export const appleMusicSelected = [...statsCount]
  .filter((stat) => stat.media.includes("Apple Music"))
  .map((item) => `Apple Music-${item.value}`)
  .concat([...audience].map((item) => `Apple Music-${item.value}`));

export const fbSelectedLen = fbSelected.length;
export const igSelectedLen = igSelected.length;
export const twitterSelectedLen = twitterSelected.length;
export const tikTokSelectedLen = tikTokSelected.length;
export const youTubeSelectedLen = youTubeSelected.length;
export const spotifySelectedLen = spotifySelected.length;
export const appleMusicSelectedLen = appleMusicSelected.length;
