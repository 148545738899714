import { DB } from "firebase-tools/db";
import { IPost } from "interfaces/IPost";

export class PostService extends DB {
  private _doc: string = "";
  private _collection: string = "";

  constructor(collection?: string, doc?: string) {
    super();
    if (doc) {
      this.doc = doc;
    }
    if (collection) {
      this.collection = collection;
    }
  }

  set doc(value: string) {
    this._doc = value;
  }

  set collection(value: string) {
    this._collection = value;
  }

  get doc() {
    return this._doc;
  }

  get collection() {
    return this._collection;
  }

  async getPostByDoc(): Promise<IPost> {
    return this.getDoc(this.collection, this.doc);
  }

  async getUserByField(
    fieldName: string,
    value: any,
    fieldName2?: string,
    value2?: any,
    operation: firebase.firestore.WhereFilterOp = "=="
  ): Promise<Array<IPost>> {
    return this.getByField(
      this.collection,
      fieldName,
      value,
      fieldName2,
      value2,
      operation
    );
  }

  async getPosts(
    orderField: string = "created_at",
    orderDirection: firebase.firestore.OrderByDirection = "desc"
  ): Promise<Array<IPost>> {
    return this.get(this.collection, orderField, orderDirection);
  }

  async addPost(post: IPost): Promise<string | null> {
    return this.set2(this.collection, this.doc, post);
  }

  async addPosts(posts: IPost[]): Promise<boolean[]> {
    const results: boolean[] = [];

    // Using Promise.all to perform parallel execution
    await Promise.all(
      posts.map(async (post) => {
        const result = await this.set(this.collection, this.doc, post);
        results.push(result);
      })
    );

    return results;
  }

  async updatePost(post: any): Promise<boolean> {
    return this.update(this.collection, this.doc, post);
  }

  async deletePost(): Promise<boolean> {
    return this.delete(this.collection, this.doc);
  }

  async deletePosts(postIds: string[]): Promise<boolean[]> {
    const results: boolean[] = [];

    // Using Promise.all to perform parallel execution
    await Promise.all(
      postIds.map(async (postId) => {
        const result = await this.delete(this.collection, postId);
        results.push(result);
      })
    );

    return results;
  }

  getImageStorage() {
    return DB.getStorage(`post_images/${this.doc}`);
  }

  async uploadImage(filename: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.getImageStorage()
        .child(filename)
        .getDownloadURL()
        .then(resolve, reject);
    });
  }

  getVideoStorage() {
    return DB.getStorage(`post_videos/${this.doc}`);
  }

  async uploadVideo(filename: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.getVideoStorage()
        .child(filename)
        .getDownloadURL()
        .then(resolve, reject);
    });
  }
}
