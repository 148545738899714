import { Grid, LinearProgress } from "@material-ui/core";
import React, { FC, useState } from "react";
// import { PageType } from "types/PageType";
import useCollectionSubscriber from "hooks/useCollectionSubscriber";
import { INotification, NotificationTypes } from "interfaces/INotification";
// import { useStylesNot } from "containers/Dashboard/Notification/styles";
import { firestore } from "firebase-tools/firebase";
import NotificationItemForPost from "containers/Dashboard/Notification/NotificationItemForPost";
import Pagination from "./Pagination";
import NotificationItemForEvent from "containers/Dashboard/Notification/NotificationItemForEvent";
import NotificationItemForComment from "containers/Dashboard/Notification/NotificationItemForComment";
import NotificationItemForEventComment from "containers/Dashboard/Notification/NotificationItemForEventComment";

const AllNotifications: FC<any> = ({
  pagination = true,
  pageSize = 5,
  menu = false,
}: {
  pagination: boolean;
  pageSize: number;
  menu: boolean;
}) => {
  // const classes = useStylesNot({});

  const queries = [
    firestore.collection("notifications").orderBy("created_at", "desc"),
  ];

  let notificationData = useCollectionSubscriber<INotification>({ queries });
  let notificationList = notificationData.documents;

  const pageSizeOptions = [3, 5, 10, 20];
  const [defaultPageSize, setDefaultPageSize] = useState(pageSize);

  const [currentPage, setCurrentPage] = useState(1);
  let totalPages = Math.ceil(notificationList.length / defaultPageSize);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const startIndex = (currentPage - 1) * defaultPageSize;
  const endIndex = startIndex + defaultPageSize;
  notificationList = notificationList.slice(startIndex, endIndex);

  return (
    <Grid item container>
      <Grid item container style={{ backgroundColor: "#FFFFFF" }}>
        {notificationData.loading && (
          <LinearProgress
            style={{ marginTop: 5, marginBottom: 5, height: 5, width: "100%" }}
          />
        )}
        {notificationList?.map((val, index) => (
          <>
            {val.notificationType === "post" && (
              <NotificationItemForPost prop={val} menu={menu} />
            )}
            {val.notificationType === "event" && (
              <NotificationItemForEvent prop={val} menu={menu} />
            )}
            {val.notificationType === NotificationTypes.comment && (
              <NotificationItemForComment prop={val} menu={menu} />
            )}
            {val.notificationType === NotificationTypes.event_comment && (
              <NotificationItemForEventComment prop={val} menu={menu} />
            )}
          </>
        ))}

        {pagination && (
          <Grid container style={{ padding: "20px" }}>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              pageSize={defaultPageSize}
              setPageSize={setDefaultPageSize}
              pageSizeOptions={pageSizeOptions}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default AllNotifications;
