import React, { FunctionComponent } from "react";
import { Grid, Box, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useStylesV2 } from "./stylesV2";
// import { ReactComponent as EditIcon } from "static/media/edit.svg";
import useCollectionSubscriber from "hooks/useCollectionSubscriber";
import { firestore } from "firebase-tools/firebase";
import { IUser } from "interfaces/IUser";

interface ClientListProps {
  client: any;
  setClient: (e: any) => void;
  setLoading?: (loading: boolean) => void;
  styleProps?: any;
  error?: string;
  setErrors?: any;
  align?: any;
  email?: string;
}

export const ClientListV2: FunctionComponent<ClientListProps> = ({
  client,
  setClient,
  setLoading,
  styleProps,
  error,
  setErrors,
  align = "center",
  email,
}) => {
  const queries = [
    firestore
      .collection("users")
      .where("role", "==", "user")
      .orderBy("firstName", "asc"),
  ];
  let users = useCollectionSubscriber<IUser>({ queries }).documents;
  users = users.filter((user) => !user.isArchived && !user.isExternal);

  const classes = useStylesV2({
    listLength: users.length > 11 ? "400px" : "auto",
    ...styleProps,
  });
  // if (email) {
  //   let selectedUser = users.find((u) => u.email === email);
  //   if (selectedUser) {
  //     setClient(selectedUser);
  //   }
  // }
  return (
    <Grid id="client-list" className={classes.clientList2}>
      <Box
        component="span"
        className={classes.dateLabel}
        style={{
          textAlign: align as any,
        }}
      >
        Choose Client
      </Box>
      <Box>
        <Autocomplete
          id="combo-box-demo"
          value={client || { firstName: "", lastName: "" }}
          options={users}
          getOptionLabel={(option) => {
            const lastName = !!option?.lastName ? " " + option?.lastName : "";

            return `${option?.firstName + lastName}`;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              classes={{
                root: classes.autoCompleteField,
              }}
              style={{
                width: "100%",
              }}
            />
          )}
          onChange={(_, value) => {
            setClient(value);
            setLoading && value !== "" && value !== null && setLoading(true);
            setErrors &&
              setErrors((errors: any) => ({
                ...errors,
                client: "",
              }));
          }}
          autoHighlight={true}
          className={classes.autoComplete}
          style={{
            width: "100%",
          }}
        />

        {error && (
          <span style={{ color: "red", fontSize: 14, paddingLeft: 6 }}>
            {error}
          </span>
        )}
      </Box>
    </Grid>
  );
};
