/**
 * @prettier
 */

import React, { FunctionComponent, useState, Fragment } from "react";
import { navigate } from "@reach/router";
import { Button, CircularProgress } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import Email from "components/Common/Form/Email";
import Name from "components/Common/Form/Name";
import { UserService } from "services/UserService";
import { IUser, Roles } from "interfaces/IUser";
import { useStyles } from "./styles";
import cuid from "cuid";

interface InviteFormType {
  handleClose: () => void;
}

export const InviteForm: FunctionComponent<InviteFormType> = (inviteProps) => {
  const [error, setError] = useState("");
  const [isInviteSent, handleSendInvite] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles({});

  const createUser = async (name: string, email: string) => {
    setIsLoading(true);
    const User = new UserService();
    const uuid: string = cuid();
    User.getUserByField("email", email).then(async (users: Array<IUser>) => {
      if (users && users.length) {
        setError("You already sent an invite to this email!");
      } else {
        const User = new UserService(uuid);
        await User.addUser({
          id: uuid,
          email,
          firstName: name.split(" ").slice(0, 1).join(" "),
          lastName: name.split(" ").slice(1).join(" "),
          avatarUrl: "",
          role: Roles.user,
          isActivated: true,
          isInvited: false,
          createdAt: new Date(),
          facebook: {
            url: "",
            apiKey: "",
          },
          instagram: {
            url: "",
            apiKey: "",
          },
          twitter: {
            url: "",
            apiKey: "",
          },
          secondaryEmails: [],
        } as IUser)
          .then(() => {
            handleSendInvite(true);
            inviteProps.handleClose();
            navigate(`/client-info/${uuid}`);
          })
          .catch((error) => {
            if (error) {
              setError("An error occurred. Try again!");
            }
          });
      }
    });
    setIsLoading(false);
  };

  return (
    <Formik
      initialValues={{ name: "", email: "" }}
      onSubmit={(values) => {
        createUser(values.name, values.email);
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Required!"),
        email: Yup.string().email("Invalid email!").required("Required!"),
      })}
    >
      {(props) => {
        const { values, touched, errors, handleChange, handleSubmit } = props;
        return (
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            {!isInviteSent ? (
              <Fragment>
                <p
                  className={classes.label}
                  style={
                    errors.name && touched.name
                      ? { color: "#f44336" }
                      : { color: "#313131" }
                  }
                >
                  Client Name
                </p>
                <Name
                  color="#313131"
                  label=""
                  variant="outlined"
                  value={values.name}
                  error={errors.name && touched.name}
                  placeholder="David Bowie"
                  onChangeHandler={handleChange}
                  borderColor={
                    errors.name && touched.name ? "#f44336" : "#1D4E89"
                  }
                  helperText={errors.name && touched.name && errors.name}
                />
                <p
                  className={classes.label}
                  style={
                    errors.email && touched.email
                      ? { color: "#f44336" }
                      : { color: "#313131" }
                  }
                >
                  Email
                </p>
                <Email
                  color="#313131"
                  label=""
                  variant="outlined"
                  value={values.email}
                  error={errors.email && touched.email}
                  placeholder="client@email.com"
                  onChangeHandler={handleChange}
                  marginLeft="0"
                  borderColor={
                    errors.email && touched.email ? "#f44336" : "#1D4E89"
                  }
                  helperText={errors.email && touched.email && errors.email}
                />
                <p className={classes.error}>{error}</p>
                <div className={classes.line}></div>
                <div className={classes.buttonContainer}>
                  <Button
                    disableRipple
                    type="button"
                    classes={{
                      root: classes.cancel,
                      label: classes.cancelLabel,
                    }}
                    onClick={inviteProps.handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    disableRipple
                    type="submit"
                    classes={{
                      label: classes.inviteLabel,
                    }}
                    disabled={isLoading}
                    style={{ minWidth: "141px" }}
                  >
                    {!isLoading && "Create Client"}
                    {isLoading && (
                      <CircularProgress size={30} color="inherit" />
                    )}
                  </Button>
                </div>
              </Fragment>
            ) : null}
          </form>
        );
      }}
    </Formik>
  );
};
