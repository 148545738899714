import React, { FunctionComponent, useState, useEffect } from "react";
import { Grid, capitalize } from "@material-ui/core";

import { useStyles } from "../styles";
import { IndicatorProps } from "../MetricsIndicator";
import { area } from "../ChartV2/chartOptions";
import TikTokGraph from "./chart/TikTokGraph";
import { formatDate } from "utils/dateFormat";
import MediaItemV2 from "../Media/MediaItemV2";
import ErrorMessage from "components/Error/ErrorMessage";
import TiktokMediaTitle from "../Media/TiktokMediaTitle";

interface MetricProps extends IndicatorProps {
  igType?: string;
  igHandler?: (e: any) => void;
  mediaLoading?: boolean;
  posts: Array<any>;
  igMetadata?: any;
  fbMetadata?: any;
  prevLoading: boolean;
  prevMetrics: any;
  hiddenSubscriber?: boolean;
  dateRange?: {
    startDate: any;
    endDate: any;
  };
}

const TikTokMetrics: FunctionComponent<MetricProps> = ({
  role,
  metrics: metricsData,
  icon,
  url,
  prevLoading,
  prevMetrics,
  posts,
  dateRange,
}) => {
  let metrics = metricsData;
  const midpoint = Math.ceil(metricsData.dates.length / 2);
  let metricsDates = metricsData.dates.slice(midpoint);
  metrics.dates = metricsDates;

  metrics.dates = metricsDates;

  const followers = {
    series: [
      {
        name: `${"Followers"}`,
        data: metrics.followers,
      },
    ],
  };
  const likes = {
    series: [
      {
        name: `${"Total Likes"}`,
        data: metrics.likesCount || [],
      },
    ],
  };
  const videoCount = {
    series: [
      {
        name: `${"Total Videos"}`,
        data: metrics.videoCount || [],
      },
    ],
  };

  const dateLength = metricsDates?.length;

  const classes = useStyles({});
  const [screenWidth, setWidth] = useState(
    window.matchMedia("(min-width: 1320px)").matches
  );

  useEffect(() => {
    function handleResize() {
      setWidth(window.matchMedia("(min-width: 1320px)").matches);
    }
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [metrics, metricsData.dates, metricsDates, screenWidth]);

  const areaOptions = {
    ...area.options,
    markers:
      dateLength > 30
        ? {}
        : {
            size: 5,
            colors: ["white"],
            strokeColor: "#5187E0",
            strokeWidth: 2,
          },
    xaxis: {
      categories: dateLength > 30 ? metricsDates : formatDate(metrics.dates),
      type: dateLength > 30 ? "datetime" : "date",
      labels: {
        datetimeUTC: false,
      },
    },
  };

  let postsMarkup;
  if (posts?.length > 0) {
    postsMarkup = posts.map((post, index) => (
      <MediaItemV2 post={post} key={index} role={role} />
    ));
  }

  return (
    <>
      <br />

      <div style={{ marginBottom: "45px" }}>
        {metrics.dates.length > 0 && (
          <div className={classes.socialLabel}>
            <a href={url} target="_blank" rel="noopener noreferrer">
              <img className={classes.icon} src={icon} alt={role} />
            </a>
            <span className={classes.statsName}>{capitalize(role)}</span>
          </div>
        )}
        <br />
        {posts?.length === 0 ? (
          <ErrorMessage message=" No available posts for this period" />
        ) : (
          <>
            <TiktokMediaTitle role={role} />
            <div className={classes.media} id="media">
              {postsMarkup}
            </div>
          </>
        )}
        <br />
        {metrics.dates.length > 0 && (
          <>
            <Grid className={classes.metrics} id="metrics">
              {metricsDates && (
                <TikTokGraph
                  metrics={{ ...followers, areaOptions }}
                  dateGraph={metricsDates}
                  key="follower-count"
                  icon={icon}
                  role={role}
                  name={"Follower Count"}
                  url={url}
                  prevLoading={prevLoading}
                  prevData={prevMetrics}
                  dateRange={dateRange}
                  metricsData={metricsData}
                />
              )}

              {metricsDates && (
                <TikTokGraph
                  metrics={{ ...likes, areaOptions }}
                  dateGraph={metricsDates}
                  key="total-likes"
                  icon={icon}
                  role={role}
                  name={"Total Likes"}
                  url={url}
                  prevLoading={prevLoading}
                  prevData={prevMetrics}
                  dateRange={dateRange}
                  metricsData={metricsData}
                />
              )}
              {metricsDates && (
                <TikTokGraph
                  metrics={{ ...videoCount, areaOptions }}
                  dateGraph={metricsDates}
                  key="total-videos"
                  icon={icon}
                  role={role}
                  name={"Total Videos"}
                  url={url}
                  prevLoading={prevLoading}
                  prevData={prevMetrics}
                  dateRange={dateRange}
                  metricsData={metricsData}
                />
              )}
            </Grid>
          </>
        )}
      </div>
    </>
  );
};

const areEqual = (prevProps, nextProps): any => {
  if (prevProps.metrics === nextProps.metrics) {
    return true;
  }
};

export default React.memo(TikTokMetrics, areEqual);
