import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  chartContainer: {
    borderRadius: "15px",
    padding: "20px",
    height: "315px",
    marginBottom: 30,
    background: "#F5F5F5",
    boxShadow: "0px 5px 45px rgba(0, 0, 0, 0.07)",
    "@media screen and (max-width: 900px)": {
      flexDirection: "column",
    },
  },
  areaChartLegend: {
    fontFamily: "Avenir Book, sans-serif",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "22px",
    color: (props: any) => props.color,
  },
  chartTitle: {
    fontFamily: "Avenir Book, sans-serif",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "38px",
    marginBottom: 20,
  },
});
