import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import FbIcon from "../../assets/facebook.png";
import InstIcon from "../../assets/instagram.png";
import { DeletePost } from "./DeletePost";
import ViewPost from "./ViewPost";
import { CustomPagination } from "./CustomPagination";
import { useStyles } from "./styles";

const columns = [
  { id: "clientName", label: "Client", minWidth: 100 },
  { id: "title", label: "Post Name", minWidth: 150 },
  { id: "platform", label: "Platform", minWidth: 100 },
  { id: "date", label: "Date & Time", minWidth: 150 },
  { id: "status", label: "Status", minWidth: 100 },
  { id: "actions", label: "", minWidth: 50 },
];

const getStatusStyle = (status) => {
  switch (status) {
    case "Pending":
      return {
        color: "#000000",
        backgroundColor: "#FFEECC",
        width: "75px",
        borderRadius: "4px",
        textAlign: "center",
        fontWeight: 350,
        fontSize: "16px",
        fontFamily: "Avenir",
      };
    case "Failed":
      return {
        color: "#000000",
        backgroundColor: "#F9DCE1",
        width: "75px",
        borderRadius: "4px",
        textAlign: "center",
        fontWeight: 350,
        fontSize: "16px",
        fontFamily: "Avenir",
      };
    case "Posted":
      return {
        color: "#000000",
        backgroundColor: "#E9FBE9",
        borderRadius: "4px",
        width: "75px",
        textAlign: "center",
        fontWeight: 350,
        fontSize: "16px",
        fontFamily: "Avenir",
      };
    default:
      return {};
  }
};

const feedsStyle: any = {
  textDecoration: "none",
};

const getIcon = (name, link, category, classes) => {
  switch (name) {
    case "Facebook":
      return link ? (
        <p
          onClick={(e) => {
            e.preventDefault();
            window.open(link);
          }}
          style={{ color: "#4CAF50", cursor: "pointer" }}
        >
          <Grid container>
            <Grid md={4}>
              <img src={FbIcon} alt="Facebook" />
            </Grid>
            <Grid md={8} className={classes.smHide}>
              <p style={feedsStyle}>
                {" "}
                {category ? category : "Facebook Feeds"}
              </p>
            </Grid>
          </Grid>
        </p>
      ) : (
        <Grid container>
          <Grid md={4}>
            <img src={FbIcon} alt="Facebook" />
          </Grid>
          <Grid md={8}>
            <p style={feedsStyle}> {category ? category : "Facebook Feeds"}</p>
          </Grid>{" "}
        </Grid>
      );
    case "Instagram":
      return link ? (
        <p
          onClick={(e) => {
            e.preventDefault();
            window.open(link);
          }}
          style={{ color: "#4CAF50", cursor: "pointer" }}
        >
          <Grid container>
            <Grid xs={12} md={4}>
              <img src={InstIcon} alt="Instagram" width={29} />{" "}
            </Grid>
            <Grid md={8} className={classes.smHide}>
              <span style={feedsStyle}>
                {" "}
                {category ? category : "Instagram Feeds"}
              </span>
            </Grid>{" "}
          </Grid>
        </p>
      ) : (
        <Grid container>
          <Grid xs={12} md={4}>
            <img src={InstIcon} alt="Instagram" width={29} />
          </Grid>
          <Grid md={8} className={classes.smHide}>
            <span style={feedsStyle}>
              {" "}
              {category ? category : "Instagram Feeds"}
            </span>
          </Grid>{" "}
        </Grid>
      );
    default:
      return <img src={FbIcon} alt="Facebook" />;
  }
};

const format = (timeObject) => {
  const { seconds, nanoseconds } = timeObject;
  const timestamp = new Date(seconds * 1000 + nanoseconds / 1e6);
  const options: any = {
    weekday: "short",
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    timestamp
  );
  return formattedDate;
};

const PostList = ({ data, isLoading }) => {
  const classes = useStyles({});

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const getFbLink = (username: string, id: string) =>
    `https://web.facebook.com/${username}/posts/${id}`;

  const getIgLink = (instagramUsername: string, postId: string) =>
    `https://instagram.com/${instagramUsername}/p/${postId}`;

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  let newPostData = data?.map((val) => {
    let fbLink: any = null;
    if (val?.fbPostData?.id && val?.fbPostData?.username) {
      fbLink = getFbLink(val?.fbPostData?.username, val?.fbPostData?.id);
    }
    if (
      val?.platform === "Instagram" &&
      val?.scheduledCategory === "Instagram Feed" &&
      val?.igPostData?.id &&
      val?.igPostData?.username
    ) {
      fbLink = getIgLink(val?.igPostData?.username, val?.igPostData?.id);
    }
    return {
      ...val,
      fbLink,
    };
  });

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(value);
    setPage(0);
  };

  return (
    <Paper style={{ width: "100%" }}>
      <TableContainer style={{ width: "100%" }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                >
                  <Typography
                    variant="subtitle1"
                    component="div"
                    style={{
                      fontWeight: 800,
                      fontSize: "16px",
                      fontFamily: "Avenir",
                      color: "#000000",
                    }}
                  >
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              (rowsPerPage > 0
                ? newPostData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : newPostData
              ).map((row) => (
                <TableRow key={row?.id}>
                  {columns.map((column) => (
                    <TableCell key={column?.id}>
                      {column?.id === "status" ? (
                        <p style={getStatusStyle(row?.status) as any}>
                          {row[column?.id]}
                        </p>
                      ) : column.id === "platform" ? (
                        <>
                          {getIcon(
                            row?.platform,
                            row?.fbLink,
                            row?.scheduledCategory,
                            classes
                          )}
                        </>
                      ) : column.id === "date" ? (
                        <>{format(row?.date)}</>
                      ) : column.id === "actions" ? (
                        <>
                          <ViewPost post={row} />
                          <DeletePost id={row?.id} status={row?.status} />
                        </>
                      ) : (
                        row[column.id]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
          <TableBody>
            <CustomPagination
              count={newPostData.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PostList;
