import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    width: "80%",
    margin: "0 auto",
    paddingBottom: 50,
    fontFamily: "'Avenir Black', sans-serif",
    "@media (max-width: 767px)": {
      width: "100%",
    },
  },
  pageTitle: {
    minHeight: 85.5,
    fontSize: "30px",
    fontWeight: 900,
    fontStyle: "normal",
    lineHeight: "25px",
    color: "#101010",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "85px 0 20px 0",
    fontFamily: "Avenir Black, sans-serif",

    flexDirection: "column",
  },
  title: {
    fontSize: 48,
    lineHeight: "3rem",
  },
  campaign: {
    width: "100%",
    padding: 20,
    marginBottom: 20,
    boxShadow: "0 5px 10px 4px rgb(0 0 0 / 10%)",
    borderRadius: 15,
    backgroundColor: "#fff",
  },
  campaignHeading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 34,
  },
  campaignBody: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 767px)": {
      flexWrap: "wrap",
    },
  },
  header: {
    fontFamily: "'Avenir Black', sans-serif",
    fontWeight: 900,
    fontSize: 20,
    "@media (max-width: 767px)": {
      fontSize: 18,
    },
  },
  content: {
    fontFamily: "'Avenir Book', sans-serif",
    "@media (max-width: 767px)": {
      fontSize: 14,
      marginBottom: "0.4rem",
    },
  },
  btn: {
    borderRadius: 15,
    textTransform: "capitalize",
    fontFamily: "'Avenir Black', sans-serif",
    border: "1px solid #000",

    "&:hover": {
      opacity: 0.85,
    },

    "&:disabled": {
      color: "#fff !important",
      opacity: 0.5,
      cursor: "not-allowed !important",
    },
  },
  campaignRow: {
    display: "flex",
    marginBottom: 20,
    flexWrap: "wrap",
  },
  fieldName: {
    minWidth: 200,
  },
  input: {
    width: "100%",
    padding: "12.5px",
    border: "2px solid #ABADAD",
    boxSizing: "border-box",
    borderRadius: "10px",
    cursor: "pointer",
    fontFamily: "'Avenir Book', sans-serif",
    lineHeight: "20px",
    fontSize: "15px",
    "&:focus": {
      border: "2px solid #000",
      outline: "none",
    },
    "&:hover": {
      border: "2px solid #000",
    },
    "&:active": {
      border: "2px solid #000",
    },
    "&::placeholder": {
      fontFamily: "'Avenir Book', sans-serif",
      fontSize: "inherit",
      color: "#ABADAD",
    },
    "&::-webkit-input-placeholder": {
      fontFamily: "'Avenir Book', sans-serif",
      fontSize: "inherit",
      color: "#ABADAD",
    },
    "&:-ms-input-placeholder": {
      fontFamily: "'Avenir Book', sans-serif",
      fontSize: "inherit",
      color: "#ABADAD",
    },
  },
  inputContainer: {
    width: "46%",
    "@media (max-width: 767px)": {
      width: "100%",
      marginTop: "0.5rem",
    },
  },
  timeFrame: {
    marginLeft: 24,
    "@media (max-width: 767px)": {
      marginLeft: 0,
    },
  },
  textAreaContainer: {
    width: "46%",
    minHeight: 100,
    "@media (max-width: 767px)": {
      width: "100%",
    },
  },
  btm: {
    width: "72%",
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 767px)": {
      width: "100%",
    },
  },
  w50: {
    width: "50%",
    "@media (max-width: 767px)": {
      width: "100%",
    },
  },
  progress: {
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "#15891b",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#15891b",
    },
  },
  errorSize: {
    fontSize: 13,
  },
  campaignHeader: {
    // display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // flexDirection: "column",
  },
  campaignTitle: {
    fontFamily: "'Avenir Black', sans-serif",
    fontSize: 24,
    marginBottom: "10px",
    fontWeight: "bold",
  },
  campaignContent: {
    fontFamily: "'Avenir Book', sans-serif",
    // fontSize: 24,
  },
  templateSection: {
    // width: "85%",
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
    paddingTop: 10,
  },
  textAreaTemp: {
    minHeight: 150,
  },
  email: {
    display: "flex",
    justifyContent: "center",
    padding: "4px 0",
    width: "100%",
    backgroundColor: "#0af50ad4",
    color: "#000",
    borderRadius: 15,
    marginTop: 20,
  },
  // ----

  table: {
    minWidth: 650,
  },
  tableHeader: {
    backgroundColor: "#ffffff",
    height: 80,
    "& th": {
      fontWeight: 900,
      fontFamily: "'Avenir Black', sans-serif",
    },
  },
  tableCell: {
    height: "22px",
    left: "0%",
    right: "94.24%",
    top: "calc(50% - 22px/2 - 0.04px)",
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: "15px",
    lineHeight: "22px",
    color: "#000000",
  },
  row: {
    height: 60,
  },
  postDetails: {
    height: "22px",
    left: "0%",
    right: "0%",
    top: "calc(50% - 22px/2 - 14px)",
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: "15px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#000000",
  },
  post: {
    display: "flex",
    flexDirection: "column",
  },
});
