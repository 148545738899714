import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  readmoreButton: {
    display: "inline",
    backgroundColor: "transparent",
    outline: "none",
    border: 0,
    padding: 0,
    margin: 0,
    cursor: "pointer",
    color: "#1d4e89",
    fontStyle: "italic",
  },
  readmoreTextHide: {
    maxHeight: 0,
    fontSize: 0,
    opacity: 0,
  },
  readmoreTextShow: {
    maxHeight: "10rem",
    opacity: 1,
    fontSize: "inherit",
  },

  readmoreTextRemaining: {
    transition: "opacity 240ms ease",
  },
});
