import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  editBox: {
    border: "2px solid #ABADAD",
    width: "94%",
    minHeight: "140px",
    padding: "12.5px",
    boxSizing: "border-box",
    borderRadius: "10px",
    fontFamily: "'Avenir Book', sans-serif",
    resize: "vertical",
    overflow: "auto",
    fontSize: "15px",
    "&:focus": {
      outline: "none",
      border: "2px solid #000",
    },
    "&:hover": {
      border: "2px solid #000",
    },
    "&:active": {
      border: "2px solid #000",
    },
    "&:empty:not(:focus):before": {
      content: "attr(placeholder)",
      color: "#ABADAD",
      fontFamily: "'Avenir Book', sans-serif",
      fontSize: "inherit",
    },
  },
}));
