import React, { useState, FC, useEffect } from "react";
import moment from "moment";
import { DateRange } from "materialui-daterange-picker";
import "react-toastify/dist/ReactToastify.css";
import history from "history/browser";

import { PageType } from "types/PageType";
import { useStyles } from "./styles";
import { firestore } from "../../../firebase-tools/firebase";
import { ReactComponent as CalenderViewIcon } from "assets/calenderView.svg";
import { ReactComponent as ListViewIcon } from "assets/listView.svg";

import { useUser } from "UserContext";
import { IUser } from "interfaces/IUser";

import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import { urlDates } from "utils/dateFormat";
import CreateEditPostV2 from "components/BePlanned/CreateEditPostV2";
import BePlannedFeedV2 from "components/styled/BePlannedFeedV2";
import { Grid } from "@material-ui/core";

import { ListView } from "./ListView";
import { CalenderView } from "./CalenderView";
import CreateEditEvent from "components/BePlannedMonthly/CreateEditEvent";

export const ContentPlannerFeed: FC<PageType> = ({ location }) => {
  const classes = useStyles({});
  const [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: moment().startOf("day").toDate(),
    endDate: moment().add(6, "days").startOf("day").toDate(),
  });
  const [authUser] = useUser() as Array<IUser>;

  const [, setClient]: any = useState("");
  const [loading, setLoading] = useState(false);
  const [paramsChange, setParamsChange]: any = useState("");

  let userRef = firestore.collection("users").doc(authUser.id);
  let client =
    useDocumentSubscriber({
      ref: userRef,
    }).document?.lastClientSelection || "";

  useEffect(() => {
    if (!!authUser?.lastClientSelection) {
      setClient(authUser?.lastClientSelection);
    } else {
      setClient("");
    }
    // eslint-disable-next-line
  }, []);

  const [externalOpen, setExternalOpen] = useState(false);

  useEffect(() => {
    urlDates(dateRange, history, paramsChange, setDateRange, location);

    if (client !== "") {
      setLoading(true);
    }
  }, [dateRange, client, paramsChange, location]);

  const [openList, setOpenList] = useState(true);

  const handleCalender = () => {
    setOpenList(false);
  };
  const handleList = () => {
    setOpenList(true);
  };
  return (
    <BePlannedFeedV2>
      <div id="analytics">
        <div id="page-title">
          {/* hide is mobile */}
          <Grid
            container
            className={`${classes.pageTitle}  ${classes.smHide}  be-planned-title`}
          >
            <Grid
              item
              xs={5}
              md={5}
              style={{
                fontFamily: "Avenir",
                fontSize: "30px",
                fontWeight: 900,
                lineHeight: "25px",
                letterSpacing: "0em",
                textAlign: "left",
              }}
            >
              Content Planner
            </Grid>

            <Grid
              item
              xs={6}
              md={6}
              id="create-post"
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {authUser.role !== "viewerAdmin" && (
                <>
                  <span
                    style={{
                      padding: "10px",
                    }}
                  >
                    <CreateEditPostV2
                      user={client}
                      role={authUser.role}
                      create={"create"}
                      setExternalOpen={setExternalOpen}
                      externalOpen={externalOpen}
                    />
                  </span>

                  <span
                    style={{
                      padding: "10px",
                    }}
                  >
                    {" "}
                    <CreateEditEvent create="create" user={client} />
                  </span>
                </>
              )}
            </Grid>
            <Grid
              item
              xs={1}
              md={1}
              sm={1}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "20px",
              }}
            >
              <ListViewIcon
                onClick={handleList}
                style={{
                  stroke: openList ? "none" : "white",
                  cursor: "pointer",
                }}
              />
              <CalenderViewIcon
                onClick={handleCalender}
                style={{
                  stroke: !openList ? "none" : "white",
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>

          {/* hide in desktop */}
          <Grid
            container
            className={`${classes.mdHide}`}
            style={{
              marginTop: "20px",
            }}
          >
            <Grid
              item
              xs={6}
              md={6}
              style={{
                fontFamily: "Avenir",
                fontSize: "24px",
                fontWeight: 900,
                lineHeight: "25px",
                letterSpacing: "0em",
                textAlign: "left",
              }}
            >
              Content Planner
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              sm={6}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <ListViewIcon
                onClick={handleList}
                style={{
                  stroke: openList ? "none" : "white",
                  cursor: "pointer",
                }}
              />
              <CalenderViewIcon
                onClick={handleCalender}
                style={{
                  stroke: !openList ? "none" : "white",
                  cursor: "pointer",
                }}
              />
            </Grid>

            {authUser.role !== "viewerAdmin" && (
              <Grid item container>
                <Grid
                  item
                  xs={6}
                  md={6}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  {" "}
                  <CreateEditEvent create="create" user={client} />
                </Grid>
                <Grid item xs={6} md={6}>
                  <CreateEditPostV2
                    user={client}
                    role={authUser.role}
                    create={"create"}
                    setExternalOpen={setExternalOpen}
                    externalOpen={externalOpen}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
      </div>

      {openList && <ListView location={location} />}
      {!openList && <CalenderView location={location} />}
    </BePlannedFeedV2>
  );
};
