import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "AvenirNext-Medium",
    fontSize: "19px",
  },
  chartContainer: {
    borderRadius: "15px",
    padding: "20px",
    height: "315px",
    background: "#FFFFFF",
    marginBottom: 20,
  },
  marginTop: {
    marginTop: "18px",
  },
});
