import { DateTime } from "luxon";
import moment from "moment";

export const fbMedia = async (username: string, from: any, to: any) => {
  const startDate: any = DateTime.fromJSDate(from).toISODate();
  const endDate: any = DateTime.fromJSDate(to).toISODate();
  const rawResponse = await fetch(
    `${process.env.REACT_APP_FUNCTIONS_BASE_URL}facebook-mediaMetrics`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, startDate, endDate }),
    }
  );
  const result = await rawResponse.json();
  return result;
};

export const igMedia = async (username: string, from: any, to: any) => {
  const startDate: any = DateTime.fromJSDate(from).toISODate();
  // const endDate: any = DateTime.fromJSDate(to).toISODate();
  const endDate: any = moment(to).subtract(1, "days").format("YYYY-MM-DD");
  const rawResponse = await fetch(
    `${process.env.REACT_APP_FUNCTIONS_BASE_URL}instagram-mediaMetrics?platform=admin`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        startDate,
        endDate,
        mediaType: "ALL",
      }),
    }
  );
  const resp1 = await rawResponse.json();

  // const rawResponse2 = await fetch(
  //   `${process.env.REACT_APP_FUNCTIONS_BASE_URL}instagram-mediaMetrics`,
  //   {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       username,
  //       startDate,
  //       endDate,
  //       mediaType: "IMAGE",
  //     }),
  //   }
  // );
  // const resp2 = await rawResponse2.json();

  // const dataArr2 = resp2[username];
  const dataArr1 = resp1[username];
  // let combinedDataArr = [...dataArr1, ...dataArr2];
  let combinedDataArr = [...dataArr1];
  let result = resp1;
  result[username] = combinedDataArr;

  return result;
};

export const twitterMedia = async (
  username: string,
  from: any,
  to: any,
  options: any
) => {
  const startDate: any = DateTime.fromJSDate(from).toISODate();
  // const endDate: any = DateTime.fromJSDate(to).toISODate();
  const endDate: any = moment(to).subtract(1, "days").format("YYYY-MM-DD");

  const rawResponse = await fetch(
    `${process.env.REACT_APP_FUNCTIONS_BASE_URL}twitter-getMediaMetrics`,
    {
      ...options,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, startDate, endDate }),
    }
  );
  const result = await rawResponse.json();
  return result;
};

export const tiktokMedia = async (
  userId: string,
  username: string,
  from: any,
  to: any,
  options: any
) => {
  const startDate: any = moment.utc(from).format("YYYY-MM-DD");
  const endDate: any = moment.utc(to).format("YYYY-MM-DD");
  const rawResponse = await fetch(
    `${process.env.REACT_APP_FUNCTIONS_BASE_URL}tiktok-top3Videos`,
    {
      ...options,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, username, startDate, endDate }),
    }
  );
  const result = await rawResponse.json();
  return result;
};

export const youtubeMedia = async (username: string, from: any, to: any) => {
  const startDate: any = DateTime.fromJSDate(from).toISODate();
  const endDate: any = DateTime.fromJSDate(to).toISODate();
  const rawResponse = await fetch(
    `${process.env.REACT_APP_FUNCTIONS_BASE_URL}youtube-topVideos`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, startDate, endDate }),
    }
  );
  const result = await rawResponse.json();
  return result;
};

export const appleMusicMedia = async (username: string, from: any, to: any) => {
  const startDate: any = DateTime.fromJSDate(from).toISODate();
  const endDate: any = DateTime.fromJSDate(to).toISODate();

  const endDateNumber = new Date(endDate).getTime() / 1000;

  const rawResponse = await fetch(
    `${process.env.REACT_APP_FUNCTIONS_BASE_URL}appleMusic-appleMusicTop10`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, endDateNumber, startDate }),
    }
  );
  const result = await rawResponse.json();
  return result;
};
