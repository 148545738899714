import styled from "styled-components";

const Fade = styled.div`
  .fadeIn {
    animation: fadeIn 1s ease;
  }

  .fadeOut {
    animation: fadeOut 1s ease;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default Fade;
