import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  file: {
    background: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "-8px",
    minHeight: "35px",
    padding: "0px 10px",
    width: "79%",
  },
});
