import React, { FC } from "react";

import { firestore } from "firebase-tools/firebase";
import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import AutoPosterPage from "components/BeAmplified/Campaigns/Organic/View/AutoPoster";

type CampaignType = {
  uid?: string;
  path: string;
};

const AutoPosterCampaignPage: FC<CampaignType> = ({ uid }) => {
  const campaignsRef = firestore.collection("twitter-auto-poster").doc(uid);
  const campaign = useDocumentSubscriber({ ref: campaignsRef }).document;
  return (
    <div>{campaign?.status && <AutoPosterPage campaign={campaign} />}</div>
  );
};

export default AutoPosterCampaignPage;
