import React, { FC } from "react";

import { ReactComponent as RefreshIcon } from "static/media/refreshIcon.svg";
import { ReactComponent as CircledCheck } from "static/media/circledCheck.svg";

type HistoryProps = {
  type?: string;
  message: string;
};
export const History: FC<HistoryProps> = ({ type, message }) => {
  switch (type) {
    case "success":
      return (
        <div>
          <CircledCheck />{" "}
          <span
            style={{
              marginLeft: 17,
              fontSize: 15,
              lineHeight: "22px",

              color: "#FFFFFF",
            }}
          >
            {message}
          </span>
        </div>
      );
    case "info":
      return (
        <div>
          <RefreshIcon style={{ width: 15 }} />{" "}
          <span
            style={{
              marginLeft: 17,
              fontSize: 15,
              lineHeight: "22px",

              color: "#FFFFFF",
            }}
          >
            {message}
          </span>
        </div>
      );
    case "error":
      return (
        <div>
          <RefreshIcon style={{ width: 15 }} />{" "}
          <span
            style={{
              marginLeft: 17,
              fontSize: 15,
              lineHeight: "22px",

              color: "#FF0000",
            }}
          >
            {message}
          </span>
        </div>
      );
    default:
      return <></>;
  }
};

export const PhantomLogs = (props) => {
  const { type = "info", message, logs } = props;

  if (logs) {
    return (
      <>
        {message.split("\n").map((msg) => (
          <div
            style={{
              marginLeft: 17,
              fontSize: 15,
              lineHeight: "22px",
              color: "#FFFFFF",
            }}
          >
            {msg}
          </div>
        ))}
      </>
    );
  }

  return (
    <div>
      {type === "success" ? (
        <CircledCheck />
      ) : (
        <RefreshIcon style={{ width: 15 }} />
      )}{" "}
      <span
        style={{
          marginLeft: 17,
          fontSize: 15,
          lineHeight: "22px",

          color: "#FFFFFF",
        }}
      >
        {message}
      </span>
    </div>
  );
};

export default PhantomLogs;

PhantomLogs.defaultProps = {
  type: "info",
};
