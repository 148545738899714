const preventScroll = (
  isOpen: boolean,
  windowOffset: any,
  setWindowOffset: Function
) => {
  if (isOpen) {
    setWindowOffset(window.scrollY);
    document.body.setAttribute(
      "style",
      `position: fixed; top: -${window.scrollY}px; left:0; right: 0`
    );
  } else {
    document.body.setAttribute("style", "");
    window.scrollTo(0, windowOffset);
  }
};

export default preventScroll;
