import React, { FC } from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import { useStyles } from "../styles";

interface NoDocumentsProps {
  title: string;
}
export const NoPosts: FC<NoDocumentsProps> = ({ title }) => {
  const classes = useStyles({});

  return (
    <div id="no-posts">
      <Card className={classes.noPosts}>
        <CardContent className={classes.postCardContent}>
          <Typography
            paragraph
            className={classes.cardContent}
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "20px",
            }}
          >
            {title}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};
