export const errorChecker = (selectedChannels, category, customCheck) => {
  console.log("selected channels", selectedChannels, category);
  let errors = {
    facebook: "",
    instagram: "",
    twitter: "",
    tiktok: "",
    youtube: "",
    snapchat: "",
    threads: "",
    discord: "",
    custom: "",
  };
  if (
    selectedChannels.includes("facebook") &&
    !category.facebook.filter((ct) => ct !== "").length
  ) {
    errors = {
      ...errors,
      facebook: "Select at least one Facebook category",
    };
  } else {
    errors = {
      ...errors,
      facebook: "",
    };
  }
  if (
    selectedChannels.includes("instagram") &&
    !category.instagram.filter((ct) => ct !== "").length
  ) {
    errors = {
      ...errors,
      instagram: "Select at least one Instagram category",
    };
  } else {
    errors = {
      ...errors,
      instagram: "",
    };
  }
  if (
    selectedChannels.includes("twitter") &&
    !category.twitter.filter((ct) => ct !== "").length
  ) {
    errors = {
      ...errors,
      twitter: "Select at least one Twitter category",
    };
  } else {
    errors = {
      ...errors,
      twitter: "",
    };
  }
  if (
    selectedChannels.includes("tiktok") &&
    !category.tiktok.filter((ct) => ct !== "").length
  ) {
    errors = {
      ...errors,
      tiktok: "Select at least one TikTok category",
    };
  } else {
    errors = {
      ...errors,
      tiktok: "",
    };
  }
  if (
    selectedChannels.includes("youtube") &&
    !category.youtube.filter((ct) => ct !== "").length
  ) {
    errors = {
      ...errors,
      youtube: "Select at least one Youtube category",
    };
  } else {
    errors = {
      ...errors,
      youtube: "",
    };
  }
  if (
    selectedChannels.includes("snapchat") &&
    !category.snapchat.filter((ct) => ct !== "").length
  ) {
    errors = {
      ...errors,
      snapchat: "Select at least one Snapchat category",
    };
  } else {
    errors = {
      ...errors,
      snapchat: "",
    };
  }

  if (
    selectedChannels.includes("threads") &&
    !category.threads.filter((ct) => ct !== "").length
  ) {
    errors = {
      ...errors,
      threads: "Select at least one Threads category",
    };
  } else {
    errors = {
      ...errors,
      threads: "",
    };
  }

  if (
    selectedChannels.includes("discord") &&
    !category.discord.filter((ct) => ct !== "").length
  ) {
    errors = {
      ...errors,
      discord: "Select at least one Discord category",
    };
  } else {
    errors = {
      ...errors,
      discord: "",
    };
  }
  if (
    !!customCheck.length &&
    !category.custom.filter((ct) => ct !== "").length
  ) {
    errors = {
      ...errors,
      custom: "Select at least one Custom post category",
    };
  } else {
    errors = {
      ...errors,
      custom: "",
    };
  }
  const errorCheck = !!Object.entries(errors).filter(([k, v], i) => !!v).length;
  return { errorCheck, errors };
};

export const errorCheckerV2 = (selectedChannels, categories, customCheck) => {
  console.log("selected channels", selectedChannels, categories);
  let errors = {
    facebook: "",
    instagram: "",
    twitter: "",
    tiktok: "",
    youtube: "",
    snapchat: "",
    threads: "",
    discord: "",
    custom: "",
  };
  if (
    selectedChannels.includes("facebook") &&
    !categories
      .map((val) => val?.toLowerCase()?.split(" ")[0])
      .includes("facebook")
  ) {
    errors = {
      ...errors,
      facebook: "Select at least one Facebook category",
    };
  } else {
    errors = {
      ...errors,
      facebook: "",
    };
  }
  if (
    selectedChannels.includes("instagram") &&
    !categories
      .map((val) => val?.toLowerCase()?.split(" ")[0])
      .includes("instagram")
  ) {
    console.log("insta not found", categories);
    errors = {
      ...errors,
      instagram: "Select at least one Instagram category",
    };
  } else {
    errors = {
      ...errors,
      instagram: "",
    };
  }
  if (
    selectedChannels.includes("twitter") &&
    !categories
      .map((val) => val?.toLowerCase()?.split(" ")[0])
      .includes("twitter")
  ) {
    errors = {
      ...errors,
      twitter: "Select at least one Twitter category",
    };
  } else {
    errors = {
      ...errors,
      twitter: "",
    };
  }
  if (
    selectedChannels.includes("tiktok") &&
    !categories
      .map((val) => val?.toLowerCase()?.split(" ")[0])
      .includes("tiktok")
  ) {
    errors = {
      ...errors,
      tiktok: "Select at least one TikTok category",
    };
  } else {
    errors = {
      ...errors,
      tiktok: "",
    };
  }
  if (
    selectedChannels.includes("youtube") &&
    !categories
      .map((val) => val?.toLowerCase()?.split(" ")[0])
      .includes("youtube")
  ) {
    errors = {
      ...errors,
      youtube: "Select at least one Youtube category",
    };
  } else {
    errors = {
      ...errors,
      youtube: "",
    };
  }
  if (
    selectedChannels.includes("snapchat") &&
    !categories
      .map((val) => val?.toLowerCase()?.split(" ")[0])
      .includes("snapchat")
  ) {
    errors = {
      ...errors,
      snapchat: "Select at least one Snapchat category",
    };
  } else {
    errors = {
      ...errors,
      snapchat: "",
    };
  }

  if (
    selectedChannels.includes("threads") &&
    !categories
      .map((val) => val?.toLowerCase()?.split(" ")[0])
      .includes("threads")
  ) {
    errors = {
      ...errors,
      threads: "Select at least one Threads category",
    };
  } else {
    errors = {
      ...errors,
      threads: "",
    };
  }

  if (
    selectedChannels.includes("discord") &&
    !categories
      .map((val) => val?.toLowerCase()?.split(" ")[0])
      .includes("discord")
  ) {
    errors = {
      ...errors,
      discord: "Select at least one Discord category",
    };
  } else {
    errors = {
      ...errors,
      discord: "",
    };
  }
  if (
    !!customCheck.length &&
    !categories
      .map((val) => val?.toLowerCase()?.split(" ")[0])
      .includes("custom")
  ) {
    errors = {
      ...errors,
      custom: "Select at least one Custom post category",
    };
  } else {
    errors = {
      ...errors,
      custom: "",
    };
  }
  const errorCheck = !!Object.entries(errors).filter(([k, v], i) => !!v).length;
  return { errorCheck, errors };
};
