import React, { FC, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { navigate } from "@reach/router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Box,
  Switch,
  LinearProgress,
  FormControlLabel,
} from "@material-ui/core";

import { ReactComponent as DoubleArrow } from "static/media/doublePlayArrow.svg";
import { useStyles } from "./styles";
import { ReactComponent as RunningIcon } from "static/media/runningIcon.svg";

import PageTitle from "components/Common/PageTitle";
import getLogs from "utils/getLogs";
import { PhantomLogs } from "../../Components/HistoryConsole";
import { SendButton } from "components/styled/Common";
import { EditIcon } from "components/icons/actionIcons";
import { ReactComponent as PlayIcon } from "static/media/whitePlay.svg";
import { firestore } from "firebase-tools/firebase";
import { SocialMediaPlatform } from "containers/BeAmplified/Campaigns/Organic/SelectCampaign";
import { getPlatformImage } from "../../Components/SelectCampaignBox";

interface ICampaign {
  campaign: any;
  platform?: SocialMediaPlatform;
}

const BorderLinearProgress = withStyles({
  root: {
    height: 6,
    borderRadius: 7,
    "&:hover": {
      opacity: 0.5,
    },
  },
  colorPrimary: {
    background: "#F2F2F2",
  },
  bar: {
    borderRadius: 7,
    background: "#598CE1",
    animationDuration: "8s",
  },
})(LinearProgress);

const AutoDmPage: FC<ICampaign> = ({ campaign, platform }) => {
  const classes = useStyles({});
  const [showConsole, setShowConsole] = useState(true);
  const [logs, setLogs] = useState("");

  const toggle = async () => {
    return setShowConsole(!showConsole);
  };

  useEffect(() => {
    (async () => {
      let logs = await getLogs(
        campaign.containerId || campaign.messageContainer
      );

      if (!logs.output) {
        await new Promise((resolve) => setTimeout(resolve, 10000));
        logs = await getLogs(campaign.containerId || campaign.messageContainer);
      }

      setLogs(logs.output);
    })();

    // eslint-disable-next-line
  }, [campaign.containerId, campaign.messageContainer]);

  const updateCampaignStatus = async (status: string) => {
    await firestore
      .collection(
        platform?.toLocaleLowerCase() ===
          SocialMediaPlatform.Facebook.toLocaleLowerCase()
          ? "facebook-auto-dm"
          : "twitter-auto-dm"
      )
      .doc(campaign.id)
      .update({
        status: status,
      });
  };

  return (
    <div className="root">
      <div style={{ marginTop: 40 }}>
        <button
          onClick={() =>
            navigate(
              `/campaigns/organic${
                campaign.campaignType.includes("-scrapper")
                  ? "?flow=scrappers"
                  : ""
              }`
            )
          }
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            fontSize: "18px",
            fontWeight: 600,
            color: "#000000",
            fontFamily: "'Avenir Book', sans-serif",
            border: "none",
            cursor: "pointer",
          }}
        >
          <ArrowBackIcon />
          Back to all campaigns
        </button>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          height="120px"
        >
          <PageTitle
            title={
              <div className={classes.itemsCenter}>
                <span className={classes.itemsCenter}>
                  <img
                    src={getPlatformImage(platform)}
                    alt=""
                    className={classes.mainIcon}
                  />{" "}
                  <DoubleArrow />
                </span>{" "}
                {campaign.campaignName}
              </div>
            }
          />

          <div>
            {campaign.status === "running" && (
              <SendButton
                className={classes.editButton}
                onClick={() => updateCampaignStatus("paused")}
                style={{ marginRight: 18 }}
              >
                <EditIcon color="#fff" />
                <span style={{ marginLeft: 10 }}>Pause Campaign</span>
              </SendButton>
            )}

            {campaign.status === "paused" && (
              <SendButton
                className={classes.editButton}
                onClick={() => updateCampaignStatus("running")}
                style={{ marginRight: 18 }}
              >
                <PlayIcon color="#fff" />
                <span style={{ marginLeft: 10 }}>Resume Campaign</span>
              </SendButton>
            )}

            <SendButton
              className={classes.editButton}
              onClick={() =>
                navigate(
                  `/campaigns/organic/create/${platform}/auto-dm/${campaign.id}/edit`
                )
              }
            >
              <EditIcon color="#fff" />
              <span style={{ marginLeft: 10 }}>Edit Campaign</span>
            </SendButton>
          </div>
        </Box>
      </div>

      <div className={`${classes.progress}`}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <div style={{ width: "75%" }}>
            <div style={{ marginBottom: 10 }} className={classes.label}>
              {`${platform}`} auto Dm
            </div>
            {campaign.status === "running" ? (
              <BorderLinearProgress variant="indeterminate" />
            ) : (
              <BorderLinearProgress variant="determinate" value={100} />
            )}

            {campaign.status === "running" && (
              <div className={classes.running}>
                <RunningIcon style={{ marginRight: 10 }} /> Running{" "}
              </div>
            )}
          </div>

          <div>
            <div className={classes.toggleLabel}>
              {showConsole ? "Hide console" : "Show Console"}
            </div>
            <FormControlLabel
              control={
                <Switch
                  checked={showConsole}
                  onChange={toggle}
                  name="Show console"
                  classes={{
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                  }}
                />
              }
              label=""
            />
          </div>
        </Box>
        <div>
          {showConsole && (
            <div className={classes.console}>
              {!campaign.history && !logs && (
                <PhantomLogs type="info" message="No Logs available" />
              )}
              {logs && <PhantomLogs type="info" message={logs} logs />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AutoDmPage;
