import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStyles } from "./styles";

export const Loader = () => {
  const classes = useStyles({});
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={4}>
          <div className={classes.loaderContainer} id="loader">
            <CircularProgress
              style={{
                marginRight: "10px",
                color: "#1D4E89",
              }}
            />
            <div>Loading ...</div>
          </div>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};
