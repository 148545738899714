export const handleNormalMode = (
  selectedStats,
  fbSelectedLen,
  igSelectedLen,
  twitterSelectedLen,
  tikTokSelectedLen,
  youTubeSelectedLen,
  spotifySelectedLen,
  appleMusicSelectedLen,
  setIsPrintMode,
  isPrintMode
): void => {
  // Un hide header
  const header: any = document.getElementById("header");
  header.style.display = "block";

  // Un hide date picker
  const date: any = document.getElementById("date-picker");
  date.style.display = "flex";

  // Un hide date client List
  const clientList: any = document.getElementById("client-list");
  clientList && (clientList.style.display = "block");

  // Un Hide PublicLink
  const PublicLink: any = document.getElementById("public-link");
  PublicLink.style.display = "block";

  // Un all hide twitter stats
  const igSelect: any = document.getElementById("ig-select");
  igSelect && (igSelect.style.display = "block");

  // Un hide un selected fb stats
  if (selectedStats.fbStats.selected.length < fbSelectedLen) {
    selectedStats.fbStats.selected.forEach((sectionId) => {
      const section: any = document.getElementById(sectionId);
      section && (section.style.display = "block");
    });
  } else {
    const section: any = document.getElementById("fb-stats");
    section && (section.style.display = "block");
  }

  // Un hide un selected ig stats
  if (selectedStats.igStats.selected.length < igSelectedLen) {
    selectedStats.igStats.selected.forEach((sectionId) => {
      const section: any = document.getElementById(sectionId);
      section && (section.style.display = "block");
    });
  } else {
    const section: any = document.getElementById("ig-stats");
    section && (section.style.display = "block");
  }

  // Un hide un selected twitter stats
  if (selectedStats.twitterStats.selected.length < twitterSelectedLen) {
    selectedStats.twitterStats.selected.forEach((sectionId) => {
      const section: any = document.getElementById(sectionId);
      section && (section.style.display = "block");
    });
  } else {
    const section: any = document.getElementById("twitter-stats");
    section && (section.style.display = "block");
  }

  // Un hide un selected tikTok stats
  if (selectedStats.tikTokStats.selected.length < tikTokSelectedLen) {
    selectedStats.tikTokStats.selected.forEach((sectionId) => {
      const section: any = document.getElementById(sectionId);
      section && (section.style.display = "block");
    });
  } else {
    const section: any = document.getElementById("tiktok-stats");
    section && (section.style.display = "block");
  }

  // Un hide un selected youTube stats
  if (selectedStats.youTubeStats.selected.length < youTubeSelectedLen) {
    selectedStats.youTubeStats.selected.forEach((sectionId) => {
      const section: any = document.getElementById(sectionId);
      section && (section.style.display = "block");
    });
  } else {
    const section: any = document.getElementById("youtube-stats");
    section && (section.style.display = "block");
  }

  // Un hide un selected spotify stats
  if (selectedStats.spotifyStats.selected.length < spotifySelectedLen) {
    selectedStats.spotifyStats.selected.forEach((sectionId) => {
      const section: any = document.getElementById(sectionId);
      section && (section.style.display = "block");
    });
  } else {
    const section: any = document.getElementById("spotify-stats");
    section && (section.style.display = "block");
  }

  // Un hide un selected apple music stats
  if (selectedStats.appleMusicStats.selected.length < appleMusicSelectedLen) {
    selectedStats.appleMusicStats.selected.forEach((sectionId) => {
      const section: any = document.getElementById(sectionId);
      section && (section.style.display = "block");
    });
  } else {
    const section: any = document.getElementById("apple-music-stats");
    section && (section.style.display = "block");
  }

  setIsPrintMode(!isPrintMode);
};
