import { DateTime } from "luxon";
import moment from "moment";

import { getScreenshot } from "utils/getScreenshot";

export const formatGraphicsBrief = (post) => {
  let brief = post.graphicsBrief;
  brief = brief.replaceAll("<div>", "");

  let content: any[] = brief.split("</div>");
  let index = 0;
  let arr = [""];

  for (let i = 0; i < content.length; i++) {
    const element = content[i];
    if (i !== index && element?.includes(":")) {
      index = i;
      arr.push(element);
    }
    const arrIndex = arr.length - 1;

    if (i === index && element?.includes(":")) {
      arr[arrIndex] = element;
    } else {
      arr[arrIndex] = arr[arrIndex].concat(" ", element);
    }
  }

  content = arr.map((val) => {
    const br = val.split(":");

    if (br.length > 1 && br[1]?.trim() !== "") {
      const keyWord = br.shift();
      return `<div>${keyWord}: ${br.join("")}</div>`;
    }
    return null;
  });

  const contentLen = content.join("").length;

  if (content.filter((val) => val).length) {
    return contentLen ? content.join("") : post.graphicsBrief || "";
  } else {
    return false;
  }
};

export const getPostDate = (dateInSeconds) => {
  let day: any = DateTime.fromSeconds(dateInSeconds).toJSDate();

  day = moment(day).format("ddd").split(" ")[0];

  return DateTime.fromSeconds(dateInSeconds).toFormat(
    `'${day},' dd LLL yyyy 'at' HH:mm`
  );
};

export const generateScreenshot = (post, images, uuid) => {
  const socialPost: any = document.getElementById(`post-${uuid}`);

  if (socialPost) {
    socialPost.style.border = "solid 1px #D8D8D8";

    const carousel: any = document.getElementById("carousel");
    const staticImage: any = document.getElementById("image-placeholder");

    if (!!post.video_url?.length && !!images?.length) {
      carousel.style.display = "none";
      staticImage.style.display = "flex";
    }

    const name = `Be-Planned_date_${getPostDate(post.date.seconds)}`;

    getScreenshot(`post-${uuid}`, name);

    socialPost.style.border = "none";
    if (!!post.video_url?.length && !!images?.length) {
      carousel.style.display = "block";
      staticImage.style.display = "none";
    }
  } else {
    console.error(`Element with ID 'post-${uuid}' not found.`);
  }
};

const getImages = (posts: any[]) => {
  let images: any[] = [];
  for (let post of posts) {
    if (post.image_url) {
      // images = [post.image_url];
      images.push(post.image_url);
    }
  }
  return images;
};

export const generateScreenshots = (posts) => {
  let images = getImages(posts);
  console.log("testing posts", images, posts.length, posts);
  posts.forEach((post) => {
    let uid = post.uuid;
    generateScreenshot(uid, post, images);
  });
};

export const extractCategoriesValues = (obj) => {
  let allValues = [];
  for (let key in obj) {
    allValues = allValues.concat(obj[key]);
  }
  return allValues;
};

export const filterCategoriesByChannels = (
  selectedChannels: string[],
  selectedCategories: string[]
) => {
  let items = selectedCategories;
  if (
    !selectedChannels.map((channel) => channel.toLowerCase()).includes("tiktok")
  ) {
    items = items.filter((cat) => !cat.toLowerCase().includes("tiktok"));
  }

  if (!selectedChannels.includes("instagram")) {
    items = items.filter((cat) => !cat.toLowerCase().includes("instagram"));
  }
  if (
    !selectedChannels
      .map((channel) => channel.toLowerCase())
      .includes("facebook")
  ) {
    items = items.filter((cat) => !cat.toLowerCase().includes("facebook"));
  }
  if (
    !selectedChannels
      .map((channel) => channel.toLowerCase())
      .includes("twitter")
  ) {
    items = items.filter((cat) => !cat.toLowerCase().includes("twitter"));
  }
  if (
    !selectedChannels
      .map((channel) => channel.toLowerCase())
      .includes("youtube")
  ) {
    items = items.filter((cat) => !cat.toLowerCase().includes("youtube"));
  }
  if (
    !selectedChannels
      .map((channel) => channel.toLowerCase())
      .includes("snapchat")
  ) {
    items = items.filter((cat) => !cat.toLowerCase().includes("snapchat"));
  }
  if (
    !selectedChannels
      .map((channel) => channel.toLowerCase())
      .includes("threads")
  ) {
    items = items.filter((cat) => !cat.toLowerCase().includes("threads"));
  }
  if (
    !selectedChannels
      .map((channel) => channel.toLowerCase())
      .includes("discord")
  ) {
    items = items.filter((cat) => !cat.toLowerCase().includes("discord"));
  }

  return items;
};

export const handleChannelsChange = (selectedChannels, category) => {
  const filteredCategory = { ...category };

  const channels = [
    "tiktok",
    "instagram",
    "facebook",
    "twitter",
    "youtube",
    "snapchat",
    "threads",
    "discord",
  ];

  channels.forEach((channel) => {
    if (!selectedChannels.includes(channel)) {
      filteredCategory[channel] = [];
    }
  });

  return filteredCategory;
};
export const handleCategoriesChange = (categories: string[]) => {
  const filteredCategories: Record<string, string[]> = {
    tiktok: [],
    instagram: [],
    facebook: [],
    twitter: [],
    youtube: [],
    snapchat: [],
    threads: [],
    discord: [],
  };

  categories.forEach((category) => {
    const channel = channels.find((c) => category.toLowerCase().includes(c));
    if (channel) filteredCategories[channel].push(category);
  });

  return filteredCategories;
};

const channels = [
  "tiktok",
  "instagram",
  "facebook",
  "twitter",
  "youtube",
  "snapchat",
  "threads",
  "discord",
] as const;
